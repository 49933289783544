import React, { useEffect, useState } from 'react'
import DynamicTable from '../../components/DynamicTable'
import CssBaseline from '@material-ui/core/CssBaseline'
import { API } from 'aws-amplify';
import DForm from '../../components/DForm';
import ConfirmationModal from '../../components/ConfirmationModal';
import useUIState from '../../hooks/useUIstate';
import { promotionsFormToReqBody } from './PromotionsController';
import { Promotion } from '../../interfaces/IPromotions';
import { s3put, s3remove, getZeroOnEmpty } from '../../helpers/commonFunctions';

function ProductCategoriesTable(props) {
    const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();

    const [data, setData]: [any[], any] = useState([]);
    const [filteredData, setFilteredData]: [any[], any] = useState([]);
    const [ShowFilteredData, setShowFilteredData] = useState(false);
    const [showForm, setShowForm]: [boolean, any] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal]: [boolean, any] = useState(false);
    const [formValues, setFormValues]: [any, any] = useState({});
    const [activeRow, setActiveRow]: [any, any] = useState(null);
    const [formMode, setFormMode]: [string, any] = useState("");
    const [formObj, setFormObj]: [Record<string, any>[], any] = useState([]);
    const [ProductOptions, setProductOptions]: [any, any] = useState([]);
    const [UserConfigData, setUserConfigData]: [any, any] = useState([]);

    useEffect(() => {
        const value = localStorage.getItem("UserConfigData");
        if (typeof value === 'string') {
            setUserConfigData(JSON.parse(value));
        }

        getPromotions();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (props.products.length) {
            createProductOptions(props.products);
        }
    }, [props.products])

    function createProductOptions(products) {
        const options: any[] = [{ label: "-- Select product --", value: " " }];
        products.forEach(product => {
            options.push({
                label: product.Title,
                value: product.Id.toString(),
            })
        });
        setProductOptions(options);
    }

    function getPromotions() {
        setShowLoadingModal(true);
        API.get("AE", `/Offers`, { response: true })
            .then(res => {
                let data = res.data.length ? res.data.filter(el => el.IsChallengeSpendingPromotion === false) : res.data;
                setData(data);
                if (data.length) {
                    renderData(data);
                }
            })
            .catch(error => {
                console.log(error);
                setToastType("error");
                setToastMessage("Error getting promotions");
                setShowToastMessage(true);
            })
    }

    async function renderData(data) { // corresponds with List<OfferDto>

        const promotionsRender: any[] = [];

        data.forEach(p => {
            // Datetime objects
            let startDate = new Date(p.StartDate);
            let endDate = new Date(p.EndDate);
            let currDate = new Date();

            let promotionTimeFrame = "";
            if (currDate < startDate) { promotionTimeFrame = "Toekomst"; }
            if (currDate >= startDate && currDate < endDate) { promotionTimeFrame = "Huidige" }

            // Display dates
            let displayStartDate = p.StartDate.split("T")[0];
            displayStartDate = displayStartDate.split("-");
            displayStartDate = `${displayStartDate[2]}/${displayStartDate[1]}/${displayStartDate[0]}`

            let displayEndDate = p.EndDate.split("T")[0];
            displayEndDate = displayEndDate.split("-");
            displayEndDate = `${displayEndDate[2]}/${displayEndDate[1]}/${displayEndDate[0]}`

            let product1Name = "", product2Name = "", product1 = "", product2 = "", product1Id = " ", product2Id = " ";
            let productDiscount = "", productFixedPrice = "", offerSameProductUnits = "";

            let combinedPrice = 0;
            let newPrice;

            if (p.ProductsInOffer.length) {
                product1 = p.ProductsInOffer[0] ? p.ProductsInOffer[0].Product : "";
                product2 = p.ProductsInOffer[1] ? p.ProductsInOffer[1].Product : "";
                product1Name = p.ProductsInOffer[0] ? p.ProductsInOffer[0].Product.Title : "";
                product2Name = p.ProductsInOffer[1] ? p.ProductsInOffer[1].Product.Title : "";
                product1Id = p.ProductsInOffer[0] ? p.ProductsInOffer[0].ProductId.toString() : " ";
                product2Id = p.ProductsInOffer[1] ? p.ProductsInOffer[1].ProductId.toString() : " ";
                productDiscount = p.ProductsInOffer[0] ? p.ProductsInOffer[0].ProductDiscount : "";
                productFixedPrice = p.ProductsInOffer[0] ? p.ProductsInOffer[0].ProductFixedPrice : "";
                offerSameProductUnits = p.ProductsInOffer[0] ? p.ProductsInOffer[0].OfferSameProductUnits : "";

                if (p.ProductsInOffer.length) {
                    p.ProductsInOffer.length === 1 ?
                        combinedPrice = p.ProductsInOffer[0].Product.DefaultPrice :
                        combinedPrice = p.ProductsInOffer[0].Product.DefaultPrice + p.ProductsInOffer[1].Product.DefaultPrice

                    // calculate n+1 Offers
                    if (p.ProductsInOffer[0].OfferSameProductUnits > 0) {
                        combinedPrice = combinedPrice * (p.ProductsInOffer[0].OfferSameProductUnits + 1);
                    }

                    // calculate based on fixed price
                    if (p.ProductsInOffer[0].ProductFixedPrice > 0) {
                        newPrice = p.ProductsInOffer[0].ProductFixedPrice;
                    } else {
                        newPrice = combinedPrice - (combinedPrice * p.ProductsInOffer[0].ProductDiscount);
                    }
                    newPrice = parseFloat(newPrice.toFixed(2));
                }
            }

            promotionsRender.push({
                Id: p.Id,
                Title: p.Title,
                Description: p.Description,
                Avatar: p.Avatar ? p.Avatar : " ",
                StartDate: p.StartDate,
                EndDate: p.EndDate,
                DisplayStartDate: displayStartDate,
                DisplayEndDate: displayEndDate,
                IsCombination: p.IsCombination ? "Yes" : "No",
                PromotionTimeFrame: promotionTimeFrame,
                Product1: product1,
                Product2: product2,
                Product1Name: product1Name,
                Product2Name: product2Name,
                Product1Id: product1Id,
                Product2Id: product2Id,
                ProductDiscount: productDiscount,
                ProductFixedPrice: productFixedPrice,
                OfferSameProductUnits: offerSameProductUnits,
                NewPrice: newPrice ? newPrice.toFixed(2).replace(".", ",") : 0,
                StandardPrice: combinedPrice.toFixed(2).replace(".", ","),
                IsBarcodeScanPromotion: p.IsBarcodeScanPromotion ? 'Yes' : 'No'
            })
        });
        setShowFilteredData(false);
        setData(promotionsRender);
        setShowLoadingModal(false);
    }

    async function createOrUpdatePromotion(reqBody: Promotion) {
        let path = '/Offers';
        let body: Promotion = reqBody
        if (reqBody.Id) { body.Id = reqBody.Id };
        let res;
        if (reqBody.Id) {
            res = await API.put('AE', path, { body });
        } else {
            res = await API.post('AE', path, { body });
        }
        return res;
    }

    async function deleteEntry() {
        let path = '/Offers';
        let myInit = {}
        try {
            setToastType("success");
            setToastMessage("Promotion deleted successfully!");
            setShowToastMessage(true);
            return await API.del('AE', path + "?offerId=" + activeRow.original.Id, myInit);
        } catch (err) {
            console.log(err);
            setToastType("error");
            setToastMessage("Error deleting Category");
            setShowToastMessage(true);
            return null;
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Naam',
                accessor: 'Title',
                hide: false,
            },
            {
                Header: 'Description',
                accessor: 'Description',
                hide: true,
            },
            {
                Header: 'Avatar',
                accessor: 'Avatar',
                hide: true,
            },
            {
                Header: 'StartDate',
                accessor: 'StartDate',
                hide: true,
            },
            {
                Header: 'EndDate',
                accessor: 'EndDate',
                hide: true,
            },
            {
                Header: 'Begindatum',
                accessor: 'DisplayStartDate',
                hide: false,
            },
            {
                Header: 'Einddatum',
                accessor: 'DisplayEndDate',
                hide: false,
            },
            {
                Header: 'Is gecombineerd?',
                accessor: 'IsCombination',
                hide: false,
            },
            {
                Header: 'Actieperiode',
                accessor: 'PromotionTimeFrame',
                hide: false,
            },
            {
                Header: 'Product1',
                accessor: 'Product1',
                hide: true
            },
            {
                Header: 'Product2',
                accessor: 'Product2',
                hide: true
            },
            {
                Header: 'Product1Id',
                accessor: 'Product1Id',
                hide: true
            },
            {
                Header: 'Product2Id',
                accessor: 'Product2Id',
                hide: true
            },
            {
                Header: 'Product',
                accessor: 'Product1Name',
                hide: false,
            },
            {
                Header: 'Combi Product',
                accessor: 'Product2Name',
                hide: false,
            },
            {
                Header: '+1 Offer',
                accessor: 'OfferSameProductUnits',
                hide: false,
            },
            {
                Header: 'Standaard prijs (€)',
                accessor: 'StandardPrice',
                hide: false,
            },
            {
                Header: 'Nieuwe prijs (€)',
                accessor: 'NewPrice',
                hide: false,
            },
            {
                Header: 'QR Code',
                accessor: 'Id',
                hide: true,
            },
            {
                Header: 'QR Code aanbieding',
                accessor: 'IsBarcodeScanPromotion',
                hide: true,
            },
        ],
        []
    );

    async function prepForm(row) {

        const rowValues = row !== null ? row.original : null;
        const addingRow = rowValues === null;

        const _formValues = {
            Id: addingRow ? 0 : rowValues.Id,
            Title: addingRow ? "" : rowValues.Title,
            Description: addingRow ? "" : rowValues.Description,
            StartDate: addingRow ? new Date() : rowValues.StartDate,
            EndDate: addingRow ? new Date() : rowValues.EndDate,
            IsCombination: addingRow ? false : rowValues.IsCombination === "Yes" ? true : false,
            Avatar: addingRow ? "" : rowValues.Avatar,
            Product1Id: addingRow ? " " : rowValues.Product1Id,
            Product2Id: addingRow ? " " : rowValues.Product2Id,
            ProductDiscount: addingRow ? 0 : rowValues.ProductDiscount * 100,
            ProductFixedPrice: addingRow ? 0 : rowValues.ProductFixedPrice,
            OfferSameProductUnits: addingRow ? 0 : rowValues.OfferSameProductUnits,
            IsBarcodeScanPromotion: addingRow ? false : rowValues.IsBarcodeScanPromotion === "Yes" ? true : false
        };

        const formObj = [
            {
                name: "Title",
                type: "text",
                label: "Naam",
                required: true,
                initialValue: _formValues.Title
            },
            {
                name: "Description",
                type: "text",
                label: "Beschrijving",
                initialValue: _formValues.Description
            },
            {
                name: "Product1Id",
                type: "select",
                label: "Product1",
                required: true,
                initialValue: _formValues.Product1Id,
                options: ProductOptions
            },
            {
                name: "Product2Id",
                type: "select",
                label: "Combi Product",
                initialValue: _formValues.Product2Id,
                options: ProductOptions
            },
            {
                name: "OfferSameProductUnits",
                type: "number",
                label: "+1 Offer",
                initialValue: _formValues.OfferSameProductUnits
            },
            {
                name: "ProductDiscount",
                type: "number",
                label: "Korting %",
                initialValue: _formValues.ProductDiscount
            },
            {
                name: "ProductFixedPrice",
                type: "number",
                label: "Nieuwe prijs",
                initialValue: _formValues.ProductFixedPrice
            },
            {
                name: "StartDate",
                type: "date",
                label: "Begindatum",
                initialValue: _formValues.StartDate
            },
            {
                name: "EndDate",
                type: "date",
                label: "Einddatum",
                initialValue: _formValues.EndDate
            },
            {
                name: "Avatar",
                type: "file",
                label: "Selecteer",
                objUrl: addingRow ? "" : rowValues.Avatar.trim() ? UserConfigData.Customer + '/images/promotions/pid' + rowValues.Id + "_" + rowValues.Avatar : "",
                maxHeight: 320,
                maxWidth: 320
            },
        ];

        const enrichedFormObj = enrichFeatureFilteredColumnsForForm(formObj, _formValues, addingRow, rowValues);

        setFormObj(enrichedFormObj);
        setActiveRow(rowValues);
        setFormMode(rowValues === null ? "Add" : "Edit");
        setFormValues(_formValues);
        setShowForm(true);
    }

    async function submit(values) {
        if (typeof values.ProductDiscount === "string") { values.ProductDiscount.trim() }
        if (typeof values.ProductFixedPrice === "string") { values.ProductFixedPrice.trim() }
        if (typeof values.OfferSameProductUnits === "string") { values.OfferSameProductUnits.trim() }

        // replace '' with 0 for number values to avoid pasring errors in the controller
        values.MinimumSpending = 0;
        values.VisibilitySpendingThreshold = 0;
        values.FixedDiscount = 0;
        values.PercentageDiscount = 0;
        values.OfferSameProductUnits = getZeroOnEmpty(values.OfferSameProductUnits);
        values.ProductDiscount = getZeroOnEmpty(values.ProductDiscount);
        values.ProductFixedPrice = getZeroOnEmpty(values.ProductFixedPrice);

        let validationResult = validateForm(values);

        if (validationResult.valid) {
            setShowLoadingModal(true);
            const reqBody = promotionsFormToReqBody(activeRow, values);

            setShowForm(false);
            const res = await createOrUpdatePromotion(reqBody);
            if (res) {
                setToastType("success");
                setToastMessage(`Promotion ${activeRow === null ? "added" : "edited"} successfully!`);
                setShowToastMessage(true);
                getPromotions();
            } else {
                setToastType("error");
                setToastMessage(`Error ${activeRow === null ? "adding" : "editing"} Promotion`);
                setShowToastMessage(true);
            }

            let putResp;
            let imageChange;

            if (activeRow) {
                if (values.Avatar !== activeRow.Avatar) {
                    imageChange = true;
                    putResp = await s3put(`${UserConfigData.Customer}/images/promotions/pid${res.Id}_${reqBody.Avatar}`, values.Avatar);
                }
            } else {
                imageChange = true;
                putResp = await s3put(`${UserConfigData.Customer}/images/promotions/pid${res.Id}_${reqBody.Avatar}`, values.Avatar);
            }

            if (!putResp && imageChange) {
                setToastType("error");
                setToastMessage("Image upload failed");
                setShowToastMessage(true);
            }

            setShowLoadingModal(false);
        } else {
            setToastType("error");
            setToastMessage(validationResult.message);
            setShowToastMessage(true);
        }
    }

    function validateForm(values) {
        var result = {
            valid: false,
            message: ''
        }

        let isCombi = values.Product2Id !== " ";

        // VALIDATIONS
        //if (!values.isCombination) {
        if (!isCombi) {
            if (values.Product1Id === " ") {
                result.message = "Er is geen product geselecteerd";
                return result;
            }
            if (values.ProductDiscount <= 0 && values.ProductFixedPrice <= 0) {
                result.message = "Korting % of Nieuwe prijs of moet ingevuld zijn";
                return result;
            }
            if (!IsEmpty(values.Product2Id)) {
                result.message = "Combi Product is niet van toepassing";
                return result;
            }
        }

        if (isCombi) {
            // combi product 
            if (values.Product1Id === " ") {
                result.message = "Product 1 is niet geselecteerd";
                return result;
            }
            if (values.Product2Id === " ") {
                result.message = "Combi product is niet geselecteerd";
                return result;
            }
            if (values.Product1Id === values.Product2Id) {
                result.message = "Combi producten moeten verschillen van elkaar";
                return result;
            }
            if (values.ProductDiscount <= 0 && values.ProductFixedPrice <= 0) {
                result.message = "Nieuwe prijs of Korting % moet ingevuld zijn";
                return result;
            }
        }

        if (values.ProductDiscount > 0 && values.ProductFixedPrice > 0) {
            result.message = "Korting % en Nieuwe prijs kunnen niet beide gevuld zijn";
            return result;
        }

        if (values.OfferSameProductUnits > 0 && values.Product2Id !== " ") {
            result.message = "Product combinaties kunnen niet gebruikt worden in +1 offeraanbiedingen";
            return result;
        }

        // check if a promotion for the product exists
        if (!isCombi) {
            data.forEach(offer => {
                let checkForDuplicate = false;
                if (offer.IsCombination === "No") {
                    if (activeRow) {
                        if (activeRow.Id !== offer.Id) {
                            checkForDuplicate = true;
                        }
                    } else {
                        checkForDuplicate = true;
                    }

                    if (checkForDuplicate) {
                        if (parseInt(values.Product1Id, 10) === offer.Product1.Id) {
                            // check for overlapping date

                            let startTime = new Date(offer.StartDate).getTime();
                            let endTime = new Date(offer.EndDate).getTime();

                            let formStartTime = new Date(values.StartDate).getTime();
                            let formEndTime = new Date(values.EndDate).getTime();

                            if (
                                (formStartTime >= startTime && formStartTime <= endTime) ||
                                (formEndTime >= startTime && formEndTime <= endTime)
                            ) {
                                result.message = "Aanbieding bestaat al voor dit product";
                                return result;
                            }
                        }
                    }

                }
            });
        }

        if (new Date(values.StartDate) >= new Date(values.EndDate)) {
            result.message = 'Einddatum moet groter zijn dan de begindatum'
            return result;
        }

        if (!values.Avatar || values.Avatar === " ") {
            result.message = "Een afbeelding voor de aanbieding is verplicht";
            return result;
        }

        result.valid = true;

        return result;
    }

    function IsEmpty(value) {
        return value == null || value.trim() === "";
    }

    function handleDeleteConfirmation(row) {
        setActiveRow(row);
        setShowConfirmationModal(true);
    }

    async function confirmDelete() {
        setShowLoadingModal(true);
        setShowConfirmationModal(false);
        const deleted = await deleteEntry();
        if (deleted) {
            if (activeRow.original.Avatar.trim()) {
                const filePath = `${UserConfigData.Customer}/images/promotions/pid${activeRow.original.Id}_${activeRow.original.Avatar}`
                const res = await s3remove(filePath);
                if (!res) {
                    setToastType("error");
                    setToastMessage("Image delete failed");
                    setShowToastMessage(true);
                }
            }
        }
        getPromotions();
    }

    function filterPromotions(timeFrame: "current" | "future", mode: "single" | "combi") {
        let filteredData;
        let currDate = new Date();

        if (timeFrame === "current") {
            filteredData = data.filter(el => currDate >= new Date(el.StartDate) && currDate < new Date(el.EndDate));
        }
        if (timeFrame === "future") {
            filteredData = data.filter(el => new Date(el.StartDate) > currDate);
        }
        if (mode === "single") {
            filteredData = filteredData.filter(el => el.IsCombination === "No");
        }
        else if (mode === "combi") {
            filteredData = filteredData.filter(el => el.IsCombination === "Yes");
        }
        setFilteredData(filteredData)
        setShowFilteredData(true);
    }

    // FEATURE Specific functions
    function getFeatureFilteredColumns() {
        if (UserConfigData && UserConfigData.EnabledFeatures) {
            if (UserConfigData.EnabledFeatures.includes('barcodescanpromotion')) {
                // unhide columns for barcodescan feature 
                return columns.map(column => column.accessor === "IsBarcodeScanPromotion" ? ({ ...column, hide: false }) : ({ ...column }));
            }
        }
        return columns;
    }

    function enrichFeatureFilteredColumnsForForm(formObj, formValues, addingRow, rowValues) {
        const formObjResponse = formObj;

        if (UserConfigData && UserConfigData.EnabledFeatures) {
            if (UserConfigData.EnabledFeatures.includes('barcodescanpromotion')) {
                if (rowValues && rowValues.IsBarcodeScanPromotion === 'Yes') {
                    formObjResponse.push({
                        name: "Id",
                        type: "qrcode",
                        label: "Id",
                        initialValue: formValues.Id
                    });
                }
                formObjResponse.push({
                    name: "IsBarcodeScanPromotion",
                    type: "checkbox",
                    label: "Is QR Code aanbieding?",
                    checked: addingRow ? false : rowValues.IsBarcodeScanPromotion === 'Yes' ? true : false
                });

                return formObjResponse;
            }
        }
        return formObjResponse;
    }

    return (
        <div>
            <h2 className="mb-05">Aanbiedingen</h2>
            <div className="table-filters">
                <label>Filters: </label>
                <button onClick={() => { getPromotions() }}>Alles weergeven</button>
                <button onClick={() => { filterPromotions("current", "single") }}>Huidige korting deals</button>
                <button onClick={() => { filterPromotions("current", "combi") }}>Huidige combi deals</button>
                <button onClick={() => { filterPromotions("future", "single") }}>Toekomstige korting deals</button>
                <button onClick={() => { filterPromotions("future", "combi") }}>Toekomstige combi deals</button>
            </div>
            <CssBaseline />
            {UserConfigData.Customer ?
                <DynamicTable
                    addRow={() => prepForm(null)}
                    editRow={(row) => prepForm(row)}
                    deleteRow={(row) => handleDeleteConfirmation(row)}
                    columns={getFeatureFilteredColumns()}
                    data={ShowFilteredData ? filteredData : data}
                    loading={false}
                    className="Promotions"
                />
                : null
            }
            {
                showForm ?
                    <DForm
                        formObj={formObj}
                        formMode={formMode}
                        initialValues={formValues}
                        qrcodeType={'promotion_'}
                        closeModal={() => setShowForm(false)}
                        submit={submit}
                    />
                    : null
            }
            {showConfirmationModal ?
                <ConfirmationModal
                    message={"Aanbieding verwijderen?"}
                    confirm={confirmDelete}
                    closeModal={() => setShowConfirmationModal(false)}
                />
                : null}
            <div className="mt1" />
        </div>
    )
}

export default ProductCategoriesTable;
