import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { MdCancel } from 'react-icons/md';
import { Button } from '@material-ui/core';
import awsconfig from '../aws-apiSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    display: "flex",
    flexFlow: "column",
    width: "350px",
    minWidth: "350px",
    height: "100%",
    background: "#eee",
    zIndex: 1,
    padding: "20px",
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.75)",
    borderTopLeftRadius: "5%",
    borderBottomLeftRadius: "5%",

    '& #panelContent': {
      flex: "1 1 auto",
      display: "flex",
      flexFlow: "column",
      position: "relative"
    },
    '& .panel-header': {
      display: "flex",
      justifyContent: "space-between"
    },
    '& .pCat-panel': {
      marginTop: "10px",
      display: "flex",
      flexWrap: "wrap"
    },
    '& .pCat-item': {
      background: "#ddd",
      fontWeight: 700,
      padding: "5px 10px",
      marginRight: "10px",
      marginTop: "10px",
      cursor: "pointer"
    },
    '& .pCat-item:hover': {
      background: "#e1e1e1",
    },
    '& .pCat-item.selected': {
      background: "#689f38",
      boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.75)",
    },
    '& .panel-products-container': {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "10px",
      maxHeight: "475px",
      overflowY: "auto"
    },
    '& .panel-product': {
      background: "#fff",
      width: "130px",
      height: "200px",
      padding: "10px",
      marginRight: "10px",
      marginTop: "10px",
      cursor: "pointer",
      borderRadius: "4px"
    },
    '& .panel-product:hover': {
      border: "2px solid #cccccc",
    },
    '& .panel-product.selected': {
      border: "2px solid #689f38",
    },
    '& .panel-product-title': {
      fontWeight: 700,
      marginBottom: 0
    },
    '& .panel-product-weight': {
      fontSize: "12px"
    },
    '& .image-container': {
      width: "100px",
      height: "90px"
    },
    '& .image-container img': {
      width: "100%",
    },
    '& .product-panel-action': {
      position: "absolute",
      bottom: 0,
      left: 0
    },

  }
}));

function ProductPanel(props) {
  const classes = useStyles();

  const [LockersSelected, setLockersSelected] = useState(0);
  const [ProductSelected, setProductSelected] = useState(0);
  const [ProductCategorySelected, setProductCategorySelected]: [any, any] = useState(null);
  const [FilteredProducts, setFilteredProducts]: [any, any] = useState([]);
  const [Products, setProducts]: [any, any] = useState([]);
  const [UserConfigData, setUserConfigData]: [any, any] = useState([]);

  useEffect(() => {
    setLockersSelected(props.lockersSelected.length)
  }, [props.lockersSelected])

  useEffect(() => {
    if (props.products.length) {
      let enabledProducts: any[] = [];
      enabledProducts = props.products.filter(p => JSON.parse(p.Attributes.replace(/'/g, '"')).Enabled);
      setProducts(enabledProducts);
    }
  }, [props.products])

  useEffect(() => {
    if (ProductCategorySelected) {
      const filteredProducts: any[] = [];
      Products.forEach(p => {
        if (p.ProductCategoryId === ProductCategorySelected) {
          filteredProducts.push(p)
        }
      });
      setFilteredProducts(filteredProducts);
    }
    // eslint-disable-next-line
  }, [ProductCategorySelected])

  useEffect(() => {
    const value = localStorage.getItem("UserConfigData");
    if (typeof value === 'string') {
      setUserConfigData(JSON.parse(value));
    }

    setProductCategorySelected(3) // Fruit
  }, [])


  function getAmountOrWeight(Id) {
    const product = Products.find(p => p.Id === Id)
    let attr = product.Attributes;
    attr = JSON.parse(attr.replace(/'/g, '"'))

    let amount = attr.Priority === 'Amount' ?
      attr.Amount :
      attr.Weight

    return `${attr.Priority} ${amount}`;
  }

  function switchProducts(ProductSelected) {
    const lockersToReplenish: any = [];

    if (props.lockersSelected.length) {
      props.lockersSelected.forEach(locker => {
        lockersToReplenish.push({
          lockerId: locker,
          productId: ProductSelected
        })
      });
      props.switchProducts(lockersToReplenish, props.lockersSelected);
    } else {
      props.switchProducts([ProductSelected])
    }
  }

  return (
    <div id="productPanel" className={classes.root} style={{ right: props.showPanel ? 0 : -350 }}>
      <div id="panelContent">
        <div className="panel-header">
          <h2>Producten</h2>
          <div className="pointer" id="closeProductPanel" onClick={() => props.closePanel()}><MdCancel size={32} /></div>
        </div>
        <div className="pCat-panel">
          {props.productCategories.map(cat => {
            return (
              <label
                key={cat.Id}
                onClick={() => setProductCategorySelected(cat.Id)}
                className={`pCat-item ${ProductCategorySelected === cat.Id ? "selected" : ""}`} htmlFor={cat.Title}>{cat.Title}</label>
            );
          })}
        </div>
        <div className="panel-products-container">
          {FilteredProducts.map(p => {
            return (
              <div key={p.Id} id={`ppanel${p.Id}`} className={`panel-product ${ProductSelected === p.Id ? "selected" : ""}`} onClick={() => setProductSelected(p.Id)}>
                {UserConfigData.Customer ?
                  <div className="image-container">
                    <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${UserConfigData.Customer}/images/products/pid${p.Id}_${Products.find(prod => prod.Id === p.Id).Avatar}`} alt={p.Title} />
                  </div>
                  : null
                }
                <div className="panel-product-content">
                  <p className="panel-product-title">{p.Title}</p>
                  <p className="panel-product-weight" style={{ margin: 0 }}>{getAmountOrWeight(p.Id)}</p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="product-panel-action">
          <Button
            id="switchProductBtn"
            type="button"
            variant="contained"
            className="green-btn"
            disabled={ProductSelected === 0}
            onClick={() => switchProducts(ProductSelected)}
          >
            {props.isJofemar ? `Product wisselen` : `${LockersSelected} Product(en) wisselen`}
          </Button>
        </div>

      </div>
    </div>
  )
}

export default ProductPanel
