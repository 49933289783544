import * as React from "react";
interface UIState {
  showLoadingModal: boolean
  showToastMessage: boolean
  toastType: 'error' | 'success' | 'warning' | 'info'
  toastMessage: string
  showRoleSelectModal: boolean
}

const defaultUIState: UIState = {
  showLoadingModal: false,
  showToastMessage: false,
  toastType: 'error',
  toastMessage: '',
  showRoleSelectModal: false
};
const UIContext = React.createContext<any | undefined>(
  undefined
);

const UIProvider = (props) => {
  const [state, setState] = React.useState(defaultUIState);

  return (
    <UIContext.Provider value={[state, setState]}>
      {props.children}
    </UIContext.Provider>
  );
};

export { UIContext, UIProvider }
