import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { isToday, isSameMonth, formatDisplayDate, formatExcelDate, convertToSlashDate, formatDate, formatDateAndTIme, IsInYearRange, sum, IsInDateRange, datediff, getDetailedDate } from '../../helpers/commonFunctions';
import LineChart from '../../components/LineChart';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    height: "100%",
    paddingLeft: "10px",

    '& .title': {
      padding: "0 10px 0 5px"
    },
    '& .filters-range>*': {
      margin: "0 5px"
    },
    '& .range-label': {
      cursor: "pointer",
      fontWeight: 700
    },
    '& .range-label.active': {
      color: "#1976d2"
    },
    '& .datetimepicker-dashboard': {
      margin: "0 5px"
    },
    '& .chart-date-filters': {
      margin: "5px 0"
    },
    '& .chart-date-filters input': {
      fontWeight: 700
    }
  },
}));

function ProductsPerTransactionChart(props) {

  const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni",
    "Juli", "Augustus", "September", "Oktober", "November", "December"
  ];


  const classes = useStyles();
  const weekDates = [
    formatDate(new Date().setDate(new Date().getDate() - 1)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 2)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 3)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 4)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 5)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 6)).split("T")[0],
  ];

  const [TransactionData, setTransactionData]: [any, any] = useState([])
  const [Products, setProducts]: [any, any] = useState([]);
  const [DataSetDay, setDataSetDay]: [any, any] = useState([]);
  const [LabelsDay, setLabelsDay]: [any, any] = useState([]);
  const [DataSetWeek, setDataSetWeek]: [any, any] = useState([]);
  const [LabelsWeek, setLabelsWeek]: [any, any] = useState([]);
  const [DataSetMonth, setDataSetMonth]: [any, any] = useState([]);
  const [LabelsMonth, setLabelsMonth]: [any, any] = useState([]);
  const [DataSetYear, setDataSetYear]: [any, any] = useState([]);
  const [LabelsYear, setLabelsYear]: [any, any] = useState([]);
  const [DataSetDateRange, setDataSetDateRange]: [any, any] = useState([]);
  const [LabelsDateRange, setLabelsDateRange]: [any, any] = useState([]);
  const [DataSetActive, setDataSetActive]: [any, any] = useState([]);
  const [LabelsActive, setLabelsActive]: [any, any] = useState([]);
  const [RevenueRangeActive, setRevenueRangeActive] = useState("Day");
  const [DatasetList, setDatasetList]: [any, any] = useState([]);
  const [ExcelTabDay, setExcelTabDay]: [any, any] = useState([])
  const [ExcelTabWeek, setExcelTabWeek]: [any, any] = useState([])
  const [ExcelTabMonth, setExcelTabMonth]: [any, any] = useState([])
  const [ExcelTabYear, setExcelTabYear]: [any, any] = useState([]);
  const [ExcelTabDateRange, setExcelTabDateRange]: [any, any] = useState([]);
  const [FirstDayOfMonth, setFirstDayOfMonth] = useState("");
  const [YearPeriodStart, setYearPeriodStart] = useState("");
  const [ExcelLabelsWeek, setExcelLabelsWeek] = useState<any>([]);
  const [ExcelLabelsMonth, setExcelLabelsMonth] = useState<any>([]);
  const [ExcelLabelsDateRange, setExcelLabelsDateRange] = useState<any>([]);

  const [FromDate, setFromDate] = useState<any>(null);
  const [UntilDate, setUntilDate] = useState<any>(null);

  useEffect(() => {
    setTransactionData(props.transactionData);
    setProducts(props.products);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Products.length) {
      createDayData();
      createWeekData();
      createMonthData();
      createYearData();
    }
    // eslint-disable-next-line
  }, [Products])

  useEffect(() => {
    const dataSet = [
      {
        label: '# Producten',
        data: DataSetActive,
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 3,
      }
    ]
    setDatasetList(dataSet)
    // eslint-disable-next-line
  }, [DataSetActive])

  useEffect(() => {
    if (RevenueRangeActive) {
      switch (RevenueRangeActive) {
        case 'Day':
          setDataSetActive(DataSetDay)
          break;
        case 'Week':
          setDataSetActive(DataSetWeek)
          break;
        case 'Month':
          setDataSetActive(DataSetMonth)
          break;
        case 'Year':
          setDataSetActive(DataSetYear)
          break;
        default:
          break;
      }

      if (RevenueRangeActive === "DateRange") {
        if (FromDate && UntilDate) {
          createDateRangeData(FromDate, UntilDate);
        }
      }

    }
    // eslint-disable-next-line
  }, [RevenueRangeActive])

  useEffect(() => {
    if (RevenueRangeActive === "DateRange" && FromDate && UntilDate) {
      createDateRangeData(FromDate, UntilDate);
    }
    // eslint-disable-next-line
  }, [FromDate, UntilDate]);

  function createDateRangeData(fromDate, untilDate) {
    const labels: any[] = [];
    const labelsDetailed: any[] = [];
    const dataSet: any[] = [];

    let _dateFrom = new Date(fromDate);
    let dateFromTime = _dateFrom.setHours(0, 0, 0, 0);
    let _dateUntil = new Date(untilDate);
    let dateUntilTime = _dateUntil.setHours(23, 59, 59, 999);

    const daysDiff = datediff(_dateFrom, _dateUntil);

    const transactionData = [...TransactionData];

    transactionData.forEach(entry => {
      let trDate = entry.TransactionDate;
      if (IsInDateRange(dateUntilTime, dateFromTime, trDate)) {
        if (daysDiff <= 1) {
          console.log(entry)
          // show one day
          labels.push(trDate.split("T")[1]);
          dataSet.push(sum(entry.ProductsSold, "Units"));
        }
        else if (daysDiff > 1 && daysDiff < 32) {
          if (labels.includes(convertToSlashDate(trDate.split("T")[0]))) {
            dataSet[dataSet.length - 1] = dataSet[dataSet.length - 1] + sum(entry.ProductsSold, "Units");
          } else {
            labels.push(convertToSlashDate(trDate.split("T")[0]));
            labelsDetailed.push(getDetailedDate(trDate));
            dataSet.push(sum(entry.ProductsSold, "Units"));
          }
        }
        else if (daysDiff >= 32) {
          const month = trDate.split("-")[1];

          if (labels.includes(monthNames[parseInt(month, 10) - 1])) {
            dataSet[dataSet.length - 1] = dataSet[dataSet.length - 1] + sum(entry.ProductsSold, "Units");
          } else {
            labels.push(monthNames[parseInt(month, 10) - 1]);
            dataSet.push(sum(entry.ProductsSold, "Units"));
          }
        }
      }
    });

    const excelTabDateRangeObj: any = {};
    dataSet.forEach((products, index) => {
      const attr = "products" + index
      excelTabDateRangeObj[attr] = products
    });
    excelTabDateRangeObj.label = "Products"
    excelTabDateRangeObj.dateRange =
      `${formatDisplayDate(new Date(fromDate))} - ${formatDisplayDate(new Date(untilDate))}`
    setExcelTabDateRange([excelTabDateRangeObj]);

    setLabelsDateRange(daysDiff > 1 && daysDiff < 8 ? labelsDetailed : labels);
    setExcelLabelsDateRange(labels);
    setLabelsActive(daysDiff > 1 && daysDiff < 8 ? labelsDetailed : labels);
    setDataSetDateRange(dataSet);
    setDataSetActive(dataSet);
  }

  function createDayData() {
    const labels: any[] = [];
    const dataSet: any[] = [];

    const tArr = JSON.parse(JSON.stringify(TransactionData))

    tArr.forEach(entry => {
      if (isToday(entry.TransactionDate)) {
        labels.push(entry.TransactionDate.split("T")[1]);
        dataSet.push(sum(entry.ProductsSold, "Units"));
      }
    });

    // create Day excel dataSets
    const excelTabDayObj: any = {};
    dataSet.forEach((products, index) => {
      const attr = "products" + index
      excelTabDayObj[attr] = products
    });
    excelTabDayObj.label = "Products"
    excelTabDayObj.dateRange = formatDisplayDate(new Date())
    setExcelTabDay([excelTabDayObj]);

    setLabelsActive(labels);
    setDataSetActive(dataSet);
    setLabelsDay(labels);
    setDataSetDay(dataSet);
  }

  function createWeekData() {
    const labels: any[] = [];
    const labelsDetailed: any[] = [];
    const dataSet: any[] = [];

    TransactionData.forEach(entry => {
      if (
        weekDates.includes(entry.TransactionDate.split("T")[0]) ||
        entry.TransactionDate.split("T")[0] === formatDate(new Date()).split("T")[0]
      ) {
        if (labels.includes(convertToSlashDate(entry.TransactionDate.split("T")[0]))) {
          dataSet[dataSet.length - 1] = dataSet[dataSet.length - 1] + sum(entry.ProductsSold, "Units");
        } else {
          labels.push(convertToSlashDate(entry.TransactionDate.split("T")[0]));
          labelsDetailed.push(getDetailedDate(entry.TransactionDate));
          dataSet.push(sum(entry.ProductsSold, "Units"));
        }
      }
    });

    // create Week excel dataSets
    const excelTabWeekObj: any = {};
    dataSet.forEach((products, index) => {
      const attr = "products" + index
      excelTabWeekObj[attr] = products
    });
    excelTabWeekObj.label = "Products"
    excelTabWeekObj.dateRange = `${formatDisplayDate(new Date().setDate(new Date().getDate() - 6))} - ${formatDisplayDate(new Date())}`
    setExcelTabWeek([excelTabWeekObj]);

    setExcelLabelsWeek(labelsDetailed);
    setLabelsWeek(labelsDetailed);
    setDataSetWeek(dataSet);
  }

  function createMonthData() {
    const labels: any[] = [];
    const labelsDetailed: any[] = [];
    const dataSet: any[] = [];

    TransactionData.forEach(entry => {
      if (isSameMonth(entry.TransactionDate)) {
        if (labels.includes(convertToSlashDate(entry.TransactionDate.split("T")[0]))) {
          dataSet[dataSet.length - 1] = dataSet[dataSet.length - 1] + sum(entry.ProductsSold, "Units");
        } else {
          labels.push(convertToSlashDate(entry.TransactionDate.split("T")[0]));
          labelsDetailed.push(getDetailedDate(entry.TransactionDate));
          dataSet.push(sum(entry.ProductsSold, "Units"));
        }
      }
    });

    // create Month excel dataSets
    const excelTabMonthObj: any = {};
    dataSet.forEach((products, index) => {
      const attr = "products" + index
      excelTabMonthObj[attr] = products
    });
    excelTabMonthObj.label = "Products"
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    setFirstDayOfMonth(formatExcelDate(firstDay).split("T")[0]);
    excelTabMonthObj.dateRange = `${formatDisplayDate(firstDay)} - ${formatDisplayDate(new Date())}`
    setExcelTabMonth([excelTabMonthObj]);

    setLabelsMonth(labels.length < 8 ? labelsDetailed : labels);
    setExcelLabelsMonth(labels.length < 8 ? labelsDetailed : labels);
    setDataSetMonth(dataSet);
  }

  function createYearData() {
    const labels: any[] = [];
    let dataSet: any[] = [];

    const currTimeStamp = new Date().getTime();
    const date = new Date();
    const timeStampLastYear = date.setFullYear(date.getFullYear() - 1);

    TransactionData.forEach((entry) => {
      if (IsInYearRange(currTimeStamp, timeStampLastYear, entry.TransactionDate)) {
        const month = entry.TransactionDate.split("-")[1];

        if (labels.includes(monthNames[parseInt(month, 10) - 1])) {
          dataSet[dataSet.length - 1] = dataSet[dataSet.length - 1] + sum(entry.ProductsSold, "Units");
        } else {
          labels.push(monthNames[parseInt(month, 10) - 1]);
          dataSet.push(sum(entry.ProductsSold, "Units"));
        }

      }
    });

    // "fix" dataset
    dataSet = dataSet.map(entry => parseFloat(entry.toFixed(2)))

    // create Year excel dataSets
    const excelTabYearObj: any = {};
    dataSet.forEach((products, index) => {
      const attr = "products" + index
      excelTabYearObj[attr] = products
    });
    excelTabYearObj.label = "Products"
    setYearPeriodStart(formatExcelDate(timeStampLastYear).split("T")[0]);
    excelTabYearObj.dateRange = `${formatDisplayDate(timeStampLastYear)} - ${formatDisplayDate(new Date())}`
    setExcelTabYear([excelTabYearObj]);

    setLabelsYear(labels);
    setDataSetYear(dataSet);
  }

  return (
    <div className={classes.root}>

      {DatasetList.length ? <div>
        <div className="flex-center-align jcsb title">
          <h2 className="mr1 mt1">Aantal producten per transactie</h2>

          <div className="excel-btn">
            <ExcelFile filename={`ProductsPerTransaction_${formatDateAndTIme(new Date())}`} element={<button>Download Statistieken</button>}>

              <ExcelSheet data={ExcelTabDay} name={`Dag (${formatExcelDate(new Date()).split("T")[0]})`}>
                <ExcelColumn label={"Datum"} value="dateRange" />
                <ExcelColumn label={"Transactie tijd"} value="label" />
                {LabelsDay.map((label, index) => {
                  const attr = "products" + index;
                  return <ExcelColumn key={index} label={label} value={attr} />
                })}
              </ExcelSheet>

              <ExcelSheet data={ExcelTabWeek} name={`Week (${formatExcelDate(new Date().setDate(new Date().getDate() - 6)).split("T")[0]} - ${formatExcelDate(new Date()).split("T")[0]})`}>
                <ExcelColumn label={"Datums"} value="dateRange" />
                <ExcelColumn label={"Transactie datum"} value="label" />
                {LabelsWeek.map((label, index) => {
                  const attr = "products" + index;
                  return <ExcelColumn key={index} label={label} value={attr} />
                })}
              </ExcelSheet>

              <ExcelSheet data={ExcelTabMonth} name={`Maand (${FirstDayOfMonth} - ${formatExcelDate(new Date()).split("T")[0]})`}>
                <ExcelColumn label={"Datums"} value="dateRange" />
                <ExcelColumn label={"Transactie datum"} value="label" />
                {ExcelLabelsMonth.map((label, index) => {
                  const attr = "products" + index;
                  return <ExcelColumn key={index} label={label} value={attr} />
                })}
              </ExcelSheet>

              <ExcelSheet data={ExcelTabYear} name={`Jaar (${YearPeriodStart} - ${formatExcelDate(new Date()).split("T")[0]})`}>
                <ExcelColumn label={"Datums"} value="dateRange" />
                <ExcelColumn label={"Transactie maand"} value="label" />
                {LabelsYear.map((label, index) => {
                  const attr = "products" + index;
                  return <ExcelColumn key={index} label={label} value={attr} />
                })}
              </ExcelSheet>

              <ExcelSheet data={ExcelTabDateRange} name={`Periode (${FromDate ? formatExcelDate(new Date(FromDate)).split("T")[0] : "Unset"} - ${UntilDate ? formatExcelDate(new Date(UntilDate)).split("T")[0] : "Unset"})`}>
                <ExcelColumn label={"Datums"} value="dateRange" />
                <ExcelColumn label={"Transactie periode"} value="label" />
                {ExcelLabelsDateRange.map((label, index) => {
                  const attr = "products" + index;
                  return <ExcelColumn key={index} label={label} value={attr} />
                })}
              </ExcelSheet>

            </ExcelFile>
          </div>
        </div>
        <div className="flex jcsb">
          <div className="flex-center-align filters-range">
            <label htmlFor="rangeLabel">per:</label>
            <label
              className={`range-label ${RevenueRangeActive === "Day" ? "active" : ""}`}
              onClick={() => { setDataSetActive(DataSetDay); setLabelsActive(LabelsDay); setRevenueRangeActive("Day"); }}
              id="dayRange"
            >Dag</label>
            <span>/</span>
            <label
              className={`range-label ${RevenueRangeActive === "Week" ? "active" : ""}`}
              onClick={() => { setDataSetActive(DataSetWeek); setLabelsActive(LabelsWeek); setRevenueRangeActive("Week"); }}
              id="weekRange"
            >Week</label>
            <span>/</span>
            <label
              className={`range-label ${RevenueRangeActive === "Month" ? "active" : ""}`}
              onClick={() => { setDataSetActive(DataSetMonth); setLabelsActive(LabelsMonth); setRevenueRangeActive("Month"); }}
              id="monthRange"
            >Maand</label>
            <span>/</span>
            <label
              className={`range-label ${RevenueRangeActive === "Year" ? "active" : ""}`}
              onClick={() => { setDataSetActive(DataSetYear); setLabelsActive(LabelsYear); setRevenueRangeActive("Year"); }}
              id="yearRange"
            >Jaar</label>
            <span>/</span>
            <label
              className={`range-label ${RevenueRangeActive === "DateRange" ? "active" : ""}`}
              onClick={() => { setDataSetActive(DataSetDateRange); setLabelsActive(LabelsDateRange); setRevenueRangeActive("DateRange"); }}
              id="dateRange"
            >Selecteer periode</label>
          </div>
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="flex-center-align chart-date-filters" style={{ display: RevenueRangeActive === "DateRange" ? "flex" : "none" }}>
            <label htmlFor={"From:"}>{"Van:"}</label>
            <DatePicker
              name={"FromPPT"}
              className="datetimepicker-dashboard"
              clearable
              autoOk
              // label="Van:"
              format="dd/MM/yyyy"
              placeholder={formatDisplayDate(Date.now())}
              onChange={value => {
                setFromDate(value);
              }}
              value={FromDate}
              animateYearScrolling={false}
            />
            <label htmlFor={"Until:"}>{"Tot:"}</label>
            <DatePicker
              name={"UntilPPT"}
              className="datetimepicker-dashboard"
              clearable
              autoOk
              // label="Van:"
              format="dd/MM/yyyy"
              placeholder={formatDisplayDate(Date.now())}
              onChange={value => {
                setUntilDate(value);
              }}
              value={UntilDate}
              animateYearScrolling={false}
            />
          </div>
        </MuiPickersUtilsProvider>
      </div> : null}

      {DatasetList.length ?
        <LineChart labels={LabelsActive} dataSet={[
          {
            label: '# Producten',
            data: DataSetActive,
            fill: false,
            backgroundColor: 'rgb(75, 192, 192)',
            borderColor: 'rgba(75, 192, 192, 0.2)',
            borderWidth: 3,
          }
        ]} />
        : null}
    </div>
  )
}

export default ProductsPerTransactionChart

