import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { MdArrowBack, MdCheckCircle } from "react-icons/md";
import { FaSyncAlt, FaPowerOff } from "react-icons/fa";
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonGroup } from '@material-ui/core';
import { API } from 'aws-amplify';
import { countBlockTypes, fillingPlanReqBody } from './StoreReplenishmentController';
import { getSelectedLockersRequestBody } from '../StoreSupport/StoreSupportController';
import { convertStringToArray, lockerSizeValidation } from '../ReplenishmentPlan/ReplenishmentController';
import useUIState from '../../hooks/useUIstate';
import Checkbox from '@material-ui/core/Checkbox';
import ProductPanel from '../../components/ProductPanel';
import ConfirmationModal from '../../components/ConfirmationModal';
import useDataState from '../../hooks/useDataState';
import LoadingModal from '../../components/LoadingModal';
import awsconfig from '../../aws-apiSettings';
import { formatDateAndTIme } from '../../helpers/commonFunctions'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSelect-select': {
      padding: "10.5px 14px",
      width: "250px",
    },
    '& .MuiInputBase-root ': {
      marginRight: "10px"
    },
    '& h1, & h2': {
      marginTop: '5px',
      marginBottom: '5px'
    },
    '& .blocks-container': {
      marginTop: "20px",
      overflowX: "auto",
      position: "relative",
    },
    '& .block': {
      background: '#eee',
      marginRight: "20px"
    },
    '& .double': {
      width: '450px',
      minWidth: '450px',
    },
    '& .single': {
      width: '225px',
      minWidth: '225px',
    },
    '& .block-header': {
      background: '#ddd'
    },
    '& .lockers-container': {
      flexWrap: "wrap",
      padding: "5px 0"
    },
    '& .locker': {
      width: '225px',
      padding: '5px 0',
      alignItems: 'center'
    },
    '& .product-avatar-container': {
      width: "50px",
      height: "45px",
      minWidth: "50px",
      minHeight: "45px",
      background: "#fff",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "5px"
    },
    '& .product-avatar-container svg': {
      position: "absolute",
      padding: "0.25px",
      background: "white",
      borderRadius: "50%"
    },
    '& .disabled-locker .product-avatar-container svg': {
      padding: "2px",
      background: "#ef5350"
    },
    '& .product-avatar-container img': {
      width: "100%",
      height: "100%"
    },
    '& .replenish-icon': {
      marginLeft: "5px",
      paddingTop: "5px"
    },

    '& .product-avatar-container.B': {
      width: "60px",
    },
    '& .product-avatar-container.C': {
      width: "70px",
    },
    '& .product-avatar-container.D': {
      width: "60px",
    },
    '& .green-locker .locker-shape': {
      height: "15px",
      width: "15px",
      minHeight: "15px",
      minWidth: "15px",
      backgroundColor: "#689f38",
      borderRadius: "50%",
      border: "1px solid #689f38",
      margin: "0 10px 0 5px"
    },
    '& .empty-locker .locker-shape': {
      height: "15px",
      width: "15px",
      minHeight: "15px",
      minWidth: "15px",
      backgroundColor: "#fff",
      borderRadius: "50%",
      border: "1px solid #222",
      margin: "0 10px 0 5px"
    },
    '& .almost-expired-locker .locker-shape': {
      height: "15px",
      width: "15px",
      minHeight: "15px",
      minWidth: "15px",
      backgroundColor: "#ff9800",
      margin: "0 10px 0 5px"
    },
    '& .almost-expired-locker .product-avatar-container': {
      border: "3px solid #ff9800"
    },
    '& .sold-out-locker .locker-shape': {
      width: 0,
      height: 0,
      borderLeft: "7.5px solid transparent",
      borderRight: "7.5px solid transparent",
      borderBottom: "15px solid #d81b60",
      margin: "0 10px 0 5px"
    },
    '& .sold-out-locker .product-avatar-container': {
      border: "3px solid #d81b60"
    },
    '& .product-code': {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    },
    '& .legend': {
      alignItems: "center",
    },
    '& .replenishment-bottom': {
      marginTop: "20px",
      maxWidth: "90vw"
    },
    '& .replenishment-actions>*': {
      marginRight: "20px",
    },
    '& .replenishment-actions>button': {
      minWidth: "120px",
    }
  }
}));

function StoreReplenishmentScreen(props) {
  const history = useHistory();
  const classes = useStyles();

  const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();
  const { ProductCategoriesContext, setProductCategoriesContext, ProductsContext, setProductsContext, StoreLayoutContext, setStoreLayoutContext, JofemarContext, setJofemarContext, setLockersDPlanContext, StoreContext, setStoreContext } = useDataState();

  const [StoreId, setStoreId] = useState(null);
  const [StoreObj, setStoreObj] = useState({ FillingPlan: "" });
  const [StoreTitle, setStoreTitle] = useState("")
  const [Blocks, setBlocks]: [any, any] = useState([]);
  const [Jofemar, setJofemar] = useState(null)
  const [Jofemars, setJofemars] = useState(null)
  const [IsDirtyCurrentJofemarContents, setIsDirtyCurrentJofemarContents] = useState(false); // true: allow save, false: allow jofemar navigation
  const [ProductCategories, setProductCategories]: [any, any] = useState([]);
  const [Products, setProducts]: [any, any] = useState([]);
  const [LockersSelected, setLockersSelected]: [any, any] = useState([])
  const [BlocksSelected, setBlocksSelected]: [any, any] = useState([])
  const [AlmostExpiredLockerIds]: [any, any] = useState([])
  const [SoldOutLockerIds]: [any, any] = useState([])
  const [ShowPanel, setShowPanel] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal]: [boolean, any] = useState(false);
  const [LockersDistributionPlan, setLockersDistributionPlan]: [any, any] = useState([])
  const [ShowConfirmationPreviousPlan, setShowConfirmationPreviousPlan] = useState(false);
  const [LockersToBeReplenished, setLockersToBeReplenished]: [any, any] = useState([]);
  const [ReplenishmentStep, setReplenishmentStep] = useState(2);
  const [ShowLockersClosedConfirmation, setShowLockersClosedConfirmation] = useState(false);
  const [ShowStartReplenishmentConfirmation, setShowStartReplenishmentConfirmation] = useState(false);
  const [ShowApplyFillingPlanConfirmation, setShowApplyFillingPlanConfirmation] = useState(false);
  const [ShowApplyFillingPlanConfirmation2, setShowApplyFillingPlanConfirmation2] = useState(false);
  const [FillingPlanApplied, setFillingPlanApplied] = useState(false);
  const [LockersView, setLockersView] = useState<string>("Back");
  const [UserConfigData, setUserConfigData]: [any, any] = useState([]);

  function handleLockersViewChange(view) {
    const blocksContainer = document.getElementById("blocksContainer");
    if (view === "Back") {
      setLockersView("Back");
      if (blocksContainer) {
        blocksContainer.scrollLeft = -blocksContainer.scrollWidth
      }
      return;
    }
    setLockersView("Front");
  }

  const handleCheckboxChange = (e, type, selection, selectedBlock = null) => {
    const lockersSelected: any = [...LockersSelected];
    const blocksSelected: any = [...BlocksSelected];

    if (type === 'locker') {
      if (lockersSelected.indexOf(selection) > -1) {
        const position = lockersSelected.indexOf(selection);
        lockersSelected.splice(position, 1)
      } else {
        lockersSelected.push(selection);
      }
    } else if (type === "block") {
      if (blocksSelected.indexOf(selectedBlock) > -1) {
        // de-selecting
        const pos = blocksSelected.indexOf(selectedBlock);
        blocksSelected.splice(pos, 1)

        selection.forEach(locker => {
          if (lockersSelected.includes(locker.Id)) {
            const pos = lockersSelected.indexOf(locker.Id);
            lockersSelected.splice(pos, 1);
          }
        });
      } else {
        // selecting non green lockers
        blocksSelected.push(selectedBlock);
        const lockersOfBlock: any = document.querySelectorAll(
          ".lblock" + selectedBlock
        );
        [].forEach.call(lockersOfBlock, (el) => {
          const element: any = el;
          const lockerId = parseInt(element.id.split('locker')[1], 10);
          if (element.classList.contains('green-locker') && element.classList.contains('lblock' + selectedBlock)) {
            // remove green lockers selected
            const pos = lockersSelected.indexOf(lockerId);
            if (pos >= 0) {
              lockersSelected.splice(pos, 1);
            }
          }
          if (
            element.classList.contains('empty-locker') ||
            element.classList.contains('almost-expired-locker') ||
            element.classList.contains('sold-out-locker') ||
            element.classList.contains('disabled-locker')
          ) {
            if (!lockersSelected.includes(lockerId)) {
              lockersSelected.push(lockerId)
            }
          }
        });

      }
      setBlocksSelected(blocksSelected)
    }
    setLockersSelected(lockersSelected)
  };

  useEffect(() => {
    const value = localStorage.getItem("UserConfigData");
    if (typeof value === 'string') {
      setUserConfigData(JSON.parse(value));
    }

    if (props.location.store) {
      const Store = props.location.store
      setStoreObj(props.location.store);
      setStoreContext(Store);
      localStorage.setItem("ActiveStore", JSON.stringify(Store))
      setStoreId(props.location.storeId);
      setStoreTitle(props.location.title);

    } else if (StoreContext) {
      localStorage.setItem("ActiveStore", JSON.stringify(StoreContext))
      setStoreId(StoreContext.Id);
      setStoreTitle(StoreContext.Title);
      setStoreObj(StoreContext)
    } else if (localStorage.getItem("ActiveStore")) {

      const Store = localStorage.getItem("ActiveStore");
      if (Store) {

        const StoreJson = JSON.parse(Store)
        console.log(StoreJson)

        setStoreObj(StoreJson);
        setStoreContext(StoreJson);
        setStoreId(StoreJson.StoreId);
        setStoreTitle(StoreJson.StoreTitle);
      }

    } else {
      history.push('/retail-locations');
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (StoreId) {
      getProductCategories();
    }
    // eslint-disable-next-line
  }, [StoreId])

  function getProductCategories() {
    if (ProductCategoriesContext.length) {
      setProductCategories(ProductCategoriesContext);
      setShowLoadingModal(false);
      getProducts();
    } else {
      setShowLoadingModal(true);
      API.get("AE", `/ProductCategories`, { response: true })
        .then(res => {

          setProductCategories(res.data);
          setProductCategoriesContext(res.data);
          getProducts();
        })
        .catch(error => {
          console.log(error);
          setToastType("error");
          setToastMessage("Error getting products");
          setShowToastMessage(true);
        })
    }
  }

  function getProducts() {
    if (ProductsContext.length) {
      setProducts(ProductsContext);
      setShowLoadingModal(false);
      getBlocksOfStore(StoreId);

    } else {
      setShowLoadingModal(true);
      API.get("AE", `/Products`, { response: true })
        .then(res => {
          const activeProducts: any[] = []

          res.data.forEach(product => {
            const attr = JSON.parse(product.Attributes.replace(/'/g, '"'));
            if (attr.Enabled) {
              activeProducts.push(product);
            }
          });

          setProducts(activeProducts);
          localStorage.setItem("Products", JSON.stringify(activeProducts))
          setProductsContext(activeProducts);
          getBlocksOfStore(StoreId);
        })
        .catch(error => {
          console.log(error);
          setToastType("error");
          setToastMessage("Error getting products");
          setShowToastMessage(true);
        })
    }
  }

  function getBlocksOfStore(storeId) {
    if (localStorage.getItem("StoreLayout")) {
      const storeLayout = localStorage.getItem("StoreLayout");
      if (storeLayout) {

        //set Jofemar from Jofemars
        const activeJofemarId = localStorage.getItem("ActiveJofemarId");
        const jofemars = localStorage.getItem("Jofemars")
        if (jofemars) {
          const jofemarsJson = JSON.parse(jofemars)
          console.log('number of jofemars found: ' + jofemarsJson.length)
          const jofemarJson = jofemarsJson.find(block => block.Id === Number(activeJofemarId))
          // Boolean(isDirtyCurrentJofemarContents) above not working somehow - Boolean("false") = true
          const isDirtyCurrentJofemarContents = localStorage.getItem("IsDirtyCurrentJofemarContents")
          if (isDirtyCurrentJofemarContents === "true") {
            setIsDirtyCurrentJofemarContents(true);
          } else {
            setIsDirtyCurrentJofemarContents(false);
          }
          setJofemar(jofemarJson);
          setJofemars(jofemarsJson);
          setJofemarContext(jofemarJson);
        }
        
        const storeJson = JSON.parse(storeLayout);

        // if there is a current filling plan apply it 
        // (navigation back from Jofemar screen)
        let fillingPlanStr, fillingPlan;
        if (localStorage.getItem("CurrentFillingPlan")) {
          fillingPlanStr = localStorage.getItem("CurrentFillingPlan");
          fillingPlanToLockersDistributionPlan(fillingPlanStr);

          fillingPlan = fillingPlanStr.split("|");
          fillingPlan.shift();
          fillingPlan = fillingPlan.map(l => JSON.parse(l.replace(/'/g, '"')))
          console.log(fillingPlan)

          storeJson.forEach(blocks => {
            blocks.Lockers.forEach(locker => {
              if (fillingPlan.find(l => l.LockerId === locker.Id)) {
                locker.ProductId = fillingPlan.find(l => l.LockerId === locker.Id).ProductId;
              }
            });
          });
        }

        // count block types
        const blockTypes: any = {};

        storeJson.forEach(block => {
          const type = block.Code.split('-')[0];
          if (blockTypes[type]) {
            blockTypes[type] = blockTypes[type] + 1;
          } else {
            blockTypes[type] = 1;
          }
          block.Description = `${type}-${blockTypes[type]}`;
        });

        setBlocks(storeJson);
        setStoreLayoutContext(storeLayout);
        setShowLoadingModal(false);
      }
    } else { // StoreLayout not in localStorage
      if (StoreLayoutContext.length) {
        setBlocks(StoreLayoutContext);

        setJofemar(JofemarContext);
        localStorage.setItem('StoreLayout', JSON.stringify(StoreLayoutContext));
        setShowLoadingModal(false);
      } else {
        setShowLoadingModal(true);
        API.get("AE", `/Blocks/GetBlocksAndLockers?storeId=${storeId}`, { response: true })
          .then(res => {

            //set Jofemar
            var jofemar = res.data.find(block => block.Code.indexOf("Vision") > -1);

            const jofemars = res.data.filter(function(obj) {
              return (obj.Code.indexOf("Vision") > -1);
            });

            setJofemar(jofemar);
            setJofemars(jofemars);
            localStorage.setItem("Jofemar", JSON.stringify(jofemar))
            localStorage.setItem("Jofemars", JSON.stringify(jofemars))
            setJofemarContext(jofemar);
            
            // filter out Jofemar
            const blocks = res.data.filter(block => block.Code.indexOf("Vision") <= -1);
            // sort blocks by rack
            blocks.forEach(block => {
              block.Order = parseInt(block.Code.split(' ')[1], 10);
            });
            blocks.sort((a, b) => (a.Order > b.Order) ? 1 : -1)

            assignLockersPerBlock(blocks);
          })
          .catch(error => {
            console.log(error);
            setToastType("error");
            setToastMessage("Error getting blocks");
            setShowToastMessage(true);
          });
      }
    }
  }

  async function assignLockersPerBlock(blocks) {
    const _blocks = blocks;
    setShowLoadingModal(false);

    const blocksDesc = countBlockTypes(_blocks)
    setBlocks(blocksDesc);

    localStorage.setItem('StoreLayout', JSON.stringify(blocksDesc));
    setStoreLayoutContext(blocksDesc);
    setBlocks(blocksDesc);
    setShowLoadingModal(false);
    const blocksContainer = document.getElementById("blocksContainer");
    if (blocksContainer) {
      setTimeout(() => {
        blocksContainer.scrollLeft = -blocksContainer.scrollWidth
      }, 100);
    }
    // showLockersToBeReplenished(_blocks);
  }

  function showLockersToBeReplenished(_blocks) {
    setShowApplyFillingPlanConfirmation(false);
    setFillingPlanApplied(true);

    if (StoreObj !== null) {
      const fillingPlanStr = StoreObj.FillingPlan;
      const lockersToReplenish: any[] = [];

      console.log(fillingPlanStr);
      let fillingPlan = fillingPlanStr.split("|");
      fillingPlan.splice(0, 1);

      fillingPlan.forEach(locker => {
        const _locker = locker.replace(/'/g, '"');
        lockersToReplenish.push(JSON.parse(_locker));
      });
      console.log(lockersToReplenish);
      setLockersToBeReplenished(lockersToReplenish);
      // startReplenishment(_blocks, lockersToReplenish);
    }
  }

  async function startReplenishment(_blocks, lockersToReplenish) {
    setShowLoadingModal(true);

    const lockersSelected: any[] = []
    lockersToReplenish.forEach(locker => {
      lockersSelected.push(locker.LockerId);
    });
    // setLockersSelected(lockersSelected);

    console.log('Deactivate and open Lockers');
    const [localGWLockers, lockersToUpdate, _Blocks]: any[] = getSelectedLockersRequestBody(_blocks, lockersSelected, ["LockerOpen", "LockerDisable"]);

    let body = localGWLockers;
    const res = await API.put('AE', `/Store/${StoreId}/StartReplenishment`, { body });
    setShowLoadingModal(false);
    if (res === '200' || res === '307') {
      setShowLoadingModal(true);
      // update Lockers in CG
      const updatedLockerRes = await updateLockers(lockersToUpdate);
      if (!updatedLockerRes) {
        setToastType("error");
        setToastMessage("Error updating locker(s) ");
        setShowToastMessage(true);
        setShowLoadingModal(false);
      } else {
        setToastType("success");
        setToastMessage("Replenishment started");
        setShowToastMessage(true);

        setShowStartReplenishmentConfirmation(false);

        setBlocks(_Blocks);
        setStoreLayoutContext(_Blocks);
        localStorage.setItem("StoreLayout", JSON.stringify(_Blocks))

        setShowLoadingModal(false);
      }
      console.log(lockersToUpdate);
    } else {
      setToastType("error");
      setToastMessage("Error communicating Local Gateway");
      setShowToastMessage(true);
    }
  }

  function handleSelectAction(selectAction) {
    console.log(selectAction);
    if (LockersSelected.length === 0) {
      setToastType("error");
      setToastMessage("No lockers selected");
      setShowToastMessage(true);
      setShowLoadingModal(false);
      return [];
    } else {
      selectAction === 1 ? activateLockers() : deactivateLockers()
    }
  }

  async function activateLockers() {
    const [localGWLockers, lockersToUpdate, _Blocks]: any[] = getSelectedLockersRequestBody(Blocks, LockersSelected, ["LockerEnable"]);

    lockersToUpdate.forEach(locker => {
      if (!locker.ProductId) {
        setToastType("error");
        setToastMessage("1 or more lockers are empty");
        setShowToastMessage(true);
        return;
      }
    });

    let body = localGWLockers;
    setShowLoadingModal(true);
    const res = await API.put('AE', `/Store/${StoreId}/LockerEnable`, { body });
    if (res === '200' || res === '307') {
      // update Lockers in CG
      console.log("Activate Lockers");
      // const updatedLockerRes = await updateLockers(lockersToUpdate);
      // if (!updatedLockerRes) {
      //   setToastType("error");
      //   setToastMessage("Error updating locker(s) ");
      //   setShowToastMessage(true);
      // } else {
      //   setToastType("success");
      //   setToastMessage("Selected lockers are activated");
      //   setShowToastMessage(true);

      setBlocks(_Blocks);
      setStoreLayoutContext(_Blocks);
      localStorage.setItem("StoreLayout", JSON.stringify(_Blocks))

      setShowLoadingModal(false);
      replenishActivatedLockers(lockersToUpdate);

      setBlocksSelected([]);
      setLockersSelected([]);
    } else {
      setToastType("error");
      setToastMessage("Error communicating Local Gateway");
      setShowToastMessage(true);
    }
  }

  async function deactivateLockers() {
    setShowLoadingModal(true);
    console.log('Deactivate Lockers');
    const [localGWLockers, lockersToUpdate, _Blocks]: any[] = getSelectedLockersRequestBody(Blocks, LockersSelected, ["LockerDisable"]);

    let body = localGWLockers;
    const res = await API.put('AE', `/Store/${StoreId}/LockerDisable`, { body });
    if (res === '200' || res === '307') {
      // update Lockers in CG
      const updatedLockerRes = await updateLockers(lockersToUpdate);
      if (!updatedLockerRes) {
        setToastType("error");
        setToastMessage("Error updating locker(s) ");
        setShowToastMessage(true);
      } else {
        setToastType("success");
        setToastMessage("Selected lockers are deactivated");
        setShowToastMessage(true);

        setBlocks(_Blocks);
        setStoreLayoutContext(_Blocks);
        localStorage.setItem("StoreLayout", JSON.stringify(_Blocks))

        setShowLoadingModal(false);
      }
      setBlocksSelected([]);
      setLockersSelected([]);
    } else {
      setToastType("error");
      setToastMessage("Error communicating Local Gateway");
      setShowToastMessage(true);
    }
  }

  function getProductStatus(locker) {
    let className = "green-locker"
    const product = Products.find(p => p.Id === locker.ProductId)

    let attr = product.Attributes;
    attr = JSON.parse(attr.replace(/'/g, '"'))

    let d = new Date();
    let currDate = formatDate(d);
    let todayObj = new Date(currDate);
    todayObj.setDate(todayObj.getDate() + 1);

    let tomorrowStr = formatDate(todayObj);
    let tomorrowsTime = new Date(tomorrowStr).getTime();

    let expiredDate = new Date(locker.ReplenishedAt);
    expiredDate.setDate(expiredDate.getDate() + attr.ShelfLifeDays);
    let expiredDateStr = formatDate(expiredDate);
    let expTime = new Date(expiredDateStr).getTime();

    if (tomorrowsTime >= expTime) {
      className = "almost-expired-locker";
      const almostExpiredLockerIds = [...AlmostExpiredLockerIds]
      if (!almostExpiredLockerIds.includes(locker.Id)) {
        almostExpiredLockerIds.push(locker.Id)
      }
      // setAlmostExpiredLockerIds(almostExpiredLockerIds)
    }
    if (locker.IsSoldOut) {
      className = "sold-out-locker";
      const soldOutLockerIds = [...SoldOutLockerIds]
      if (!soldOutLockerIds.includes(locker.Id)) {
        soldOutLockerIds.push(locker.Id)
      }
      // setSoldOutLockerIds(soldOutLockerIds)
    }

    return className;
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = ("0" + d.getHours()).slice(-2),
      minutes = ("0" + d.getMinutes()).slice(-2),
      seconds = ("0" + d.getSeconds()).slice(-2);

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-') + `T${hours}:${minutes}:${seconds}`;
  }

  async function switchProductsR(lockerIdsToReplenish, lockersSelected) {
    console.log("Switch Products ");
    let lockersToReplenish: any[] = [];

    if (LockersSelected.length === 0) {
      setToastType("error");
      setToastMessage("You must select a locker first");
      setShowToastMessage(true);
    } else {
      const _Blocks = JSON.parse(JSON.stringify(Blocks));
      let failedValidationMsg = "";

      lockerIdsToReplenish.forEach(lockerSelectedId => {
        _Blocks.forEach((block, blockIndex) => {
          block.Lockers.forEach((locker, lockerIndex) => {
            if (locker.Id === lockerSelectedId.lockerId) {

              let failedValidation = lockerSizeValidation(block, ProductsContext, lockerSelectedId.productId)

              if (failedValidation) {
                failedValidationMsg = failedValidation;
              } else {

                const todayStr = formatDate(new Date());
                locker.ReplenishedAt = todayStr;
                locker.ProductId = lockerSelectedId.productId;
                locker.IsSoldOut = false;
                lockersToReplenish.push(locker);
              }

            }
          });
        });
      });

      if (failedValidationMsg) {
        setToastType("error");
        setToastMessage(failedValidationMsg);
        setShowToastMessage(true);
      } else {

        setBlocks(_Blocks);
        setShowLoadingModal(true);

        let lockersUpdated = true;
        const updatedLockerRes = await updateLockers(lockersToReplenish);
        if (!updatedLockerRes) {
          setToastType("error");
          setToastMessage("Error updating locker(s) ");
          setShowToastMessage(true);
          lockersUpdated = false
        } else {
          // console.log("lockers updated");
        }

        setLockersSelected([]);
        setBlocksSelected([]);
        if (lockersUpdated) {
          updateDistributionPlan(lockersToReplenish);
        }
        setShowLoadingModal(false);
      }
    }
  }

  function updateDistributionPlan(lockersUpdated) {
    // Update lockers Distribution Plan
    const lockersDistributionPlan: any[] = [...LockersDistributionPlan];
    const productsToRemove: any[] = [];
    // prodEntry = { ProductId: locker.ProductId, Amount: 0, LockersUpdatedIds: [] }

    lockersUpdated.forEach(locker => {
      // look for lockers updated in dist plan
      // with another product on them (updated switched products in lockers in dist plan)
      lockersDistributionPlan.forEach((product, index) => {
        if (product.LockersUpdatedIds.includes(locker.Id) && product.ProductId !== locker.ProductId) {
          // if product.LockersUpdatedIds is just 1 then remove whole entry
          if (product.LockersUpdatedIds.length === 1) {
            productsToRemove.push(product.ProductId);
          } else {
            // else remove locker from product.LockersUpdatedIds
            // and update Amount
            const newLockersUpdatedIds = product.LockersUpdatedIds;
            const indexToRemove = product.LockersUpdatedIds.indexOf(locker.Id);
            newLockersUpdatedIds.splice(indexToRemove, 1);
            lockersDistributionPlan[index].LockersUpdatedIds = newLockersUpdatedIds;
            lockersDistributionPlan[index].Amount = newLockersUpdatedIds.length;
          }
        }
      });



      if (lockersDistributionPlan.find(product => product.ProductId === locker.ProductId)) {
        // if product exists in distribution plan

        const index = lockersDistributionPlan.findIndex(product => product.ProductId === locker.ProductId)
        const newLockersUpdatedIds = lockersDistributionPlan[index].LockersUpdatedIds
        newLockersUpdatedIds.push(locker.Id)
        lockersDistributionPlan[index] = {
          ProductId: locker.ProductId,
          Amount: newLockersUpdatedIds.length,
          LockersUpdatedIds: newLockersUpdatedIds
        }
      } else {
        // if product does not exists in distribution plan
        // just add it
        lockersDistributionPlan.push({
          ProductId: locker.ProductId, Amount: 1, LockersUpdatedIds: [locker.Id]
        });
      }

    });

    productsToRemove.forEach(productId => {
      if (lockersDistributionPlan.find(p => p.ProductId === productId)) {
        const indexToRemove = lockersDistributionPlan.findIndex(p => p.ProductId === productId);
        lockersDistributionPlan.splice(indexToRemove, 1);
      }
    });

    // console.log(lockersDistributionPlan);
    setLockersDistributionPlan(lockersDistributionPlan)
    localStorage.setItem("LockersDistPlan", JSON.stringify(lockersDistributionPlan))
    setLockersDPlanContext(lockersDistributionPlan)
  }

  async function updateLockers(lockers) {

    console.log("Lockers Updated:" + lockers.length + " at " + formatDateAndTIme(new Date()))

    lockers.forEach(locker => {
      // console.log(formatDateAndTIme(locker.ReplenishedAt))
    });

    return new Promise((resolve) => {
      let body = lockers;
      API.put('AE', `/Lockers`, { body })
        .then(res => {
          resolve(res);
          setToastType("success");
          setToastMessage("Selected lockers are activated");
          setShowToastMessage(true);
        })
        .catch(error => {
          console.log(error);
          setToastType("error");
          setToastMessage("Error updating locker(s) ");
          setShowToastMessage(true);
        })
    })
  }

  function deleteLockersHandler() {
    if (LockersSelected.length === 0) {
      setToastType("error");
      setToastMessage("You must select Locker(s) first");
      setShowToastMessage(true);
    } else {
      setShowConfirmationModal(true);
    }
  }

  async function confirmEmptyLockers() {
    setShowConfirmationModal(false);
    setShowLoadingModal(true)
    let lockersToEmpty: any[] = [];
    const _Blocks = [...Blocks];

    // Update lockers Distribution Plan
    const lockersDistributionPlan: any[] = [...LockersDistributionPlan];
    console.log(lockersDistributionPlan);

    LockersSelected.forEach(lockerSelectedId => {
      _Blocks.forEach(block => {
        block.Lockers.forEach(locker => {
          if (locker.Id === lockerSelectedId) {
            locker.ReplenishedAt = null;
            locker.ProductId = null;
            lockersToEmpty.push(locker);
          }
        });
      });
    });

    let productsToRemove: any[] = [];

    // update dist plan array
    lockersToEmpty.forEach(locker => {
      const lockerId = locker.Id;
      lockersDistributionPlan.forEach(entry => {
        const lockersUpdated = entry.LockersUpdatedIds;
        // remove locker from product entry in dist plan array
        if (lockersUpdated.includes(lockerId)) {

          if (entry.LockersUpdatedIds.length === 1) {
            productsToRemove.push(entry.ProductId);
          } else {
            const index = lockersUpdated.indexOf(lockerId);
            lockersUpdated.splice(index, 1);
            entry.Amount = entry.Amount - 1;
            entry.LockersUpdatedIds = lockersUpdated;
          }
        }
      });
    });

    if (productsToRemove.length) {
      productsToRemove.forEach(removeProductId => {
        if (lockersDistributionPlan.find(x => x.ProductId === removeProductId)) {
          const index = lockersDistributionPlan.findIndex(x => x.ProductId === removeProductId);
          lockersDistributionPlan.splice(index, 1);
        }
      });
    }

    console.log(lockersDistributionPlan);
    console.log(lockersToEmpty)
    setLockersSelected([])
    setLockersDistributionPlan(lockersDistributionPlan)
    localStorage.setItem("LockersDistPlan", JSON.stringify(lockersDistributionPlan))
    setLockersDPlanContext(lockersDistributionPlan)

    const updatedLockersRes = await updateLockers(lockersToEmpty);
    if (!updatedLockersRes) {
      setToastType("error");
      setToastMessage("Error updating locker(s) ");
      setShowToastMessage(true);
    } else {
      console.log("lockers emptied");
    }

    setLockersSelected([])
    setBlocksSelected([])
    setShowLoadingModal(false);
    setBlocks(_Blocks);
    localStorage.setItem('StoreLayout', JSON.stringify(_Blocks));
    setStoreLayoutContext(_Blocks);
  }

  function prepFillingPlan() {
    setShowLockersClosedConfirmation(false);
    setShowLoadingModal(true)
    console.log(LockersDistributionPlan);


    let body = fillingPlanReqBody(LockersDistributionPlan, StoreObj);
    // body.FillingPlan = StoreObj.FillingPlan
    console.log(body);

    localStorage.setItem("ActiveStore", JSON.stringify(body));
    setStoreContext(body)

    // Save current Filling plan for navigation
    let currFillingPlan = localStorage.getItem("CurrentFillingPlan") ?
      convertStringToArray(localStorage.getItem("CurrentFillingPlan")) : [];

    console.log("currFillingPlan", currFillingPlan);

    if (body.FillingPlan) {
      let bodyFillingPlanArr = convertStringToArray(body.FillingPlan);
      bodyFillingPlanArr.forEach(locker => {
        if (currFillingPlan.find(l => l.LockerId === locker.LockerId)) {
          let index = currFillingPlan.findIndex(l => l.LockerId === locker.LockerId);
          currFillingPlan[index].ProductId = locker.ProductId;
        } else {
          currFillingPlan.push(locker);
        }
      });
    }

    let newFillingPlanStr = "";
    if (currFillingPlan.length) {
      currFillingPlan.forEach(locker => {
        newFillingPlanStr = newFillingPlanStr + "|" + JSON.stringify(locker).replace(/"/g, "'")
      });

      console.log(newFillingPlanStr)
      localStorage.setItem("CurrentFillingPlan", newFillingPlanStr);
    }

    setShowLoadingModal(true)
    const lockersToReplenish: any[] = [];

    // get Lockers To Enable (end Replenishment)
    if (StoreObj !== null) {
      const fillingPlanStr = StoreObj.FillingPlan;

      if (fillingPlanStr) {
        console.log(fillingPlanStr);
        let fillingPlan = fillingPlanStr.split("|");
        fillingPlan.splice(0, 1);

        fillingPlan.forEach(locker => {
          const _locker = locker.replace(/'/g, '"');
          lockersToReplenish.push(JSON.parse(_locker));
        });
        console.log(lockersToReplenish);
        setLockersToBeReplenished(lockersToReplenish);
      }
    }

    API.put('AE', `/Stores`, { body })
      .then(res => {
        console.log(res)
        localStorage.setItem("ActiveStore", JSON.stringify(res))
        setStoreContext(res)
        // endReplenishment(Blocks, lockersToReplenish, StoreObj);

        history.push(
          {
            store: StoreObj,
            pathname: '/store-replenishment-jofemar',
            storeId: StoreId,
            title: StoreTitle,
            Jofemar: Jofemar,
            Jofemars: Jofemars,
            IsDirtyCurrentJofemarContents: IsDirtyCurrentJofemarContents,
            lockersDistributionPlan: LockersDistributionPlan,
            products: Products
          })
      })
      .catch(error => {
        console.log(error);
        setToastType("error");
        setToastMessage("Error updating store");
        setShowToastMessage(true);
      })
      .finally(() => {
        setShowLoadingModal(false);
      })
  }



  function fillingPlanToLockersDistributionPlan(FillingPlanStr) {
    let fillingPlanSplit;
    let FillingPlan: any[] = [];
    let lockersDistributionPlan: any[] = [];
    fillingPlanSplit = FillingPlanStr.split("|");
    fillingPlanSplit.shift();

    fillingPlanSplit.forEach(entry => {
      FillingPlan.push(JSON.parse(entry.replace(/'/g, '"')));
    });

    FillingPlan.forEach(entry => {
      if (!lockersDistributionPlan.find(x => x.ProductId === entry.ProductId)) {
        lockersDistributionPlan.push({
          ProductId: entry.ProductId,
          Amount: 1,
          LockersUpdatedIds: [entry.LockerId]
        })
      } else {
        const lockersDPlanEntry = lockersDistributionPlan.find(x => x.ProductId === entry.ProductId)
        const index = lockersDistributionPlan.findIndex(x => x.ProductId === entry.ProductId)
        const lockersArr = lockersDPlanEntry.LockersUpdatedIds;
        lockersArr.push(entry.LockerId);
        lockersDistributionPlan[index].Amount = lockersDistributionPlan[index].Amount + 1;
        lockersDistributionPlan[index].LockersUpdatedIds = lockersArr;
      }
    });

    return lockersDistributionPlan;
  }

  async function applyPreviousFillingPlan() {
    setShowConfirmationPreviousPlan(false);
    console.log(ShowConfirmationPreviousPlan);
    const _Blocks = [...Blocks];
    console.log(StoreObj);
    if (StoreObj !== null) {
      const fillingPlanStr = StoreObj.FillingPlan;
      console.log(fillingPlanStr);
      //const lockersDistPlan = fillingPlanToLockersDistributionPlan(fillingPlanStr);
      const lockersToReplenish: any[] = [...LockersToBeReplenished];
      const lockersToUpdate: any[] = [];

      _Blocks.forEach(block => {
        block.Lockers.forEach(locker => {
          if (lockersToReplenish.find(l => l.LockerId === locker.Id)) {
            const lockerToReplenish = lockersToReplenish.find(l => l.LockerId === locker.Id);
            const todayStr = formatDate(new Date());
            locker.ReplenishedAt = todayStr;
            locker.ProductId = lockerToReplenish.ProductId;
            locker.IsSoldOut = false;
            lockersToUpdate.push(locker);
          } else {
            // locker.ReplenishedAt = null;
            // locker.ProductId = null;
            // lockersToUpdate.push(locker);
          }
        });
      });

      setReplenishmentStep(2);

      setBlocks(_Blocks);
      setLockersSelected([]);
      //setLockersDPlanContext(lockersDistPlan);
      //setLockersDistributionPlan(lockersDistPlan)
      //localStorage.setItem("LockersDistPlan", JSON.stringify(lockersDistPlan))
      localStorage.setItem("StoreLayout", JSON.stringify(_Blocks));

      setFillingPlanApplied(true);
      setShowStartReplenishmentConfirmation(true);
    }
  }

  function replenishActivatedLockers(lockersToReplenish) {
    const _Blocks = [...Blocks];
    const lockersSelected: any = [...LockersSelected];
    const lockerIdToReplenish: any = [];

    lockersToReplenish.forEach(lockerToR => {
      _Blocks.forEach(block => {
        block.Lockers.forEach(locker => {
          if (lockerToR.Id === locker.Id) {
            const product = Products.find(p => p.Id === locker.ProductId);
            lockerIdToReplenish.push({ lockerId: locker.Id, productId: product.Id });
          }
        });
      });
    });

    console.log("lockerIdToReplenish", lockerIdToReplenish);
    console.log("lockersSelected", lockersSelected);

    setLockersSelected(lockersSelected);
    switchProductsR(lockerIdToReplenish, lockersSelected);
  }

  function determineBlockClass(blockCode) {
    if (blockCode.split('-')[0] === 'G') {
      let numberOfColumns = blockCode.split('-')[3];

      return `block ${numberOfColumns > 1 ? "double" : "single"}`;
    }
    else {
      return `block ${blockCode.split('-')[0].indexOf("2") > -1 ? "double" : "single"} ${blockCode.split('-')[0]}`;
    }
  }

  function determineBlockHeight(blockCode) {
    let blockType = blockCode.split('-')[0];

    switch (blockType) {
      case 'G':
        const lockerHeight = 61;
        let numberOfRows = blockCode.split('-')[4];
        let blockHeight = numberOfRows * lockerHeight;

        return blockHeight;
      case 'D2':
        console.log('D2 gevonden');
        return 410;
      default:
        console.log(blockType + 'Default');
        return 475;
    }
  }

  return (
    StoreId && Blocks.length ?
      <div id="ReplenishmentScreen" className={classes.root}>
        {StoreId && Blocks.length ?
          <>
            <div className="replenishment-header flex-center-align">
              <div style={{ cursor: "pointer" }}>
                <MdArrowBack size="30" onClick={() => history.goBack()} />
              </div>
              <h1 id="storeTitle" className="ml1">{StoreTitle}</h1>
              <h2 className="ml1">Vullen - {ReplenishmentStep === 1 ? "Stap 1 producten uitnemen" : "Stap 1 Producten uitnemen"}</h2>
            </div>
            <div className="replenishment-actions flex-center-align">

              <Button
                id={"activateLockersBtn"}
                type="button"
                disabled={false}
                variant="contained"
                className="green-btn"
                onClick={() => handleSelectAction(1)}
              >Activeren
              </Button>

              <Button
                id={"deactivateLockersBtn"}
                type="button"
                disabled={false}
                variant="contained"
                className="green-btn"
                onClick={() => handleSelectAction(0)}
              >Deactiveren
              </Button>

              <Button
                id={"openProductPanel"}
                type="button"
                disabled={false}
                variant="contained"
                className="green-btn"
                onClick={() => setShowPanel(true)}
              >Wissel
              </Button>

              <Button
                id="emptyLocker"
                type="button"
                disabled={false}
                variant="contained"
                className="red-btn"
                onClick={() => deleteLockersHandler()}
              >Verwijder
              </Button>

              <div className="flex-reverse" style={{ flex: 1 }}>
                <div className="flex ai-center">
                  <span>Locker weergave: </span>
                  <ButtonGroup className="ml-1" variant="contained" aria-label="contained primary button group">
                    <Button
                      className={LockersView === "Back" ? "green-btn" : ""}
                      onClick={() => handleLockersViewChange("Back")}
                    >Achterkant</Button>
                    <Button
                      className={LockersView === "Front" ? "green-btn" : ""}
                      onClick={() => handleLockersViewChange("Front")}
                    >Voorkant</Button>
                  </ButtonGroup>
                </div>
              </div>

            </div>
          </>
          : null}
        {Blocks ?
          <div
            id="blocksContainer"
            className="blocks-container flex"
            style={{
              // width: Blocks.length * 245 + 'px',
              maxWidth: ShowPanel ? "70vw" : "90vw",
              flexDirection: LockersView === "Back" ? 'row-reverse' : 'unset'
            }}>
            {Blocks.map((block, index) => {
              return (

                <div key={index} className={determineBlockClass(block.Code)}>

                  <div className="block-header flex">
                    <Checkbox
                      checked={BlocksSelected.indexOf(index + 1) > -1}
                      onChange={e => handleCheckboxChange(e, "block", block.Lockers, index + 1)}
                      inputProps={{ 'aria-label': 'checkbox' }}
                    />
                    <p>Block{" "}{block.Description}</p>
                  </div>

                  <div className="lockers-container flex" style={{ maxHeight: determineBlockHeight(block.Code) }}>

                    {block.Lockers.map((locker, lockerIndex) => {
                      return (
                        <div key={locker.Id} id={`locker${locker.Id}`}
                          onClick={e => handleCheckboxChange(e, "locker", locker.Id)}
                          className={`locker flex ${locker.Enabled ? '' : 'disabled-locker'} ${locker.ProductId ?
                            getProductStatus(locker) : "empty-locker"} lblock${index + 1}`}
                          style={{ order: LockersView === "Back" ? block.Lockers.length >= 12 && lockerIndex >= block.Lockers.length / 2 ? -1 : 0 : 'unset' }}
                        >
                          <Checkbox
                            className={`checkbox${locker.Id}`}
                            checked={LockersSelected.indexOf(locker.Id) > -1}
                            // onChange={e => handleCheckboxChange( "locker", locker.Id)}
                            inputProps={{ 'aria-label': 'checkbox' }}
                          />
                          <div className={`product-avatar-container ${block.Code.split('-')[0][0]}`}>
                            {locker.ProductId && UserConfigData.Customer ?
                              <img id={`img${locker.Id}p${locker.ProductId}`}
                                className={`p${locker.ProductId}`}
                                style={{ opacity: !locker.Enabled ? 0.3 : 1 }}
                                src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${UserConfigData.Customer}/images/products/pid${locker.ProductId}_${Products.find(prod => prod.Id === locker.ProductId).Avatar}`}
                                alt="productImg" />
                              : null
                            }
                            {
                              LockersToBeReplenished.length &&
                                LockersToBeReplenished.find(l => l.LockerId === locker.Id) &&
                                ReplenishmentStep === 1 &&
                                locker.ProductId ?
                                <FaSyncAlt color={"#1976d2"} size={20} />
                                : null}
                            {
                              LockersToBeReplenished.length &&
                                LockersToBeReplenished.find(l => l.LockerId === locker.Id) &&
                                ReplenishmentStep === 2 &&
                                locker.Enabled &&
                                locker.ProductId ?
                                <MdCheckCircle color={"#689f38"} size={20} />
                                : null}
                            {
                              locker.ProductId && locker.Enabled && !LockersToBeReplenished.find(l => l.LockerId === locker.Id) &&
                                getProductStatus(locker) === "green-locker" ?
                                <MdCheckCircle color={"#689f38"} size={20} />
                                : null
                            }
                            {
                              locker.Enabled ? null : <FaPowerOff color={"#fff"} size={20} />
                            }
                          </div>
                          {locker.ProductId &&
                            (getProductStatus(locker) === "sold-out-locker" || getProductStatus(locker) === "almost-expired-locker") ?
                            <div className={"locker-shape"} /> : null}
                          <p className="product-code">{locker.ProductId ? Products.find(p => p.Id === locker.ProductId).Code : "Code"}</p>

                        </div>
                      )
                    })}

                  </div>

                </div>
              )
            })
            }
          </div>
          : null}

        {Blocks.length ?
          <div className="replenishment-bottom flex jcsb">
            <div className="legend flex">
              <div className="ml1 flex">
                <div className="green-locker">
                  <MdCheckCircle color={"#689f38"} size={20} />
                </div>
                <span>In verkoop</span>
              </div>
              <div className="ml1 flex">
                <div className="almost-expired-locker">
                  <div className="locker-shape" />
                </div>
                <span>Uiterste verkoopdatum</span>
              </div>
              <div className="ml1 flex">
                <div className="sold-out-locker">
                  <div className="locker-shape" />
                </div>
                <span>Verkocht</span>
              </div>
            </div>
            <div>
              {ReplenishmentStep === 1 ?
                <Button
                  id="replenishment2"
                  type="button"
                  variant="contained"
                  className="green-btn"
                  onClick={() => FillingPlanApplied ?
                    applyPreviousFillingPlan() :
                    setShowApplyFillingPlanConfirmation2(true)
                  }
                >Stap 2 - producten aanvullen
                </Button> :
                JofemarButton()
              }
            </div>
          </div>
          : null}

        {ProductCategories && Products ?
          <ProductPanel
            productCategories={ProductCategories}
            products={Products}
            lockersSelected={LockersSelected}
            switchProducts={switchProductsR}
            showPanel={ShowPanel}
            isJofemar={false}
            closePanel={() => setShowPanel(false)}
          />
          : null}

        {showConfirmationModal ?
          <ConfirmationModal
            message={"Empty Locker(s)?"}
            confirm={confirmEmptyLockers}
            closeModal={() => setShowConfirmationModal(false)}
          />
          : null}

        {ShowLockersClosedConfirmation ?
          <ConfirmationModal
            message={"Zijn al de lockers dicht?"}
            confirm={() => prepFillingPlan()}
            closeModal={() => setShowLockersClosedConfirmation(false)}
          />
          : null}

        {ShowStartReplenishmentConfirmation ?
          <ConfirmationModal
            message={"Start vullen?"}
            confirmText={"JA"}
            cancelText={"NEE"}
            confirm={() => startReplenishment(Blocks, LockersToBeReplenished)}
            closeModal={() => setShowStartReplenishmentConfirmation(false)}
          />
          : null}

        {ShowApplyFillingPlanConfirmation ?
          <ConfirmationModal
            message={"Vulplan toepassen?"}
            confirmText={"JA"}
            cancelText={"NEE"}
            confirm={() => showLockersToBeReplenished(Blocks)}
            closeModal={() => setShowApplyFillingPlanConfirmation(false)}
          />
          : null}

        {ShowApplyFillingPlanConfirmation2 ?
          <ConfirmationModal
            message={"Vulplan toepassen?"}
            confirmText={"JA"}
            cancelText={"Nee ik ben klaar met vullen"}
            confirm={() => {
              setShowApplyFillingPlanConfirmation2(false);
              applyPreviousFillingPlan();
            }}
            closeModal={() => { setShowApplyFillingPlanConfirmation2(false); setShowLockersClosedConfirmation(true); }}
          />
          : null}
      </div>
      : <LoadingModal />)

  function JofemarButton() {
    if (Jofemar) {
      return <Button
        id="goToJofemarBtn"
        type="button"
        variant="contained"
        className="green-btn"
        onClick={() => setShowLockersClosedConfirmation(true)}
      >Stap 2 - Vending machine
      </Button>;
    }
    else {
      return null;
    }
  }
}
export default StoreReplenishmentScreen