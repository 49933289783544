import React, { useEffect, useState } from 'react'
import ProductsTable from './Products/ProductsTable'
import { makeStyles } from '@material-ui/core/styles';
import PromotionsTable from './Promotions/PromotionsTable';
import PromotionsCartTable from './Promotions/PromotionsCartTable';
import ProductCategoriesTable from './ProductCategories/ProductCategoriesTable';
import { API } from 'aws-amplify';
import useUIState from '../hooks/useUIstate';

const useStyles = makeStyles(theme => ({

  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    height: "100%",
    paddingLeft: "10px",
  },
}));

function ProductManagement() {
  const classes = useStyles();
  const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();
  const [ShowCategoriesTables, setShowCategoriesTables] = useState(false);
  const [ShowProductsTables, setShowProductsTables] = useState(false);
  const [ProductCategories, setProductCategories] = useState([]);
  const [Products, setProducts] = useState([]);
  const [UserConfigData, setUserConfigData]: [any, any] = useState([]);
  const [IsMounted, setIsMounted] = useState(false)

  useEffect(() => {
    const value = localStorage.getItem("UserConfigData");
    if (typeof value === 'string') {
        setUserConfigData(JSON.parse(value));
    }

    setIsMounted(true);
    localStorage.setItem("activeRoute", "/product-management");
    getProductCategories();
    return () => {
      setIsMounted(false);
    }
  }, [])

  function getProductCategories() {
    setShowLoadingModal(true);
    API.get("AE", `/ProductCategories`, { response: true })
      .then(res => {
        console.log(res.data);
        setProductCategories(res.data);
        setShowCategoriesTables(true);
        getProducts();

      })
      .catch(error => {
        console.log(error);
        setToastType("error");
        setToastMessage("Error getting products");
        setShowToastMessage(true);
      })

  }

  function getProducts() {
    setShowLoadingModal(true);
    API.get("AE", `/Products`, { response: true })
      .then(res => {
        console.log(res.data);
        setShowProductsTables(true);
        setProducts(res.data);

      })
      .catch(error => {
        console.log(error);
        setToastType("error");
        setToastMessage("Error getting products");
        setShowToastMessage(true);
      })
      .finally(() => {
        setShowLoadingModal(false)
      })
  }

  let featureChallengeSpendingPromotion = false;
  if (UserConfigData && UserConfigData.EnabledFeatures) {
    if (UserConfigData.EnabledFeatures.includes('challengespendingpromotion')) {
      featureChallengeSpendingPromotion = true;
    }
  }

  return (
    <div id="productManagement" className={classes.root}>
      {ShowCategoriesTables ?
        <ProductCategoriesTable
          data={ProductCategories}
          getData={() => getProductCategories()}
        />
        : null}
      {
        ShowCategoriesTables && ShowProductsTables ?
          <>
            <ProductsTable data={Products} productCategories={ProductCategories} getData={() => getProducts()} />
            <PromotionsTable products={Products} />
          </> : null
      }
      {
        ShowCategoriesTables && ShowProductsTables && featureChallengeSpendingPromotion ?
        <>
          <PromotionsCartTable products={Products} />
        </> : null
      }
    </div>
  )
}

export default ProductManagement