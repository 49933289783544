import { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom';
import ProductManagement from '../content/ProductManagement';
import DistributionPlan from '../content/ReplenishmentPlan/DistributionPlan';
import ReplenishmentScreen from '../content/ReplenishmentPlan/ReplenishmentScreen';
import ReplenishmentJofemarScreen from '../content/ReplenishmentPlan/ReplenishmentJofemarScreen';
import StoreReplenishmentScreen from '../content/StoreReplenishment/StoreReplenishmentScreen';
import StoreReplenishmentJofemarScreen from '../content/StoreReplenishment/StoreReplenishmentJofemarScreen';
import StoreScreen from '../content/Stores/StoreScreen';
import UserManagementTable from '../content/UserManagement/UserManagementTable';
import StoreSupportScreen from '../content/StoreSupport/StoreSupportScreen';
import Dashboard from '../content/Dashboard/Dashboard';
import useAuthState from '../hooks/useAuthState';
import StoreOverviewScreen from '../content/StoreOverview/StoreOverviewScreen';
import StoreSupportJofemarScreen from '../content/StoreSupport/StoreSupportJofemarScreen';

function RoutesContainer() {
  const { User } = useAuthState();
  const [UserGroup, setUserGroup] = useState("")

  useEffect(() => {
    if (User.attributes["custom:role"]) {
      setUserGroup(User.attributes["custom:role"])
    }
  }, [User])

  return (
    <div id="tableContainer">
      <Switch>

        {UserGroup !== "replenishmentuser" ?
          <Route
            exact
            path="/dashboard"
            component={Dashboard}
          /> : null}

        <Route
          exact
          path="/retail-locations"
          component={StoreScreen}
        />

        {UserGroup !== "replenishmentuser" ?
          <Route
            exact
            path="/replenishment-plan"
            component={ReplenishmentScreen}
          /> : null}

        {UserGroup !== "replenishmentuser" ?
          <Route
            exact
            path="/replenishment-plan-jofemar"
            component={ReplenishmentJofemarScreen}
          /> : null}

        <Route
          exact
          path="/distributieplan"
          component={DistributionPlan}
        />

        {UserGroup !== "replenishmentuser" ?
          <Route
            exact
            path="/product-management"
            component={ProductManagement}
          /> : null}

        <Route
          exact
          path="/store-replenishment"
          component={StoreReplenishmentScreen}
        />
        <Route
          exact
          path="/store-replenishment-jofemar"
          component={StoreReplenishmentJofemarScreen}
        />
        <Route
          exact
          path="/store-support"
          component={StoreSupportScreen}
        />
        <Route
          exact
          path="/store-support-jofemar"
          component={StoreSupportJofemarScreen}
        />
        <Route
          exact
          path="/store-overview"
          component={StoreOverviewScreen}
        />

        {UserGroup !== "replenishmentuser" ?
          <Route
            exact
            path="/user-management"
            component={UserManagementTable}
          /> : null}

      </Switch>
    </div>
  )
}

export default RoutesContainer
