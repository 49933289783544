import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import useUIState from '../../hooks/useUIstate';
import { MdArrowBack } from "react-icons/md";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { MdRemove, MdAdd } from 'react-icons/md';
import useDataState from '../../hooks/useDataState';
import DistributionPrint from './DistributionPrint';
import { getInt } from '../../helpers/commonFunctions';
import awsconfig from '../../aws-apiSettings';
import API from '@aws-amplify/api';

const useStyles = makeStyles((theme) => ({
  root: {
    '& h1, & h2': {
      marginTop: '5px',
      marginBottom: '5px'
    },
    '& .dplan-header': {
      borderBottom: '1px solid #ddd',
    },

    '& .header-element': {
      padding: '5px',
      fontWeight: 700
    },
    '& .body-element': {
      padding: '10px 5px',
      alignItems: "center",
      display: "flex",
    },
    '& .product-entry-dplan': {
      width: "100%",
      padding: "5px 0",
      borderBottom: '1px solid #ddd',
    },
    '& .product-image-container': {
      minWidth: '65px',
      height: '65px',
      background: "white",
      marginRight: "5px",
    },
    '& .product-image-container img': {
        width: '63px',
        height: '63px',
        objectFit: "fill"
    },
    '& .body-element.additional-value .MuiTextField-root': {
      width: "50px",
      margin: "0 5px",
      background: "white",
    },
    '& .body-element.additional-value .MuiButtonBase-root': {
      padding: "5px",
      minWidth: "40px"
    },
    '& .product': { flex: 1 },
    '& .replenishment-value': { width: "100px" },
    '& .additional-value': { width: "200px" },
    '& .total-value': { width: "100px" },
    '& #lockersProductsPlan': {
      marginTop: "40px"
    },
    '& .replenishment-bottom': {
      marginTop: "40px",
      maxWidth: "90vw"
    }
  }
}));

function DistributionPlan(props) {
  const history = useHistory();
  const classes = useStyles();
  const { LockersDPlanContext, JofemarDPlanContext, ProductsContext, StoreContext } = useDataState();
  const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();

  const [StoreTitle, setStoreTitle] = useState("");
  const [JofemarTitle, setJofemarTitle] = useState("");
  const [Store, setStore] = useState(null)
  const [LockersProducts, setLockersProducts]: [any, any] = useState([]);
  const [DProducts, setDProducts]: [any, any] = useState([]);
  const [JofemarProducts, setJofemarProducts]: [any, any] = useState([]);
  const [LockersAdditionalValues, setLockersAdditionalValues]: [any, any] = useState([])
  const [JofemarAdditionalValues, setJofemarAdditionalValues]: [any, any] = useState([])
  const [ShowPDFPreview, setShowPDFPreview] = useState(false);
  const [Products, setProducts]: [any, any] = useState([])
  const [UserConfigData, setUserConfigData]: [any, any] = useState([]);

  function handleChange(e, row, action = "", value = 0) {
    const lockersAdditionalValues = [...LockersAdditionalValues];
    if (!action) {
      if (e.target.value === '') {
        lockersAdditionalValues[row].AdditionalAmount = e.target.value;
      } else {
        lockersAdditionalValues[row].AdditionalAmount = getInt(e.target.value, 0, 0);
      }
    } else if (action === "decrement") {
      if (value > 0) {
        lockersAdditionalValues[row].AdditionalAmount = value - 1;
      }
    } else if (action === "increment") {
      lockersAdditionalValues[row].AdditionalAmount = value + 1;
    }
    setLockersAdditionalValues(lockersAdditionalValues);
  }

  function handleChangeJofemar(e, row, action = "", value = 0) {
    const jofemarAdditionalValues = [...JofemarAdditionalValues];
    if (!action) {
      if (e.target.value === '') {
        jofemarAdditionalValues[row].AdditionalAmount = e.target.value;
      } else {
        jofemarAdditionalValues[row].AdditionalAmount = getInt(e.target.value, 0, 0);
      }
    } else if (action === "decrement") {
      if (value > 0) {
        jofemarAdditionalValues[row].AdditionalAmount = value - 1;
      }
    } else if (action === "increment") {
      jofemarAdditionalValues[row].AdditionalAmount = value + 1;
    }
    setJofemarAdditionalValues(jofemarAdditionalValues);
  }

  useEffect(() => {
    const value = localStorage.getItem("UserConfigData");
    if (typeof value === 'string') {
      setUserConfigData(JSON.parse(value));
    }

    console.log(props.location)
    if (props.location.storeId) {
      setStoreTitle(props.location.title);
      setProducts(props.location.products);
      setDProducts(props.location.products);
      setStore(props.location.store);
      setJofemarTitle(props.location.JofemarTitle)
      console.log(props.location)

    } else if (ProductsContext && Object.entries(ProductsContext).length) {
      setProducts(ProductsContext);
      setDProducts(ProductsContext);
      setStore(StoreContext);
    } else if (localStorage.getItem("Products")) {
      const Products = localStorage.getItem("Products")
      if (Products) {
        setProducts(JSON.parse(Products));
        setDProducts(JSON.parse(Products));
      }
      const Store = localStorage.getItem("ActiveStore");
      if (Store) {
        const StoreJson = JSON.parse(Store)
        setStoreTitle(StoreJson.StoreTitle);
        setStore(StoreJson);
      }
    } else {
      history.push('/retail-locations');
    }
  }, [])

  function initDistPlan() {
    setShowLoadingModal(false)
  }

  useEffect(() => {
    if (LockersDPlanContext.length) {
      setLockersProducts(LockersDPlanContext);
    } else {
      if (props.location.lockersDistributionPlan) {
        setLockersProducts(props.location.lockersDistributionPlan)
      } else if (props.location.lockersDistributionPlanCG) {
        let lockersDistPlan = props.location.lockersDistributionPlanCG.split("|");
        lockersDistPlan.shift();
        console.log(lockersDistPlan);
        const lockersProducts: any[] = [];
        lockersDistPlan.forEach(p => {
          lockersProducts.push(JSON.parse(p.replace(/'/g, '"')));
        });
        setLockersProducts(lockersProducts);
      } else {
        const lockersProducts = localStorage.getItem("LockersDistPlan");
        if (lockersProducts) {
          const lockersJson = JSON.parse(lockersProducts);
          setLockersProducts(lockersJson);
        }
      }
    }
  }, [LockersDPlanContext])

  useEffect(() => {
    if (JofemarDPlanContext.length) {
      setJofemarProducts(JofemarDPlanContext);
    } else {
      if (props.location.jofemarDistributionPlan) {
        setJofemarProducts(props.location.jofemarDistributionPlan)

      } else if (props.location.jofemarDistributionPlanCG) {
        console.log(props.location.jofemarDistributionPlanCG)
        let jofemarDistPlan = props.location.jofemarDistributionPlanCG.split("|");
        jofemarDistPlan.shift();
        console.log(jofemarDistPlan);
        const jofemarProducts: any[] = [];
        jofemarDistPlan.forEach(p => {
          let product = JSON.parse(p.replace(/'/g, '"'));
          product.ReplenishmentAmount = product.Amount;
          jofemarProducts.push(product);

        });
        setJofemarProducts(jofemarProducts);

      } else {
        const jofemarProducts = localStorage.getItem("JofemarDistPlan");
        if (jofemarProducts) {
          const jofemarJson = JSON.parse(jofemarProducts);
          setJofemarProducts(jofemarJson);
        }
      }
    }
  }, [JofemarDPlanContext])

  useEffect(() => {
    if (LockersProducts.length) {
      // set lockers additional values
      console.log(LockersProducts);
      const lockersAdditionalValues: any[] = [];
      LockersProducts.forEach(product => {
        lockersAdditionalValues.push({
          ProductId: product.ProductId,
          AdditionalAmount: props.location.lockersDistributionPlanCG ?
            product.AdditionalAmount : 0
        })
      });


      setLockersAdditionalValues(lockersAdditionalValues);
    }
  }, [LockersProducts])

  useEffect(() => {
    if (JofemarProducts.length) {
      // set Jofemar additional values
      console.log(JofemarProducts);
      const jofemarAdditionalValues: any[] = [];
      JofemarProducts.forEach(product => {
        jofemarAdditionalValues.push({
          ProductId: product.ProductId,
          AdditionalAmount: props.location.jofemarDistributionPlanCG ?
            product.AdditionalAmount : 0
        })
      });
      setJofemarAdditionalValues(jofemarAdditionalValues);
    }
  }, [JofemarProducts])

  function getcurrentDutchDate() {

    const days = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
    const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni",
      "Juli", "Augustus", "September", "Oktober", "November", "December"
    ];
    const d = new Date();
    const dayName = days[d.getDay()];
    const monthName = monthNames[d.getMonth()]
    const day = d.getDate();
    return `${dayName} ${day} ${monthName}`;
  }

  function getAmountOrWeight(Id) {
    const _productsStr = localStorage.getItem("Products")
    let attr;
    if (_productsStr) {
      const _products = JSON.parse(_productsStr);
      attr = _products.find(p => p.Id === Id).Attributes
    }

    attr = JSON.parse(attr.replace(/'/g, '"'))

    let amount = attr.Priority === 'Amount' ?
      attr.Amount :
      attr.Weight

    return `${attr.Priority} ${amount}`;
  }

  function getTitle(Id) {
    const _productsStr = localStorage.getItem("Products")
    let title = "";
    if (_productsStr) {
      const _products = JSON.parse(_productsStr);
      title = _products.find(x => x.Id === Id).Title
    }

    return title;
  }

  function saveDistributionPlan() {
    setShowLoadingModal(true);

    const lockersDistributionPlan: any[] = [];
    let lockersDistributionStr = "";

    if (LockersProducts.length) {
      LockersProducts.forEach((product, index) => {
        lockersDistributionPlan.push({
          ProductId: product.ProductId,
          Amount: product.Amount,
          AdditionalAmount: LockersAdditionalValues[index].AdditionalAmount,
          Total: product.Amount + LockersAdditionalValues[index].AdditionalAmount
        })
      });

      lockersDistributionPlan.forEach(product => {
        lockersDistributionStr = lockersDistributionStr + "|" + JSON.stringify(product).replace(/"/g, "'");
      });

      console.log(lockersDistributionPlan);
      console.log(lockersDistributionStr);
    }

    const jofemarDistributionPlan: any[] = [];
    let jofemarDistributionStr = "";

    if (JofemarProducts.length) {
      JofemarProducts.forEach((product, index) => {
        jofemarDistributionPlan.push({
          ProductId: product.ProductId,
          Amount: product.ReplenishmentAmount,
          AdditionalAmount: JofemarAdditionalValues[index].AdditionalAmount,
          Total: product.ReplenishmentAmount + JofemarAdditionalValues[index].AdditionalAmount
        })
      });

      jofemarDistributionPlan.forEach(product => {
        jofemarDistributionStr = jofemarDistributionStr + "|" + JSON.stringify(product).replace(/"/g, "'");
      });

      console.log(jofemarDistributionPlan);
      console.log(jofemarDistributionStr);
    }

    const reqBody = JSON.parse(JSON.stringify(Store));
    reqBody.LockersDistributionPlan = lockersDistributionStr;
    reqBody.JofemarDistributionPlan = jofemarDistributionStr;
    const body = reqBody;
    console.log(body);

    API.put('AE', `/Stores`, { body })
      .then(res => {
        console.log(res);
        setShowPDFPreview(true);
      })
      .catch(error => {
        console.log(error);
        setToastType("error");
        setToastMessage("Error updating store");
        setShowToastMessage(true);
      })
      .finally(() => {
        setShowLoadingModal(false);
      })

  }

  return (
    <div id="distributionPlan" className={classes.root}>
      <div className="replenishment-header flex-center-align">
        {/* <div style={{ cursor: "pointer" }}>
          <MdArrowBack size="30" onClick={() => history.goBack()} />
        </div> */}
        <h1
          id="storeTitle"
        //  className="ml1"
        >Distributieplan</h1>
      </div>
      <div className="mt1">
        <h2 className="mt0">{StoreTitle}</h2>
        <h4 className="mt0 gray">{getcurrentDutchDate()}</h4>
      </div>
      {LockersAdditionalValues.length && DProducts.length ?
        <div id="lockersProductsPlan">
          <h3>Lockers</h3>
          <div id="lockersPlanContents">
            <div className="dplan-header flex">
              <div className="header-element product">Product</div>
              <div className="header-element replenishment-value">Vulplan</div>
              <div className="header-element additional-value">Voorraad</div>
              <div className="header-element total-value">Total</div>
            </div>

            <div className="dplan-body flex-col">
              {LockersProducts.map((product, index) => {
                return (
                  <div key={product.Title} className="product-entry-dplan flex">
                    <div className="product flex">
                      <div className="product-image-container">
                        {product.ProductId && UserConfigData.Customer ?
                          <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${UserConfigData.Customer}/images/products/pid${product.ProductId}_${Products.find(prod => prod.Id === product.ProductId).Avatar}`} alt="prod-img" />
                          : null
                        }
                      </div>
                      <div className="flex-col ml1 jcc">
                        <div id="productTitle gray">{getTitle(product.ProductId)}</div>
                        <div id="productAmount gray">{getAmountOrWeight(product.ProductId)}</div>
                      </div>
                    </div>
                    <div className="body-element replenishment-value">{product.Amount}</div>
                    <div className="body-element additional-value">
                      <Button key={"btnDecrease" + index} variant="contained" className="gray-btn"
                        onClick={(e) => handleChange(e, index, "decrement", LockersAdditionalValues[index].AdditionalAmount)}
                      >
                        <MdRemove size={22} />
                      </Button>
                      <TextField
                        key={"txtAmount" + index}
                        type="text"
                        onChange={e => handleChange(e, index)}
                        value={LockersAdditionalValues[index].AdditionalAmount}
                        variant="outlined"
                        size="small"
                      />
                      <Button key={"btnIncrease" + index} variant="contained" className="gray-btn"
                        onClick={(e) => handleChange(e, index, "increment", LockersAdditionalValues[index].AdditionalAmount)}
                      >
                        <MdAdd size={22} />
                      </Button>
                    </div>
                    <div className="body-element total-value">{product.Amount + LockersAdditionalValues[index].AdditionalAmount}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        : null}
      {JofemarAdditionalValues.length && DProducts.length ?
        <div id="jofemarProductsPlan" className="mt1">
          <h3>Jofemar {JofemarTitle}</h3>
          <div id="lockersPlanContents">
            <div className="dplan-header flex">
              <div className="header-element product">Product</div>
              <div className="header-element replenishment-value">Vulplan</div>
              <div className="header-element additional-value">Vooraad</div>
              <div className="header-element total-value">Total</div>
            </div>

            <div className="dplan-body flex-col">
              {JofemarProducts.map((product, index) => {
                return (
                  <div key={product.Title} className="product-entry-dplan flex">
                    <div className="product flex">
                      {UserConfigData.Customer ?
                        <div className="product-image-container">
                          {product.ProductId ?
                            <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${UserConfigData.Customer}/images/products/pid${product.ProductId}_${Products.find(prod => prod.Id === product.ProductId).Avatar}`} alt="prod-img" />
                            : null
                          }
                        </div>
                        : null
                      }
                      <div className="flex-col ml1 jcc">
                        <div id="productTitle gray">{getTitle(product.ProductId)}</div>
                        <div id="productAmount gray">{getAmountOrWeight(product.ProductId)}</div>
                      </div>
                    </div>
                    <div className="body-element replenishment-value">{product.ReplenishmentAmount}</div>
                    <div className="body-element additional-value">
                      <Button variant="contained" className="gray-btn"
                        onClick={(e) => handleChangeJofemar(e, index, "decrement", JofemarAdditionalValues[index].AdditionalAmount)}
                      >
                        <MdRemove size={22} />
                      </Button>
                      <TextField
                        type="text"
                        onChange={e => handleChangeJofemar(e, index)}
                        value={JofemarAdditionalValues[index].AdditionalAmount}
                        variant="outlined"
                        size="small"
                      />
                      <Button variant="contained" className="gray-btn"
                        onClick={(e) => handleChangeJofemar(e, index, "increment", JofemarAdditionalValues[index].AdditionalAmount)}
                      >
                        <MdAdd size={22} />
                      </Button>
                    </div>
                    <div className="body-element total-value">{product.ReplenishmentAmount + JofemarAdditionalValues[index].AdditionalAmount}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        : null}
      <div className="replenishment-bottom flex jcsb">
        <div>
          <Button
            id="backToStores"
            type="button"
            variant="contained"
            className="gray-btn"
            onClick={() => history.goBack()}
          // onClick={() => window.print()}
          >{props.location.jofemarDistributionPlanCG || props.location.lockersDistributionPlanCG
            ? "Winkels" : "Terug naar jofemar"}
          </Button>
        </div>
        <div>
          <Button
            id="previewPrintDistribution"
            type="button"
            variant="contained"
            className="green-btn"
            onClick={() => saveDistributionPlan()}
          >Save & Preview distributieplan paper
          </Button>
        </div>
      </div>


      {
        ShowPDFPreview ?
          <DistributionPrint
            title={StoreTitle}
            date={getcurrentDutchDate()}
            LockersProducts={LockersProducts}
            JofemarProducts={JofemarProducts}
            LockersAdditionalValues={LockersAdditionalValues}
            JofemarAdditionalValues={JofemarAdditionalValues}
            getAmountOrWeight={getAmountOrWeight}
            closePreview={() => setShowPDFPreview(false)}
          />
          : null
      }


    </div>
  )
}
export default DistributionPlan;
