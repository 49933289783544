import { Button } from '@material-ui/core';


interface Propsatt {
  passedFunction: any;
  disabled: boolean;
  activeId: number;
  jofemarInfo: {
    id: number;
    name: string;
  }[]
}

function JofemarSelectionPanel(propsatt) {
  if (propsatt.jofemarInfo.length > 1) {
    return (
      <div>
        {propsatt.jofemarInfo.map((item, index) => (
          <Button
            key={"jofemar" + index}
            id={item.id}
            disabled={propsatt.disabled}
            type="button"
            variant="contained"
            className={(propsatt.activeId === item.id ? "green-btn" : "inactive-btn") + " jofemarButtonSpacing"}
            onClick={() => propsatt.passedFunction(item)}
          >{item.name}
          </Button>
        ))}
      </div>
    );
  } else {
    return null;
  }
}

export default JofemarSelectionPanel;