import { useEffect } from 'react';
import { useState } from 'react';
import LoginScreen from './screens/LoginScreen';
import './App.scss';
import { Router, Route, Switch, useHistory } from 'react-router-dom';
import PrivateRoutesWrapper from './screens/PrivateRoutesWrapper';
import useAuthState from './hooks/useAuthState';
import useUIState from './hooks/useUIstate';
import LoadingModal from './components/LoadingModal';
import CustomSnackbar from './components/CustomSnackBar';
import { API, Auth } from "aws-amplify";
import { FlagsProvider } from 'flagged';

function App() {

  const { AuthStatus, setAuthStatus, setUser } = useAuthState();
  const history = useHistory();
  const { showLoadingModal, toastType, toastMessage, showToastMessage, setShowToastMessage } = useUIState();
  const [EnabledFeatures, setEnabledFeatures] = useState([]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setAuthStatus('loggedIn');
        console.log(user);
        setUser(user);
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        setAuthStatus('noUser')
        history.push('/login')
      });
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (AuthStatus === 'loggedIn') {
      async function fetchCustomerConfig() {
        API.get("AE", `/Stores/WhoAmI`, { response: true })
          .then(res => {
            localStorage.removeItem("UserConfigData");
            localStorage.setItem("UserConfigData", JSON.stringify(res.data));
            setEnabledFeatures(res.data.EnabledFeatures);
            console.log(JSON.stringify(res.data));

            const route = localStorage.getItem("activeRoute");
            history.push(route ? route : "/retail-locations");
          })
      }

      fetchCustomerConfig();
    }
    // eslint-disable-next-line
  }, [AuthStatus])

  return (
    <FlagsProvider features={EnabledFeatures}>
      <div className="App">
        <Router history={history}>
          <Switch>
            {/* {AuthStatus === 'loggedIn' && <PrivateRoute exact path="/" component={PrivateRoutesWrapper} />} */}
            {AuthStatus === 'loggedIn' && <PrivateRoutesWrapper />}
            {AuthStatus !== 'loggedIn' && <Route path="/login" component={LoginScreen} />}
            {/* <Redirect from="*" to="/" /> */}
          </Switch>
        </Router>
        {showLoadingModal ? <LoadingModal /> : null}
        {showToastMessage && toastMessage ?
          <CustomSnackbar
            open={showToastMessage}
            toastMessage={toastMessage}
            toastType={toastType}
            handleClose={() => setShowToastMessage(false)}
          /> : null
        }
      </div>
    </FlagsProvider>
  );
}

export default App;
