import { useEffect } from 'react'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table'
import { MdAddCircleOutline } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { MdDeleteForever, MdTranslate } from "react-icons/md";
import { useState } from 'react';
import { Feature } from 'flagged';

function DynamicTable({ columns, data, loading, ...props }) {
    const [showInactiveProducts, setShowInactiveProducts] = useState(false);
    const [savedCustomGlobalFilterValue, setSavedCustomGlobalFilterValue] = useState("");
    const [initialLoadDone, setInitialLoadDone] = useState(false);

    const customGlobalFilter =
        (rows, columns, filterValue) => {
            return rows.filter(row => {
                var show = false;

                columns.forEach(columnName => {
                    var rowValue = row.values[columnName]
                    if (rowValue !== undefined) {
                        var lower = String(rowValue).toLowerCase();
                        show = show || lower.indexOf(String(filterValue).toLowerCase()) >= 0;
                    }
                })

                // additional filter on 'Enabled' field
                if (props.showActiveField && !showInactiveProducts) {
                    columns.forEach(columnName => {
                        if (columnName === 'Enabled') {
                            var rowValue = row.values[columnName]
                            if (rowValue !== undefined) {
                                show = show && rowValue === 'Yes';
                            }
                        }
                    })
                }
                return show;
            });
        }

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        // Get the state from the instance
        state: { pageIndex, pageSize },

    } = useTable({
        columns,
        data,
        globalFilter: customGlobalFilter,
        initialState: {
            pageIndex: 0
        }, // Pass our hoisted table state
    },
        useGlobalFilter,
        useSortBy,
        usePagination,
    )

    useEffect(() => {
        if (props.globalFilter || props.showActiveField) {
            if (!initialLoadDone && data.length > 0) {
                // If we haven't loaded this data before, and we have more than 0 rows (so we have data), filter data for the first time (because that's not automatic?!)
                console.log('initial filtering..')
                setShowInactiveProducts(false)
                setGlobalFilter(savedCustomGlobalFilterValue) // force datatable refresh
                setInitialLoadDone(true);
            }
        }
        // props.dispatch({ type: actions.resetPage })
    }, [customGlobalFilter]);

    // Render the UI for your table
    return (
        <div id="dynamicTable" className={props.className ? props.className : ""}>
            {props.globalFilter || props.showActiveField ?
                <div className="flex filter-container">
                    {props.globalFilter ?
                        <div>
                            <span>Zoeken</span>
                            <input
                                style={true ? ({ marginRight: '20px' }) : ({})}
                                type="text"
                                value={savedCustomGlobalFilterValue}
                                onChange={e => {
                                    setSavedCustomGlobalFilterValue(e.target.value)
                                    setGlobalFilter(e.target.value)
                                }
                                }
                            />

                        </div>
                        : null}
                    {props.showActiveField ?
                        <div>
                            <label htmlFor="checkboxInactiveProducts">Inactieve producten tonen</label>
                            <input
                                type="checkbox"
                                id="checkboxInactiveProducts"
                                checked={showInactiveProducts}
                                onChange={e => {
                                    setShowInactiveProducts(e.target.checked)
                                    setGlobalFilter(savedCustomGlobalFilterValue) // force datatable refresh
                                }
                                }
                            />
                        </div>
                        : null}
                </div>
                : null}
            <MaUTable {...getTableProps()}>
                <TableHead>
                    {headerGroups.map(headerGroup => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {props.addRow ?
                                <TableCell className="add-btn">
                                    <MdAddCircleOutline
                                        size={24}
                                        onClick={() => props.addRow()}
                                    />
                                </TableCell>
                                : null}
                            {headerGroup.headers.map(column => {
                                return column.hide === true ? null : (
                                    <TableCell {...column.getHeaderProps(column.getSortByToggleProps({ style: { width: column.width ? column.width : 150 } }))}>
                                        {column.render('Header')}
                                    </TableCell>
                                );
                            }
                            )}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <TableRow {...row.getRowProps()}>
                                {props.addRow ?
                                    <TableCell>
                                        <div className="flex">
                                            {props.editRow ?
                                                <div className="edit-btn">
                                                    <MdEdit
                                                        size={24}
                                                        onClick={() => props.editRow(row)}
                                                    />
                                                </div> : null}
                                            {props.deleteRow ?
                                                <div className="delete-btn">
                                                    <MdDeleteForever
                                                        size={24}
                                                        onClick={() => props.deleteRow(row)}
                                                    />
                                                </div> : null}
                                            <Feature name="multilingual">
                                                {props.editRow ?
                                                    <div className="translations-btn">
                                                        <MdTranslate
                                                            size={24}
                                                            onClick={() => props.editRow(row)}
                                                        />
                                                    </div> : null}
                                            </Feature>
                                        </div>
                                    </TableCell> : null}
                                {row.cells.map(cell => {
                                    return cell.column.hide === true ? null : (
                                        <TableCell {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </MaUTable>

            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}
                <span>
                    Pagina{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Ga naar pagina:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            console.log(page)
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>

        </div>

    )
}
export default DynamicTable;