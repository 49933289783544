import React, { useEffect, useState } from 'react'
import DynamicTable from '../../components/DynamicTable'
import CssBaseline from '@material-ui/core/CssBaseline'
import { API } from 'aws-amplify';
import DForm from '../../components/DForm';
import ConfirmationModal from '../../components/ConfirmationModal';
import useUIState from '../../hooks/useUIstate';
import { s3put, s3remove } from '../../helpers/commonFunctions';
import { pCategoryFormToReqBody } from './ProductCategoriesController';
import { ProductCategory } from '../../interfaces/IProductCategory';

function ProductCategoriesTable(props) {
    const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();

    const [data, setData]: [any[], any] = useState([]);
    const [showForm, setShowForm]: [boolean, any] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal]: [boolean, any] = useState(false);
    const [formValues, setFormValues]: [any, any] = useState({});
    const [activeRow, setActiveRow]: [any, any] = useState(null);
    const [formMode, setFormMode]: [string, any] = useState("");
    const [formObj, setFormObj]: [Record<string, any>[], any] = useState([]);
    const [UserConfigData, setUserConfigData]: [any, any] = useState([]);


    useEffect(() => {
        const value = localStorage.getItem("UserConfigData");
        if (typeof value === 'string') {
            setUserConfigData(JSON.parse(value));
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        renderData();
        // eslint-disable-next-line
    }, [props.data])

    async function renderData() {
        const productsRender: ProductCategory[] = [];
        props.data.forEach(p => {
            productsRender.push({
                Id: p.Id,
                Code: p.Code,
                Title: p.Title,
                Description: p.Description,
                Avatar: p.Avatar,
            })
        });
        setData(productsRender);
        setShowLoadingModal(false);
    }

    async function createOrUpdateProductCategory(reqBody: ProductCategory) {
        let path = '/ProductCategories';
        let body: ProductCategory = {
            "Code": new Date().getTime().toString(),
            "Title": reqBody.Title,
            "Description": reqBody.Description,
            "Avatar": reqBody.Avatar
        }
        if (reqBody.Id) { body.Id = reqBody.Id };
        let res;
        if (reqBody.Id) {
            res = await API.put('AE', path, { body });
        } else {
            res = await API.post('AE', path, { body });
        }
        return res;
    }

    async function countProducts() {
        let path = '/ProductCategories/CountProducts';
        let myInit = {}
        try {
            return await API.get('AE', path + "?productCategoryId=" + activeRow.original.Id, myInit);
        } catch (err) {
            console.log(err);
            return 1;
        }
    }

    async function deleteEntry() {
        let path = '/ProductCategories';
        let myInit = {}
        try {
            return await API.del('AE', path + "?productCategoryId=" + activeRow.original.Id, myInit);
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'Id',
                hide: true,
            },
            {
                Header: 'Categorie code',
                accessor: 'Code',
                hide: true,
            },
            {
                Header: 'Naam',
                accessor: 'Title',
            },
            {
                Header: 'Beschrijving',
                accessor: 'Description',
                hide: true,
            },
            {
                Header: 'Avatar',
                accessor: 'Avatar',
                hide: true,
            },
        ],
        []
    );

    async function prepForm(row) {

        const rowValues = row !== null ? row.original : null;
        const addingRow = rowValues === null;

        const _formValues = {
            Code: addingRow ? "" : rowValues.Code,
            Title: addingRow ? "" : rowValues.Title,
            Description: addingRow ? "" : rowValues.Description,
            Avatar: addingRow ? "" : rowValues.Avatar
        };

        const formObj = [
            // {
            //   name: "Code",
            //   type: "text",
            //   label: "Categorie code",
            //   required: true,
            //   initialValue: addingRow ? "" : rowValues.Code
            // },
            {
                name: "Title",
                type: "text",
                label: "Naam",
                required: true,
                initialValue: addingRow ? "" : rowValues.Title
            },
            {
                name: "Description",
                type: "text",
                label: "Beschrijving",
                checked: addingRow ? "" : rowValues.Description
            },
            {
                name: "Avatar",
                type: "file",
                label: "Selecteer",
                objUrl: addingRow ? "" : rowValues.Avatar.trim() ? UserConfigData.Customer + '/images/product-categories/catid' + rowValues.Id + "_" + rowValues.Avatar : "",
                maxHeight: 320,
                maxWidth: 320
            },
        ];
        setFormObj(formObj);
        setActiveRow(rowValues);
        setFormMode(rowValues === null ? "Add" : "Edit");
        setFormValues(_formValues);
        setShowForm(true);
    }
    
    async function submit(values) {
        let validated = true;

        console.log(values);
        console.log(activeRow);

        if (!values.Avatar || values.Avatar === " ") {
            setToastType("error");
            setToastMessage("You have to add an image for the category");
            setShowToastMessage(true);
            validated = false;
        }

        // check for unique code
        if (data.find(el => el.Code === values.Code)) {
            let valueExists;
            if (activeRow === null) {
                valueExists = true;
            } else {
                if (data.find(el => el.Code === values.Code).Id !== activeRow.Id) {
                    valueExists = true;
                }
            }
            if (valueExists) {
                setToastType("error");
                setToastMessage(`Code already exists`);
                setShowToastMessage(true);
                validated = false;
            }
        }

        /*
        resize image
        */
        //await resizeImage(values, 60);

        if (validated) {
            setShowLoadingModal(true);
            const reqBody = pCategoryFormToReqBody(activeRow, values);

            setShowForm(false);
            const res = await createOrUpdateProductCategory(reqBody);
            if (res) {
                setToastType("success");
                setToastMessage(`ProductCategory ${activeRow === null ? "added" : "edited"} successfully!`);
                setShowToastMessage(true);
                props.getData();
            } else {
                setToastType("error");
                setToastMessage(`Error ${activeRow === null ? "adding" : "editing"} ProductCategory`);
                setShowToastMessage(true);
            }

            let putResp;
            let imageChange;

            if (activeRow) {
                if (values.Avatar !== activeRow.Avatar) {
                    imageChange = true;
                    putResp = await s3put(`${UserConfigData.Customer}/images/product-categories/catid${res.Id}_${reqBody.Avatar}`, values.Avatar);
                }
            } else {
                imageChange = true;
                putResp = await s3put(`${UserConfigData.Customer}/images/product-categories/catid${res.Id}_${reqBody.Avatar}`, values.Avatar);
            }

            if (!putResp && imageChange) {
                setToastType("error");
                setToastMessage("Image upload failed");
                setShowToastMessage(true);
            }

            setShowLoadingModal(false);
        }
    }

    function handleDeleteConfirmation(row) {
        setActiveRow(row);
        setShowConfirmationModal(true);
    }

    function showToast(type, message) {
        setToastType(type);
        setToastMessage(message);
        setShowToastMessage(true);
    }

    async function confirmDelete() {
        setShowLoadingModal(true);
        setShowConfirmationModal(false);
        const productCount = await countProducts();
        if (productCount > 0) {
            showToast("error", `Category still contains ${productCount} products`);
        }
        else {
            const deleted = await deleteEntry();
            if (deleted) {
                showToast("success", `Category deleted successfully!`);
                if (activeRow.original.Avatar.trim()) {
                    const filePath = `${UserConfigData.Customer}/images/product-categories/catId${activeRow.original.Id}_${activeRow.original.Avatar}`
                    const res = await s3remove(filePath);
                    if (!res) {
                        showToast("error", `Image delete failed`);
                    }
                }
            }
            else {
                showToast("error", `Error deleting Category`);
            }
        }
        props.getData();
    }

    return (
        <div>
            <h1>Product Management</h1>
            <h2 className="mb-05">Productcategorieën</h2>
            <CssBaseline />
            <DynamicTable
                addRow={() => prepForm(null)}
                editRow={(row) => prepForm(row)}
                deleteRow={(row) => handleDeleteConfirmation(row)}
                columns={columns}
                data={data}
                loading={false}
                className="ProductCategories"
            />
            {
                showForm ?
                    <DForm
                        formObj={formObj}
                        formMode={formMode}
                        initialValues={formValues}
                        closeModal={() => setShowForm(false)}
                        submit={submit}
                    />
                    : null
            }
            {showConfirmationModal ?
                <ConfirmationModal
                    message={"Categorie verwijderen?"}
                    confirm={confirmDelete}
                    closeModal={() => setShowConfirmationModal(false)}
                />
                : null}
        </div>
    )
}

export default ProductCategoriesTable;
