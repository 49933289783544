import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import useUIState from '../../hooks/useUIstate';
import { MdArrowBack } from "react-icons/md";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { MdRemove, MdAdd } from 'react-icons/md';
import useDataState from '../../hooks/useDataState';
import ProductPanel from '../../components/ProductPanel';
import awsconfig from '../../aws-apiSettings';
import JofemarSelectionPanel from '../../components/JofemarSelectionPanel';
import { Feature } from 'flagged';
import { updateJofemarReqBody } from '../StoreSupport/StoreSupportController';
import { API } from 'aws-amplify';
import ConfirmationModal from '../../components/ConfirmationModal';
import { getInt } from '../../helpers/commonFunctions';

const useStyles = makeStyles((theme) => ({
    root: {
        '& h1, & h2': {
            marginTop: '5px',
            marginBottom: '5px'
        },
        '& #jofemarHeader': {
            background: '#eee',
            padding: "5px"
        },
        '& #jofemarHeader>*': {
            color: '#777',
            margin: 0
        },
        '& #jofemarContents': {
            background: '#white',
        },
        '& #jofemarBody': {
            maxHeight: "500px",
            overflowY: "scroll"
        },
        '& .jofemar-row': {
            padding: '5px',
            background: "#eaeaea"
        },
        '& .jofemar-columns': {
            background: "#eee"
        },
        '& .jofemar-row-header, & .jofemar-column-body': {
            background: "#fff",
            borderBottom: "1px solid #ddd"
        },
        '& .jofemar-column-body.selected': {
            background: "#e8f5e9",
        },
        '& .header-element': {
            padding: '5px',
        },
        '& .body-element': {
            padding: '5px',
            alignItems: "center",
            display: "flex",
            fontWeight: 700
        },
        '& .product-image-container': {
            minWidth: '65px',
            height: '65px',
            background: "white",
            marginRight: "5px",
            border: "1px solid #ddd"
        },
        '& .product-image-container img': {
            width: '63px',
            height: '63px',
            objectFit: "fill"
        },
        '& .body-element.runtime-pusher .MuiTextField-root': {
            width: "50px",
            margin: "0 5px",
            background: "white",
        },
        '& .body-element.runtime-pusher .MuiButtonBase-root': {
            padding: "5px",
            minWidth: "40px"
        },
        '& .body-element.elevator-adjust .MuiTextField-root': {
            width: "50px",
            margin: "0 5px",
            background: "white",
        },
        '& .body-element.elevator-adjust .MuiButtonBase-root': {
            padding: "5px",
            minWidth: "40px"
        },
        '& .elevatorAdjustValue': {
            padding: "3px",
            minWidth: "70px"
        },
        '& .product-img': { width: "170px" },
        '& .current-stock': { width: "150px" },
        '& .max-capacity': { width: "80px" },
        '& .latest-refill': { width: "170px" },
        '& .is-enabled': { width: "100px" },
        '& .runtime-pusher': { width: "180px" },
        '& .elevator-adjust': { width: "180px" },
        '& .button-row': { width: "200px" },
        '& .replenishment-bottom': {
            marginTop: "20px",
            maxWidth: "90vw",
        }
    }
}));


function StoreSupportJofemarScreen(props) {
    const history = useHistory();
    const classes = useStyles();

    const { ProductsContext, ProductCategoriesContext, JofemarContext, StoreContext } = useDataState();
    const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();

    const [Store, setStore] = useState(null);
    const [StoreId, setStoreId] = useState(null);
    const [Jofemar, setJofemar] = useState({ Attributes: "" });

    const [Jofemars, setJofemars]: [any, any] = useState([]);
    const [JofemarTitle, setJofemarTitle] = useState("");
    const [ActiveJofemarId, setActiveJofemarId] = useState(-1);
    const [JofemarInfo, setJofemarInfo]: [any, any] = useState([]);
    const [IsDirtyCurrentJofemarContents, setIsDirtyCurrentJofemarContents] = useState(false); // true: allow save, false: allow jofemar navigation

    const [StoreTitle, setStoreTitle] = useState("");
    const [JofemarContents, setJofemarContents]: [any, any] = useState([]);
    const [Values, setValues]: [any, any] = useState([]);
    const [ShowPanel, setShowPanel] = useState(false);
    const [ActiveRow, setActiveRow] = useState(0);
    const [ActiveColumn, setActiveColumn] = useState(0);
    const [Products, setProducts]: [any, any] = useState([]);
    const [UserConfigData, setUserConfigData]: [any, any] = useState([]);
    const [ShowProductDispensedConfirmationModal, setShowProductDispensedConfirmationModal]: [boolean, any] = useState(false);
    const [JofemarUpdateStockMachine, setJofemarUpdateStockMachine] = useState("");
    const [JofemarUpdateStockCode, setJofemarUpdateStockCode] = useState("");

    useEffect(() => {
        const value = localStorage.getItem("UserConfigData");
        if (typeof value === 'string') {
            setUserConfigData(JSON.parse(value));
        }

        console.log("props", props);
        if (props.location.Jofemar) {
            setStoreTitle(props.location.title);
            setJofemar(props.location.Jofemar);
            setJofemars(props.location.Jofemars);
            setActiveJofemarId(props.location.Jofemar.Id)
            setProducts(props.location.products);
            setStore(props.location.store);
            setStoreId(props.location.storeId);
        } else if (JofemarContext && Object.entries(JofemarContext).length) {
            // when is this scenario applicable?
            setJofemar(JofemarContext);
            setProducts(ProductsContext);
            setStore(StoreContext);
            setStoreId(StoreContext.Id);
        } else if (localStorage.getItem("Jofemar")) {
            // when is this scenario applicable?
            const JofemarLS = localStorage.getItem("Jofemar")
            if (JofemarLS) {
                setJofemar(JSON.parse(JofemarLS));
            }
            const Products = localStorage.getItem("Products")
            if (Products) {
                setProducts(JSON.parse(Products));
            }
            const Store = localStorage.getItem("ActiveStore")
            if (Store) {
                setStore(JSON.parse(Store));
                setStoreId(JSON.parse(Store).StoreId);
            }
        } else {
            history.push('/retail-locations');
        }
    }, [])

    useEffect(() => {
        if (ActiveJofemarId !== -1) {
            localStorage.setItem("ActiveJofemarId", String(ActiveJofemarId));
        }
    }, [ActiveJofemarId])

    useEffect(() => {
        if (Jofemars) {
            const jofemarInfo: any[] = [];
            Jofemars.forEach(item => {
                jofemarInfo.push({ id: item.Id, name: item.Code });
            });
            setJofemarInfo(jofemarInfo);
        }

        if (Jofemar) {
            let attrStr = "";
            const store = JSON.parse(JSON.stringify(Store))

            console.log("Jofemar.Attributes: " + Jofemar.Attributes)
            attrStr = Jofemar.Attributes;

            let attr = attrStr.split('|');
            const attributes: any[] = [];

            attr.forEach((el, index) => {
                let element = el;
                if (element) {
                    attributes.push(JSON.parse(element.replace(/'/g, '"')));
                }
            });

            if (attributes.length > 0) {
                setJofemarTitle(attributes[0].vendingMachineLogicalName);
            }

            const values: any = [];
            attributes.forEach(row => {
                const Columns: any = [];
                row.Columns.forEach(col => {
                    Columns.push({ UpdateKeepRunningForMs: col.KeepRunningForMs, ElevatorAdjustMs: col.ElevatorAdjustMs })
                });
                values.push({ Columns })
            });

            // updated from ls (persist from navigation)
            if (localStorage.getItem("CurrentJofemarContents") && values.length && attributes.length) {
                const str = localStorage.getItem("CurrentJofemarContents") || "";
                const currJofemarContents = JSON.parse(str);
                currJofemarContents.forEach((row, rowIndex) => {
                    row.Columns.forEach((col, colIndex) => {
                        values[rowIndex].Columns[colIndex].UpdatedStock = col.UpdatedStock;
                        attributes[rowIndex].Columns[colIndex].ProductId = col.ProductId;
                        attributes[rowIndex].Columns[colIndex].LatestRefill = col.LatestRefill;
                        attributes[rowIndex].Columns[colIndex].CurrentStock = col.CurrentStock;
                        attributes[rowIndex].Columns[colIndex].Enabled = col.Enabled;
                        attributes[rowIndex].Columns[colIndex].KeepRunningForMs = col.KeepRunningForMs;
                        attributes[rowIndex].Columns[colIndex].ElevatorAdjustMs = col.ElevatorAdjustMs;
                    });
                });
            }

            setValues(values);
            setJofemarContents(attributes);
        }

    }, [Jofemar, Jofemars])

    function handleChange(e, row, column, action = "", value = 0) {
        setIsDirtyCurrentJofemarContents(true)
        const maxPusherTime = 50;
        const values = [...Values];

        if (!action) {
            if (e.target.value === '') {
                values[row].Columns[column].UpdateKeepRunningForMs = e.target.value;
            } else {
                values[row].Columns[column].UpdateKeepRunningForMs = getInt(e.target.value, 0, 0);
            }
        } else if (action === "decrement") {
            if (value > 0) {
                values[row].Columns[column].UpdateKeepRunningForMs = value - 1;
            }
        } else if (action === "increment") {
            if (value < maxPusherTime) {
                values[row].Columns[column].UpdateKeepRunningForMs = value + 1;
            }
        }
        setValues(values);
    }
    function handleChangeElevator(e, row, column, action = "", value = 0) {
        setIsDirtyCurrentJofemarContents(true)
        const maxAdjustTime = 500;
        const incrementBy = 25;
        const values = [...Values];

        if (!action) {
            if (e.target.value === '') {
                values[row].Columns[column].ElevatorAdjustMs = e.target.value;
            } else {
                values[row].Columns[column].ElevatorAdjustMs = getInt(e.target.value, 0, 0);
            }
        } else if (action === "decrement") {
            if (value > -maxAdjustTime) {
                values[row].Columns[column].ElevatorAdjustMs = value - incrementBy;
            }
        } else if (action === "increment") {
            if (value < maxAdjustTime) {
                values[row].Columns[column].ElevatorAdjustMs = value + incrementBy;
            }
        }
        setValues(values);
    }

    function getDisplayDateTime(datetime) {
        //console.log(datetime)
        let date = datetime.split("T")[0];
        let time = datetime.split("T")[1];

        date = date.split("-");
        date = `${date[2]}/${date[1]}/${date[0]}`;

        time = time.split(":");
        time = `${time[0]}:${time[1]}`;

        return `${date} ${time}`;
    }

    function saveJofemarConfiguration() {
        if (!IsDirtyCurrentJofemarContents) {
            console.log('alleen sluiten')
            history.push('/retail-locations');
        }
        const jofemarContents = [...JofemarContents];

        //console.log("Jofemar", Jofemar);
        //console.log("jofemarContents", jofemarContents);
        //console.log("Values", Values);


        const valuesToSave = getJofemarConfigValuesToSave(jofemarContents);
        console.log(valuesToSave)
        const reqBody = { BlockId: Jofemar["Id"], JofemarChannelConfigs: valuesToSave };
        console.log(reqBody);
        let body = reqBody;
        console.log(body);
        setShowLoadingModal(true)
        API.put('AE', `/Stores/UpdateJofemarConfig`, { body })
            .then(res => {
                console.log(res);
                setIsDirtyCurrentJofemarContents(false);
                setShowLoadingModal(true)
            })
            .catch(error => {
                console.log(error);
                setToastType("error");
                setToastMessage("Error updating JofemarConfig");
                setShowToastMessage(true);
            })
            .finally(() => {
                setShowLoadingModal(false);
            })
    }

    function getJofemarConfigValuesToSave(jofemarContents: any[]) {
        const valuesToSave: any = [];
        jofemarContents.forEach((row, rowIndex) => {
            row.Columns.forEach((col, colIndex) => {
                if (col.KeepRunningForMs !== Values[rowIndex].Columns[colIndex].UpdateKeepRunningForMs || col.ElevatorAdjustMs !== Values[rowIndex].Columns[colIndex].ElevatorAdjustMs) {
                    col.KeepRunningForMs = Values[rowIndex].Columns[colIndex].UpdateKeepRunningForMs;
                    col.ElevatorAdjustMs = Values[rowIndex].Columns[colIndex].ElevatorAdjustMs;
                    valuesToSave.push({ Code: col.Code, KeepRunningForMs: col.KeepRunningForMs, ElevatorAdjustMs: col.ElevatorAdjustMs });
                }
            });
        });
        return valuesToSave;
    }

    async function activateJofemar(isTrue: boolean) {
        console.log('Activeer Jofemar: ' + isTrue);

        const jofemarContents = [...JofemarContents];
        console.log("jofemarContents", jofemarContents);

        setShowLoadingModal(true);
        if (isTrue) {
            var vendingMachineName = jofemarContents[0].vendingMachineLogicalName;
            console.log('ResetSoldOutChannels -> vendingMachineLogicalName: ' + vendingMachineName);

            const body = { "Name": vendingMachineName };
            console.log(body);

            var isReset = false;
            var url = `/Store/${StoreId}/VendingMachineResetSoldOutChannels`;
            await API.put('AE', url, { body })
                .then(res => {
                    console.log(`${url} VendingMachineName: ${res.VendingMachineName}, result: ${res.Success}`);
                    isReset = res.Success;
                })
                .catch(error => {
                    console.log(`${url} result: ${error}`);
                })
                .finally(() => {
                })
            if (!isReset) {
                setToast("error", "Reset van Jofemar uitverkocht status is niet gelukt", true);
                return;
            }
        }

        jofemarContents.forEach((row) => {
            row.Columns.forEach((col) => {
                col.Enabled = isTrue;
            });
        });

        console.log(jofemarContents)
        const reqBody = updateJofemarReqBody(Jofemar, jofemarContents);
        console.log(reqBody)

        const body = reqBody;

        API.put('AE', `/Blocks`, { body })
            .then(res => {
                console.log("AE/Blocks put result: " + res);

                // save changes to CurrentJofemarContents
                localStorage.setItem("CurrentJofemarContents", JSON.stringify(jofemarContents));
                UpdateJofemarArray();
            })
            .catch(error => {
                console.log(error);
                setToast("error", "De/activatie is niet gelukt", false);
            })
            .finally(() => {
                setToast("success", isTrue ? "Activatie succesvol" : "Deactivatie succesvol", true);
            })
    }

    function setToast(type, message, hideModal) {
        setToastType(type);
        setToastMessage(message)
        setShowToastMessage(true);
        if (hideModal) {
            setShowLoadingModal(false);
        }
    }

    function updateJofemarStockAfterDispense(vendingMachineName, code) {
        const jofemarContents = [...JofemarContents];
        jofemarContents.forEach((row) => {
            if (row['vendingMachineLogicalName'] === vendingMachineName) {
                console.log("vendingmachine found");
                row.Columns.forEach((col) => {
                    if (col['Code'] === code) {
                        console.log("code found");
                        // update Stock!
                        if (col.CurrentStock > 0) {
                            col.CurrentStock--;
                        }
                    }
                });
            }
        });

        const body = updateJofemarReqBody(Jofemar, jofemarContents);
        console.log(body);

        // save changes to AE/Blocks
        API.put('AE', `/Blocks`, { body })
            .then(res => {
                console.log("AE/Blocks put result: " + res);

                // save changes to CurrentJofemarContents
                localStorage.setItem("CurrentJofemarContents", JSON.stringify(jofemarContents));
                UpdateJofemarArray();
            })
            .catch(error => {
                console.log(error);
                setToastType("error");
                setToastMessage("Blocks zijn niet geupdate, probleem met opslaan van CurrentStock");
                setShowToastMessage(true);
            })
            .finally(() => {
                setToastType("success");
                setToastMessage("Product is uitgegeven, controleer de tray van de vendingmachine of het product erin ligt.");
                setShowToastMessage(true);
                setShowLoadingModal(false);
            });
    }

    async function dispenseProduct(vendingMachineName, code, keepRunningForMs, elevatorAdjustMs) {
        console.log('Dispense -> vendingMachineLogicalName: ' + vendingMachineName + ' Code: ' + code);

        const body: any[] = [];
        body.push({ vendingMachineName: vendingMachineName, code: code, keepRunningForMs: keepRunningForMs, elevatorAdjustMs : elevatorAdjustMs });

        //const res = await API.put('localGateway', `/vendingmachine/Dispense`, { body });
        try {
            const res = await API.put('AE', `/Store/${StoreId}/VendingMachineDispense`, { body });
            console.log(res);

            //[{"VendingMachineName":"Vending 1","Code":"T18_C0","Success":false}]

            if (res.length > 0 && res[0].Success === true) {

                // update jofemarContents with new stockvalue
                updateJofemarStockAfterDispense(vendingMachineName, code);
            } else {
                askIfProductIsDispensed();
            }
        } catch (error) {
            askIfProductIsDispensed();
        }



        function askIfProductIsDispensed() {
            setJofemarUpdateStockMachine(vendingMachineName);
            setJofemarUpdateStockCode(code);
            setShowProductDispensedConfirmationModal(true);
            setToastType("error");
            setToastMessage("Er is iets misgegaan, VendingMachineDispense resultaat is false (Product kan WEL zijn uitgegeven)");
            setShowToastMessage(true);
        }
    }

    const handleJofemarOnClick = (jofemarItem) => {
        loadJofemarById(jofemarItem.id)
    };

    handleJofemarOnClick.disabled = !{ IsDirtyCurrentJofemarContents }
    handleJofemarOnClick.jofemarInfo = JofemarInfo;

    function loadJofemarById(id) {
        console.log('loading jofemar with id: ' + id)
        // get from storage
        const jofemars = localStorage.getItem("Jofemars")

        if (jofemars) {
            localStorage.removeItem("CurrentJofemarContents")
            const jofemarsJson = JSON.parse(jofemars)
            console.log('number of jofemars found: ' + jofemarsJson.length)
            const jofemar = jofemarsJson.find(block => block.Id === id)
            let attrStr = "";
            attrStr = jofemar.Attributes;

            let attr = attrStr.split('|');
            const attributes: any[] = [];

            attr.forEach((el, index) => {
                let element = el;

                if (element) {
                    attributes.push(JSON.parse(element.replace(/'/g, '"')));
                }
            });
            localStorage.setItem("CurrentJofemarContents", JSON.stringify(attributes));
            setJofemar(jofemar);
            localStorage.setItem("Jofemar", JSON.stringify(jofemar))
            setActiveJofemarId(id);
        }
    }

    function UpdateJofemarArray() {
        // update Jofemars array
        let index = 0;
        for (let i = 0; i < Jofemars.length; i++) {
            if (Jofemars[i].Id === ActiveJofemarId) {
                index = i;
                break;
            }
        }

        const jofemarContents = [...JofemarContents];
        const jofemars = Jofemars
        const reqBody = updateJofemarReqBody(jofemars[index], jofemarContents);
        jofemars[index] = reqBody;
        localStorage.setItem("Jofemars", JSON.stringify(jofemars))
        setJofemars(jofemars)
    }


    return (
        <div id="replenishmentJofemar" className={classes.root}>
            <div className="replenishment-header flex-center-align">
                <div style={{ cursor: "pointer" }}>
                    <MdArrowBack size="30" onClick={() => history.goBack()} />
                </div>
                <h1 id="storeTitle" className="ml1">{StoreTitle}</h1>
                <h2 className="ml1">Support - Vending Machine</h2>
            </div>
            {JofemarContents ?
                <div id="jofemarContents">
                    <div id="jofemarHeader flex-center-align">
                        <h4>Jofemar {JofemarTitle}</h4>
                        <JofemarSelectionPanel activeId={ActiveJofemarId} disabled={IsDirtyCurrentJofemarContents} jofemarInfo={JofemarInfo} passedFunction={handleJofemarOnClick}></JofemarSelectionPanel>
                        <br />
                        <Button
                            id="activateJofemar"
                            type="button"
                            variant="contained"
                            className="green-btn"
                            onClick={() => activateJofemar(true)}
                        >Activeren
                        </Button>
                        &nbsp;
                        <Button
                            id="deactivateJofemar"
                            type="button"
                            variant="contained"
                            className="green-btn"
                            onClick={() => activateJofemar(false)}
                        >Deactiveren
                        </Button>
                    </div>
                    &nbsp;
                    <div id="jofemarBody">
                        {JofemarContents.map((entry, index) => {
                            return (
                                <div key={index} className="jofemar-row">
                                    <div className="jofemar-row-header flex">
                                        <div className="header-element product-img">Rij #{index + 1}</div>
                                        <div className="header-element current-stock">Huidige voorraad</div>
                                        <div className="header-element max-capacity">Max</div>
                                        <div className="header-element latest-refill">Laatst bijgevuld op</div>
                                        <div className="header-element is-enabled">Actief</div>
                                        <div className="header-element button-row">Spiraal</div>
                                        <Feature name="usejofemarconfigstage2">
                                            <div className="header-element runtime-pusher">Extra looptijd pusher</div>
                                            <Feature name="adjustjofemarelevatorheight">
                                                <div className="header-element elevator-adjust">Lifthoogte bijstelling</div>
                                            </Feature>
                                        </Feature>
                                    </div>

                                    {entry.Columns.map((col, colIndex) => {
                                        return (
                                            <div key={`${index}${colIndex}`} id={`r${index}col${colIndex}`} className={`jofemar-column-body flex ${ActiveRow === index && ActiveColumn === colIndex && ShowPanel ? "selected" : ""}`}>
                                                <div className="body-element product-img">
                                                    <div className="product-image-container">
                                                        {col.ProductId && UserConfigData.Customer ?
                                                            <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${UserConfigData.Customer}/images/products/pid${col.ProductId}_${Products.find(prod => prod.Id === col.ProductId).Avatar}`} alt="prod-img" />
                                                            : null
                                                        }
                                                    </div>
                                                    <div>{col.ProductId ? Products.find(p => p.Id === col.ProductId).Code : "Geen product"}</div>
                                                </div>
                                                <div className="body-element current-stock">
                                                    {col.CurrentStock}
                                                </div>
                                                <div className="body-element max-capacity">
                                                    {entry.nrDepth}
                                                </div>
                                                <div className="body-element latest-refill">
                                                    {col.LatestRefill ? getDisplayDateTime(col.LatestRefill) : null}
                                                </div>
                                                <div className="body-element is-enabled">
                                                    {col.Enabled ? "Ja" : "Nee"}
                                                </div>
                                                <div className="body-element button-row">
                                                    <Button
                                                        variant="contained"
                                                        className="green-btn"
                                                        onClick={() => dispenseProduct(entry.vendingMachineLogicalName, col.Code, Values[index].Columns[colIndex].UpdateKeepRunningForMs, Values[index].Columns[colIndex].ElevatorAdjustMs)}
                                                    >
                                                        Product uitgeven
                                                    </Button>
                                                </div>
                                                <Feature name="usejofemarconfigstage2">
                                                    <div className="body-element runtime-pusher">
                                                        <Button variant="contained" className="gray-btn decrement-runtime"
                                                            onClick={(e) => handleChange(e, index, colIndex, "decrement", Values[index].Columns[colIndex].UpdateKeepRunningForMs)}
                                                        >
                                                            <MdRemove size={22} />
                                                        </Button>
                                                        <TextField
                                                            className="UpdatedRuntimeValue"
                                                            type="text"
                                                            onChange={e => handleChange(e, index, colIndex)}
                                                            value={Values[index].Columns[colIndex].UpdateKeepRunningForMs}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                        <Button variant="contained" className="gray-btn increment-runtime"
                                                            onClick={(e) => handleChange(e, index, colIndex, "increment", Values[index].Columns[colIndex].UpdateKeepRunningForMs)}
                                                        >
                                                            <MdAdd size={22} />
                                                        </Button>
                                                    </div>
                                                    <Feature name="adjustjofemarelevatorheight">
                                                        <div className="body-element elevator-adjust">
                                                            <Button variant="contained" className="gray-btn decrement-runtime"
                                                                onClick={(e) => handleChangeElevator(e, index, colIndex, "decrement", Values[index].Columns[colIndex].ElevatorAdjustMs)}
                                                            >
                                                                <MdRemove size={22} />
                                                            </Button>
                                                            <TextField
                                                                className="elevatorAdjustValue"
                                                                type="text"
                                                                onChange={e => handleChangeElevator(e, index, colIndex)}
                                                                value={Values[index].Columns[colIndex].ElevatorAdjustMs}
                                                                variant="outlined"
                                                                size="small"
                                                            />
                                                            <Button variant="contained" className="gray-btn increment-runtime"
                                                                onClick={(e) => handleChangeElevator(e, index, colIndex, "increment", Values[index].Columns[colIndex].ElevatorAdjustMs)}
                                                            >
                                                                <MdAdd size={22} />
                                                            </Button>
                                                        </div>
                                                    </Feature>
                                                </Feature>
                                            </div>
                                        )
                                    })}



                                </div>
                            )
                        })}
                    </div>

                    <div className="replenishment-bottom flex jcsb">

                        <div>
                            <Button
                                type="button"
                                variant="contained"
                                className="gray-btn"
                                onClick={() => history.goBack()}
                            >Stap 1 - Lockers
                            </Button>
                            <Button
                                type="button"
                                disabled={!IsDirtyCurrentJofemarContents}
                                variant="contained"
                                className="green-btn"
                                onClick={() => saveJofemarConfiguration()}
                            >Opslaan
                            </Button>

                        </div>
                    </div>
                </div>
                : null}

            {ShowProductDispensedConfirmationModal ?
                <ConfirmationModal
                    message={"Is het product uitgegeven?"}
                    confirmText={"JA"}
                    cancelText={"NEE"}
                    confirm={() => { updateJofemarStockAfterDispense(JofemarUpdateStockMachine, JofemarUpdateStockCode); setShowProductDispensedConfirmationModal(false) }}
                    closeModal={() => setShowProductDispensedConfirmationModal(false)}
                />
                : null}

        </div>
    )
}

export default StoreSupportJofemarScreen
