import React, { useEffect, useState } from 'react'
import DynamicTable from '../../components/DynamicTable'
import CssBaseline from '@material-ui/core/CssBaseline'
import { API } from 'aws-amplify';
import DForm from '../../components/DForm';
import ConfirmationModal from '../../components/ConfirmationModal';
import useUIState from '../../hooks/useUIstate';
import { promotionsFormToReqBody } from './PromotionsController';
import { Promotion } from '../../interfaces/IPromotions';
import { s3put, s3remove, getZeroOnEmpty } from '../../helpers/commonFunctions';
import { id } from 'date-fns/locale';

function PromotionsCartTable(props) {
    const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();

    const [data, setData]: [any[], any] = useState([]);
    const [filteredData, setFilteredData]: [any[], any] = useState([]);
    const [ShowFilteredData, setShowFilteredData] = useState(false);
    const [showForm, setShowForm]: [boolean, any] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal]: [boolean, any] = useState(false);
    const [formValues, setFormValues]: [any, any] = useState({});
    const [activeRow, setActiveRow]: [any, any] = useState(null);
    const [formMode, setFormMode]: [string, any] = useState("");
    const [formObj, setFormObj]: [Record<string, any>[], any] = useState([]);
    const [UserConfigData, setUserConfigData]: [any, any] = useState([]);
    const [PromotionTypeOptions, setPromotionTypeOptions]: [any, any] = useState([]);

    useEffect(() => {
        const value = localStorage.getItem("UserConfigData");
        if (typeof value === 'string') {
            setUserConfigData(JSON.parse(value));
        }

        getPromotions();
        createPromotionTypeOptions();
    }, [])

    function createPromotionTypeOptions() {
        const options: any[] = [];
        for (var i = 3; i < 5; i++) {
            options.push({ label: getPromotiontypeDescription(i), value: i });
        }
        setPromotionTypeOptions(options);
    }

    function getPromotions() {
        setShowLoadingModal(true);
        API.get("AE", `/Offers`, { response: true })
            .then(res => {
                let data = res.data.length ? res.data.filter(el => el.IsChallengeSpendingPromotion === true) : res.data;
                setData(data);
                if (data.length) {
                    renderData(data);
                }
            })
            .catch(error => {
                console.log(error);
                setToastType("error");
                setToastMessage("Error getting promotions");
                setShowToastMessage(true);
            })
    }

    async function renderData(data) { // corresponds with List<OfferDto>

        const promotionsRender: any[] = [];

        data.forEach(p => {
            // Datetime objects
            let startDate = new Date(p.StartDate);
            let endDate = new Date(p.EndDate);
            let currDate = new Date();

            let promotionTimeFrame = "";
            if (currDate < startDate) { promotionTimeFrame = "Toekomst"; }
            if (currDate >= startDate && currDate < endDate) { promotionTimeFrame = "Huidige" }

            // Display dates
            let displayStartDate = p.StartDate.split("T")[0];
            displayStartDate = displayStartDate.split("-");
            displayStartDate = `${displayStartDate[2]}/${displayStartDate[1]}/${displayStartDate[0]}`

            let displayEndDate = p.EndDate.split("T")[0];
            displayEndDate = displayEndDate.split("-");
            displayEndDate = `${displayEndDate[2]}/${displayEndDate[1]}/${displayEndDate[0]}`

            let discountDescription = "";

            if (p.FixedDiscount > 0) {
                discountDescription = "€ " + p.FixedDiscount;
            } else if (p.Discount > 0) {
                discountDescription = p.Discount + " %";
            }

            promotionsRender.push({
                Id: p.Id,
                Title: p.Title,
                Description: p.Description,
                Avatar: p.Avatar ? p.Avatar : " ",
                StartDate: p.StartDate,
                EndDate: p.EndDate,
                DisplayStartDate: displayStartDate,
                DisplayEndDate: displayEndDate,
                IsChallengeSpendingPromotion: p.IsChallengeSpendingPromotion ? "Yes" : "No",
                FixedDiscount: p.FixedDiscount,
                PercentageDiscount: p.Discount,
                MinimumSpending: p.MinimumSpending,
                VisibilitySpendingThreshold: p.VisibilitySpendingThreshold,
                PromotionTimeFrame: promotionTimeFrame,
                DiscountDescription: discountDescription,
                IsBarcodeScanPromotion: p.IsBarcodeScanPromotion ? 'Yes' : 'No',
                MaximumNoOfUsages: p.MaximumNoOfUsages,
                NoOfUsages: p.NoOfUsages
            })
        });
        setShowFilteredData(false);
        setData(promotionsRender);
        setShowLoadingModal(false);
    }

    function getPromotiontype(percentageDiscount) {
        var promotionType;

        if (percentageDiscount > 0) {
            promotionType = 4;
        } else {
            promotionType = 3;
        }

        return promotionType;
    }

    function getPromotiontypeDescription(promotionType) {
        var description;

        switch (promotionType) {
            case 3:
                {
                    description = "vaste korting";
                    break;
                }
            case 4:
                {
                    description = "percentuele korting";
                    break;
                }
            case 5:
                {
                    description = "gratis product"; // fase 2
                    break;
                }
            default:
                {
                    description = "";
                    break;
                }
        }

        return description;
    }

    async function createOrUpdatePromotion(reqBody: Promotion) {
        let path = '/Offers';
        let body: Promotion = reqBody
        if (reqBody.Id) { body.Id = reqBody.Id };
        let res;
        if (reqBody.Id) {
            res = await API.put('AE', path, { body });
        } else {
            res = await API.post('AE', path, { body });
        }
        return res;
    }

    async function deleteEntry() {
        let path = '/Offers';
        let myInit = {}
        try {
            setToastType("success");
            setToastMessage("Promotion deleted successfully!");
            setShowToastMessage(true);
            return await API.del('AE', path + "?offerId=" + activeRow.original.Id, myInit);
        } catch (err) {
            console.log(err);
            setToastType("error");
            setToastMessage("Error deleting Category");
            setShowToastMessage(true);
            return null;
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Naam',
                accessor: 'Title',
                hide: false,
            },
            {
                Header: 'Description',
                accessor: 'Description',
                hide: true,
            },
            {
                Header: 'Avatar',
                accessor: 'Avatar',
                hide: true,
            },
            {
                Header: 'StartDate',
                accessor: 'StartDate',
                hide: true,
            },
            {
                Header: 'EndDate',
                accessor: 'EndDate',
                hide: true,
            },
            {
                Header: 'Begindatum',
                accessor: 'DisplayStartDate',
                hide: false,
            },
            {
                Header: 'Einddatum',
                accessor: 'DisplayEndDate',
                hide: false,
            },
            {
                Header: 'Actieperiode',
                accessor: 'PromotionTimeFrame',
                hide: false,
            },
            {
                Header: 'Korting',
                accessor: 'DiscountDescription',
                hide: false,
            },
            {
                Header: 'Minimum bedrag',
                accessor: 'MinimumSpending',
                hide: false,
            },
            {
                Header: 'Tonen vanaf bedrag',
                accessor: 'VisibilitySpendingThreshold',
                hide: false,
            },
            {
                Header: 'QR Code',
                accessor: 'Id',
                hide: true,
            },
            {
                Header: 'QR Code aanbieding',
                accessor: 'IsBarcodeScanPromotion',
                hide: true,
            },
            {
                Header: 'Maximum aantal keer',
                accessor: 'MaximumNoOfUsages',
                hide: true,
            },
            {
                Header: 'Gebruikt',
                accessor: 'NoOfUsages',
                hide: true,
            },
        ],
        []
    );

    async function prepForm(row) {

        const rowValues = row !== null ? row.original : null;
        const addingRow = rowValues === null;

        const _formValues = {
            Id: addingRow ? 0 : rowValues.Id,
            Title: addingRow ? "" : rowValues.Title,
            Description: addingRow ? "" : rowValues.Description,
            StartDate: addingRow ? new Date() : rowValues.StartDate,
            EndDate: addingRow ? new Date() : rowValues.EndDate,
            IsChallengeSpendingPromotion: addingRow ? true : rowValues.IsChallengeSpendingPromotion === "Yes" ? true : false,
            FixedDiscount: addingRow ? 0 : rowValues.FixedDiscount,
            PercentageDiscount: addingRow ? 0 : rowValues.PercentageDiscount,
            MinimumSpending: addingRow ? 0 : rowValues.MinimumSpending,
            VisibilitySpendingThreshold: addingRow ? 0 : rowValues.VisibilitySpendingThreshold,
            Avatar: addingRow ? "" : rowValues.Avatar,
            PromotionTypeId: addingRow ? 3 : getPromotiontype(rowValues.PercentageDiscount),
            IsBarcodeScanPromotion: addingRow ? false : rowValues.IsBarcodeScanPromotion === "Yes" ? true : false,
            MaximumNoOfUsages: addingRow ? 0 : rowValues.MaximumNoOfUsages
        };

        const formObj = [
            {
                name: "PromotionTypeId",
                type: "select",
                label: "Type aanbieding",
                required: true,
                initialValue: _formValues.PromotionTypeId,
                options: PromotionTypeOptions
            },
            {
                name: "Title",
                type: "text",
                label: "Naam",
                required: true,
                initialValue: _formValues.Title
            },
            {
                name: "Description",
                type: "text",
                label: "Beschrijving",
                initialValue: _formValues.Description
            },
            {
                name: "MinimumSpending",
                type: "number",
                label: "Minimum bedrag",
                initialValue: _formValues.MinimumSpending
            },
            {
                name: "VisibilitySpendingThreshold",
                type: "number",
                label: "Tonen vanaf bedrag",
                initialValue: _formValues.VisibilitySpendingThreshold
            },
            {
                name: "FixedDiscount",
                type: "number",
                label: "Vast kortingsbedrag",
                initialValue: _formValues.FixedDiscount
            },
            {
                name: "PercentageDiscount",
                type: "number",
                label: "Percentuele korting",
                initialValue: _formValues.PercentageDiscount
            },
            {
                name: "StartDate",
                type: "date",
                label: "Begindatum",
                initialValue: _formValues.StartDate
            },
            {
                name: "EndDate",
                type: "date",
                label: "Einddatum",
                initialValue: _formValues.EndDate
            },
            {
                name: "Avatar",
                type: "file",
                label: "Selecteer",
                objUrl: addingRow ? "" : rowValues.Avatar.trim() ? UserConfigData.Customer + '/images/promotions/pid' + rowValues.Id + "_" + rowValues.Avatar : "",
                maxHeight: 320,
                maxWidth: 320
            },
        ];

        const enrichedFormObj = enrichFeatureFilteredColumnsForForm(formObj, _formValues, addingRow, rowValues);

        setFormObj(enrichedFormObj);
        setActiveRow(rowValues);
        setFormMode(rowValues === null ? "Add" : "Edit");
        setFormValues(_formValues);
        setShowForm(true);
    }

    async function submit(values) {
        values.IsCombination = false;

        // replace '' with 0 for number values to avoid parsing errors in the controller
        values.Product1Id = " ";
        values.Product2Id = " ";
        values.MinimumSpending = getZeroOnEmpty(values.MinimumSpending);
        values.VisibilitySpendingThreshold = getZeroOnEmpty(values.VisibilitySpendingThreshold);
        values.FixedDiscount = getZeroOnEmpty(values.FixedDiscount);
        values.PercentageDiscount = getZeroOnEmpty(values.PercentageDiscount);
        values.OfferSameProductUnits = getZeroOnEmpty(values.OfferSameProductUnits);
        values.ProductDiscount = getZeroOnEmpty(values.ProductDiscount);
        values.ProductFixedPrice = getZeroOnEmpty(values.ProductFixedPrice);
        values.MaximumNoOfUsages = getZeroOnEmpty(values.MaximumNoOfUsages);

        let validationResult = validateForm(values);

        if (validationResult.valid) {
            setShowLoadingModal(true);
            const reqBody = promotionsFormToReqBody(activeRow, values);

            setShowForm(false);
            const res = await createOrUpdatePromotion(reqBody);
            if (res) {
                setToastType("success");
                setToastMessage(`Promotion ${activeRow === null ? "added" : "edited"} successfully!`);
                setShowToastMessage(true);
                getPromotions();
            } else {
                setToastType("error");
                setToastMessage(`Error ${activeRow === null ? "adding" : "editing"} Promotion`);
                setShowToastMessage(true);
            }

            let putResp;
            let imageChange;

            if (activeRow) {
                if (values.Avatar !== activeRow.Avatar) {
                    imageChange = true;
                    putResp = await s3put(`${UserConfigData.Customer}/images/promotions/pid${res.Id}_${reqBody.Avatar}`, values.Avatar);
                }
            } else {
                imageChange = true;
                putResp = await s3put(`${UserConfigData.Customer}/images/promotions/pid${res.Id}_${reqBody.Avatar}`, values.Avatar);
            }

            if (!putResp && imageChange) {
                setToastType("error");
                setToastMessage("Image upload failed");
                setShowToastMessage(true);
            }

            setShowLoadingModal(false);
        } else {
            setToastType("error");
            setToastMessage(validationResult.message);
            setShowToastMessage(true);
        }
    }

    function validateForm(values) {
        var result = {
            valid: false,
            message: ''
        }

        // VALIDATIONS
        if (values.MinimumSpending <= 0) {
            result.message = "Minimum bedrag is verplicht"
            return result;
        }

        if (values.IsBarcodeScanPromotion) {
            if (values.VisibilitySpendingThreshold != 0) {
                result.message = "Indien 'is QR code aanbieding' is geselecteerd, moet 'Tonen vanaf bedrag' altijd 0 zijn. Dit zorgt ervoor dat een gescande aanbieding altijd wordt getoond."
                return result;
            }
        }
        else {
            if (values.VisibilitySpendingThreshold <= 0) {
                result.message = "Tonen vanaf bedrag is verplicht"
                return result;
            }
            if (values.VisibilitySpendingThreshold >= values.MinimumSpending) {
                result.message = "Minimum bedrag moet groter zijn dan Tonen vanaf bedrag"
                return result;
            }
        }

        if (values.PromotionTypeId === 3) {
            // static discount
            if (values.FixedDiscount <= 0) {
                result.message = "Vast kortingsbedrag is verplicht";
                return result;
            }

            if (values.FixedDiscount > values.MinimumSpending) {
                result.message = "Vast kortingsbedrag moet kleiner zijn dan minimum bedrag";
                return result;
            }
        }

        if (values.PromotionTypeId === 4) {
            // percentage discount
            if (values.PercentageDiscount <= 0) {
                result.message = "Percentuele korting is verplicht";
                return result;
            }

            if (values.PercentageDiscount > 100) {
                result.message = "Percentuele korting mag niet groter zijn dan 100";
                return result;
            }
        }
        // check if a promotion for the product exists
        if (values.PromotionTypeId === 3) {
            if (values.PercentageDiscount > 0) {
                result.message = "Percentuele korting is niet van toepassing";
                return result;
            }
        }

        if (values.PromotionTypeId === 4) {
            if (values.FixedDiscount > 0) {
                result.message = "Vast kortingsbedrag is niet van toepassing";
                return result;
            }
        }

        if (new Date(values.StartDate) >= new Date(values.EndDate)) {
            result.message = 'Einddatum moet groter zijn dan de begindatum'
            return result;
        }

        if (!values.Avatar || values.Avatar === " ") {
            result.message = "Een afbeelding voor de aanbieding is verplicht";
            return result;
        }

        result.valid = true;

        return result;
    }

    function handleDeleteConfirmation(row) {
        setActiveRow(row);
        setShowConfirmationModal(true);
    }

    async function confirmDelete() {
        setShowLoadingModal(true);
        setShowConfirmationModal(false);
        const deleted = await deleteEntry();
        if (deleted) {
            if (activeRow.original.Avatar.trim()) {
                const filePath = `${UserConfigData.Customer}/images/promotions/pid${activeRow.original.Id}_${activeRow.original.Avatar}`
                const res = await s3remove(filePath);
                if (!res) {
                    setToastType("error");
                    setToastMessage("Image delete failed");
                    setShowToastMessage(true);
                }
            }
        }
        getPromotions();
    }

    function filterPromotions(timeFrame: "all" | "current" | "future", mode: "discount") {
        let filteredData;
        let currDate = new Date();
        filteredData = data.filter(el => el.IsChallengeSpendingPromotion === "Yes");

        if (timeFrame === "current") {
            filteredData = filteredData.filter(el => currDate >= new Date(el.StartDate) && currDate < new Date(el.EndDate));
        }
        if (timeFrame === "future") {
            filteredData = filteredData.filter(el => new Date(el.StartDate) > currDate);
        }

        setFilteredData(filteredData)
        setShowFilteredData(true);
    }

    // FEATURE Specific functions
    function getFeatureFilteredColumns() {
        if (UserConfigData && UserConfigData.EnabledFeatures) {
            if (UserConfigData.EnabledFeatures.includes('barcodescanpromotion')) {
                // unhide columns for barcodescan feature (IsBarcodeScanPromotion, MaximumNoOfUsages, NoOfUsages)
                return columns.map(column => ["IsBarcodeScanPromotion", "MaximumNoOfUsages", "NoOfUsages"].includes(column.accessor) ? ({ ...column, hide: false }) : ({ ...column }));
            }
        }
        return columns;
    }

    function enrichFeatureFilteredColumnsForForm(formObj, formValues, addingRow, rowValues) {
        const formObjResponse = formObj;

        if (UserConfigData && UserConfigData.EnabledFeatures) {
            if (UserConfigData.EnabledFeatures.includes('barcodescanpromotion')) {
                if (rowValues && rowValues.IsBarcodeScanPromotion === 'Yes') {
                    formObjResponse.push({
                        name: "Id",
                        type: "qrcode",
                        label: "Id",
                        initialValue: formValues.Id
                    });
                }
                formObjResponse.push({
                    name: "IsBarcodeScanPromotion",
                    type: "checkbox",
                    label: "Is QR Code aanbieding?",
                    checked: addingRow ? false : rowValues.IsBarcodeScanPromotion === 'Yes' ? true : false
                })

                if (rowValues && rowValues.IsBarcodeScanPromotion === 'Yes') {
                    formObjResponse.push({
                        name: "MaximumNoOfUsages",
                        type: "number",
                        label: "Maximum aantal keer gebruik QR Code",
                        checked: addingRow ? 0 : rowValues.MaximumNoOfUsages
                    });
                }
                return formObjResponse;
            }
        }
        return formObjResponse;
    }

    return (
        <div>
            <h2 className="mb-05">Aanbiedingen op basis van besteed bedrag</h2>
            <div className="table-filters">
                <label>Filters: </label>
                <button onClick={() => { filterPromotions("all", "discount") }}>Alles weergeven</button>
                <button onClick={() => { filterPromotions("current", "discount") }}>Huidige korting deals</button>
                <button onClick={() => { filterPromotions("future", "discount") }}>Toekomstige korting deals</button>
            </div>
            <CssBaseline />
            {UserConfigData.Customer ?
                <DynamicTable
                    addRow={() => prepForm(null)}
                    editRow={(row) => prepForm(row)}
                    deleteRow={(row) => handleDeleteConfirmation(row)}
                    columns={getFeatureFilteredColumns()}
                    data={ShowFilteredData ? filteredData : data}
                    //data={filteredData}
                    loading={false}
                    className="Promotions"
                />
                : null
            }
            {
                showForm ?
                    <DForm
                        formObj={formObj}
                        formMode={formMode}
                        initialValues={formValues}
                        qrcodeType={'challengespendingpromotion_'}
                        closeModal={() => setShowForm(false)}
                        submit={submit}
                    />
                    : null
            }
            {showConfirmationModal ?
                <ConfirmationModal
                    message={"Aanbieding verwijderen?"}
                    confirm={confirmDelete}
                    closeModal={() => setShowConfirmationModal(false)}
                />
                : null}
            <div className="mt1" />
        </div>
    )
}

export default PromotionsCartTable;
