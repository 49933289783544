import React, { useEffect, useState } from 'react'
import DynamicTable from '../../components/DynamicTable'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Auth, API } from 'aws-amplify';
import DForm from '../../components/DForm';
import ConfirmationModal from '../../components/ConfirmationModal';
import useUIState from '../../hooks/useUIstate';
import { s3put, s3remove } from '../../helpers/commonFunctions';
import { userFormToAPI } from './UserManagementController'

enum UserRoles {
    SuperAdmin = "superadmin",
    Administrator = "administrator",
    StoreManager = "storemanager",
    ReplenishmentUser = "replenishmentuser",
}

const UserRolesTable = {
    superadmin: "Super Admin",
    administrator: "Administrator",
    storemanager: "Store Manager",
    replenishmentuser: "Replenishment User",
}

function UserManagementTable() {
    const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();
    const [data, setData]: [any[], any] = useState([]);
    const [Stores, setStores]: [any[], any] = useState([]);
    const [showForm, setShowForm]: [boolean, any] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal]: [boolean, any] = useState(false);
    const [formValues, setFormValues]: [any, any] = useState({});
    const [activeRow, setActiveRow]: [any, any] = useState(null);
    const [formMode, setFormMode]: [string, any] = useState("");
    const [formObj, setFormObj]: [Record<string, any>[], any] = useState([]);
    const [MultiSelectValues, setMultiSelectValues]: any = useState({
        Stores: []
    });

    function handleMultiSelectChange(name, value) {
        const _multiSelectValues = { ...MultiSelectValues };
        _multiSelectValues[name] = value;
        setMultiSelectValues(_multiSelectValues);
    }

    useEffect(() => {
        localStorage.setItem("activeRoute", "/user-management");

        setShowLoadingModal(true);
        getStores();
        // eslint-disable-next-line
    }, [])
    async function getStores() {
        API.get("AE", `/Stores`, { response: true })
            .then(res => {

                setStores(res.data);

                renderUsers();
            })
            .catch(error => {
                console.log(error);
            });
    }


    let nextToken;

    async function renderUsers() {

        const users: any[] = [];
        // loop with nextToken if available
        do {
            const _users = await listUsers();
            if (Array.isArray(_users)) {
                users.push(..._users);
            }
        } while (nextToken);

        const usersRender: any[] = [];
        users.forEach(user => {
            const id = user.Attributes.find(attr => attr.Name === "sub");
            const name = user.Attributes.find(attr => attr.Name === "name");
            const familyName = user.Attributes.find(attr => attr.Name === "family_name");
            const phoneNumber = user.Attributes.find(attr => attr.Name === "phone_number");
            const picture = user.Attributes.find(attr => attr.Name === "picture");
            const role = user.Attributes.find(attr => attr.Name === "custom:role");
            const storeId = user.Attributes.find(attr => attr.Name === "custom:storeId");
            usersRender.push({
                id: id.Value,
                email: user.Username,
                name: name ? name.Value : "",
                familyName: familyName ? familyName.Value : "",
                phoneNumber: phoneNumber ? phoneNumber.Value : "",
                picture: picture ? picture.Value : "",
                role: role ? role.Value : "",
                roleTable: role ? UserRolesTable[role.Value] : "",
                storeId: storeId ? storeId.Value : "",
                status: user.UserStatus === "CONFIRMED" ? 'BEVESTIGD' : user.UserStatus,
            })
        });

        setData(usersRender);
        setShowLoadingModal(false);
    }

    async function listUsers() {
        let path = '/listUsers';
        let myInit = {
            queryStringParameters: {
                "limit": 25,
                "token": nextToken
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        try {
            const { NextToken, ...rest } = await API.get('AdminQueries', path, myInit);
            nextToken = NextToken;
            return rest.Users;
        } catch (err) {
            console.log(err);
            setToastType("error");
            setToastMessage("Error getting users");
            setShowToastMessage(true);
        }

    }


    async function adminUpdateUserAttributes(reqBody) {
        const { assignedStoreIds, username, name, familyName, phoneNumber, pictureName, role } = reqBody;

        let path = '/adminUpdateUserAttributes';
        let myInit = {
            body: {
                "username": username,
                "name": name,
                "family_name": familyName,
                "phone_number": phoneNumber,
                "picture": pictureName,
                "role": role,
                "storeId": assignedStoreIds
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        try {
            return await API.post('AdminQueries', path, myInit);
        } catch (err) {
            console.log(err);
            setToastType("error");
            setToastMessage("Error updating user's attributes");
            setShowToastMessage(true);
            return null;
        }
    }

    async function createUser(reqBody) {
        const { assignedStoreIds, username, name, familyName, phoneNumber, pictureName, tempPassword, role } = reqBody;

        let path = '/createUser';
        let myInit = {
            body: {
                "username": username,
                "name": name,
                "family_name": familyName,
                "phone_number": phoneNumber,
                "picture": pictureName,
                "tempPassword": tempPassword,
                "role": role,
                "storeId": assignedStoreIds
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        try {
            setToastType("success");
            setToastMessage("User created successfully!");
            setShowToastMessage(true);
            return await API.post('AdminQueries', path, myInit);
        } catch (err) {
            console.log(err);
            setToastType("error");
            setToastMessage("Error creating user");
            setShowToastMessage(true);
            return null;
        }
    }

    async function deleteUser() {
        let path = '/deleteUser';
        let myInit = {
            body: {
                "username": activeRow.original.email,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        try {
            setToastType("success");
            setToastMessage("User deleted successfully!");
            setShowToastMessage(true);
            return await API.post('AdminQueries', path, myInit);
        } catch (err) {
            console.log(err);
            setToastType("error");
            setToastMessage("Error deleting user");
            setShowToastMessage(true);
            return null;
        }
    }

    async function addUserToGroup(username, role) {
        let path = '/addUserToGroup';
        let myInit = {
            body: {
                "username": username,
                "groupname": role
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        try {
            return await API.post('AdminQueries', path, myInit);
        } catch (err) {
            console.log(err);
            setToastType("error");
            setToastMessage("Error adding user to " + role);
            setShowToastMessage(true);
            return null;
        }
    }

    async function removeUserFromGroup(username, role) {
        let path = '/removeUserFromGroup';
        let myInit = {
            body: {
                "username": username,
                "groupname": role
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        try {
            return await API.post('AdminQueries', path, myInit);
        } catch (err) {
            console.log(err);
            setToastType("error");
            setToastMessage("Error removing user from " + role);
            setShowToastMessage(true);
            return null;
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'id',
                accessor: 'id',
                hide: true,
            },
            {
                Header: 'E-mail',
                accessor: 'email',
            },
            {
                Header: 'name',
                accessor: 'name',
                hide: true
            },
            {
                Header: 'familyName',
                accessor: 'familyName',
                hide: true
            },
            {
                Header: 'phoneNumber',
                accessor: 'phoneNumber',
                hide: true
            },
            {
                Header: 'picture',
                accessor: 'picture',
                hide: true
            },
            {
                Header: 'role',
                accessor: 'role',
                hide: true
            },
            {
                Header: 'Gebruikersrol',
                accessor: 'roleTable',
            },
            {
                Header: 'Assigned Stores',
                accessor: 'storeId',
                hide: true
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
        ],
        []
    );

    async function prepForm(row) {
        const rowValues = row !== null ? row.original : null;
        const addingRow = rowValues === null;
        console.log(rowValues);

        const _formValues = {
            username: addingRow ? "" : rowValues.email,
            tempPassword: addingRow ? "" : rowValues.tempPassword,
            name: addingRow ? "" : rowValues.name,
            familyName: addingRow ? "" : rowValues.familyName,
            phoneNumber: addingRow ? "" : rowValues.phoneNumber.substring(1),
            picture: addingRow ? "" : rowValues.picture,
            role: addingRow ? UserRoles.SuperAdmin : rowValues.role
        };

        const storesValue: any[] = [];
        if (!addingRow && rowValues.storeId) {
            let tempArr = rowValues.storeId.split(",")
            tempArr.forEach(el => {
                const store = Stores.find(store => store.Id === parseInt(el, 10))
                if (store) {
                    storesValue.push({
                        label: store.Title,
                        value: store.Id
                    })
                }
            });
        }
        handleMultiSelectChange("Stores", storesValue)

        const formObj = [
            {
                name: "username",
                type: "email",
                label: "Gebruikersnaam",
                required: true,
                disabled: addingRow ? false : true,
                initialValue: addingRow ? "" : rowValues.email
            },
            addingRow ?
                {
                    name: "tempPassword",
                    type: "password",
                    label: "Tijdelijk wachtwoord",
                    required: true,
                    initialValue: addingRow ? "" : rowValues.tempPassword
                } : null,
            {
                name: "role",
                type: "select",
                label: "Gebruikersrol",
                required: true,
                initialValue: addingRow ? UserRoles.SuperAdmin : rowValues.role,
                options: [
                    { label: "Super Admin", value: UserRoles.SuperAdmin },
                    { label: "Administator", value: UserRoles.Administrator },
                    { label: "Winkeleigenaar", value: UserRoles.StoreManager },
                    { label: "Vul medewerker", value: UserRoles.ReplenishmentUser },
                ]
            },
            {
                name: "Stores",
                type: "multiselect",
                label: "Winkel toekennen ",
                required: true,
                initialValue: addingRow ? null : MultiSelectValues.Stores,
                options: Stores.map(obj => {
                    let rObj = {}
                    rObj["value"] = obj.Id;
                    rObj["label"] = obj.Title;
                    return rObj
                })
            },
            {
                name: "name",
                type: "text",
                label: "Voornaam",
                required: true,
                initialValue: addingRow ? "" : rowValues.name
            },
            {
                name: "familyName",
                type: "text",
                label: "Achternaam",
                required: true,
                initialValue: addingRow ? "" : rowValues.familyName
            },
            {
                name: "phoneNumber",
                type: "tel",
                label: "Telefoonnummer",
                required: true,
                // eslint-disable-next-line
                // pattern: "^\(?([+]31|0031|0)-?6(\s?|-)([0-9]\s{0,3}){8}$",
                initialValue: addingRow ? "" : rowValues.phoneNumber
            },
            {
                name: "picture",
                type: "file",
                label: "Selecteer",
                objUrl: addingRow ? "" : rowValues.picture.trim() ? 'users/images/' + rowValues.email.replace("@", "_") + "_" + rowValues.picture : "",
                maxHeight: 320,
                maxWidth: 320
            },
        ];
        setFormObj(formObj);

        setActiveRow(rowValues);
        setFormMode(rowValues === null ? "Add" : "Edit");
        setFormValues(_formValues);
        setShowForm(true);
    }

    async function submit(values) {

        setShowLoadingModal(true);
        const { username, role } = values;

        let reqBody = userFormToAPI(activeRow, values, MultiSelectValues);

        let userActionSuccessful;
        // Add entry
        if (activeRow === null) {
            // check if user exists
            if (!data.find(user => user.email === username)) {
                setShowForm(false);
                // const res = await createUser(username, name, familyName, phoneNumber, pictureName, tempPassword, role, assignedStoreIds);
                const res = await createUser(reqBody);
                if (res) {
                    await addUserToGroup(username, role);
                    userActionSuccessful = true;
                    renderUsers();
                }
            } else {
                setToastType("error");
                setToastMessage("User already exists");
                setShowToastMessage(true);
            }
        } else {
            // Edit entry
            setShowForm(false);
            if (activeRow.role) {
                await removeUserFromGroup(username, activeRow.role);
            }
            const res = await addUserToGroup(username, role);
            if (res) {

                const updateRes = await adminUpdateUserAttributes(reqBody);
                if (updateRes) {
                    setToastType("success");
                    setToastMessage("User edited successfully!");
                    setShowToastMessage(true);
                    userActionSuccessful = true;
                    renderUsers();
                }
            }
        }

        if (userActionSuccessful) {
            const path = `users/images/${username.replace("@", "_")}_${reqBody.pictureName}`;
            const putRes = await s3put(path, values.picture);
            if (!putRes) {
                setToastType("error");
                setToastMessage("Image upload failed");
                setShowToastMessage(true);
            }
        }
        setShowLoadingModal(false);
    }

    function handleDeleteConfirmation(row) {
        setActiveRow(row);
        setShowConfirmationModal(true);
    }

    async function confirmDelete() {
        setShowLoadingModal(true);
        setShowConfirmationModal(false);
        const deleted = await deleteUser();
        if (deleted) {
            console.log(activeRow);
            if (activeRow.original.picture.trim()) {
                const filePath = `users/images/${activeRow.original.email.replace("@", "_")}_${activeRow.original.picture}`
                console.log(filePath)
                const res = await s3remove(filePath);
                if (!res) {
                    setToastType("error");
                    setToastMessage("Image delete failed");
                    setShowToastMessage(true);
                }
            }
        }
        renderUsers();
    }

    return (
        <div>
            <h1>Gebruikersbeheer</h1>
            <CssBaseline />
            <DynamicTable
                addRow={() => prepForm(null)}
                editRow={(row) => prepForm(row)}
                deleteRow={(row) => handleDeleteConfirmation(row)}
                columns={columns}
                data={data}
                loading={false}
            />
            {
                showForm ?
                    <DForm
                        formObj={formObj}
                        formMode={formMode}
                        initialValues={formValues}
                        closeModal={() => setShowForm(false)}
                        multiSelectValues={MultiSelectValues}
                        handleMultiSelectChange={handleMultiSelectChange}
                        submit={submit}
                    />
                    : null
            }
            {showConfirmationModal ?
                <ConfirmationModal
                    message={"Verwijder gebruiker?"}
                    confirm={confirmDelete}
                    closeModal={() => setShowConfirmationModal(false)}
                />
                : null}
        </div>
    )
}

export default UserManagementTable;
