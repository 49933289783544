import { Promotion } from "../../interfaces/IPromotions";

export function promotionsFormToReqBody(activeRow, values) {

  let pictureName;
  if (values.Avatar.name) {
    pictureName = values.Avatar.name;
  } else {
    pictureName = values.Avatar ? values.Avatar : " ";
  }
  if (activeRow && !values.Avatar) {
    pictureName = activeRow.Avatar;
  }

  const ProductsInOffer: any[] = [];
  let isCombination;
  // combination
  if (values.Product1Id !== " " && values.Product2Id !== " ") {
    ProductsInOffer.push(
      {
        ProductId: parseInt(values.Product1Id, 10),
        ProductDiscount: parseFloat(values.ProductDiscount) / 100,
        ProductFixedPrice: parseFloat(values.ProductFixedPrice),
        OfferSameProductUnits: parseFloat(values.OfferSameProductUnits),
      },
      {
        ProductId: parseInt(values.Product2Id, 10),
        ProductDiscount: parseFloat(values.ProductDiscount) / 100,
        ProductFixedPrice: parseFloat(values.ProductFixedPrice),
        OfferSameProductUnits: parseFloat(values.OfferSameProductUnits),
      },
    )
    isCombination = true;
  } else if (values.Product1Id !== " " || values.Product2Id !== " ") {
    // single product
    ProductsInOffer.push(
      {
        ProductId: values.Product2Id === " " ? parseInt(values.Product1Id, 10) : parseInt(values.Product2Id, 10),
        ProductDiscount: parseFloat(values.ProductDiscount) / 100,
        ProductFixedPrice: parseFloat(values.ProductFixedPrice),
        OfferSameProductUnits: parseFloat(values.OfferSameProductUnits),
      }
    )
    isCombination = parseFloat(values.OfferSameProductUnits) ? true : false;
  }

  const reqBody: Promotion = {
    Title: values.Title,
    Description: values.Description,
    Avatar: pictureName,
    StartDate: values.StartDate,
    EndDate: values.EndDate,
    IsCombination: isCombination,
    ProductsInOffer: ProductsInOffer,
    IsBarcodeScanPromotion: values.IsBarcodeScanPromotion,
    IsChallengeSpendingPromotion: values.IsChallengeSpendingPromotion,
    IsFreeProduct: false, // fase 2
    FixedDiscount: values.FixedDiscount,
    Discount: values.PercentageDiscount,
    MinimumSpending: values.MinimumSpending,
    VisibilitySpendingThreshold: values.VisibilitySpendingThreshold,
    MaximumNoOfUsages: values.MaximumNoOfUsages
  }
  if (activeRow !== null) {
    reqBody.Id = activeRow.Id;
  }

  return reqBody;
}