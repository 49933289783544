import React, { useRef, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { makeStyles } from '@material-ui/core/styles';
import ModalBase from '../../components/ModalBase';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "760px",
    padding: "20px",
    '& h1, & h2': {
      marginTop: '5px',
      marginBottom: '5px'
    },
    '& .dplan-header': {
      borderBottom: '1px solid #ddd',
    },

    '& .header-element': {
      padding: '5px',
      fontWeight: 700
    },
    '& .body-element': {
      padding: '10px 5px',
      alignItems: "center",
      display: "flex",
    },
    '& .product-entry-dplan': {
      width: "100%",
      padding: "5px 0",
      borderBottom: '1px solid #ddd',
    },
    '& .product-image-container': {
      minWidth: '65px',
      height: '65px',
      background: "white",
      marginRight: "5px",
    },
    '& .product-image-container img': {
        width: '63px',
        height: '63px',
        objectFit: "fill"
    },
    '& .body-element.additional-value .MuiTextField-root': {
      width: "50px",
      margin: "0 5px",
      background: "white",
    },
    '& .body-element.additional-value .MuiButtonBase-root': {
      padding: "5px",
      minWidth: "40px"
    },
    '& .product': { flex: 1 },
    '& .replenishment-value': { width: "100px" },
    '& .additional-value': { width: "200px" },
    '& .total-value': { width: "100px" },
    '& #lockersProductsPlan': {
      marginTop: "40px"
    },
    '& .print-bottom': {
      marginTop: "40px",
      textAlign: "center"
    }
  }
}));

function DistributionPrint(props) {
  const classes = useStyles();

  const [LockersProducts, setLockersProducts] = useState([]);
  const [JofemarProducts, setJofemarProducts] = useState([]);
  const [LockersAdditionalValues, setLockersAdditionalValues] = useState([])
  const [JofemarAdditionalValues, setJofemarAdditionalValues] = useState([])
  // eslint-disable-next-line
  const [Products, setProducts] = useState([])

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    // content: () => componentRef.current,
    content: () => componentRef.current,

  });

  useEffect(() => {
    const productsStr = localStorage.getItem("Products")
    if (productsStr) {
      const newProducts = [];
      const products = JSON.parse(productsStr);
      products.forEach(product => {
        newProducts.push(product);
      });
      setProducts(newProducts)
    }

  }, [])


  useEffect(() => {
    if (props.LockersProducts.length) { setLockersProducts(props.LockersProducts) }
    // if (props.Products.length) { setProducts(props.Products) }
    if (props.JofemarProducts.length) { setJofemarProducts(props.JofemarProducts) }
    if (props.LockersAdditionalValues.length) { setLockersAdditionalValues(props.LockersAdditionalValues) }
    if (props.JofemarAdditionalValues.length) { setJofemarAdditionalValues(props.JofemarAdditionalValues) }
  }, [props])

  function getAmountOrWeight(Id) {
    const _productsStr = localStorage.getItem("Products")
    let attr;
    if (_productsStr) {
      const _products = JSON.parse(_productsStr);
      attr = _products.find(p => p.Id === Id).Attributes
    }

    attr = JSON.parse(attr.replace(/'/g, '"'))

    let amount = attr.Priority === 'Amount' ?
      attr.Amount :
      attr.Weight

    return `${attr.Priority} ${amount}`;
  }

  function getTitle(Id) {
    const _productsStr = localStorage.getItem("Products")
    let title = "";
    if (_productsStr) {
      const _products = JSON.parse(_productsStr);
      title = _products.find(x => x.Id === Id).Title
    }

    return title;
  }

  return (
    <ModalBase>

      <div className="col-flex print-outer">
        <div className={classes.root} ref={componentRef}>
          <div className="replenishment-header flex-center-align">
            <h1>Distributieplan</h1>
          </div>
          <div className="mt1">
            <h2 className="mt0">{props.title}</h2>
            <h4 className="mt0 gray">{props.date}</h4>
          </div>


          {LockersAdditionalValues.length ?

            <div id="lockersProductsPlan">
              <h3>Lockers</h3>
              <div id="lockersPlanContents">
                <div className="dplan-header flex">
                  <div className="header-element product">Product</div>
                  {/* <div className="header-element replenishment-value">Vulplan</div>
                  <div className="header-element additional-value">Vooraad</div> */}
                  <div className="header-element total-value">Total</div>
                </div>


                <div className="dplan-body flex-col">
                  {LockersProducts.map((product, index) => {
                    return (
                      <div key={product.ProductId} className="product-entry-dplan flex">
                        <div className="product flex">
                          <div className="flex-col ml1 jcc">
                            <div id="productTitle gray">{getTitle(product.ProductId)}</div>
                            <div id="productAmount gray">{getAmountOrWeight(product.ProductId)}</div>
                          </div>
                        </div>
                        {/* <div className="body-element replenishment-value">{product.Amount}</div>
                        <div className="body-element additional-value">{LockersAdditionalValues[index].AdditionalAmount}</div> */}
                        <div className="body-element total-value">{product.Amount + LockersAdditionalValues[index].AdditionalAmount}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            : null}
          {JofemarAdditionalValues.length ?

            <div id="jofemarProductsPlan" className="mt1">
              <h3>Jofemar</h3>
              <div id="lockersPlanContents">
                <div className="dplan-header flex">
                  <div className="header-element product">Product</div>
                  {/* <div className="header-element replenishment-value">Vulplan</div>
                  <div className="header-element additional-value">Vooraad</div> */}
                  <div className="header-element total-value">Total</div>
                </div>

                <div className="dplan-body flex-col">
                  {JofemarProducts.map((product, index) => {
                    return (
                      <div key={product.ProductId} className="product-entry-dplan flex">
                        <div className="product flex">

                          <div className="flex-col ml1 jcc">
                            <div id="productTitle gray">{getTitle(product.ProductId)}</div>
                            <div id="productAmount gray">{getAmountOrWeight(product.ProductId)}</div>
                          </div>
                        </div>
                        {/* <div className="body-element replenishment-value">{product.ReplenishmentAmount}</div>
                        <div className="body-element additional-value">
                          {JofemarAdditionalValues[index].AdditionalAmount}
                        </div> */}
                        <div className="body-element total-value">{product.ReplenishmentAmount + JofemarAdditionalValues[index].AdditionalAmount}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            : null}

        </div>
        <div className="print-bottom flex jcsb">
          <Button
            type="button"
            variant="contained"
            className="gray-btn"
            onClick={() => props.closePreview()}
          >Annuleren
          </Button>
          <Button
            type="button"
            variant="contained"
            className="green-btn"
            id="printDistributionBtn"
            onClick={handlePrint}
          >Print distributieplan
          </Button>
        </div>
      </div>
    </ModalBase>
  );
}

export default DistributionPrint
