import { convertStringToArray } from "../ReplenishmentPlan/ReplenishmentController";

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-') + "T00:00:00";
}

export function productUpdateList(LockersSelected, _Blocks, productId, lockersUpdatedIds, _prodEntry) {

  console.log(productId)

  let lockersToReplenish: any[] = [];

  LockersSelected.forEach(lockerSelectedId => {
    _Blocks.forEach((block, blockIndex) => {
      block.Lockers.forEach((locker, lockerIndex) => {
        if (locker.Id === lockerSelectedId) {
          const todayStr = formatDate(new Date());
          locker.ReplenishedAt = todayStr;
          locker.ProductId = productId;
          locker.IsSoldOut = false;
          lockersToReplenish.push(locker);
          // update lockers in dist plan
          if (!lockersUpdatedIds.includes(lockerSelectedId)) {
            _prodEntry.Amount = _prodEntry.Amount + 1;
            lockersUpdatedIds.push(lockerSelectedId);
          }
        }
      });
    });
  });

  _prodEntry.LockersUpdatedIds = lockersUpdatedIds;

  return { lockersToReplenish, _prodEntry }
}

export function fillingPlanReqBody(LockersDistributionPlan, StoreObj) {
  const FillingPlan: any[] = convertStringToArray(StoreObj.FillingPlan);

  LockersDistributionPlan.forEach(product => {
    product.LockersUpdatedIds.forEach(lockerId => {
      if (FillingPlan.find(l => l.LockerId === lockerId)) {
        const index = FillingPlan.findIndex(l => l.LockerId === lockerId);
        FillingPlan[index].ProductId = product.ProductId;
      } else {
        FillingPlan.push({
          LockerId: lockerId,
          ProductId: product.ProductId
        })
      }
    });
  });

  let FillingPlanStr = "";
  FillingPlan.forEach(entry => {
    FillingPlanStr = FillingPlanStr + "|" + JSON.stringify(entry);
  });
  FillingPlanStr = FillingPlanStr.replace(/"/g, "'");

  const updatedStoreObj = JSON.parse(JSON.stringify(StoreObj));
  updatedStoreObj.FillingPlan = FillingPlanStr;

  return updatedStoreObj;
}

export function updateJofemarReqBody(Jofemar, updatedAttributes) {
  let jofemar = Jofemar;
  let attributesStr = "";

  updatedAttributes.forEach(row => {
    attributesStr = attributesStr + "|" + JSON.stringify(row)
  });
  attributesStr = attributesStr.replace(/"/g, "'");
  jofemar.Attributes = attributesStr;

  return Jofemar;
}

export function countBlockTypes(blocks) {
  // count block types
  const blockTypes: any = {};

  blocks.forEach(block => {
    const type = block.Code.split('-')[0];
    if (blockTypes[type]) {
      blockTypes[type] = blockTypes[type] + 1;
    } else {
      blockTypes[type] = 1;
    }
    block.Description = `${type}-${blockTypes[type]}`;
  });

  return blocks;
}