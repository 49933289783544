

export function getSelectedLockersRequestBody(Blocks, LockersSelected, actions) {

  const localGWLockers: any[] = [];
  const lockersToUpdate: any[] = [];
  const _Blocks = [...Blocks];

  LockersSelected.forEach(lockerSelected => {
    _Blocks.forEach((block, blockIndex) => {
      block.Lockers.forEach((locker, lockerIndex) => {
        if (lockerSelected === locker.Id) {
          localGWLockers.push({
            rack: blockIndex,
            locker: lockerIndex
          });

          if (actions.includes("LockerOpen")) {
            locker.Opened = true;
          }
          if (actions.includes("LockerDisable")) {
            locker.Enabled = false;
          }
          if (actions.includes("LockerEnable")) {
            locker.Enabled = true;
            locker.Opened = false;
          }

          lockersToUpdate.push(locker);
        }
      });
    });
  });

  return [localGWLockers, lockersToUpdate, _Blocks];


}

export function updateJofemarReqBody(Jofemar, updatedAttributes) {
  let jofemar = Jofemar;
  let attributesStr = "";

  updatedAttributes.forEach(row => {
    attributesStr = attributesStr + "|" + JSON.stringify(row)
  });
  attributesStr = attributesStr.replace(/"/g, "'");
  jofemar.Attributes = attributesStr;

  return Jofemar;
}