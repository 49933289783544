/* Config file for aws authentication */
const awsconfig = {
  Auth: {
    region: "eu-west-1",
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    userPoolId: process.env.REACT_APP_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOLWEBCLIENTID,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_WEBDEPLOYBUCKET,
      region: "eu-west-1",
    }
  },
  API: {
    endpoints: [
      {
        name: "AdminQueries",
        endpoint: process.env.REACT_APP_ADMQUERIES_URL,
        region: "eu-west-1",
      },
      {
        name: "AE",
        endpoint: process.env.REACT_APP_WEBAPI_URL,
        region: "eu-west-1",
      },
    ],
  },
};

export default awsconfig;
