import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "5px",
    borderRadius: "4px",
    outline: "none"
  },
  paperInner: {
    display: "flex",
    alignItems: "center",

    '&>*': {
      margin: '7px'
    }
  }
}));

export default function ModalBase(props) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="loading-modal"
      aria-describedby="loading-modal"
      open={true}
      className={classes.root}
    >
      <div id="modalBase" tabIndex={-1} className={classes.paper}>
        <div className={classes.paperInner}>
          {props.children}
        </div>
      </div>
    </Modal>
  );
}
