import React from "react";
import { Button } from '@material-ui/core';
import Modal from "@material-ui/core/Modal";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    '& .MuiButtonBase-root ': {
      margin: theme.spacing(1),
      // width: 320,
      padding: "9px 14px",
      marginTop: "12px",
    },
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "5px",
    borderRadius: "4px",
    outline: "none"
  },
  paperInner: {
    display: "flex",
    alignItems: "center",
    maxWidth: "450px",
    padding: "0 20px",


    '& .actions': {
      flexDirection: "row-reverse"
    },
    '& .actions button': {
      minWidth: "150px",
      width: "100%"
    },
  }
}));

export default function ConfirmationModal(props) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="confirmation-modal"
      aria-describedby="confirmation-modal"
      open={true}
      className={classes.root}
    >
      <div tabIndex={-1} className={classes.paper}>
        <div className={classes.paperInner}>
          <div className="flex-col">
            <div className={`message ${props.submessage ? "" : "text-center"}`}><h2>{props.message}</h2></div>
            {props.submessage ?
              <p className="mt0" id="subMessage">{props.submessage}</p>
              : null}
            <div className="actions flex">
              <Button
                id="confirmBtn"
                variant="contained"
                onClick={props.confirm}
                color="primary"
              >
                {props.confirmText ? props.confirmText : "Opslaan"}
              </Button>
              <Button
                variant="contained"
                onClick={props.closeModal}
              >
                {props.cancelText ? props.cancelText : "Annuleren"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
