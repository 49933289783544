import { ProductCategory } from "../../interfaces/IProductCategory";

export function pCategoryFormToReqBody(activeRow, values) {

  let pictureName;
  if (values.Avatar.name) {
    pictureName = values.Avatar.name;
  } else {
    pictureName = values.Avatar ? values.Avatar : " ";
  }
  if (activeRow && !values.Avatar) {
    pictureName = activeRow.Avatar;
  }

  const reqBody: ProductCategory = {
    Code: values.Code,
    Avatar: pictureName,
    Description: values.Description,
    Title: values.Title
  }
  if (activeRow !== null) {
    reqBody.Id = activeRow.Id;
  }

  return reqBody;
}