import React, { useEffect, useState } from 'react'
import DynamicTable from '../../components/DynamicTable'
import CssBaseline from '@material-ui/core/CssBaseline'
import useUIState from '../../hooks/useUIstate';
import { formatDateAndTimeDashboard } from '../../helpers/commonFunctions';

function TransactionList(props) {
  const { setShowLoadingModal, } = useUIState();
  const [data, setData]: [any[], any] = useState([]);

  useEffect(() => {
    if (props.transactionData.length) {
      selectTransactionData();
    }
    // eslint-disable-next-line
  }, [props.TransactionData])

  function selectTransactionData() {
    const transactionsData = JSON.parse(JSON.stringify(props.transactionData));
    let transactionsToRender: any[] = [];

    // order transactions (descending)
    const recentTransactions = transactionsData.sort((a, b) => {
      return new Date(b.TransactionDate).getTime() - new Date(a.TransactionDate).getTime();
    })

    // reduce to max first 25 transactions
    recentTransactions.splice(25);

    console.log(recentTransactions);
    console.log(props.products);

    // format renderData
    recentTransactions.forEach(transaction => {
      let transactionProductsString = "";

      transaction.ProductsSold.forEach(product => {
        const productExists = props.products.find(p => p.Id === product.ProductId)

        if (productExists) {
          const productInfo = props.products.find(p => p.Id === product.ProductId);

          // add to productsstring (productname + amount)
          transactionProductsString += productInfo.Title + ":   " + product.Units + "\n";
        }
      });

      transactionsToRender.push({
        TransactionDate: formatDateAndTimeDashboard(transaction.TransactionDate),
        TransactionProducts: transactionProductsString,
        TransactionTotalPrice: "\u20ac" + " " + transaction.AmountPaid?.toFixed(2)
      });
    })

    renderData(transactionsToRender);
  }

  async function renderData(transactionsToRender) {
    setData(transactionsToRender);
    setShowLoadingModal(false);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Transactie (Datum / Tijd)',
        accessor: 'TransactionDate',
      },
      {
        Header: 'Producten',
        accessor: 'TransactionProducts'
      },
      ,
      {
        Header: 'Totaalprijs',
        accessor: 'TransactionTotalPrice'
      },
    ],
    []
  );

  return (
    <div>
      <h2 className="mb-05">Meest recente transacties (max. 25)</h2>
      <CssBaseline />
      <DynamicTable
        className="TransactionList"
        columns={columns}
        data={data}
        loading={false}
        globalFilter={false}
      />
    </div>
  )
}

export default TransactionList;
