import { useContext } from "react";
import { DataContext } from "../context/DataContext";

const useDataState = () => {
  const [state, setState] = useContext(DataContext);

  function setStoreContext(Store) {
    setState(state => ({
      ...state,
      StoreContext: Store
    }));
  }

  function setProductsContext(products) {
    setState(state => ({
      ...state,
      ProductsContext: products
    }));
  }

  function setProductCategoriesContext(categories) {
    setState(state => ({
      ...state,
      ProductCategoriesContext: categories
    }));
  }

  function setStoreLayoutContext(storeLayout) {
    setState(state => ({
      ...state,
      StoreLayoutContext: storeLayout
    }));
  }

  function setJofemarContext(Jofemar) {
    setState(state => ({
      ...state,
      JofemarContext: Jofemar
    }));
  }

  function setLockersDPlanContext(LockersDPlan) {
    setState(state => ({
      ...state,
      LockersDPlanContext: LockersDPlan
    }));
  }

  function setJofemarDPlanContext(JofemarDPlan) {
    setState(state => ({
      ...state,
      JofemarDPlanContext: JofemarDPlan
    }));
  }

  return {
    StoreContext: state.StoreContext,
    setStoreContext,
    ProductsContext: state.ProductsContext,
    setProductsContext,
    ProductCategoriesContext: state.ProductCategoriesContext,
    setProductCategoriesContext,
    StoreLayoutContext: state.StoreLayoutContext,
    setStoreLayoutContext,
    JofemarContext: state.JofemarContext,
    setJofemarContext,
    LockersDPlanContext: state.LockersDPlanContext,
    setLockersDPlanContext,
    JofemarDPlanContext: state.JofemarDPlanContext,
    setJofemarDPlanContext,
  }

}

export default useDataState;