

// username: string,
// name: string,
// familyName: string,
// phoneNumber: string,
// picture: string,
// tempPassword: string,
// _role: string,
// _storeId: string

export function userFormToAPI(activeRow, values, MultiSelectValues) {

  const { username, name, familyName, tempPassword, role } = values; // formValues

  const phoneNumber = "+" + values.phoneNumber.toString().replace(/\s/g, '');
  let pictureName;
  if (values.picture.name) {
    pictureName = values.picture.name;
  } else {
    pictureName = values.picture ? values.picture : " ";
  }
  if (activeRow && !values.picture) {
    pictureName = activeRow.picture;
  }

  let assignedStoreIds = MultiSelectValues.Stores.length ?
    MultiSelectValues.Stores.map(a => `${a.value}`).join(",") : "";

  const reqBody = {
    username,
    name,
    familyName,
    tempPassword,
    role,
    pictureName,
    phoneNumber,
    assignedStoreIds
  }

  return reqBody;
} 