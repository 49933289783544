export function calculateOffersNoVat(offers, productsSold) {
  //
  // console.log("offers", offers);
  // console.log("productsSold", productsSold)

  const _Offers = JSON.parse(JSON.stringify(offers));
  const _ProductsSold = JSON.parse(JSON.stringify(productsSold));
  let offersAmountNoVAT = 0;

  _Offers.forEach(offer => {
    // sum of products of offer without vat
    let productsNoVat: number[] = [];
    let productsVat: number[] = [];

    offer.ProductIds?.forEach(productId => {
      const product = _ProductsSold.find(p => p.ProductId === productId);
      const productIndex = _ProductsSold.findIndex(p => p.ProductId === productId);

      if (product) {
        const noVatPrice = product.Price - (product.Price * (product.VAT / 100));
        productsNoVat.push(noVatPrice);
        productsVat.push(product.Price);
      }

      // remove calculated product
      if (_ProductsSold[productIndex].Units > 1) {
        _ProductsSold[productIndex].Units -= 1;
      } else {
        _ProductsSold.splice(productIndex, 1);
      }
    });

    const offerNoVAT = productsNoVat.reduce((a, b) => a + b, 0);
    const offerVAT = productsVat.reduce((a, b) => a + b, 0);

    if (offer.FixedPrice) {
      // calc by fixed price
      const diff = offerVAT - offer.FixedPrice;
      offersAmountNoVAT += offerNoVAT - diff;
    }
    else if (offer.DiscountPercentage) {
      // console.log('// calc by discount');
      const discount = offerNoVAT - (offerNoVAT * (offer.DiscountPercentage / 100));
      offersAmountNoVAT += discount;
    }

  });

  return [offersAmountNoVAT, _ProductsSold]
}

export function calculateSingleProductsNoVAT(productsSold) {
  let amountTotal = 0;

  productsSold.forEach(product => {
    for (let i = 0; i < product.Units; i++) {
      const priceNoVAT = product.Price - (product.Price * (product.VAT / 100))
      amountTotal += priceNoVAT;
    }
  });

  return amountTotal;
}