import React from 'react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import useUIState from '../../hooks/useUIstate';
import { MdArrowBack } from "react-icons/md";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { MdRemove, MdAdd } from 'react-icons/md';
import useDataState from '../../hooks/useDataState';
import ProductPanel from '../../components/ProductPanel';
import awsconfig from '../../aws-apiSettings';
import JofemarSelectionPanel from '../../components/JofemarSelectionPanel';
import { getInt } from '../../helpers/commonFunctions';
import { updateJofemarReqBody } from './ReplenishmentController';
import { API } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  root: {
    '& h1, & h2': {
      marginTop: '5px',
      marginBottom: '5px'
    },
    '& #jofemarHeader': {
      background: '#eee',
      padding: "5px"
    },
    '& #jofemarHeader>*': {
      color: '#777',
      margin: 0
    },
    '& #jofemarContents': {
      background: '#white',
    },
    '& #jofemarBody': {
      maxHeight: "500px",
      overflowY: "scroll"
    },
    '& .jofemar-row': {
      padding: '5px',
      background: "#eaeaea"
    },
    '& .jofemar-columns': {
      background: "#eee"
    },
    '& .jofemar-row-header, & .jofemar-column-body': {
      background: "#fff",
      borderBottom: "1px solid #ddd"
    },
    '& .jofemar-column-body.selected': {
      background: "#e8f5e9",
    },
    '& .header-element': {
      padding: '5px',
    },
    '& .body-element': {
      padding: '5px',
      alignItems: "center",
      display: "flex",
      fontWeight: 700
    },
    '& .product-image-container': {
      minWidth: '65px',
      height: '65px',
      background: "white",
      marginRight: "5px",
      border: "1px solid #ddd"
    },
    '& .product-image-container img': {
        width: '63px',
        height: '63px',
        objectFit: "fill"
    },
    '& .body-element.update-stock .MuiTextField-root': {
      width: "50px",
      margin: "0 5px",
      background: "white",
    },
    '& .body-element.update-stock .MuiButtonBase-root': {
      padding: "5px",
      minWidth: "40px"
    },
    '& .product-img': { width: "170px" },
    '& .current-stock': { width: "150px" },
    '& .max-capacity': { width: "80px" },
    '& .latest-refill': { width: "170px" },
    '& .update-stock': { width: "180px" },
    '& .button-row': { width: "200px" },
    '& .replenishment-bottom': {
      marginTop: "20px",
      maxWidth: "90vw"
    }
  }
}));


function ReplenishmentJofemarScreen(props) {
  const history = useHistory();
  const classes = useStyles();

  const { ProductsContext, ProductCategoriesContext, setJofemarDPlanContext, JofemarDPlanContext, JofemarContext, StoreContext } = useDataState()
  const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();

  const [StoreId, setStoreId] = useState(null);
  const [Store, setStore] = useState(null)
  const [Jofemar, setJofemar] = useState({ Attributes: "" });
  
  const [Jofemars, setJofemars]: [any, any] = useState([]);
  const [JofemarTitle, setJofemarTitle] = useState("");
  const [ActiveJofemarId, setActiveJofemarId] = useState(-1); // 
  const [JofemarInfo, setJofemarInfo]: [any, any] = useState([]);
  const [IsDirtyCurrentJofemarContents, setIsDirtyCurrentJofemarContents] = useState(false); // true: allow save, false: allow jofemar navigation
  
  const [StoreTitle, setStoreTitle] = useState("");
  const [JofemarContents, setJofemarContents]: [any, any] = useState([]);
  const [Values, setValues]: [any, any] = useState([])
  const [ShowPanel, setShowPanel] = useState(false);
  const [ActiveRow, setActiveRow] = useState(0);
  const [ActiveColumn, setActiveColumn] = useState(0);
  const [JofemarDistributionPlan, setJofemarDistributionPlan]: [any, any] = useState([])
  const [Products, setProducts]: [any, any] = useState([]);
  const [UserConfigData, setUserConfigData]: [any, any] = useState([]);

  useEffect(() => {
    const value = localStorage.getItem("UserConfigData");
    if (typeof value === 'string') {
      setUserConfigData(JSON.parse(value));
    }

    console.log(props);
    if (props.location.Jofemar) {
      console.log('props')
      setStoreId(props.location.storeId);
      setStoreTitle(props.location.title);
      setJofemar(props.location.Jofemar);
      setActiveJofemarId(props.location.Jofemar.Id)
      setJofemars(props.location.Jofemars);
      setIsDirtyCurrentJofemarContents(props.location.IsDirtyCurrentJofemarContents);
      setProducts(props.location.products);
      setStore(props.location.store);
    } else if (JofemarContext && Object.entries(JofemarContext).length) {
      // when is this scenario applicable?
      console.log('context')
      //set Jofemar from Jofemars
      const activeJofemarId = localStorage.getItem("ActiveJofemarId");
      const jofemars = localStorage.getItem("Jofemars")
      if (jofemars) {
        const jofemarsJson = JSON.parse(jofemars)
        const jofemarJson = jofemarsJson.find(block => block.Id === Number(activeJofemarId))
        const isDirtyCurrentJofemarContents = localStorage.getItem("IsDirtyCurrentJofemarContents")
        if (isDirtyCurrentJofemarContents === "true") {
          setIsDirtyCurrentJofemarContents(true);
        } else {
          setIsDirtyCurrentJofemarContents(false);
        }
        setJofemar(jofemarJson);
        setJofemars(jofemarsJson);
      }

      setProducts(ProductsContext);
      setStore(StoreContext);
      setJofemarDistributionPlan(JofemarDPlanContext);
      
    } else if (localStorage.getItem("Jofemar")) {
      // when is this scenario applicable?
      console.log("local storage")
      const JofemarLS = localStorage.getItem("Jofemar")
      if (JofemarLS) {
        setJofemar(JSON.parse(JofemarLS));
      }
      const Products = localStorage.getItem("Products")
      if (Products) {
        setProducts(JSON.parse(Products));
      }
      const Store = localStorage.getItem("ActiveStore")
      if (Store) {
        setStore(JSON.parse(Store));
      }
    } else {
      history.push('/retail-locations');
    }
  }, [])

  useEffect(() => {
    if (ActiveJofemarId !== -1) {
      localStorage.setItem("ActiveJofemarId", String(ActiveJofemarId));
    }
  }, [ActiveJofemarId])

  useEffect(() => {
    localStorage.setItem("IsDirtyCurrentJofemarContents", String(IsDirtyCurrentJofemarContents));
  }, [IsDirtyCurrentJofemarContents])

  useEffect(() => {
    if (Jofemars) {
      const jofemarInfo: any[] = [];
      const JofemarsLS = localStorage.getItem("Jofemars")

      if (typeof JofemarsLS === 'string') {
        // create array for buttons in jofemar control panel
        var jofemarList = JSON.parse(JofemarsLS);
        jofemarList.forEach(item => {
          jofemarInfo.push({ id: item.Id, name: item.Code});
        });
      }
      setJofemarInfo(jofemarInfo);
    }
  
    
    if (Jofemar) {

      console.log(Jofemar)

      let attr = Jofemar.Attributes.split('|');
      const attributes: any[] = [];

      attr.forEach((el, index) => {
        let element = el;
        if (element) {
          attributes.push(JSON.parse(element.replace(/'/g, '"')));
        }
      });

      if (attributes.length > 0) {
        setJofemarTitle(attributes[0].vendingMachineLogicalName);
      }

      const values: any = [];
      attributes.forEach(row => {
        const Columns: any = [];
        row.Columns.forEach(col => {
          Columns.push({ UpdatedStock: col.CurrentStock })
        });
        values.push({ Columns })
      });

      // updated from ls (persist from navigation)
      if (localStorage.getItem("CurrentJofemarContents") && values.length && attributes.length) {
        const str = localStorage.getItem("CurrentJofemarContents") || "";
        const currJofemarContents = JSON.parse(str);
        currJofemarContents.forEach((row, rowIndex) => {
          row.Columns.forEach((col, colIndex) => {
            values[rowIndex].Columns[colIndex].UpdatedStock = col.UpdatedStock;
            attributes[rowIndex].Columns[colIndex].ProductId = col.ProductId;
            attributes[rowIndex].Columns[colIndex].LatestRefill = col.LatestRefill;
            attributes[rowIndex].Columns[colIndex].CurrentStock = col.CurrentStock;
          });
        });
      }

      console.log(attributes)

      setValues(values);
      setJofemarContents(attributes);
    }

  }, [Jofemar])




  function handleChange(e, row, column, action = "", value = 0) {
    setIsDirtyCurrentJofemarContents(true)
    const values = [...Values];
    if (!action) {
      if (e.target.value === '') {
        values[row].Columns[column].UpdatedStock = e.target.value;
      } else {
        values[row].Columns[column].UpdatedStock = getInt(e.target.value, 0, 0);
      }
    } else if (action === "decrement") {
      if (value > 0) {
        values[row].Columns[column].UpdatedStock = value - 1;
      }
    } else if (action === "increment") {
      if (value < JofemarContents[row].nrDepth) {
        values[row].Columns[column].UpdatedStock = value + 1;
      }
    }
    setValues(values);

    // update ls
    const currJofemarContents: any[] = [...JofemarContents];
    values.forEach((r, rowIndex) => {
      r.Columns.forEach((col, colIndex) => {
        currJofemarContents[rowIndex].Columns[colIndex].CurrentStock = getInt(currJofemarContents[rowIndex].Columns[colIndex].CurrentStock, 0, 0);
        currJofemarContents[rowIndex].Columns[colIndex].UpdatedStock = getInt(col.UpdatedStock, 0, 0);
      });
    });
    localStorage.setItem("CurrentJofemarContents", JSON.stringify(currJofemarContents));
  }

  function switchProducts(_productId) {
    setIsDirtyCurrentJofemarContents(true)
    let productId = _productId[0];
    const jofemarContents = JSON.parse(JSON.stringify(JofemarContents));
    jofemarContents[ActiveRow].Columns[ActiveColumn].ProductId = productId;
    jofemarContents[ActiveRow].Columns[ActiveColumn].CurrentStock = 0;

    setJofemarContents(jofemarContents);

    // update ls
    const currJofemarContents: any[] = [...jofemarContents];
    currJofemarContents.forEach((r, rowIndex) => {
      r.Columns.forEach((col, colIndex) => {
        col.UpdatedStock = Values[rowIndex].Columns[colIndex].UpdatedStock;
      });
    });
    localStorage.setItem("CurrentJofemarContents", JSON.stringify(currJofemarContents));
  }




  const handleJofemarOnClick = (jofemarItem) => {
    loadJofemarById(jofemarItem.id)
  };

  handleJofemarOnClick.disabled = !{IsDirtyCurrentJofemarContents}
  handleJofemarOnClick.jofemarInfo = JofemarInfo;
  
  function loadJofemarById(id) {
    console.log('loading jofemar with id: ' + id)
  
    // get from storage
    const jofemars = localStorage.getItem("Jofemars")

    if (jofemars) {
      localStorage.removeItem("CurrentJofemarContents")
      const jofemarsJson = JSON.parse(jofemars)
      console.log('number of jofemars found: ' + jofemarsJson.length)
      const jofemar = jofemarsJson.find(block => block.Id === id)

      let attrStr = "";
      attrStr = jofemar.Attributes;

      let attr = attrStr.split('|');
      const attributes: any[] = [];

      attr.forEach((el, index) => {
        let element = el;
        
        if (element) {
          attributes.push(JSON.parse(element.replace(/'/g, '"')));
        }
      });
      localStorage.setItem("CurrentJofemarContents", JSON.stringify(attributes));

      setJofemar(jofemar);
      setActiveJofemarId(id);
    }
  }








  function getDisplayDateTime(datetime) {
    let date = datetime.split("T")[0];
    let time = datetime.split("T")[1];

    date = date.split("-");
    date = `${date[2]}/${date[1]}/${date[0]}`;

    time = time.split(":");
    time = `${time[0]}:${time[1]}`;

    return `${date} ${time}`;
  }

  function openProductPanel(row, column) {
    setActiveRow(row);
    setActiveColumn(column);
    setShowPanel(true);
  }

  function prepDistributionPlan() {
    const jofemarContents = [...JofemarContents];

    console.log("Jofemar", Jofemar);
    console.log("jofemarContents", jofemarContents);
    console.log("Values", Values);

    // update dist plan 
    const jofemarDistributionPlan: any[] = [];

    console.log(jofemarDistributionPlan)

    jofemarContents.forEach((row, rowIndex) => {
      row.Columns.forEach((col, colIndex) => {
        if (col.ProductId) {
          const updateStockAmount = Values[rowIndex].Columns[colIndex].UpdatedStock;
          // updatedAttributes[rowIndex].Columns[colIndex].CurrentStock = updateStockAmount;

          if (updateStockAmount - col.CurrentStock > 0) {
            console.log(updateStockAmount - col.CurrentStock)

            // if entry already exists
            if (jofemarDistributionPlan.find(x => x.ProductId === col.ProductId)) {
              console.log("amount increased")
              const index = jofemarDistributionPlan.findIndex(x => x.ProductId === col.ProductId);
              jofemarDistributionPlan[index].ReplenishmentAmount = jofemarDistributionPlan[index].ReplenishmentAmount + (updateStockAmount - col.CurrentStock);
            } else {
              console.log("pushed")
              jofemarDistributionPlan.push({
                ProductId: col.ProductId,
                ReplenishmentAmount: updateStockAmount - col.CurrentStock
              })
            }
          }
          if (updateStockAmount < col.CurrentStock) {
            const index = jofemarDistributionPlan.findIndex(x => x.ProductId === col.ProductId);
            console.log("amount decreased")
            if (jofemarDistributionPlan[index]) {
              jofemarDistributionPlan[index].ReplenishmentAmount = jofemarDistributionPlan[index].ReplenishmentAmount - (col.CurrentStock - updateStockAmount);
            }
          }
        }

      });
    });

    console.log(jofemarDistributionPlan)

    const filteredPlan = jofemarDistributionPlan.filter(x => x.ReplenishmentAmount > 0);

    console.log(filteredPlan)

    setJofemarDistributionPlan(filteredPlan);
    localStorage.setItem("JofemarDistPlan", JSON.stringify(filteredPlan))
    setJofemarDPlanContext(filteredPlan);

    jofemarContents.forEach((row, rowIndex) => {
      row.Columns.forEach((col, colIndex) => {
        if (col.CurrentStock !== Values[rowIndex].Columns[colIndex].UpdatedStock) {
          col.CurrentStock = Values[rowIndex].Columns[colIndex].UpdatedStock;
        }
      });
    });

    const updatedJofemar = updateJofemarReqBody(Jofemar, jofemarContents);

    console.log(updatedJofemar);
    const body = JSON.parse(JSON.stringify(Store));
    body.JofemarFillingPlan = updatedJofemar.Attributes;
    console.log(body)

    setShowLoadingModal(true)
    API.put('AE', `/Stores`, { body })
      .then(res => {
        console.log(res);

        console.log(props.location)

        history.push(
          {
            pathname: '/distributieplan',
            store: body,
            storeId: StoreId,
            title: StoreTitle,
            Jofemar: Jofemar,
            products: Products,
            JofemarTitle: JofemarTitle,
            lockersDistributionPlan: props.location.lockersDistributionPlan,
            jofemarDistributionPlan: props.location.jofemarDistributionPlan
          })
      })
      .catch(error => {
        console.log(error);
        setToastType("error");
        setToastMessage("Error updating Jofemar");
        setShowToastMessage(true);
      })
      .finally(() => {
        setShowLoadingModal(false);
      })

  }

  function GoBack() {
    // update array in storage
    UpdateJofemarArray()
    history.goBack()
  }

  function UpdateJofemarArray() {
    // update Jofemars array
    let index = 0;
    for (let i=0;i<Jofemars.length;i++) {
      if (Jofemars[i].Id === ActiveJofemarId) {
        index = i;
        break;
      }
    }
    
    const jofemarContents = [...JofemarContents];
    const jofemars = Jofemars
    const reqBody = updateJofemarReqBody(jofemars[index], jofemarContents);
    jofemars[index] = reqBody;
    localStorage.setItem("Jofemars", JSON.stringify(jofemars))
    setJofemars(jofemars)
  }

  return (
    <div id="replenishmentJofemar" className={classes.root}>
      <div className="replenishment-header flex-center-align">
        <div style={{ cursor: "pointer" }}>
          <MdArrowBack size="30" onClick={() => history.goBack()} />
        </div>
        <h1 id="storeTitle" className="ml1">{StoreTitle}</h1>
        <h2 className="ml1">Vulplan - Stap 2 Vending machine</h2>
      </div>
      {JofemarContents ?
        <div id="jofemarContents">
          <div id="jofemarHeader">
          <h4>Jofemar {JofemarTitle}</h4>
          </div>
          {/* todo:RSA datamodel change needed for multi-jofemar planning: for now disabled always {true} instead of {IsDirtyCurrentJofemarContents} */}
          <JofemarSelectionPanel activeId={ActiveJofemarId} disabled={true} jofemarInfo={JofemarInfo} passedFunction={handleJofemarOnClick}></JofemarSelectionPanel>
          <div id="jofemarBody">
            {JofemarContents.map((entry, index) => {
              return (
                <div key={index} className="jofemar-row">
                  <div className="jofemar-row-header flex">
                    <div className="header-element product-img">Rij #{index + 1}</div>
                    <div className="header-element current-stock">Huidige voorraad</div>
                    <div className="header-element max-capacity">Max</div>
                    <div className="header-element latest-refill">Laatst bijgevuld op</div>
                    <div className="header-element update-stock">Werk voorraad bij tot</div>
                    <div className="header-element button-row" />
                  </div>

                  {entry.Columns.map((col, colIndex) => {
                    return (
                      <div key={`${index}${colIndex}`} id={`r${index}col${colIndex}`} className={`jofemar-column-body flex ${ActiveRow === index && ActiveColumn === colIndex && ShowPanel ? "selected" : ""}`}>
                        <div className="body-element product-img">
                          <div className="product-image-container">
                            {col.ProductId && UserConfigData.Customer ?
                              <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${UserConfigData.Customer}/images/products/pid${col.ProductId}_${Products.find(prod => prod.Id === col.ProductId).Avatar}`} alt="prod-img" />
                              : null
                            }
                          </div>
                          <div>{col.ProductId ? Products.find(p => p.Id === col.ProductId).Code : "Geen product"}</div>
                        </div>
                        <div className="body-element current-stock">
                          {col.CurrentStock}
                        </div>
                        <div className="body-element max-capacity">
                          {entry.nrDepth}
                        </div>
                        <div className="body-element latest-refill">
                          {col.LatestRefill ? getDisplayDateTime(col.LatestRefill) : null}
                        </div>
                        <div className="body-element update-stock">
                          <Button variant="contained" className="gray-btn decrement-stock"
                            onClick={(e) => handleChange(e, index, colIndex, "decrement", Values[index].Columns[colIndex].UpdatedStock)}
                          >
                            <MdRemove size={22} />
                          </Button>
                          <TextField
                            className="UpdatedStockValue"
                            type="text"
                            onChange={e => handleChange(e, index, colIndex)}
                            value={Values[index].Columns[colIndex].UpdatedStock}
                            variant="outlined"
                            size="small"
                          />
                          <Button variant="contained" className="gray-btn increment-stock"
                            onClick={(e) => handleChange(e, index, colIndex, "increment", Values[index].Columns[colIndex].UpdatedStock)}
                          >
                            <MdAdd size={22} />
                          </Button>
                        </div>
                        <div className="body-element button-row">
                          <Button
                            variant="contained"
                            className="gray-btn"
                            onClick={() => openProductPanel(index, colIndex)}
                          >
                            Product wisselen
                          </Button>
                        </div>
                      </div>
                    )
                  })}



                </div>
              )
            })}
          </div>

          <div className="replenishment-bottom flex jcsb">

            <div>
              <Button
                type="button"
                variant="contained"
                className="gray-btn"
                onClick={() => GoBack()}
              >Stap 1 - Lockers
              </Button>
            </div>
            <div>
              <Button
                id="goToDistributionPlan"
                type="button"
                variant="contained"
                className="green-btn"
                onClick={() => prepDistributionPlan()}
              >Stap 3 maak distributieplan
              </Button>
            </div>
          </div>
        </div>
        : null}

      {ProductCategoriesContext && ProductsContext ?
        <ProductPanel
          productCategories={ProductCategoriesContext}
          products={ProductsContext}
          lockersSelected={[]}
          switchProducts={switchProducts}
          isJofemar={true}
          showPanel={ShowPanel}
          closePanel={() => setShowPanel(false)}
        />
        : null}
    </div>
  )
}

export default ReplenishmentJofemarScreen
