
function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-') + "T00:00:00";
}

export function productUpdateList(LockersSelected, _Blocks, productId, lockersUpdatedIds, _prodEntry) {

  console.log(productId)

  let lockersToReplenish: any[] = [];

  LockersSelected.forEach(lockerSelectedId => {
    _Blocks.forEach((block, blockIndex) => {
      block.Lockers.forEach((locker, lockerIndex) => {
        if (locker.Id === lockerSelectedId) {
          const todayStr = formatDate(new Date());
          locker.ReplenishedAt = todayStr;
          locker.ProductId = productId;
          locker.IsSoldOut = false;
          lockersToReplenish.push(locker);
          // update lockers in dist plan
          if (!lockersUpdatedIds.includes(lockerSelectedId)) {
            _prodEntry.Amount = _prodEntry.Amount + 1;
            lockersUpdatedIds.push(lockerSelectedId);
          }
        }
      });
    });
  });

  _prodEntry.LockersUpdatedIds = lockersUpdatedIds;

  return { lockersToReplenish, _prodEntry }
}

export function fillingPlanReqBody(LockersDistributionPlan, StoreObj, firstPass) {
  const FillingPlan: any[] = firstPass ? convertStringToArray(StoreObj.FillingPlan) : [];

  LockersDistributionPlan.forEach(product => {
    product.LockersUpdatedIds.forEach(lockerId => {
      if (FillingPlan.find(l => l.LockerId === lockerId)) {
        const index = FillingPlan.findIndex(l => l.LockerId === lockerId);
        FillingPlan[index].ProductId = product.ProductId;
      } else {
        FillingPlan.push({
          LockerId: lockerId,
          ProductId: product.ProductId
        })
      }
    });
  });

  let FillingPlanStr = "";
  FillingPlan.forEach(entry => {
    FillingPlanStr = FillingPlanStr + "|" + JSON.stringify(entry);
  });
  FillingPlanStr = FillingPlanStr.replace(/"/g, "'");

  const updatedStoreObj = JSON.parse(JSON.stringify(StoreObj));
  updatedStoreObj.FillingPlan = FillingPlanStr;

  return updatedStoreObj;
}

export function updateJofemarReqBody(Jofemar, updatedAttributes) {
  let jofemar = Jofemar;
  let attributesStr = "";

  updatedAttributes.forEach(row => {
    attributesStr = attributesStr + "|" + JSON.stringify(row)
  });
  attributesStr = attributesStr.replace(/"/g, "'");
  jofemar.Attributes = attributesStr;

  return Jofemar;
}

export function convertStringToArray(str) {
  let arr;
  if (str) {

    arr = str.split("|");
    arr.shift();
    arr = arr.map(l => JSON.parse(l.replace(/'/g, '"')))
    return arr;
  } else {
    return [];
  }
}

export function lockerSizeValidation(block, Products, productId) {

  const lockerSizes = {
    "180mm x 200mm": ["A", "B", "C", "D", "E"],
    "180mm x 300mm": ["B", "C", "D", "E"],
    "180mm x 400mm": ["C"],
    "247mm x 300mm": ["D", "E"],
    "372mm x 300mm": ["E"],
  };
  let FailedValidationMsg = "";

  const product = Products.find(p => p.Id === productId);
  const attr = JSON.parse(product.Attributes.replace(/'/g, '"'));
  const lockerSize = attr.LockerSize;
  if (lockerSize=="NVT")
    return FailedValidationMsg;
  if (!lockerSizes[lockerSize].includes(block.Code[0])) {
    FailedValidationMsg = `Locker size validation error: ${product.Title} 
    has a locker size of ${lockerSize} and can be inserted only in ${lockerSizes[lockerSize]}
    types of lockers`;
  }

  return FailedValidationMsg;
}