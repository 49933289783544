import { Product } from "../../interfaces/IProduct";

export function productFormToReqBody(activeRow, values) {

  let pictureName;
  if (values.Avatar.name) {
    pictureName = values.Avatar.name;
  } else {
    pictureName = values.Avatar ? values.Avatar : " ";
  }
  if (activeRow && !values.Avatar) {
    pictureName = activeRow.Avatar;
  }

  const AttributesObj = {
    ShelfLifeDays: values.ShelfLifeDays,
    LockerSize: values.LockerSize,
    Priority: values.Priority,
    Amount: values.Amount,
    Weight: values.Weight,
    Enabled: values.Enabled
  }

  console.log(JSON.stringify(AttributesObj).replace(/"/g, "'"));

  const reqBody: Product = {
    ProductCategoryId: values.ProductCategoryId,
    Code: values.Code,
    Avatar: pictureName,
    Description: values.Description,
    Title: values.Title,
    IsOnStartPage: values.IsOnStartPage,
    DefaultPrice: values.DefaultPrice,
    VAT: values.VAT,
    Attributes: JSON.stringify(AttributesObj).replace(/"/g, "'")
  }
  if (activeRow !== null) {
    reqBody.Id = activeRow.Id;
  }

  return reqBody;
}