import { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import MultiSelect from "react-multi-select-component";
import { Button, Select, MenuItem } from '@material-ui/core';
import { CheckboxWithLabel } from 'formik-material-ui';
import { s3remove } from "../helpers/commonFunctions";
import { Storage } from "aws-amplify";
import CustomImageInput from "./CustomImageInput";
import useUIState from '../hooks/useUIstate';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import QRCode from "react-qr-code";
import { downloadPng } from 'svg-crowbar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    '& .MuiInputBase-root, & .multi-select, & .form-group-fileinput input': {
      margin: theme.spacing(1),
      width: 320,
    },
    '& .MuiSelect-select': {
      padding: "10.5px 14px",
    },
    '& label': {
      marginLeft: theme.spacing(1),
      marginBottom: '0',
      fontSize: '0.8rem'
    },
    '& .MuiButtonBase-root ': {
      margin: theme.spacing(1),
      width: 320,
      padding: "9px 14px",
      marginTop: "12px",
    },
    '& .MuiCheckbox-root': {
      width: 'auto'
    },
    '& .form-content': {
      maxWidth: '680px',
      flexWrap: "wrap"
    },
    '& .form-group-checkbox': {
      width: "320px",
      margin: "0 8px"
    },
    '& .form-group-qrcode': {
      marginLeft: "30px"
    },
    '& .center-items': {
      alignItems: "center"
    },
    '& .form-group-fileinput input': {
      padding: "8.5px 14px",
      border: "1px solid #ccc",
      borderRadius: "4px"
    },
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "5px",
    borderRadius: "4px",
    outline: "none"
  },
  paperInner: {
    display: "flex",
    alignItems: "center",

    '&>*': {
      margin: '7px'
    }
  }
}));

const FormikDatePicker = ({
  name,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  return (
    <DatePicker
      name={rest.fieldName}
      clearable
      autoOk
      format="dd/MM/yyyy"
      placeholder="10/10/2018"
      onChange={value => {
        rest.changeDate(rest.fieldName, value);
      }}
      value={value}
      animateYearScrolling={false}
    />
  );
};

export default function DForm(props) {
  const { setToastMessage, setShowToastMessage, setToastType } = useUIState();
  const classes = useStyles();
  const [ImgUrl, setImgUrl]: [any, any] = useState("");
  const [RemovedAvatar, setRemovedAvatar] = useState(false);

  const customValueRenderer = (selected, options) => {
    return selected.length
      ? selected.map(({ label }) => label + ", ")
      : "Press to select";
  };

  useEffect(() => {
    if (props.initialValues.picture || props.initialValues.Avatar) {
      getAvatar();
    }
    // eslint-disable-next-line
  }, [props])

  function getAvatar() {
    const filteredFormObj = props.formObj.filter((el) => {
      return el != null;
    });
    const entry = filteredFormObj.find(el => el.name === 'picture') ||
      filteredFormObj.find(el => el.name === 'Avatar');
    const filePath = entry.objUrl;

    Storage.get(filePath, { download: true })
      .then((res: any) => {
        const url = URL.createObjectURL(res.Body);
        console.log(url);
        setImgUrl(url);
      })
  }

  function handleQRCodeClick(value) {
    downloadPng(document.querySelector('#QRCode'), value, { css: 'internal' });
  }

  async function removeImage(filePath) {
    const res = await s3remove(filePath);
    if (res) {
      setImgUrl("");
    } else {
      getAvatar();
      setToastType("error");
      setToastMessage("Image wasn't removed. Please try again");
      setShowToastMessage(true);
    }
  }

  return (
    <Modal
      aria-labelledby="DForm"
      aria-describedby="DForm"
      open={true}
      className={classes.root}
    >
      <div tabIndex={-1} className={classes.paper}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className={classes.paperInner}>
            <Formik
              initialValues={props.initialValues}
              onSubmit={(values, { setSubmitting }) => {
                props.submit(values);
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="flex-col">
                    <h3>{props.formMode === 'Add' ? 'Toevoegen' : 'Bewerken'} item</h3>
                    <div className="flex form-content">

                      {/* eslint-disable-next-line */}
                      {props.formObj.map(el => {
                        if (el) {
                          if (
                            el.type === "text" ||
                            el.type === "email" ||
                            el.type === "number" ||
                            el.type === "password" ||
                            el.type === "tel"
                          ) {
                            return (
                              <div key={el.name} className="form-group flex-col">
                                <label htmlFor={el.name}>{el.label}</label>
                                <TextField
                                  id={el.name}
                                  name={el.name}
                                  type={el.type}
                                  required={el.required ? true : false}
                                  disabled={el.disabled ? true : false}
                                  inputProps={{ pattern: el.pattern ? el.pattern : null }}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values[el.name]}
                                  variant="outlined"
                                  size="small"
                                />
                                {errors[el.name] && touched[el.name] && errors[el.name]}
                              </div>
                            );
                          }

                          if (el.type === "select") {
                            return (<div key={el.name} className="form-group flex-col">
                              <label htmlFor={el.label}>{el.label}</label>
                              <Select
                                id={el.name}
                                name={el.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                defaultValue={values[el.name]}
                                variant="outlined"
                              >

                                {el.options.map(opt => {
                                  return (
                                    <MenuItem
                                      key={opt.label}
                                      value={opt.value}
                                    >{opt.label}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                              {errors[el.name] && touched[el.name] && errors[el.name]}
                            </div>);
                          }

                          if (el.type === "multiselect") {
                            return (<div key={el.name} id={el.name} className="form-group flex-col">
                              <label htmlFor={el.label}>{el.label}</label>
                              <MultiSelect
                                valueRenderer={customValueRenderer}
                                options={el.options}
                                value={props.multiSelectValues[el.name]}
                                onChange={(selected) => props.handleMultiSelectChange(el.name, selected)}
                                labelledBy={"Select"}
                              />
                              {/* <span>{JSON.stringify(props.multiSelectValues)}</span> */}
                            </div>)
                          }

                          if (el.type === "checkbox") {
                            return (<div key={el.name} id={el.name} className="form-group form-group-checkbox flex-col">
                              <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name={el.name}
                                Label={{ label: el.label }}
                              />
                            </div>)
                          }

                          if (el.type === "file") {
                            return (<div key={el.name} id={el.name} className="form-group form-group-fileinput flex-col">
                              <label htmlFor={el.name}>{el.label}</label>
                              {
                                ImgUrl !== "" ?
                                  <>
                                    <img width="80" height="80" src={ImgUrl} alt="avatar" className="src" />
                                    <button id="removeImg" type="button" onClick={() => {
                                      removeImage(el.objUrl);
                                      setFieldValue(el.name, " ");
                                      setRemovedAvatar(true);
                                    }}>Remove</button>
                                  </>
                                  :
                                  <Field
                                    name={el.name}
                                    component={CustomImageInput}
                                    title={""}
                                    setFieldValue={setFieldValue}
                                    errorMessage={errors["file"] ? errors["file"] : undefined}
                                    touched={touched["file"]}
                                    style={{ display: "flex" }}
                                    onBlur={handleBlur}
                                    maxWidth={el.maxWidth}
                                    maxHeight={el.maxHeight}
                                  />
                              }
                            </div>)
                          }

                          if (el.type === "qrcode") {
                            return (
                              (props.formMode === 'Add' ? null :
                                <div key={el.name} id={el.name} className="form-group form-group-qrcode flex-col center-items">
                                  <label htmlFor={el.label}>QR Code (klik voor download)</label>
                                  <QRCode id="QRCode" style={{ backgroundColor: "#fff", marginLeft: 8, marginTop: 8, height: "auto", maxWidth: "100%" }} size={104} viewBox={'0 0 124 124'} value={props.qrcodeType + values[el.label]} onClick={() => handleQRCodeClick(props.qrcodeType + values[el.label])} />
                                </div>)
                            )
                          }

                          if (el.type === "date") {
                            return (<div key={el.name} id={el.name} className="form-group flex-col">
                              <label htmlFor={el.label}>{el.label}</label>
                              <Field
                                component={FormikDatePicker}
                                changeDate={setFieldValue}
                                fieldName={el.name}
                                name={el.name}
                              />
                            </div>)
                          }
                        }
                      })}

                    </div>
                    <div className="flex">

                      <Button
                        variant="contained"
                        onClick={
                          RemovedAvatar ?
                            () => props.submit(values) :
                            props.closeModal
                        }
                      >
                        Annuleren
                      </Button>

                      <Button
                        id="formSubmitBtn"
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Opslaan
                      </Button>
                    </div>
                  </div>

                </form>
              )}
            </Formik>

          </div>
        </MuiPickersUtilsProvider>
      </div>
    </Modal>
  );
}
