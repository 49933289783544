import React, { useEffect, useState } from 'react'
import DynamicTable from '../../components/DynamicTable'
import CssBaseline from '@material-ui/core/CssBaseline'
import useUIState from '../../hooks/useUIstate';
import { isToday } from '../../helpers/commonFunctions';

function ProductsList(props) {
  const {
    setShowLoadingModal,
  } = useUIState();

  const [data, setData]: [any[], any] = useState([]);

  useEffect(() => {
    if (props.transactionData.length) {
      createDayData();
    }
    // eslint-disable-next-line
  }, [props.TransactionData])

  function createDayData() {
    const transactionData = JSON.parse(JSON.stringify(props.transactionData));
    const TransactionsToday = transactionData.filter(t => isToday(t.TransactionDate));
    let Products: any[] = [];

    TransactionsToday.forEach(transaction => {

      transaction.ProductsSold.forEach(product => {
        const productExists = props.products.find(p => p.Id === product.ProductId)

        if (productExists) {
          const productToUpdate = Products.find(p => p.ProductId === product.ProductId);

          if (productToUpdate) {
            const index = Products.findIndex(p => p.ProductId === product.ProductId);
            Products[index].Units = Products[index].Units + product.Units;
          } else {
            Products.push({
              ProductId: product.ProductId,
              Units: product.Units
            })
          }
        }

      });
    });
    renderData(Products);
  }


  async function renderData(productsData) {
    const productsRender: any[] = [];
    productsData.forEach(prod => {
      productsRender.push({
        ProductCode: props.products.find(p => p.Id === prod.ProductId).Title,
        UnitsSold: prod.Units,
      })
    });

    productsRender.sort((a, b) => (a.UnitsSold < b.UnitsSold) ? 1 : -1)

    setData(productsRender);
    setShowLoadingModal(false);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Productnaam',
        accessor: 'ProductCode',
      },
      {
        Header: 'Aantal',
        accessor: 'UnitsSold',
        width: 80
      },
    ],
    []
  );

  return (
    <div>
      <h2 className="mb-05">Vandaag verkochte producten</h2>
      <CssBaseline />
      <DynamicTable
        className="ProductsList"
        columns={columns}
        data={data}
        loading={false}
        globalFilter={true}
      // addRow={() => console.log(123)}
      />

    </div>
  )
}

export default ProductsList;
