import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";

import withStyles from "@material-ui/core/styles/withStyles";
import customImageInputStyle from "./CustomImageInputStyle";
import classnames from "classnames";
import { MdFolderOpen } from 'react-icons/md';

class CustomImageInput extends Component {
    constructor(props) {
        super(props);
        this.fileUpload = React.createRef();
        this.showFileUpload = this.showFileUpload.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    state = {
        file: undefined,
        imagePreviewUrl: undefined
    };

    showFileUpload() {
        if (this.fileUpload) {
            this.fileUpload.current.click();
        }
    }

    resizeImage(e) {
        return new Promise((resolve, reject) => {
            console.log(this.props.maxWidth)
            console.log(this.props.maxHeight)
            if (!this.props.maxWidth || !this.props.maxHeight) {
                resolve(e.target.files[0]);
                return;
            }
            console.log(e.target.files[0]);

            const fileName = e.target.files[0].name;
            const reader = new FileReader();

            reader.readAsDataURL(e.target.files[0]);

            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result.toString();

                img.onload = () => {
                    const scaleFactorWidth = this.props.maxWidth / img.width;
                    const scaleFactorHeight = this.props.maxHeight / img.height;
                    // the image is already smaller than max size
                    if (scaleFactorWidth >= 1 || scaleFactorHeight >= 1) {
                        resolve(e.target.files[0]);
                        return;
                    }

                    let newWidth = 0;
                    let newHeight = 0;
                    if (scaleFactorWidth > scaleFactorHeight) {
                        newWidth = img.width * scaleFactorHeight;
                        newHeight = this.props.maxHeight;
                    }
                    else {
                        newWidth = this.props.maxWidth;
                        newHeight = img.height * scaleFactorWidth;
                    }
                    console.log(img.width + ' ' + img.height + ' ' + scaleFactorWidth + ' ' + scaleFactorHeight + ' ' + newWidth + ' ' + newHeight);

                    var canvas = document.createElement('canvas'),
                        ctx = canvas.getContext("2d"),
                        oc = document.createElement('canvas'),
                        octx = oc.getContext('2d');

                    canvas.width = newWidth;
                    canvas.height = newHeight;

                    var cur = {
                        width: Math.floor(img.width * 0.5),
                        height: Math.floor(img.height * 0.5)
                    }
                    oc.width = cur.width;
                    oc.height = cur.height;

                    octx.drawImage(img, 0, 0, cur.width, cur.height);

                    // Use down-stepping to create a better quality resized image
                    while (cur.width * 0.5 > newWidth) {
                        cur = {
                            width: Math.floor(cur.width * 0.5),
                            height: Math.floor(cur.height * 0.5)
                        };
                        octx.drawImage(oc, 0, 0, cur.width * 2, cur.height * 2, 0, 0, cur.width, cur.height);
                    }

                    ctx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);

                    ctx.canvas.toBlob((blob) => {
                        resolve(new File([blob], fileName, {
                            type: e.target.files[0].type,
                            lastModified: Date.now()
                        }));
                    }, e.target.files[0].type, 1);
                };
            };
        });
    }

    async handleImageChange(e) {
        e.preventDefault();
        let file = e.target.files[0];
        if (this.props.maxWidth && this.props.maxHeight) {
            file = await this.resizeImage(e);
        }
        if (file) {
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    file: file,
                    imagePreviewUrl: reader.result
                });
            };
            reader.readAsDataURL(file);
            this.props.setFieldValue(this.props.field.name, file);
        }
    }

    showPreloadImage() {
        const { errorMessage, classes } = this.props;
        const { file, imagePreviewUrl } = this.state;

        let comp = null;

        if (errorMessage) {
            comp = <Icon style={{ fontSize: 36 }}>error_outline</Icon>;
        } else if (file) {
            comp = (
                <img className={classes.avatarThumb} src={imagePreviewUrl} alt="..." />
            );
        } else {
            // comp = <Icon style={{ fontSize: 36 }}>folder</Icon>;
            comp = <MdFolderOpen size="36" />

        }
        return comp;
    }

    componentDidMount() {
        // console.log(this.fileUpload.current);
    }

    render() {
        const { errorMessage, title, classes } = this.props;
        const { name } = this.props.field;

        const avatarStyle = classnames(
            classes.bigAvatar,
            this.state.file ? [classes.whiteBack] : [classes.primaryBack],
            { [classes.errorBack]: errorMessage }
        );

        return (
            <div className={classes.container}>
                <input
                    className={classes.hidden}
                    id={name}
                    name={name}
                    type="file"
                    accept="image/*"
                    onChange={async (e) => await this.handleImageChange(e)}
                    ref={this.fileUpload}
                // onBlur={onBlur}
                //className="form-control"
                />
                <Typography className={classes.title} variant="h5">
                    {title}
                </Typography>
                <Avatar className={avatarStyle} onClick={this.showFileUpload}>
                    {this.showPreloadImage()}
                </Avatar>

                {errorMessage ? (
                    <Typography variant="caption" color="error">
                        {errorMessage}
                    </Typography>
                ) : null}
            </div>
        );
    }
}

export default withStyles(customImageInputStyle)(CustomImageInput);
