import * as React from "react";
interface DataState {
  StoreContext: any,
  ProductsContext: any[]
  ProductCategoriesContext: any[],
  StoreLayoutContext: any[]
  JofemarContext: any,
  LockersDPlanContext: any[],
  JofemarDPlanContext: any[],
}

const defaultDataState: DataState = {
  StoreContext: null,
  ProductsContext: [],
  ProductCategoriesContext: [],
  StoreLayoutContext: [],
  JofemarContext: null,
  LockersDPlanContext: [],
  JofemarDPlanContext: [],
};
const DataContext = React.createContext<any | undefined>(
  undefined
);

const DataProvider = (props) => {
  const [state, setState] = React.useState(defaultDataState);

  return (
    <DataContext.Provider value={[state, setState]}>
      {props.children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider }
