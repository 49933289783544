import { useContext } from "react";
import { UIContext } from "../context/UIContext";

const useUIState = () => {
  const [state, setState] = useContext(UIContext);

  function setShowLoadingModal(boolean: boolean) {
    setState(state => ({
      ...state,
      showLoadingModal: boolean
    }));
  }

  function setToastType(type: 'error' | 'success' | 'info' | 'warning') {
    setState(state => ({
      ...state,
      toastType: type
    }));
  }

  function setShowToastMessage(boolean: boolean) {
    setState(state => ({
      ...state,
      showToastMessage: boolean
    }));
  }

  function setToastMessage(msg: string) {
    setState(state => ({
      ...state,
      toastMessage: msg
    }));
  }

  function setShowRoleSelectModal(boolean: boolean) {
    setState(state => ({
      ...state,
      showRoleSelectModal: boolean
    }));
  }

  return {
    showLoadingModal: state.showLoadingModal,
    setShowLoadingModal,
    showToastMessage: state.showToastMessage,
    setShowToastMessage,
    toastMessage: state.toastMessage,
    setToastMessage,
    toastType: state.toastType,
    setToastType,
    showRoleSelectModal: state.showRoleSelectModal,
    setShowRoleSelectModal,
  }

}

export default useUIState;