import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { MdAccountCircle } from 'react-icons/md';
import { FaRegAddressCard, FaSignOutAlt } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import { API, Auth } from "aws-amplify";
import useAuthState from '../hooks/useAuthState';
import DashboardIcon from '../images/dashboard.svg';
import StoresIcon from '../images/store.svg';
import ProductsIcon from '../images/products.svg';
import AvatarIcon from '../images/avatar.png';
import { Feature } from 'flagged';
import ReactExport from "react-export-excel";

const useStyles = makeStyles(theme => ({

  root: {
    display: "flex",
    flexFlow: "column",
    width: "95px",
    minWidth: "95px",
    height: "100%",
    zIndex: 1,
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.75)",

    '& #avatarIcon': {
      width: "40px",
      marginLeft: "-10px",
    },
    '& a': {
      textDecoration: "none"
    },
    '& #sidebarHeader': {
      paddingTop: "20px",
      paddingBottom: "30px",
    },
    '& #sidebarContent': {
      flex: "1 1 auto",
      display: "flex",
      flexFlow: "column"
    },
    '& .sidebar-links': {
      padding: "10px 16px 10px 6px",
      marginBottom: "1px",
      fontSize: "20px",
      fontWeight: 700,
    },
    '& .sidebar-links.active': {
      paddingLeft: "1px",
      borderLeft: "5px solid white",
    },
    '& .link-img': {
      width: "28px",
    },
  },
}));

function Sidebar() {
  const classes = useStyles();
  const history = useHistory();
  const { setAuthStatus, User } = useAuthState();
  const [activeLink, setActiveLink] = useState("");
  const [UserGroup, setUserGroup] = useState("")
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const [EmailAddresses, setEmailAddresses] = useState([]);
  useEffect(() => {
    ExportAllEmailAddresses();
  }, [])

  function ExportAllEmailAddresses() {
    API.get("AE", `/Customers/GetCustomers`, { response: true })
        .then(res => {
          res.data.forEach((part) => {
            part.ModifiedAt = part.ModifiedAt.substring(0, 10);
          });
            setEmailAddresses(res.data);
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
        })
  }

  const signOut = () => {
    Auth.signOut()
      .then((data) => {
        localStorage.clear();
        setAuthStatus("noUser");
        history.push("/login");
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (User.attributes["custom:role"]) {
      setUserGroup(User.attributes["custom:role"])
    }
  }, [User])

  return (
    <div id="sidebar" className={classes.root}>
      <div id="sidebarHeader">
        <div className="flex-center-justify" id="avatar" onClick={() => signOut()}>
          <img id="avatarIcon" src={AvatarIcon} alt="Avatar" />
        </div>
      </div>
      <div id="sidebarContent">

        {/* {UserGroup !== "replenishmentuser" ?
          <Link title="Dashboard" to="/dashboard" onClick={() => setActiveLink("Dashboard")}>
            <div className={`sidebar-links ${activeLink === "Dashboard" ? "active" : ""}`}>
              <div className="flex-center-justify">
                <img className="link-img" src={DashboardIcon} alt="Dashboard" />
              </div>
            </div>
          </Link> : null} */}

        {/* <Link onClick={() => setActiveLink("Notifications")} to="/dashboard">
          <div className={`sidebar-links ${activeLink === "Notifications" ? "active" : ""}`}>
            <div className="flex-center-justify">
              <img className="link-img" src={NotificationsIcon} alt="Notifications" />
            </div>
          </div>
        </Link> */}

        <Link title="Winkels" to="/retail-locations" id="retailLocations" onClick={() => setActiveLink("/retail-locations")}>
          <div className={`sidebar-links ${activeLink === "/retail-locations" ? "active" : ""}`}>
            <div className="flex-center-justify">
              <img className="link-img" src={StoresIcon} alt="/retail-locations" />
            </div>
          </div>
        </Link>

        {UserGroup !== "replenishmentuser" ?
          <Link title="Product Management" to="/product-management" id="productManagement" onClick={() => setActiveLink("/product-management")}>
            <div className={`sidebar-links ${activeLink === "/product-management" ? "active" : ""}`}>
              <div className="flex-center-justify">
                <img className="link-img" src={ProductsIcon} alt="/product-management" />
              </div>
            </div>
          </Link>
          : null}

        {/* <Link onClick={() => setActiveLink("Settings")} to="/dashboard">
          <div className={`sidebar-links ${activeLink === "Settings" ? "active" : ""}`}>
            <div className="flex-center-justify">
              <img className="link-img" src={SettingsIcon} alt="Settings" />
            </div>
          </div>
        </Link> */}

        {UserGroup !== "replenishmentuser" ?
          <Link title="Gebruikersbeheer" to="/user-management" id="userManagement" onClick={() => setActiveLink("/user-management")}>
            <div className={`sidebar-links ${activeLink === "/user-management" ? "active" : ""}`}>
              <div className="flex-center-justify">
                <MdAccountCircle size={24} />
              </div>
            </div>
          </Link> : null}
          
        <Feature name="barcodescanpromotionemail">
          <div className={`sidebar-links`}>
            <div className="flex-center-justify">
            <ExcelFile filename={`Emails`} element={<FaRegAddressCard size={24} />}>
                <ExcelSheet data={EmailAddresses} name="Email">
                    <ExcelColumn label="MailAddress" value="MailAddress" />
                    <ExcelColumn label="ModifiedAt" value="ModifiedAt" />
                </ExcelSheet>
            </ExcelFile>
            </div>
          </div>
        </Feature>

        <Link title="Uitloggen" onClick={() => signOut()} to="/">
          <div className={`sidebar-links`}>
            <div className="flex-center-justify">
              <FaSignOutAlt size={24} />
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Sidebar
