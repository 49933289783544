
import React from 'react'
import { Line } from '@reactchartjs/react-chart.js'



function LineChart(props) {
  const data = {
    labels: props.labels,
    datasets: props.dataSet,
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 1
          },
        },
      ],
      xAxes: [{
        ticks: {
          display: true,
        }
      }],
    },
  }
  return <Line data={data} options={options} />
}

export default LineChart