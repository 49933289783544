import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify'
import awsconfig from './aws-apiSettings'
import { HashRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { AuthProvider } from "./context/AuthContext";
import { UIProvider } from './context/UIContext';
import { DataProvider } from './context/DataContext';

Amplify.configure(awsconfig);

ReactDOM.render(
  <UIProvider>
    <AuthProvider>
      <DataProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            {/* <React.StrictMode> */}
            <App />
            {/* </React.StrictMode> */}
          </Router>
        </ThemeProvider>
      </DataProvider>
    </AuthProvider>
  </UIProvider>,
  document.getElementById('root')
);

reportWebVitals();
