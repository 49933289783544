import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { isToday, isSameMonth, formatDate, formatDateAndTIme, formatDisplayDate, formatExcelDate, IsInYearRange, compare, datediff, IsInDateRange, getDetailedDate, convertToSlashDate } from '../../helpers/commonFunctions';
import ReactExport from "react-export-excel";
import LineChart from '../../components/LineChart';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(theme => ({

  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    height: "100%",
    paddingLeft: "10px",

    '& .title': {
      padding: "0 10px 0 5px"
    },
    '& .filters-range': {
      marginTop: "10px",
      marginBottom: "10px"
    },
    '& .filters-range>*': {
      margin: "0 5px"
    },
    '& .range-label': {
      cursor: "pointer",
      fontWeight: 700
    },
    '& .range-label.active': {
      color: "#1976d2"
    },
    '& .total-transactions': {
      fontWeight: 700,
      position: "absolute",
      bottom: 0,
      left: 20
    },
  },
}));

const deletedOffers = [
  { "Id": 2, "Title": "Pruimen", "Description": "", "StartDate": "2021-05-05T08:35:43.643Z", "EndDate": "2021-05-13T08:35:00Z", "IsCombination": false, "Avatar": null, "ProductsInOffer": [{ "ProductId": 7, "ProductDiscount": 0.2, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 3, "Title": "Combideal cadeau en kaas", "Description": "gratis kaas bij aankoop cadeaupakket", "StartDate": "2021-05-06T09:48:57.727Z", "EndDate": "2021-05-20T09:48:00Z", "IsCombination": true, "Avatar": null, "ProductsInOffer": [{ "ProductId": 10, "ProductDiscount": 0.0, "ProductFixedPrice": 25.0, "OfferSameProductUnits": 0, "Product": null }, { "ProductId": 11, "ProductDiscount": 0.0, "ProductFixedPrice": 25.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 4, "Title": "pruim en peer voor 1 euro", "Description": "pruimen en peer euroknaller", "StartDate": "2021-05-06T09:55:08.883Z", "EndDate": "2021-05-20T09:55:00Z", "IsCombination": true, "Avatar": null, "ProductsInOffer": [{ "ProductId": 8, "ProductDiscount": 0.0, "ProductFixedPrice": 1.0, "OfferSameProductUnits": 0, "Product": null }, { "ProductId": 7, "ProductDiscount": 0.0, "ProductFixedPrice": 1.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 6, "Title": "korting sap", "Description": "korting sap", "StartDate": "2021-05-06T09:59:03.051Z", "EndDate": "2021-05-20T09:59:00Z", "IsCombination": false, "Avatar": null, "ProductsInOffer": [{ "ProductId": 12, "ProductDiscount": 0.1, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 7, "Title": "test", "Description": "", "StartDate": "2021-05-09T09:25:22.828Z", "EndDate": "2021-05-26T09:25:00Z", "IsCombination": true, "Avatar": "store-icon.jpg", "ProductsInOffer": [{ "ProductId": 11, "ProductDiscount": 0.1, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }, { "ProductId": 12, "ProductDiscount": 0.1, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 9, "Title": "Appeltje Eitje Combideal", "Description": "Zakje Elstar/Conference 1.5 kg + doosje scharreleieren 10 stuks", "StartDate": "2021-05-20T19:24:00Z", "EndDate": "2021-06-30T19:24:00Z", "IsCombination": true, "Avatar": "combideal eieren fruit.jpg", "ProductsInOffer": [{ "ProductId": 51, "ProductDiscount": 0.4, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }, { "ProductId": 45, "ProductDiscount": 0.4, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 11, "Title": "Aanbieding eieren", "Description": "korting scharreleieren (10 stuks)", "StartDate": "2021-06-04T06:32:00.103Z", "EndDate": "2021-06-15T06:32:00Z", "IsCombination": false, "Avatar": "Scharrelplus-eieren-enkel.png", "ProductsInOffer": [{ "ProductId": 45, "ProductDiscount": 0.25, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 12, "Title": "Aanbieding eieren", "Description": "korting scharreleieren (20 stuks)", "StartDate": "2021-06-04T06:33:57.406Z", "EndDate": "2021-06-15T06:33:00Z", "IsCombination": false, "Avatar": "Scharrelplus-eieren-dubbel.png", "ProductsInOffer": [{ "ProductId": 44, "ProductDiscount": 0.25, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 13, "Title": "Aanbieding eieren", "Description": "korting scharreleieren (30 stuks)", "StartDate": "2021-06-04T06:34:49.926Z", "EndDate": "2021-06-15T06:34:00Z", "IsCombination": false, "Avatar": "Tray-scharreplus-eieren.png", "ProductsInOffer": [{ "ProductId": 43, "ProductDiscount": 0.25, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 14, "Title": "Aanbieding eieren", "Description": "korting mega eieren (5 stuks)", "StartDate": "2021-06-04T06:35:28.162Z", "EndDate": "2021-06-15T06:35:00Z", "IsCombination": false, "Avatar": "Mega-eieren.png", "ProductsInOffer": [{ "ProductId": 48, "ProductDiscount": 0.25, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 15, "Title": "Aanbieding eieren", "Description": "korting vrije uitloop eieren (10 stuks)", "StartDate": "2021-06-04T06:36:13.481Z", "EndDate": "2021-06-15T06:36:00Z", "IsCombination": false, "Avatar": "vrije uitloop klein.jpg", "ProductsInOffer": [{ "ProductId": 46, "ProductDiscount": 0.25, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 16, "Title": "Aanbieding eieren", "Description": "korting vrije uitloop eieren (20 stuks)", "StartDate": "2021-06-04T06:37:32.146Z", "EndDate": "2021-06-15T06:37:00Z", "IsCombination": false, "Avatar": "2 vrije uitloop klein.jpg", "ProductsInOffer": [{ "ProductId": 47, "ProductDiscount": 0.25, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 17, "Title": "Korting: Appel-Peer sap 1L", "Description": "Puur appel-peer sap extra voordelig", "StartDate": "2021-06-16T08:46:56.873Z", "EndDate": "2021-06-26T08:46:00Z", "IsCombination": false, "Avatar": "Sap-literfles.png", "ProductsInOffer": [{ "ProductId": 12, "ProductDiscount": 0.2, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 18, "Title": "Korting: Appel-Peer sap 2x1L", "Description": "Puur appel-peer sap extra voordelig", "StartDate": "2021-06-16T08:51:41.941Z", "EndDate": "2021-06-26T08:51:00Z", "IsCombination": false, "Avatar": "Sap-literfles-dubbell.png", "ProductsInOffer": [{ "ProductId": 33, "ProductDiscount": 0.2, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 19, "Title": "Korting: Appel-Peer sap 5L", "Description": "Puur appel-peer sap extra voordelig", "StartDate": "2021-06-16T08:52:24.555Z", "EndDate": "2021-06-26T08:52:00Z", "IsCombination": false, "Avatar": "Saptap.png", "ProductsInOffer": [{ "ProductId": 60, "ProductDiscount": 0.2, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 20, "Title": "Actie jong belegen kaas", "Description": "Rauwmelkse jong belegen kaas in de aanbieding", "StartDate": "2021-06-27T16:33:00Z", "EndDate": "2021-07-07T16:33:00Z", "IsCombination": false, "Avatar": "_JFA5544.png", "ProductsInOffer": [{ "ProductId": 3, "ProductDiscount": 0.1, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 21, "Title": "Actie jonge kaas", "Description": "Rauwmelkse jonge kaas in de aanbieding", "StartDate": "2021-06-27T16:36:00Z", "EndDate": "2021-07-07T16:36:00Z", "IsCombination": false, "Avatar": "kaas-web.gif", "ProductsInOffer": [{ "ProductId": 57, "ProductDiscount": 0.1, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 22, "Title": "Actie belegen kaas", "Description": "Rauwmelkse belegen kaas in de aanbieding", "StartDate": "2021-06-27T16:37:00Z", "EndDate": "2021-07-07T16:37:00Z", "IsCombination": false, "Avatar": "kaas-web.gif", "ProductsInOffer": [{ "ProductId": 37, "ProductDiscount": 0.1, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 23, "Title": "Vakantie Saptap aanbieding", "Description": "Lekker en puur sap tijdens de zomervakantie", "StartDate": "2021-08-03T18:39:58.82Z", "EndDate": "2021-08-24T18:39:00Z", "IsCombination": false, "Avatar": "aanbieding saptpa.jpg", "ProductsInOffer": [{ "ProductId": 60, "ProductDiscount": 0.05, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
  { "Id": 24, "Title": "Appel&Sap aanbieding", "Description": "1.5 kg appels + liter puur appel-peer sap nu extra voordelig!", "StartDate": "2021-08-16T06:58:26.185Z", "EndDate": "2021-08-31T06:58:00Z", "IsCombination": true, "Avatar": "aanbieding sap en appels.jpg", "ProductsInOffer": [{ "ProductId": 67, "ProductDiscount": 0.1, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }, { "ProductId": 12, "ProductDiscount": 0.1, "ProductFixedPrice": 0.0, "OfferSameProductUnits": 0, "Product": null }] },
]

function TransactionsPerOfferChart(props) {

  const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni",
    "Juli", "Augustus", "September", "Oktober", "November", "December"
  ];

  const classes = useStyles();
  const weekDates = [
    formatDate(new Date().setDate(new Date().getDate() - 1)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 2)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 3)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 4)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 5)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 6)).split("T")[0],
  ];

  const [TransactionData, setTransactionData]: [any, any] = useState([])
  const [Offers, setOffers]: [any, any] = useState([]);
  const [DataSetDay, setDataSetDay]: [any, any] = useState([]);
  const [DataSetWeek, setDataSetWeek]: [any, any] = useState([]);
  const [DataSetMonth, setDataSetMonth]: [any, any] = useState([]);
  const [DataSetYear, setDataSetYear]: [any, any] = useState([]);
  const [DataSetActive, setDataSetActive]: [any, any] = useState([]);
  const [LabelsActive, setLabelsActive]: [any, any] = useState([]);
  const [RevenueRangeActive, setRevenueRangeActive] = useState("Day");
  const [DatasetList, setDatasetList]: [any, any] = useState([]);
  // const [Colors, setColors]: [any, any] = useState([]);
  // const [BorderColors, setBorderColors]: [any, any] = useState([]);
  const [DayTotal, setDayTotal]: [any, any] = useState(0);
  const [WeekTotal, setWeekTotal]: [any, any] = useState(0);
  const [MonthTotal, setMonthTotal]: [any, any] = useState(0);
  const [YearTotal, setYearTotal]: [any, any] = useState(0);
  const [ExcelTabDay, setExcelTabDay]: [any, any] = useState([])
  const [ExcelTabWeek, setExcelTabWeek]: [any, any] = useState([])
  const [ExcelTabMonth, setExcelTabMonth]: [any, any] = useState([])
  const [ExcelTabYear, setExcelTabYear]: [any, any] = useState([])

  const [FirstDayOfMonth, setFirstDayOfMonth] = useState("");
  const [YearPeriodStart, setYearPeriodStart] = useState("");
  const [EmptyDatasets, setEmptyDatasets] = useState<any>([]);

  const [LabelsDay, setLabelsDay]: [any, any] = useState([]);
  const [LabelsWeek, setLabelsWeek]: [any, any] = useState([]);
  const [LabelsMonth, setLabelsMonth]: [any, any] = useState([]);
  const [LabelsYear, setLabelsYear]: [any, any] = useState([]);

  const [DataSetDateRange, setDataSetDateRange]: [any, any] = useState([]);
  const [ExcelTabDateRange, setExcelTabDateRange]: [any, any] = useState([]);
  const [LabelsDateRange, setLabelsDateRange]: [any, any] = useState([]);
  const [FromDate, setFromDate] = useState<any>(null);
  const [UntilDate, setUntilDate] = useState<any>(null);
  const [DateRangeTotal, setDateRangeTotal] = useState<number>(0);


  useEffect(() => {
    const transactions = JSON.parse(JSON.stringify(props.transactionData))
    setTransactionData(transactions);

    const offers: any[] = [];
    transactions.forEach(t => {
      if (t.Offers && t.Offers.length) {
        t.Offers.forEach(o => {
          if (!offers.find(offer => offer.OfferId === o.OfferId)) {
            o.Id = o.OfferId;
            o.Title = o.OfferTitle;
            offers.push(o)
          }
        });
      }
    });

    if (process.env.REACT_APP_ENV === "prd") {
      deletedOffers.forEach(d => {
        if (!offers.find(o => o.Id === d.Id)) {
          offers.push(d);
        }
      });
    }

    setOffers(offers);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Offers.length && TransactionData.length) {
      const [colors, borderColors] = setOffersLabelsandColors();
      const emptyDataSets: any = [];

      Offers.forEach((offer, index) => {
        emptyDataSets.push({
          label: offer.Title,
          data: [],
          borderColor: borderColors[index],
          backgroundColor: colors[index],
          fill: "false",
          id: offer.Id
        });
      })
      setEmptyDatasets(emptyDataSets);
    }
    // eslint-disable-next-line
  }, [Offers])

  useEffect(() => {
    if (EmptyDatasets.length) {
      if (!DataSetDay.length) createDayData();
      if (!DataSetWeek.length) createWeekData();
      if (!DataSetMonth.length) createMonthData();
      if (!DataSetYear.length) createYearData();
    }
    // eslint-disable-next-line
  }, [EmptyDatasets])

  useEffect(() => {
    if (DataSetActive.length) {
      setDatasetList(DataSetActive);
    } else {
      setLabelsActive([]);
    }
  }, [DataSetActive])

  useEffect(() => {
    if (RevenueRangeActive) {
      switch (RevenueRangeActive) {
        case 'Day':
          setDataSetActive(DataSetDay);
          setLabelsActive(LabelsDay);
          break;
        case 'Week':
          setDataSetActive(DataSetWeek);
          setLabelsActive(LabelsWeek);
          break;
        case 'Month':
          setDataSetActive(DataSetMonth);
          setLabelsActive(LabelsMonth);
          break;
        case 'Year':
          setDataSetActive(DataSetYear);
          setLabelsActive(LabelsYear);
          break;
        default:
          break;
      }
      if (RevenueRangeActive === "DateRange") {
        if (FromDate && UntilDate) {
          createDateRangeData(FromDate, UntilDate);
        } else {
          setDatasetList([]);
          setLabelsActive([]);
        }
      }
    }
    // eslint-disable-next-line
  }, [RevenueRangeActive])

  function setOffersLabelsandColors() {
    const colors: any[] = [];
    const borderColors: any[] = [];

    Offers.forEach(offer => {
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);

      colors.push(`rgba(${r},${g},${b},0.2)`);
      borderColors.push(`rgba(${r},${g},${b},1)`);
    });

    return [colors, borderColors];
  }

  useEffect(() => {
    if (RevenueRangeActive === "DateRange" && FromDate && UntilDate) {
      createDateRangeData(FromDate, UntilDate);
    } else {
      setDatasetList([]);
      setLabelsActive([]);
    }
    // eslint-disable-next-line
  }, [FromDate, UntilDate]);

  function createDateRangeData(fromDate, untilDate) {
    const emptyDataSets = JSON.parse(JSON.stringify(EmptyDatasets));

    let _dateFrom = new Date(fromDate);
    let dateFromTime = _dateFrom.setHours(0, 0, 0, 0);
    let _dateUntil = new Date(untilDate);
    let dateUntilTime = _dateUntil.setHours(23, 59, 59, 999);

    const daysDiff = datediff(_dateFrom, _dateUntil);
    const transactionData = [...TransactionData];

    const TransactionsDateRange =
      transactionData.filter(t => IsInDateRange(dateUntilTime, dateFromTime, t.TransactionDate))

    let datasetsWithTransactions: any[] = [];
    let labels: any[] = [];
    let labelsDetailed: any[] = [];
    let totalTransactions = 0;

    if (daysDiff <= 1) {
      const [_datasetsWithTransactions, _labels, _totalTransactions]: any = createLineChartDatasets(TransactionsDateRange, emptyDataSets, "Day");
      totalTransactions = _totalTransactions;
      labels = _labels.map(l => l.split('T')[1]);
      datasetsWithTransactions = [..._datasetsWithTransactions];
      setLabelsDateRange(labels);
      setLabelsActive(labels);
      setDataSetActive(_datasetsWithTransactions);
    }
    else if (daysDiff > 1 && daysDiff < 32) {
      const [_datasetsWithTransactions, _labels, _totalTransactions]: any = createLineChartDatasets(TransactionsDateRange, emptyDataSets, "Week");
      totalTransactions = _totalTransactions;
      labelsDetailed = _labels.map(l => getDetailedDate(l));
      datasetsWithTransactions = [..._datasetsWithTransactions];
      setLabelsActive(labelsDetailed);
      setLabelsDateRange(labelsDetailed);
      setDataSetActive(_datasetsWithTransactions);
    }
    else if (daysDiff >= 32) {
      const [_datasetsWithTransactions, _labels, _totalTransactions]: any = createLineChartDatasets(TransactionsDateRange, emptyDataSets, "Year");
      totalTransactions = _totalTransactions;
      datasetsWithTransactions = [..._datasetsWithTransactions];
      setLabelsActive(_labels);
      setLabelsDateRange(_labels);
      setDataSetActive(_datasetsWithTransactions);
    }


    // create day excel dataSets
    const excelData: any[] = [];
    datasetsWithTransactions.forEach((dataSet, index) => {
      const excelDateObj: any = {};
      dataSet.data.forEach((transaction, trIndex) => {
        const attr = "transaction" + trIndex
        excelDateObj[attr] = transaction
      });
      if (index === 0) {
        excelDateObj.dateRange = `${formatDisplayDate(new Date(fromDate))} - ${formatDisplayDate(new Date(untilDate))}`
      }
      excelDateObj.offer = dataSet.label;
      excelDateObj.transactionsTotal = dataSet.transactionsTotal;
      excelData.push(excelDateObj);
    });

    setExcelTabDateRange(excelData);
    setDateRangeTotal(totalTransactions);
  }

  function createLineChartDatasets(transactions, emptyDataSets, range) {
    const labels: any = [];
    let totalTransactions = 0;
    const datasetsWithTransactions: any = [];

    if (range === "Day") {
      transactions.forEach((transaction, index) => {
        labels.push(transaction.TransactionDate);
        const idsToChange: any[] = [];
        if (transaction.Offers.length) {
          totalTransactions++;
        }

        transaction.Offers.forEach(o => {
          idsToChange.push({ Id: o.OfferId });
        });

        emptyDataSets.forEach(ds => { ds.data[index] = 0; });

        idsToChange.forEach(itc => {
          const datasetToUpdate = emptyDataSets.find(ds => ds.id === itc.Id);
          if (datasetToUpdate) {
            const _index = emptyDataSets.findIndex(ds => ds.id === itc.Id);
            emptyDataSets[_index].data[index] = 1;
          }
        });
      });
      labels.sort(compare);

      emptyDataSets.forEach(ds => {
        let isEmpty = true;
        let transactionsTotal = 0;
        ds.data.forEach(entry => {
          transactionsTotal = transactionsTotal + entry;
          if (entry > 0) {
            isEmpty = false;
          }
        });
        if (!isEmpty) {
          ds.transactionsTotal = transactionsTotal;
          datasetsWithTransactions.push(ds);
        }
      });
      datasetsWithTransactions.sort((a, b) => (a.transactionsTotal < b.transactionsTotal) ? 1 : -1)

      return [datasetsWithTransactions, labels, totalTransactions];

    } else if (range === 'Week' || range === 'Month') {

      const transactionsGroupedDaily: any[] = [];
      transactions.forEach((tr, index) => {
        const trDate = tr.TransactionDate.split('T')[0];
        const transactionToUpdate = transactionsGroupedDaily.find(tgd => tgd.TransactionDate === trDate);
        if (tr.Offers.length) {
          totalTransactions++;
        }

        if (!transactionsGroupedDaily.length || !transactionToUpdate) { // just push it
          const newTr = JSON.parse(JSON.stringify(tr));
          newTr.TransactionDate = trDate;

          newTr.Offers.forEach(p => {
            p.Transactions = 1
          });

          transactionsGroupedDaily.push(newTr);
        } else { // combine products sold
          tr.Offers.forEach(offer => {
            offer.Transactions = 1;
            if (transactionToUpdate.Offers.find(trp => trp.OfferId === offer.OfferId)) {
              const indexToUpdate = transactionToUpdate.Offers.findIndex(trp => trp.OfferId === offer.OfferId);
              // transactionToUpdate.Offers[indexToUpdate].Units = transactionToUpdate.Offers[indexToUpdate].Units + offer.Units;
              transactionToUpdate.Offers[indexToUpdate].Transactions =
                transactionToUpdate.Offers[indexToUpdate].Transactions + offer.Transactions;
            } else {
              transactionToUpdate.Offers.push(offer);
            }
          });
        }
      });

      transactionsGroupedDaily.forEach((transaction, index) => {
        labels.push(transaction.TransactionDate);
        const idsToChange: any[] = [];

        transaction.Offers.forEach(offer => {
          idsToChange.push({ Id: offer.OfferId, Transactions: offer.Transactions });
        });

        emptyDataSets.forEach(ds => { ds.data[index] = 0; });

        idsToChange.forEach(itc => {
          const datasetToUpdate = emptyDataSets.find(ds => ds.id === itc.Id);
          if (datasetToUpdate) {
            const _index = emptyDataSets.findIndex(ds => ds.id === itc.Id);
            emptyDataSets[_index].data[index] = emptyDataSets[_index].data[index] + itc.Transactions;
          }
        });
      });
      labels.sort(compare);

      const datasetsWithTransactions: any[] = [];
      emptyDataSets.forEach(ds => {
        let isEmpty = true
        let transactionsTotal = 0;
        ds.data.forEach(entry => {
          transactionsTotal = transactionsTotal + entry;
          if (entry > 0) {
            isEmpty = false;
          }
        });
        if (!isEmpty) {
          ds.transactionsTotal = transactionsTotal;
          datasetsWithTransactions.push(ds);
        }
      });
      datasetsWithTransactions.sort((a, b) => (a.transactionsTotal < b.transactionsTotal) ? 1 : -1)

      return [datasetsWithTransactions, labels, totalTransactions];
    } else if (range === "Year") {
      // group transactions per month
      // and combine products sold
      const transactionsGroupedMonthly: any[] = []
      transactions.forEach(tr => {
        const trDate = tr.TransactionDate.split('T')[0];
        totalTransactions++;
        const year = tr.TransactionDate.split('-')[0];
        const month = tr.TransactionDate.split('-')[1];

        const transactionToUpdate =
          transactionsGroupedMonthly
            .find(tgd => tgd.TransactionDate.split('-')[0] === year && tgd.TransactionDate.split('-')[1] === month);

        if (!transactionsGroupedMonthly.length || !transactionToUpdate) { // just push it
          const newTr = JSON.parse(JSON.stringify(tr));
          newTr.TransactionDate = trDate;
          newTr.Offers.forEach(p => {
            p.Transactions = 1
          });
          transactionsGroupedMonthly.push(newTr);
        } else { // combine pffers

          tr.Offers.forEach(offer => {
            offer.Transactions = 1;
            if (transactionToUpdate.Offers.find(trp => trp.OfferId === offer.OfferId)) {
              const indexToUpdate = transactionToUpdate.Offers.findIndex(trp => trp.OfferId === offer.OfferId);
              transactionToUpdate.Offers[indexToUpdate].Units = transactionToUpdate.Offers[indexToUpdate].Units + offer.Units;
              transactionToUpdate.Offers[indexToUpdate].Transactions =
                transactionToUpdate.Offers[indexToUpdate].Transactions + offer.Transactions;
            } else {
              transactionToUpdate.Offers.push(offer);
            }
          });

        }

      });

      transactionsGroupedMonthly.forEach((transaction, index) => {
        labels.push(transaction.TransactionDate);
        const idsToChange: any[] = [];

        transaction.Offers.forEach(offer => {
          idsToChange.push({ Id: offer.OfferId, Units: offer.Units, Transactions: offer.Transactions });
        });

        emptyDataSets.forEach(ds => { ds.data[index] = 0; });

        idsToChange.forEach(itc => {
          const datasetToUpdate = emptyDataSets.find(ds => ds.id === itc.Id);
          if (datasetToUpdate) {
            const _index = emptyDataSets.findIndex(ds => ds.id === itc.Id);
            emptyDataSets[_index].data[index] = emptyDataSets[_index].data[index] + itc.Transactions;
          }
        });
      });
      labels.sort(compare);

      const yearLabels: any[] = [];
      labels.forEach(label => {
        const year = label.split('-')[0];
        const month = label.split('-')[1];
        yearLabels.push(monthNames[parseInt(month, 10) - 1] + " " + year)
      });

      const datasetsWithTransactions: any[] = [];
      emptyDataSets.forEach(ds => {
        let isEmpty = true;
        let transactionsTotal = 0;
        ds.data.forEach(entry => {
          transactionsTotal = transactionsTotal + entry;
          if (entry > 0) {
            isEmpty = false;
          }
        });
        if (!isEmpty) {
          ds.transactionsTotal = transactionsTotal;
          datasetsWithTransactions.push(ds);
        }
      });
      datasetsWithTransactions.sort((a, b) => (a.transactionsTotal < b.transactionsTotal) ? 1 : -1)

      return [datasetsWithTransactions, yearLabels, totalTransactions];
    }
  }

  function createDayData() {
    const emptyDataSets = JSON.parse(JSON.stringify(EmptyDatasets));
    const _TransactionsData = JSON.parse(JSON.stringify(TransactionData))
    const TransactionsToday =
      _TransactionsData.filter(t => isToday(t.TransactionDate));

    if (TransactionsToday.length) {

      const [datasetsWithTransactions, labels, totalTransactions]: any =
        createLineChartDatasets(TransactionsToday, emptyDataSets, "Day")

      const _labels = labels.map(d => d.split('T')[1]);

      setLabelsDay(_labels);
      setDataSetDay(datasetsWithTransactions);
      setDayTotal(totalTransactions);

      setDataSetActive(datasetsWithTransactions);
      setLabelsActive(_labels);

      // create day excel dataSets
      const excelDay: any[] = [];
      datasetsWithTransactions.forEach((dataSet, index) => {
        const excelDayObj: any = {};
        dataSet.data.forEach((transaction, trIndex) => {
          const attr = "transaction" + trIndex
          excelDayObj[attr] = transaction
        });
        if (index === 0) {
          excelDayObj.dateRange = formatDisplayDate(new Date())
        }
        excelDayObj.offer = dataSet.label;
        excelDayObj.transactionsTotal = dataSet.transactionsTotal;
        excelDay.push(excelDayObj);
      });

      setExcelTabDay(excelDay);

    } else {
      setLabelsDay([]);
      setDataSetDay([]);
      setDayTotal(0);
      setDatasetList([]);
    }

  }

  function createWeekData() {
    const emptyDataSets = JSON.parse(JSON.stringify(EmptyDatasets));

    const TransactionsWeek =
      TransactionData.filter(t => weekDates.includes(t.TransactionDate.split("T")[0]) || t.TransactionDate.split("T")[0] === formatDate(new Date()).split("T")[0]);

    if (TransactionsWeek.length) {
      // group transactions per day
      // and combine products sold

      const [datasetsWithTransactions, labels, totalTransactions]: any =
        createLineChartDatasets(TransactionsWeek, emptyDataSets, "Week");

      const chartLabels = labels.map(l => getDetailedDate(l));

      // create week excel dataSets
      const excelWeek: any[] = [];
      datasetsWithTransactions.forEach((dataSet, index) => {
        const excelWeekObj: any = {};
        dataSet.data.forEach((transaction, trIndex) => {
          const attr = "transaction" + trIndex
          excelWeekObj[attr] = transaction
        });
        if (index === 0) {
          excelWeekObj.dateRange =
            `${formatDisplayDate(new Date().setDate(new Date().getDate() - 6))} - ${formatDisplayDate(new Date())}`
        }
        excelWeekObj.offer = dataSet.label;
        excelWeekObj.transactionsTotal = dataSet.transactionsTotal;
        excelWeek.push(excelWeekObj);
      });

      setExcelTabWeek(excelWeek);

      setWeekTotal(totalTransactions);
      setLabelsWeek(chartLabels);
      setDataSetWeek(datasetsWithTransactions);

    }

  }

  function createMonthData() {
    const emptyDataSets = JSON.parse(JSON.stringify(EmptyDatasets));

    const TransactionsMonth =
      TransactionData.filter(t => isSameMonth(t.TransactionDate));

    if (TransactionsMonth.length) {
      // group transactions per day
      // and combine products sold

      const [datasetsWithTransactions, labels, totalTransactions]: any =
        createLineChartDatasets(TransactionsMonth, emptyDataSets, "Month");

      let chartLabels: any[] = []
      if (labels.length < 8) {
        chartLabels = labels.map(l => getDetailedDate(l));
      } else {
        chartLabels = labels.map(l => convertToSlashDate(l.split("T")[0]));
      }

      // create month excel dataSets
      const excelMonth: any[] = [];
      datasetsWithTransactions.forEach((dataSet, index) => {
        const excelMonthObj: any = {};
        dataSet.data.forEach((transaction, trIndex) => {
          const attr = "transaction" + trIndex
          excelMonthObj[attr] = transaction
        });
        if (index === 0) {
          const date = new Date();
          const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
          setFirstDayOfMonth(formatExcelDate(firstDay).split("T")[0]);
          excelMonthObj.dateRange = `${formatDisplayDate(firstDay)} - ${formatDisplayDate(new Date())}`
        }
        excelMonthObj.offer = dataSet.label;
        excelMonthObj.transactionsTotal = dataSet.transactionsTotal;
        excelMonth.push(excelMonthObj);
      });

      setExcelTabMonth(excelMonth);
      setMonthTotal(totalTransactions);
      setLabelsMonth(chartLabels);
      setDataSetMonth(datasetsWithTransactions);
    }
  }

  function createYearData() {
    const emptyDataSets = JSON.parse(JSON.stringify(EmptyDatasets));

    const currTimeStamp = new Date().getTime();
    const date = new Date();
    const timeStampLastYear = date.setFullYear(date.getFullYear() - 1);

    const TransactionsYear =
      TransactionData.filter(t => IsInYearRange(currTimeStamp, timeStampLastYear, t.TransactionDate));

    if (TransactionsYear.length) {

      const [datasetsWithTransactions, yearLabels, totalTransactions]: any =
        createLineChartDatasets(TransactionsYear, emptyDataSets, "Year");

      // create year excel dataSets
      const excelYear: any[] = [];
      datasetsWithTransactions.forEach((dataSet, index) => {
        const excelYearObj: any = {};
        dataSet.data.forEach((transaction, trIndex) => {
          const attr = "transaction" + trIndex
          excelYearObj[attr] = transaction
        });
        if (index === 0) {
          excelYearObj.dateRange = `${formatDisplayDate(timeStampLastYear)} - ${formatDisplayDate(new Date())}`
        }
        excelYearObj.transactionsTotal = dataSet.transactionsTotal;
        excelYearObj.offer = dataSet.label;
        excelYear.push(excelYearObj);
      });
      setYearPeriodStart(formatExcelDate(timeStampLastYear).split("T")[0]);
      setExcelTabYear(excelYear);

      setYearTotal(totalTransactions);
      setLabelsYear(yearLabels);
      setDataSetYear(datasetsWithTransactions);
    }
  }

  return (
    <div className={classes.root}>

      <div>
        <div className="flex-center-align jcsb title">
          <h2 className="mr1">Specificatie aanbiedingen</h2>

          <div className="excel-btn">
            <ExcelFile filename={`SpecificatieAanbiedingen_${formatDateAndTIme(new Date())}`} element={<button>Download Statistieken</button>}>
              <ExcelSheet data={ExcelTabDay} name={`Dag (${formatExcelDate(new Date()).split("T")[0]})`}>
                <ExcelColumn label={"Datum"} value="dateRange" />
                <ExcelColumn label={"Aanbieding / Transactie Tijd"} value="offer" />
                {LabelsDay.map((label, index) => {
                  const attr = "transaction" + index;
                  return <ExcelColumn key={index} label={label} value={attr} />
                })}
                <ExcelColumn label={"Total Transacties per aanbieding"} value="transactionsTotal" />
              </ExcelSheet>
              <ExcelSheet data={ExcelTabWeek} name={`Week (${formatExcelDate(new Date().setDate(new Date().getDate() - 6)).split("T")[0]} - ${formatExcelDate(new Date()).split("T")[0]})`}>
                <ExcelColumn label={"Periode"} value="dateRange" />
                <ExcelColumn label={"Aanbieding / Transactie Dag"} value="offer" />
                {LabelsWeek.map((label, index) => {
                  const attr = "transaction" + index;
                  return <ExcelColumn key={index} label={label} value={attr} />
                })}
                <ExcelColumn label={"Total Transacties per aanbieding"} value="transactionsTotal" />
              </ExcelSheet>

              <ExcelSheet data={ExcelTabMonth} name={`Maand (${FirstDayOfMonth} - ${formatExcelDate(new Date()).split("T")[0]})`}>
                <ExcelColumn label={"Periode"} value="dateRange" />
                <ExcelColumn label={"Aanbieding / Transactie Dag"} value="offer" />
                {LabelsMonth.map((label, index) => {
                  const attr = "transaction" + index;
                  return <ExcelColumn key={index} label={label} value={attr} />
                })}
                <ExcelColumn label={"Total Transacties per aanbieding"} value="transactionsTotal" />
              </ExcelSheet>

              <ExcelSheet data={ExcelTabYear} name={`Jaar (${YearPeriodStart} - ${formatExcelDate(new Date()).split("T")[0]})`}>
                <ExcelColumn label={"Periode"} value="dateRange" />
                <ExcelColumn label={"Aanbieding / Transactie Dag"} value="offer" />
                {LabelsYear.map((label, index) => {
                  const attr = "transaction" + index;
                  return <ExcelColumn key={index} label={label} value={attr} />
                })}
                <ExcelColumn label={"Total Transacties per aanbieding"} value="transactionsTotal" />
              </ExcelSheet>

              <ExcelSheet data={ExcelTabDateRange} name={`Periode (${FromDate ? formatExcelDate(new Date(FromDate)).split("T")[0] : "Unset"} - ${UntilDate ? formatExcelDate(new Date(UntilDate)).split("T")[0] : "Unset"})`}>
                <ExcelColumn label={"Periode"} value="dateRange" />
                <ExcelColumn label={"Aanbieding / Transactie"} value="offer" />
                {LabelsDateRange.map((label, index) => {
                  const attr = "transaction" + index;
                  return <ExcelColumn key={index} label={label} value={attr} />
                })}
                <ExcelColumn label={"Total Transactions per product"} value="transactionsTotal" />
              </ExcelSheet>

            </ExcelFile>
          </div>
        </div>

        <div className="flex">
          <div className="flex-center-align filters-range">
            <label htmlFor="rangeLabel">per:</label>
            <label
              className={`range-label ${RevenueRangeActive === "Day" ? "active" : ""}`}
              onClick={() => { setDataSetActive(DataSetDay); setLabelsActive(LabelsDay); setRevenueRangeActive("Day"); }}
              id="dayRange"
            >Dag</label>
            <span>/</span>
            <label
              className={`range-label ${RevenueRangeActive === "Week" ? "active" : ""}`}
              onClick={() => { setDataSetActive(DataSetWeek); setLabelsActive(LabelsWeek); setRevenueRangeActive("Week"); }}
              id="weekRange"
            >Week</label>
            <span>/</span>
            <label
              className={`range-label ${RevenueRangeActive === "Month" ? "active" : ""}`}
              onClick={() => { setDataSetActive(DataSetMonth); setLabelsActive(LabelsMonth); setRevenueRangeActive("Month"); }}
              id="monthRange"
            >Maand</label>
            <span>/</span>
            <label
              className={`range-label ${RevenueRangeActive === "Year" ? "active" : ""}`}
              onClick={() => { setDataSetActive(DataSetYear); setLabelsActive(LabelsYear); setRevenueRangeActive("Year"); }}
              id="YearRange"
            >Jaar</label>
            <span>/</span>
            <label
              className={`range-label ${RevenueRangeActive === "DateRange" ? "active" : ""}`}
              onClick={() => { setRevenueRangeActive("DateRange"); }}
              id="dateRange"
            >Selecteer periode</label>
          </div>
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="flex-center-align chart-date-filters" style={{ display: RevenueRangeActive === "DateRange" ? "flex" : "none" }}>
            <label htmlFor={"From:"}>{"Van:"}</label>
            <DatePicker
              name={"FromPPT"}
              className="datetimepicker-dashboard"
              clearable
              autoOk
              // label="Van:"
              format="dd/MM/yyyy"
              placeholder={formatDisplayDate(Date.now())}
              onChange={value => {
                setFromDate(value);
              }}
              value={FromDate}
              animateYearScrolling={false}
            />
            <label htmlFor={"Until:"}>{"Tot:"}</label>
            <DatePicker
              name={"UntilPPT"}
              className="datetimepicker-dashboard"
              clearable
              autoOk
              // label="Van:"
              format="dd/MM/yyyy"
              placeholder={formatDisplayDate(Date.now())}
              onChange={value => {
                setUntilDate(value);
              }}
              value={UntilDate}
              animateYearScrolling={false}
            />
          </div>
        </MuiPickersUtilsProvider>
      </div>

      <LineChart labels={LabelsActive} dataSet={DatasetList} />
      <p className="total-transactions">
        Totale transacties: {RevenueRangeActive === "Day" ?
          DayTotal : RevenueRangeActive === "Week" ?
            WeekTotal : RevenueRangeActive === "Month" ?
              MonthTotal : RevenueRangeActive === "Year" ?
                YearTotal : DateRangeTotal
        }
      </p>

    </div>
  )
}

export default TransactionsPerOfferChart

