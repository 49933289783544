import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { isToday, isSameMonth, formatDisplayDate, formatExcelDate, convertToSlashDate, formatDate, formatDateAndTIme, IsInYearRange, sum, datediff, IsInDateRange, getDetailedDate } from '../../helpers/commonFunctions';
import ReactExport from "react-export-excel";
import LineChart from '../../components/LineChart';
import { calculateOffersNoVat, calculateSingleProductsNoVAT } from './RevenueController';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(theme => ({

  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    height: "100%",
    paddingLeft: "10px",

    '& .title': {
      padding: "0 10px 0 5px"
    },
    '& .filters-range>*': {
      margin: "0 5px"
    },
    '& .range-label': {
      cursor: "pointer",
      fontWeight: 700
    },
    '& .range-label.active': {
      color: "#1976d2"
    }
  },
}));

function RevenueChart(props) {

  const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni",
    "Juli", "Augustus", "September", "Oktober", "November", "December"
  ];

  const currDate = new Date();
  const dateStamp = formatDate(currDate).split("T")[0];

  const classes = useStyles();
  const weekDates = [
    formatDate(new Date().setDate(new Date().getDate() - 1)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 2)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 3)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 4)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 5)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 6)).split("T")[0],
  ];

  const [TransactionData, setTransactionData]: [any, any] = useState([])
  const [Products, setProducts]: [any, any] = useState([]);
  const [DataSetDay, setDataSetDay]: [any, any] = useState([]);
  const [DataSetNoVATDay, setDataSetNoVATDay]: [any, any] = useState([]);
  const [LabelsDay, setLabelsDay]: [any, any] = useState([]);
  const [DataSetWeek, setDataSetWeek]: [any, any] = useState([]);
  const [DataSetNoVATWeek, setDataSetNoVATWeek]: [any, any] = useState([]);
  const [LabelsWeek, setLabelsWeek]: [any, any] = useState([]);
  const [DataSetMonth, setDataSetMonth]: [any, any] = useState([]);
  const [DataSetNoVATMonth, setDataSetNoVATMonth]: [any, any] = useState([]);
  const [LabelsMonth, setLabelsMonth]: [any, any] = useState([]);
  const [DataSetYear, setDataSetYear]: [any, any] = useState([]);
  const [DataSetNoVATYear, setDataSetNoVATYear]: [any, any] = useState([]);
  const [LabelsYear, setLabelsYear]: [any, any] = useState([]);
  const [DataSetActive, setDataSetActive]: [any, any] = useState([]);
  const [LabelsActive, setLabelsActive]: [any, any] = useState([]);
  const [RevenueRangeActive, setRevenueRangeActive] = useState("Day");
  const [VATRevenueActive, setVATRevenueActive] = useState(true);
  const [DatasetList, setDatasetList]: [any, any] = useState([]);
  const [ExcelTabDayVat, setExcelTabDayVat]: [any, any] = useState([])
  const [ExcelTabDayNoVat, setExcelTabDayNoVat]: [any, any] = useState([])
  const [ExcelTabWeekVat, setExcelTabWeekVat]: [any, any] = useState([])
  const [ExcelTabWeekNoVat, setExcelTabWeekNoVat]: [any, any] = useState([])
  const [ExcelTabMonthVat, setExcelTabMonthVat]: [any, any] = useState([])
  const [ExcelTabMonthNoVat, setExcelTabMonthNoVat]: [any, any] = useState([])
  const [ExcelTabYearVat, setExcelTabYearVat]: [any, any] = useState([])
  const [ExcelTabYearNoVat, setExcelTabYearNoVat]: [any, any] = useState([])

  const [ExcelLabelsWeek, setExcelLabelsWeek] = useState<any>([]);
  const [ExcelLabelsMonth, setExcelLabelsMonth] = useState<any>([]);
  const [ExcelLabelsDateRange, setExcelLabelsDateRange] = useState<any>([]);

  const [FirstDayOfMonth, setFirstDayOfMonth] = useState("");
  const [YearPeriodStart, setYearPeriodStart] = useState("");

  const [AmountTotalDayVat, setAmountTotalDayVat] = useState(0);
  const [AmountTotalDayNoVat, setAmountTotalDayNoVat] = useState(0);
  const [AmountTotalWeekVat, setAmountTotalWeekVat] = useState(0);
  const [AmountTotalWeekNoVat, setAmountTotalWeekNoVat] = useState(0);
  const [AmountTotalMonthVat, setAmountTotalMonthVat] = useState(0);
  const [AmountTotalMonthNoVat, setAmountTotalMonthNoVat] = useState(0);
  const [AmountTotalYearVat, setAmountTotalYearVat] = useState(0);
  const [AmountTotalYearNoVat, setAmountTotalYearNoVat] = useState(0);
  const [AmountTotalActive, setAmountTotalActive] = useState(0)

  const [DataSetDateRange, setDataSetDateRange]: [any, any] = useState([]);
  const [DataSetNoVatDateRange, setDataSetNoVatDateRange]: [any, any] = useState([]);
  const [ExcelTabDateRange, setExcelTabDateRange]: [any, any] = useState([]);
  const [ExcelTabNoVatDateRange, setExcelTabNoVatDateRange]: [any, any] = useState([]);
  const [AmountTotalDateRangeVat, setAmountTotalDateRangeVat] = useState(0);
  const [AmountTotalDateRangeNoVat, setAmountTotalDateRangeNoVat] = useState(0);
  const [LabelsDateRange, setLabelsDateRange]: [any, any] = useState([]);
  const [FromDate, setFromDate] = useState<any>(null);
  const [UntilDate, setUntilDate] = useState<any>(null);

  useEffect(() => {
    const transactions = JSON.parse(JSON.stringify(props.transactionData))
    setTransactionData(transactions);
    setProducts(props.products);
  }, []);

  useEffect(() => {
    switch (RevenueRangeActive) {
      case 'Day':
        setLabelsActive(LabelsDay);
        setDataSetActive(VATRevenueActive ? DataSetDay : DataSetNoVATDay)
        setAmountTotalActive(VATRevenueActive ? AmountTotalDayVat : AmountTotalDayNoVat);
        break;
      case 'Week':
        setLabelsActive(LabelsWeek);
        setDataSetActive(VATRevenueActive ? DataSetWeek : DataSetNoVATWeek)
        setAmountTotalActive(VATRevenueActive ? AmountTotalWeekVat : AmountTotalWeekNoVat);
        break;
      case 'Month':
        setLabelsActive(LabelsMonth);
        setDataSetActive(VATRevenueActive ? DataSetMonth : DataSetNoVATMonth)
        setAmountTotalActive(VATRevenueActive ? AmountTotalMonthVat : AmountTotalMonthNoVat);
        break;
      case 'Year':
        setLabelsActive(LabelsYear);
        setDataSetActive(VATRevenueActive ? DataSetYear : DataSetNoVATYear)
        setAmountTotalActive(VATRevenueActive ? AmountTotalYearVat : AmountTotalYearNoVat);
        break;
      default:
        break;
    }

    if (RevenueRangeActive === "DateRange") {
      if (FromDate && UntilDate) {
        createDateRangeData(FromDate, UntilDate);
      }
    }
  }, [VATRevenueActive])

  useEffect(() => {
    if (RevenueRangeActive === "DateRange" && FromDate && UntilDate) {
      createDateRangeData(FromDate, UntilDate);
    }
    // eslint-disable-next-line
  }, [FromDate, UntilDate]);

  useEffect(() => {
    if (Products.length) {
      createDayData();
      createWeekData();
      createMonthData();
      createYearData();
    }
  }, [Products])

  useEffect(() => {
    const dataSet = [
      {
        label: 'Omzet €',
        data: DataSetActive,
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 3,
      }
    ]
    setDatasetList(dataSet)

  }, [DataSetActive])

  useEffect(() => {
    if (RevenueRangeActive) {
      switch (RevenueRangeActive) {
        case 'Day':
          setLabelsActive(LabelsDay);
          setDataSetActive(VATRevenueActive ? DataSetDay : DataSetNoVATDay)
          setAmountTotalActive(VATRevenueActive ? AmountTotalDayVat : AmountTotalDayNoVat);
          break;
        case 'Week':
          setLabelsActive(LabelsWeek);
          setDataSetActive(VATRevenueActive ? DataSetWeek : DataSetNoVATWeek)
          setAmountTotalActive(VATRevenueActive ? AmountTotalWeekVat : AmountTotalWeekNoVat);
          break;
        case 'Month':
          setLabelsActive(LabelsMonth);
          setDataSetActive(VATRevenueActive ? DataSetMonth : DataSetNoVATMonth)
          setAmountTotalActive(VATRevenueActive ? AmountTotalMonthVat : AmountTotalMonthNoVat);
          break;
        case 'Year':
          setLabelsActive(LabelsYear);
          setDataSetActive(VATRevenueActive ? DataSetYear : DataSetNoVATYear)
          setAmountTotalActive(VATRevenueActive ? AmountTotalYearVat : AmountTotalYearNoVat);
          break;
        default:
          break;
      }

      if (RevenueRangeActive === "DateRange") {
        if (FromDate && UntilDate) {
          createDateRangeData(FromDate, UntilDate);
        }
      }
    }
  }, [RevenueRangeActive])

  function createDateRangeData(fromDate, untilDate) {

    let _dateFrom = new Date(fromDate);
    let dateFromTime = _dateFrom.setHours(0, 0, 0, 0);
    let _dateUntil = new Date(untilDate);
    let dateUntilTime = _dateUntil.setHours(23, 59, 59, 999);

    const daysDiff = datediff(_dateFrom, _dateUntil);
    const transactionData = [...TransactionData];

    const TransactionsDateRange =
      transactionData.filter(t => IsInDateRange(dateUntilTime, dateFromTime, t.TransactionDate))

    // let datasetsWithTransactions: any[] = [];
    let labels: any[] = [];
    const labelsDetailed: any[] = [];
    const dataSet: any[] = [];
    const dataSetNoVat: any[] = [];

    TransactionsDateRange.forEach(entry => {
      if (daysDiff <= 1) {
        labels.push(entry.TransactionDate.split("T")[1]);
        dataSet.push(entry.AmountPaid);

        let amountNoVat;
        if (entry.Offers && entry.Offers.length) {
          // the productsSold part are the products that are not in any offers
          let [offersAmountNoVAT, productsSold] = calculateOffersNoVat(entry.Offers, entry.ProductsSold);

          let totalAmountSingleProductsNoVAT = calculateSingleProductsNoVAT(productsSold);

          const sum = offersAmountNoVAT + totalAmountSingleProductsNoVAT;
          amountNoVat = parseFloat(sum.toFixed(2));

        } else {
          // old entries
          amountNoVat = entry.AmountPaid - (entry.AmountPaid * 0.09)
          amountNoVat = parseFloat(amountNoVat.toFixed(2))
        }

        dataSetNoVat.push(amountNoVat);
      }
      else if (daysDiff > 1 && daysDiff < 32) {
        let amountNoVat = entry.AmountPaid - (entry.AmountPaid * 0.09);
        amountNoVat = parseFloat(amountNoVat.toFixed(2))
        let amountVat = parseFloat(entry.AmountPaid.toFixed(2))


        if (labels.includes(convertToSlashDate(entry.TransactionDate.split("T")[0]))) {

          let newAmountVat = dataSet[dataSet.length - 1] + amountVat
          dataSet[dataSet.length - 1] = parseFloat(newAmountVat.toFixed(2));
          let newAmountNoVat = dataSetNoVat[dataSetNoVat.length - 1] + amountNoVat;
          dataSetNoVat[dataSetNoVat.length - 1] = parseFloat(newAmountNoVat.toFixed(2))

          // dataSet[dataSet.length - 1] = dataSet[dataSet.length - 1] + getAmountPaidByProductsSold(entry.ProductsSold).TotalsVatAll;
          // dataSetNoVat[dataSetNoVat.length - 1] = dataSetNoVat[dataSetNoVat.length - 1] + getAmountPaidByProductsSold(entry.ProductsSold).TotalsNoVatAll;
        } else {
          labels.push(convertToSlashDate(entry.TransactionDate.split("T")[0]));
          labelsDetailed.push(getDetailedDate(entry.TransactionDate));

          dataSet.push(amountVat);
          dataSetNoVat.push(amountNoVat);

          // dataSet.push(parseFloat(getAmountPaidByProductsSold(entry.ProductsSold).TotalsVatAll.toFixed(2)));
          // dataSetNoVat.push(parseFloat(getAmountPaidByProductsSold(entry.ProductsSold).TotalsNoVatAll.toFixed(2)));
        }
      }
      else if (daysDiff >= 32) {
        const month = entry.TransactionDate.split("-")[1];

        let amountNoVat = entry.AmountPaid - (entry.AmountPaid * 0.09);
        amountNoVat = parseFloat(amountNoVat.toFixed(2))
        let amountVat = parseFloat(entry.AmountPaid.toFixed(2))

        if (labels.includes(monthNames[parseInt(month, 10) - 1])) {

          let newAmountVat = dataSet[dataSet.length - 1] + amountVat
          dataSet[dataSet.length - 1] = parseFloat(newAmountVat.toFixed(2));
          let newAmountNoVat = dataSetNoVat[dataSetNoVat.length - 1] + amountNoVat;
          dataSetNoVat[dataSetNoVat.length - 1] = parseFloat(newAmountNoVat.toFixed(2))

          // dataSet[dataSet.length - 1] = dataSet[dataSet.length - 1] + getAmountPaidByProductsSold(entry.ProductsSold).TotalsVatAll;
          // dataSetNoVat[dataSetNoVat.length - 1] = dataSetNoVat[dataSetNoVat.length - 1] + getAmountPaidByProductsSold(entry.ProductsSold).TotalsNoVatAll;
        } else {
          labels.push(monthNames[parseInt(month, 10) - 1]);

          dataSet.push(amountVat);
          dataSetNoVat.push(amountNoVat);

          // dataSet.push(parseFloat(getAmountPaidByProductsSold(entry.ProductsSold).TotalsVatAll.toFixed(2)));
          // dataSetNoVat.push(parseFloat(getAmountPaidByProductsSold(entry.ProductsSold).TotalsNoVatAll.toFixed(2)));
        }
      }
    });

    // create day excel dataSets
    const excelTabDateRangeVatObj: any = {};
    let amountTotalVat = 0;
    dataSet.forEach((amount, index) => {
      const attr = "revenue" + index
      excelTabDateRangeVatObj[attr] = amount
      amountTotalVat = amountTotalVat + amount;
    });
    excelTabDateRangeVatObj.label = "Omzet (BTW)"
    excelTabDateRangeVatObj.dateRange = `${formatDisplayDate(new Date(fromDate))} - ${formatDisplayDate(new Date(untilDate))}`

    setAmountTotalDateRangeVat(amountTotalVat)

    const excelTabDateRangeNoVatObj: any = {};
    let amountTotalNoVat = 0;
    dataSetNoVat.forEach((amount, index) => {
      const attr = "revenue" + index
      excelTabDateRangeNoVatObj[attr] = amount
      amountTotalNoVat = amountTotalNoVat + amount;
    });
    excelTabDateRangeNoVatObj.label = "Omzet (geen BTW)"
    excelTabDateRangeNoVatObj.dateRange = `${formatDisplayDate(new Date(fromDate))} - ${formatDisplayDate(new Date(untilDate))}`

    setAmountTotalDateRangeNoVat(amountTotalNoVat)

    setExcelTabDateRange([excelTabDateRangeVatObj]);
    setExcelTabNoVatDateRange([excelTabDateRangeNoVatObj]);

    setLabelsDateRange(daysDiff > 1 && daysDiff < 8 ? labelsDetailed : labels);
    setExcelLabelsDateRange(labels);
    setDataSetDateRange(dataSet);
    setDataSetNoVatDateRange(dataSetNoVat);

    setAmountTotalActive(VATRevenueActive ? amountTotalVat : amountTotalNoVat);
    setLabelsActive(daysDiff > 1 && daysDiff < 8 ? labelsDetailed : labels);
    setDataSetActive(VATRevenueActive ? dataSet : dataSetNoVat);
  }

  function createDayData() {
    const labels: any[] = [];
    const dataSet: any[] = [];
    const dataSetNoVat: any[] = [];

    TransactionData.forEach(entry => {
      if (isToday(entry.TransactionDate)) {
        labels.push(entry.TransactionDate.split("T")[1]);
        dataSet.push(entry.AmountPaid);

        let amountNoVat;

        if (entry.Offers && entry.Offers.length) {
          // the productsSold part are the products that are not in any offers
          let [offersAmountNoVAT, productsSold] = calculateOffersNoVat(entry.Offers, entry.ProductsSold);

          let totalAmountSingleProductsNoVAT = calculateSingleProductsNoVAT(productsSold);

          const sum = offersAmountNoVAT + totalAmountSingleProductsNoVAT;
          amountNoVat = parseFloat(sum.toFixed(2));

        } else {
          // old entries
          amountNoVat = entry.AmountPaid - (entry.AmountPaid * 0.09)
          amountNoVat = parseFloat(amountNoVat.toFixed(2))
        }

        dataSetNoVat.push(amountNoVat);

        // dataSet.push(parseFloat(getAmountPaidByProductsSold(entry.ProductsSold).TotalsVatAll.toFixed(2)));
        // dataSetNoVat.push(parseFloat(getAmountPaidByProductsSold(entry.ProductsSold).TotalsNoVatAll.toFixed(2)));

      }
    });

    // create day excel dataSets
    const excelTabDayVatObj: any = {};
    let amountTotalVat = 0;
    dataSet.forEach((amount, index) => {
      const attr = "revenue" + index
      excelTabDayVatObj[attr] = amount
      amountTotalVat = amountTotalVat + amount;
    });
    excelTabDayVatObj.label = "Omzet (BTW)"
    excelTabDayVatObj.dateRange = formatDisplayDate(new Date())

    setAmountTotalDayVat(amountTotalVat)

    const excelTabDayNoVatObj: any = {};
    let amountTotalNoVat = 0;
    dataSetNoVat.forEach((amount, index) => {
      const attr = "revenue" + index
      excelTabDayNoVatObj[attr] = amount
      amountTotalNoVat = amountTotalNoVat + amount;
    });
    excelTabDayNoVatObj.label = "Omzet (geen BTW)"
    excelTabDayNoVatObj.dateRange = formatDisplayDate(new Date())

    setAmountTotalDayNoVat(amountTotalNoVat)

    setExcelTabDayVat([excelTabDayVatObj]);
    setExcelTabDayNoVat([excelTabDayNoVatObj]);

    setLabelsDay(labels);
    setDataSetDay(dataSet);
    setDataSetNoVATDay(dataSetNoVat)

    setAmountTotalActive(VATRevenueActive ? amountTotalVat : amountTotalNoVat);
    setDataSetActive(dataSet);
    setLabelsActive(labels);
  }

  function createWeekData() {
    const labels: any[] = [];
    const labelsDetailed: any[] = [];
    const dataSet: any[] = [];
    const dataSetNoVat: any[] = [];

    TransactionData.forEach(entry => {
      if (
        weekDates.includes(entry.TransactionDate.split("T")[0]) ||
        entry.TransactionDate.split("T")[0] === formatDate(new Date()).split("T")[0]
      ) {
        let amountNoVat = entry.AmountPaid - (entry.AmountPaid * 0.09);
        amountNoVat = parseFloat(amountNoVat.toFixed(2))
        let amountVat = parseFloat(entry.AmountPaid.toFixed(2))


        if (labels.includes(convertToSlashDate(entry.TransactionDate.split("T")[0]))) {

          let newAmountVat = dataSet[dataSet.length - 1] + amountVat
          dataSet[dataSet.length - 1] = parseFloat(newAmountVat.toFixed(2));
          let newAmountNoVat = dataSetNoVat[dataSetNoVat.length - 1] + amountNoVat;
          dataSetNoVat[dataSetNoVat.length - 1] = parseFloat(newAmountNoVat.toFixed(2))

          // dataSet[dataSet.length - 1] = dataSet[dataSet.length - 1] + getAmountPaidByProductsSold(entry.ProductsSold).TotalsVatAll;
          // dataSetNoVat[dataSetNoVat.length - 1] = dataSetNoVat[dataSetNoVat.length - 1] + getAmountPaidByProductsSold(entry.ProductsSold).TotalsNoVatAll;
        } else {
          labels.push(convertToSlashDate(entry.TransactionDate.split("T")[0]));
          labelsDetailed.push(getDetailedDate(entry.TransactionDate));

          dataSet.push(amountVat);
          dataSetNoVat.push(amountNoVat);

          // dataSet.push(parseFloat(getAmountPaidByProductsSold(entry.ProductsSold).TotalsVatAll.toFixed(2)));
          // dataSetNoVat.push(parseFloat(getAmountPaidByProductsSold(entry.ProductsSold).TotalsNoVatAll.toFixed(2)));
        }
      }
    });

    // create Week excel dataSets
    const excelTabWeekVatObj: any = {};
    let amountTotalVat = 0;
    dataSet.forEach((amount, index) => {
      const attr = "revenue" + index
      excelTabWeekVatObj[attr] = amount
      amountTotalVat = amountTotalVat + amount;
    });
    excelTabWeekVatObj.label = "Omzet (BTW)"
    excelTabWeekVatObj.dateRange = `${formatDisplayDate(new Date().setDate(new Date().getDate() - 6))} - ${formatDisplayDate(new Date())}`

    setAmountTotalWeekVat(amountTotalVat)

    const excelTabWeekNoVatObj: any = {};
    let amountTotalNoVat = 0;
    dataSetNoVat.forEach((amount, index) => {
      const attr = "revenue" + index
      excelTabWeekNoVatObj[attr] = amount
      amountTotalNoVat = amountTotalNoVat + amount;
    });
    excelTabWeekNoVatObj.label = "Omzet (geen BTW)"
    excelTabWeekNoVatObj.dateRange = `${formatDisplayDate(new Date().setDate(new Date().getDate() - 6))} - ${formatDisplayDate(new Date())}`

    setAmountTotalWeekNoVat(amountTotalNoVat)

    setExcelTabWeekVat([excelTabWeekVatObj]);
    setExcelTabWeekNoVat([excelTabWeekNoVatObj]);

    setLabelsWeek(labelsDetailed);
    setExcelLabelsWeek(labelsDetailed);
    setDataSetWeek(dataSet);
    setDataSetNoVATWeek(dataSetNoVat);
  }

  function createMonthData() {
    const labels: any[] = [];
    const labelsDetailed: any[] = [];
    const dataSet: any[] = [];
    const dataSetNoVat: any[] = [];

    TransactionData.forEach(entry => {
      if (isSameMonth(entry.TransactionDate)) {
        let amountNoVat = entry.AmountPaid - (entry.AmountPaid * 0.09);
        amountNoVat = parseFloat(amountNoVat.toFixed(2))
        let amountVat = parseFloat(entry.AmountPaid.toFixed(2))

        if (labels.includes(convertToSlashDate(entry.TransactionDate.split("T")[0]))) {

          let newAmountVat = dataSet[dataSet.length - 1] + amountVat
          dataSet[dataSet.length - 1] = parseFloat(newAmountVat.toFixed(2));
          let newAmountNoVat = dataSetNoVat[dataSetNoVat.length - 1] + amountNoVat;
          dataSetNoVat[dataSetNoVat.length - 1] = parseFloat(newAmountNoVat.toFixed(2))

          // dataSet[dataSet.length - 1] = dataSet[dataSet.length - 1] + getAmountPaidByProductsSold(entry.ProductsSold).TotalsVatAll;
          // dataSetNoVat[dataSetNoVat.length - 1] = dataSetNoVat[dataSetNoVat.length - 1] + getAmountPaidByProductsSold(entry.ProductsSold).TotalsNoVatAll;
        } else {
          labels.push(convertToSlashDate(entry.TransactionDate.split("T")[0]));
          labelsDetailed.push(getDetailedDate(entry.TransactionDate));

          dataSet.push(amountVat);
          dataSetNoVat.push(amountNoVat);

          // dataSet.push(parseFloat(getAmountPaidByProductsSold(entry.ProductsSold).TotalsVatAll.toFixed(2)));
          // dataSetNoVat.push(parseFloat(getAmountPaidByProductsSold(entry.ProductsSold).TotalsNoVatAll.toFixed(2)));
        }
      }
    });

    // create Month excel dataSets
    const excelTabMonthVatObj: any = {};
    let amountTotalVat = 0;
    dataSet.forEach((amount, index) => {
      const attr = "revenue" + index
      excelTabMonthVatObj[attr] = amount
      amountTotalVat = amountTotalVat + amount;
    });
    excelTabMonthVatObj.label = "Omzet (BTW)"
    setAmountTotalMonthVat(amountTotalVat)

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    setFirstDayOfMonth(formatExcelDate(firstDay).split("T")[0]);
    excelTabMonthVatObj.dateRange = `${formatDisplayDate(firstDay)} - ${formatDisplayDate(new Date())}`

    const excelTabMonthNoVatObj: any = {};
    let amountTotalNoVat = 0;
    dataSetNoVat.forEach((amount, index) => {
      const attr = "revenue" + index
      excelTabMonthNoVatObj[attr] = amount
      amountTotalNoVat = amountTotalNoVat + amount;
    });
    excelTabMonthNoVatObj.label = "Omzet (geen BTW)"
    excelTabMonthNoVatObj.dateRange = `${formatDisplayDate(firstDay)} - ${formatDisplayDate(new Date())}`

    setAmountTotalMonthNoVat(amountTotalNoVat)

    setExcelTabMonthVat([excelTabMonthVatObj]);
    setExcelTabMonthNoVat([excelTabMonthNoVatObj]);

    setExcelLabelsMonth(labels.length < 8 ? labelsDetailed : labels);
    setLabelsMonth(labels.length < 8 ? labelsDetailed : labels);
    setDataSetMonth(dataSet);
    setDataSetNoVATMonth(dataSetNoVat)
  }

  function createYearData() {
    const labels: any[] = [];
    let dataSet: any[] = [];
    let dataSetNoVat: any[] = [];

    const currTimeStamp = new Date().getTime();
    const date = new Date();
    const timeStampLastYear = date.setFullYear(date.getFullYear() - 1);

    const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni",
      "Juli", "Augustus", "September", "Oktober", "November", "December"
    ];

    TransactionData.forEach((entry) => {
      if (IsInYearRange(currTimeStamp, timeStampLastYear, entry.TransactionDate)) {
        const month = entry.TransactionDate.split("-")[1];

        let amountNoVat = entry.AmountPaid - (entry.AmountPaid * 0.09);
        amountNoVat = parseFloat(amountNoVat.toFixed(2))
        let amountVat = parseFloat(entry.AmountPaid.toFixed(2))

        if (labels.includes(monthNames[parseInt(month, 10) - 1])) {

          let newAmountVat = dataSet[dataSet.length - 1] + amountVat
          dataSet[dataSet.length - 1] = parseFloat(newAmountVat.toFixed(2));
          let newAmountNoVat = dataSetNoVat[dataSetNoVat.length - 1] + amountNoVat;
          dataSetNoVat[dataSetNoVat.length - 1] = parseFloat(newAmountNoVat.toFixed(2))

          // dataSet[dataSet.length - 1] = dataSet[dataSet.length - 1] + getAmountPaidByProductsSold(entry.ProductsSold).TotalsVatAll;
          // dataSetNoVat[dataSetNoVat.length - 1] = dataSetNoVat[dataSetNoVat.length - 1] + getAmountPaidByProductsSold(entry.ProductsSold).TotalsNoVatAll;
        } else {
          labels.push(monthNames[parseInt(month, 10) - 1]);

          dataSet.push(amountVat);
          dataSetNoVat.push(amountNoVat);

          // dataSet.push(parseFloat(getAmountPaidByProductsSold(entry.ProductsSold).TotalsVatAll.toFixed(2)));
          // dataSetNoVat.push(parseFloat(getAmountPaidByProductsSold(entry.ProductsSold).TotalsNoVatAll.toFixed(2)));
        }

      }
    });

    // "fix" datasets
    dataSet = dataSet.map(entry => parseFloat(entry.toFixed(2)))
    dataSetNoVat = dataSetNoVat.map(entry => parseFloat(entry.toFixed(2)))

    // create Year excel dataSets
    const excelTabYearVatObj: any = {};
    let amountTotalVat = 0;
    dataSet.forEach((amount, index) => {
      const attr = "revenue" + index
      excelTabYearVatObj[attr] = amount
      amountTotalVat = amountTotalVat + amount;
    });
    excelTabYearVatObj.label = "Omzet (BTW)"
    excelTabYearVatObj.dateRange = `${formatDisplayDate(timeStampLastYear)} - ${formatDisplayDate(new Date())}`
    setYearPeriodStart(formatExcelDate(timeStampLastYear).split("T")[0]);
    setAmountTotalYearVat(amountTotalVat)

    const excelTabYearNoVatObj: any = {};
    let amountTotalNoVat = 0;
    dataSetNoVat.forEach((amount, index) => {
      const attr = "revenue" + index
      excelTabYearNoVatObj[attr] = amount
      amountTotalNoVat = amountTotalNoVat + amount;
    });
    excelTabYearNoVatObj.label = "Omzet (geen BTW)"
    excelTabYearNoVatObj.dateRange = `${formatDisplayDate(timeStampLastYear)} - ${formatDisplayDate(new Date())}`

    setExcelTabYearVat([excelTabYearVatObj]);
    setExcelTabYearNoVat([excelTabYearNoVatObj]);
    setAmountTotalYearNoVat(amountTotalNoVat)

    setLabelsYear(labels);
    setDataSetYear(dataSet);
    setDataSetNoVATYear(dataSetNoVat)
  }

  return (
    <div className={classes.root}>

      <div className="flex-center-align jcsb title">
        <h2 className="mr1">Winkelomzet <span style={{ fontWeight: "normal" }} >{AmountTotalActive.toFixed(2).replace(".", ",")}€</span></h2>


        <div className="excel-btn">
          <ExcelFile filename={`Revenue-Report_${formatDateAndTIme(new Date())}`} element={<button>Download Statistieken</button>}>
            <ExcelSheet data={ExcelTabDayVat} name={`Dag BTW (${formatExcelDate(new Date()).split("T")[0]})`}>
              <ExcelColumn label={"Datum"} value="dateRange" />
              <ExcelColumn label={"Transactie Time"} value="label" />
              {LabelsDay.map((label, index) => {
                const attr = "revenue" + index;
                return <ExcelColumn key={index} label={label} value={attr} />
              })}
            </ExcelSheet>
            <ExcelSheet data={ExcelTabDayNoVat} name={`Dag (${formatExcelDate(new Date()).split("T")[0]})`}>
              <ExcelColumn label={"Datum"} value="dateRange" />
              <ExcelColumn label={"Transactie Time"} value="label" />
              {LabelsDay.map((label, index) => {
                const attr = "revenue" + index;
                return <ExcelColumn key={index} label={label} value={attr} />
              })}
            </ExcelSheet>

            <ExcelSheet data={ExcelTabWeekVat} name={`Week BTW ${formatExcelDate(new Date().setDate(new Date().getDate() - 6)).split("T")[0]}|${formatExcelDate(new Date()).split("T")[0]}`}>
              <ExcelColumn label={"Datums"} value="dateRange" />
              <ExcelColumn label={"Transactie Day"} value="label" />
              {LabelsWeek.map((label, index) => {
                const attr = "revenue" + index;
                return <ExcelColumn key={index} label={label} value={attr} />
              })}
            </ExcelSheet>
            <ExcelSheet data={ExcelTabWeekNoVat} name={`Week ${formatExcelDate(new Date().setDate(new Date().getDate() - 6)).split("T")[0]}|${formatExcelDate(new Date()).split("T")[0]}`}>
              <ExcelColumn label={"Datums"} value="dateRange" />
              <ExcelColumn label={"Transactie Day"} value="label" />
              {LabelsWeek.map((label, index) => {
                const attr = "revenue" + index;
                return <ExcelColumn key={index} label={label} value={attr} />
              })}
            </ExcelSheet>

            <ExcelSheet data={ExcelTabMonthVat} name={`Maand BTW ${FirstDayOfMonth}|${formatExcelDate(new Date()).split("T")[0]}`}>
              <ExcelColumn label={"Datums"} value="dateRange" />
              <ExcelColumn label={"Transactie Day"} value="label" />
              {ExcelLabelsMonth.map((label, index) => {
                const attr = "revenue" + index;
                return <ExcelColumn key={index} label={label} value={attr} />
              })}
            </ExcelSheet>
            <ExcelSheet data={ExcelTabMonthNoVat} name={`Maand ${FirstDayOfMonth}|${formatExcelDate(new Date()).split("T")[0]}`}>
              <ExcelColumn label={"Datums"} value="dateRange" />
              <ExcelColumn label={"Transactie Day"} value="label" />
              {ExcelLabelsMonth.map((label, index) => {
                const attr = "revenue" + index;
                return <ExcelColumn key={index} label={label} value={attr} />
              })}
            </ExcelSheet>

            <ExcelSheet data={ExcelTabYearVat} name={`Jaar BTW ${YearPeriodStart}|${formatExcelDate(new Date()).split("T")[0]}`}>
              <ExcelColumn label={"Datums"} value="dateRange" />
              <ExcelColumn label={"Transactie Day"} value="label" />
              {LabelsYear.map((label, index) => {
                const attr = "revenue" + index;
                return <ExcelColumn key={index} label={label} value={attr} />
              })}
            </ExcelSheet>
            <ExcelSheet data={ExcelTabYearNoVat} name={`Jaar ${YearPeriodStart}|${formatExcelDate(new Date()).split("T")[0]}`}>
              <ExcelColumn label={"Datums"} value="dateRange" />
              <ExcelColumn label={"Transactie Day"} value="label" />
              {LabelsYear.map((label, index) => {
                const attr = "revenue" + index;
                return <ExcelColumn key={index} label={label} value={attr} />
              })}
            </ExcelSheet>

            <ExcelSheet data={ExcelTabDateRange} name={`Periode BTW (${FromDate ? formatExcelDate(new Date(FromDate)).split("T")[0] : "Unset"} - ${UntilDate ? formatExcelDate(new Date(UntilDate)).split("T")[0] : "Unset"})`}>
              <ExcelColumn label={"Datums"} value="dateRange" />
              <ExcelColumn label={"Transactie Tijd/Day"} value="label" />
              {ExcelLabelsDateRange.map((label, index) => {
                const attr = "revenue" + index;
                return <ExcelColumn key={index} label={label} value={attr} />
              })}
            </ExcelSheet>
            <ExcelSheet data={ExcelTabNoVatDateRange} name={`Periode (${FromDate ? formatExcelDate(new Date(FromDate)).split("T")[0] : "Unset"} - ${UntilDate ? formatExcelDate(new Date(UntilDate)).split("T")[0] : "Unset"})`}>
              <ExcelColumn label={"Datums"} value="dateRange" />
              <ExcelColumn label={"Transactie Tijd/Day"} value="label" />
              {ExcelLabelsDateRange.map((label, index) => {
                const attr = "revenue" + index;
                return <ExcelColumn key={index} label={label} value={attr} />
              })}
            </ExcelSheet>

          </ExcelFile>
        </div>
      </div>
      <div className="flex jcsb">

        <div className="flex-center-align filters-range">
          <label htmlFor="rangeLabel">per:</label>
          <label
            className={`range-label ${RevenueRangeActive === "Day" ? "active" : ""}`}
            onClick={() => { setDataSetActive(DataSetDay); setLabelsActive(LabelsDay); setRevenueRangeActive("Day"); }}
            id="dayRange"
          >Dag</label>
          <span>/</span>
          <label
            className={`range-label ${RevenueRangeActive === "Week" ? "active" : ""}`}
            onClick={() => { setDataSetActive(DataSetWeek); setLabelsActive(LabelsWeek); setRevenueRangeActive("Week"); }}
            id="weekRange"
          >Week</label>
          <span>/</span>
          <label
            className={`range-label ${RevenueRangeActive === "Month" ? "active" : ""}`}
            onClick={() => { setDataSetActive(DataSetMonth); setLabelsActive(LabelsMonth); setRevenueRangeActive("Month"); }}
            id="monthRange"
          >Maand</label>
          <span>/</span>
          <label
            className={`range-label ${RevenueRangeActive === "Year" ? "active" : ""}`}
            onClick={() => { setDataSetActive(DataSetYear); setLabelsActive(LabelsYear); setRevenueRangeActive("Year"); }}
            id="yearRange"
          >Jaar</label>
          <span>/</span>
          <label
            className={`range-label ${RevenueRangeActive === "DateRange" ? "active" : ""}`}
            onClick={() => { setDataSetActive(DataSetDateRange); setLabelsActive(LabelsDateRange); setRevenueRangeActive("DateRange"); }}
            id="dateRange"
          >Selecteer periode</label>
        </div>
        <div className="flex-center-align">
          <FormControlLabel
            control={
              <Checkbox
                checked={VATRevenueActive}
                onChange={() => setVATRevenueActive(!VATRevenueActive)}
                name="checkedB"
                color="primary"
              />
            }
            label="inclusief BTW"
          />
        </div>

      </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="flex-center-align chart-date-filters" style={{ display: RevenueRangeActive === "DateRange" ? "flex" : "none" }}>
          <label htmlFor={"From:"}>{"Van:"}</label>
          <DatePicker
            name={"FromPPT"}
            className="datetimepicker-dashboard"
            clearable
            autoOk
            // label="Van:"
            format="dd/MM/yyyy"
            placeholder={formatDisplayDate(Date.now())}
            onChange={value => {
              setFromDate(value);
            }}
            value={FromDate}
            animateYearScrolling={false}
          />
          <label htmlFor={"Until:"}>{"Tot:"}</label>
          <DatePicker
            name={"UntilPPT"}
            className="datetimepicker-dashboard"
            clearable
            autoOk
            // label="Van:"
            format="dd/MM/yyyy"
            placeholder={formatDisplayDate(Date.now())}
            onChange={value => {
              setUntilDate(value);
            }}
            value={UntilDate}
            animateYearScrolling={false}
          />
        </div>
      </MuiPickersUtilsProvider>

      <LineChart labels={LabelsActive} dataSet={DatasetList} />

    </div>
  )
}

export default RevenueChart

