import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import useUIState from '../../hooks/useUIstate';
import { MdArrowBack, MdCheckCircle } from "react-icons/md";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Select, MenuItem, ButtonGroup } from '@material-ui/core';
import { API } from 'aws-amplify';
import Checkbox from '@material-ui/core/Checkbox';
import useDataState from '../../hooks/useDataState';
import { getSelectedLockersRequestBody } from './StoreSupportController';
import LoadingModal from '../../components/LoadingModal';
import awsconfig from '../../aws-apiSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSelect-select': {
      padding: "10.5px 14px",
      width: "250px",
    },
    '& .MuiInputBase-root ': {
      marginRight: "10px"
    },
    '& h1, & h2': {
      marginTop: '5px',
      marginBottom: '5px'
    },
    '& .blocks-container': {
      marginTop: "20px",
      overflowX: "auto",
      position: "relative",
    },
    '& .block': {
      background: '#eee',
      marginRight: "20px"
    },
    '& .double': {
      width: '450px',
      minWidth: '450px',
    },
    '& .single': {
      width: '225px',
      minWidth: '225px',
    },
    '& .block-header': {
      background: '#ddd'
    },
    '& .lockers-container': {
      flexWrap: "wrap",
      padding: "5px 0"
    },
    '& .locker': {
      width: '225px',
      padding: '5px 0',
      alignItems: 'center'
    },
    '& .product-avatar-container': {
      width: "50px",
      height: "45px",
      minWidth: "50px",
      minHeight: "45px",
      background: "#fff",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "5px"
    },
    '& .product-avatar-container svg': {
      position: "absolute",
      padding: "0.25px",
      background: "white",
      borderRadius: "50%"
    },
    '& .product-avatar-container img': {
      width: "100%",
      height: "100%"
    },
    '& .product-avatar-container.B': {
      width: "60px",
    },
    '& .product-avatar-container.C': {
      width: "70px",
    },
    '& .product-avatar-container.D': {
      width: "60px",
    },
    '& .green-locker .locker-shape': {
      height: "15px",
      width: "15px",
      minHeight: "15px",
      minWidth: "15px",
      backgroundColor: "#689f38",
      borderRadius: "50%",
      border: "1px solid #689f38",
      margin: "0 10px"
    },
    '& .empty-locker .locker-shape': {
      height: "15px",
      width: "15px",
      minHeight: "15px",
      minWidth: "15px",
      backgroundColor: "#fff",
      borderRadius: "50%",
      border: "1px solid #222",
      margin: "0 10px"
    },
    '& .almost-expired-locker .locker-shape': {
      height: "15px",
      width: "15px",
      minHeight: "15px",
      minWidth: "15px",
      backgroundColor: "#ff9800",
      margin: "0 10px"
    },
    '& .almost-expired-locker .product-avatar-container': {
      border: "3px solid #ff9800"
    },
    '& .sold-out-locker .locker-shape': {
      width: 0,
      height: 0,
      borderLeft: "7.5px solid transparent",
      borderRight: "7.5px solid transparent",
      borderBottom: "15px solid #d81b60",
      margin: "0 10px"
    },
    '& .sold-out-locker .product-avatar-container': {
      border: "3px solid #d81b60"
    },
    '& .product-code': {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    },
    '& .legend': {
      alignItems: "center",
    },
    '& .replenishment-bottom': {
      marginTop: "20px",
      maxWidth: "90vw"
    },
    '& .replenishment-actions>*': {
      marginRight: "20px",
    }
  }
}));

function StoreSupportScreen(props) {
  const history = useHistory();
  const classes = useStyles();

  const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();
  const { ProductCategoriesContext, setProductCategoriesContext, ProductsContext, setProductsContext, StoreLayoutContext, setStoreLayoutContext, JofemarContext, setJofemarContext, StoreContext, setStoreContext } = useDataState();

  const [StoreId, setStoreId] = useState(null);
  const [StoreObj, setStoreObj] = useState({ FillingPlan: "" });
  const [StoreTitle, setStoreTitle] = useState("");
  const [SelectType, setSelectType] = useState(0);
  const [Blocks, setBlocks]: [any, any] = useState([]);
  const [Jofemar, setJofemar] = useState(null);
  const [ProductCategories, setProductCategories]: [any, any] = useState([]);
  const [Products, setProducts]: [any, any] = useState([]);
  const [LockersSelected, setLockersSelected]: [any, any] = useState([]);
  const [BlocksSelected, setBlocksSelected]: [any, any] = useState([]);
  const [AlmostExpiredLockerIds]: [any, any] = useState([]);
  const [SoldOutLockerIds]: [any, any] = useState([]);
  const [ShowPanel] = useState(false);
  const [LockersView, setLockersView] = useState<string>("Back");
  const [UserConfigData, setUserConfigData]: [any, any] = useState([]);

  const [Jofemars, setJofemars] = useState(null)


  const handleCheckboxChange = (e, type, selection, selectedBlock = null) => {
    const lockersSelected: any = [...LockersSelected];
    const blocksSelected: any = [...BlocksSelected];
    console.log(lockersSelected)

    if (type === 'locker') {
      if (lockersSelected.indexOf(selection) > -1) {
        const position = lockersSelected.indexOf(selection);
        lockersSelected.splice(position, 1)
      } else {
        lockersSelected.push(selection);
      }
    } else if (type === "block") {
      if (blocksSelected.indexOf(selectedBlock) > -1) {
        // de-selecting
        const pos = blocksSelected.indexOf(selectedBlock);
        blocksSelected.splice(pos, 1)

        selection.forEach(locker => {
          if (lockersSelected.includes(locker.Id)) {
            const pos = lockersSelected.indexOf(locker.Id);
            lockersSelected.splice(pos, 1);
          }
        });
      } else {
        // selecting non green lockers
        blocksSelected.push(selectedBlock);
        const lockersOfBlock: any = document.querySelectorAll(
          ".lblock" + selectedBlock
        );
        [].forEach.call(lockersOfBlock, (el) => {
          const element: any = el;
          const lockerId = parseInt(element.id.split('locker')[1], 10);
          if (element.classList.contains('green-locker') && element.classList.contains('lblock' + selectedBlock)) {
            // remove green lockers selected
            const pos = lockersSelected.indexOf(lockerId);
            if (pos >= 0) {
              lockersSelected.splice(pos, 1);
            }
          }
          if (
            element.classList.contains('empty-locker') ||
            element.classList.contains('almost-expired-locker') ||
            element.classList.contains('sold-out-locker')
          ) {
            if (!lockersSelected.includes(lockerId)) {
              lockersSelected.push(lockerId)
            }
          }
        });

      }
      setBlocksSelected(blocksSelected)
    }

    setLockersSelected(lockersSelected)
  };

  function handleLockersViewChange(view) {
    const blocksContainer = document.getElementById("blocksContainer");
    if (view === "Back") {
      setLockersView("Back");
      if (blocksContainer) {
        setTimeout(() => {

          blocksContainer.scrollLeft = -blocksContainer.scrollWidth
        }, 100);
      }
      return;
    }
    setLockersView("Front");
  }

  useEffect(() => {
    const value = localStorage.getItem("UserConfigData");
    if (typeof value === 'string') {
      setUserConfigData(JSON.parse(value));
    }

    if (props.location.store) {
      const Store = props.location.store
      setStoreObj(props.location.store);
      setStoreContext(Store);
      localStorage.setItem("ActiveStore", JSON.stringify(Store))
      setStoreId(props.location.storeId);
      setStoreTitle(props.location.title);

    } else if (StoreContext) {
      localStorage.setItem("ActiveStore", JSON.stringify(StoreContext))
      setStoreId(StoreContext.Id);
      setStoreTitle(StoreContext.Title);
      setStoreObj(StoreContext)
    } else if (localStorage.getItem("ActiveStore")) {

      const Store = localStorage.getItem("ActiveStore");
      if (Store) {

        const StoreJson = JSON.parse(Store)
        console.log(StoreJson)

        setStoreObj(StoreJson);
        setStoreContext(StoreJson);
        setStoreId(StoreJson.StoreId);
        setStoreTitle(StoreJson.StoreTitle);
      }

    } else {
      history.push('/retail-locations');
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (StoreId) {
      getProductCategories();
    }
    // eslint-disable-next-line
  }, [StoreId])

  function getProductCategories() {
    if (ProductCategoriesContext.length) {
      console.log("test");
      setProductCategories(ProductCategoriesContext);
      setShowLoadingModal(false);
      getProducts();
    } else {
      setShowLoadingModal(true);
      API.get("AE", `/ProductCategories`, { response: true })
        .then(res => {

          setProductCategories(res.data);
          setProductCategoriesContext(res.data);
          getProducts();
        })
        .catch(error => {
          console.log(error);
          setToastType("error");
          setToastMessage("Error getting products");
          setShowToastMessage(true);
        })
    }

  }

  function getProducts() {
    if (ProductsContext.length) {
      setProducts(ProductsContext);
      setShowLoadingModal(false);
      getBlocksOfStore(StoreId);

    } else {
      setShowLoadingModal(true);
      API.get("AE", `/Products`, { response: true })
        .then(res => {
          console.log(res.data);
          setProducts(res.data);
          localStorage.setItem("Products", JSON.stringify(res.data))
          setProductsContext(res.data);
          getBlocksOfStore(StoreId);
        })
        .catch(error => {
          console.log(error);
          setToastType("error");
          setToastMessage("Error getting products");
          setShowToastMessage(true);
        })
    }
  }

  function getBlocksOfStore(storeId) {

    if (localStorage.getItem("StoreLayout")) {
      const storeLayout = localStorage.getItem("StoreLayout");
      if (storeLayout) {
        
        // set Jofemars
        const jofemars = localStorage.getItem("Jofemars")
        if (jofemars) {
          const jofemarsJson = JSON.parse(jofemars);
          setJofemars(jofemarsJson);
        }

        //set Jofemar
        const jofemar = localStorage.getItem("Jofemar")

        if (jofemar) {
          const jofemarJson = JSON.parse(jofemar);
          setJofemar(jofemarJson);
          setJofemarContext(jofemarJson);
        }

        const storeJson = JSON.parse(storeLayout);
        setBlocks(storeJson);
        setStoreLayoutContext(storeLayout);
        setShowLoadingModal(false);
      }
    } else {
      if (StoreLayoutContext.length) {
        setBlocks(StoreLayoutContext);
        setJofemar(JofemarContext);
        localStorage.setItem('StoreLayout', JSON.stringify(StoreLayoutContext));
        setShowLoadingModal(false);
      } else {
        setShowLoadingModal(true);
        API.get("AE", `/Blocks/GetBlocksAndLockers?storeId=${storeId}`, { response: true })
          .then(res => {

            //set Jofemar
            const jofemar = res.data.find(block => block.Code.indexOf("Vision") > -1);
            
            // set Jofemars
            const jofemars = res.data.filter(function(obj) {
              return (obj.Code.indexOf("Vision") > -1);
            });

            setJofemar(jofemar);
            setJofemars(jofemars);
            localStorage.setItem("Jofemar", JSON.stringify(jofemar))
            localStorage.setItem("Jofemars", JSON.stringify(jofemars))
            setJofemarContext(jofemar);

            // filter out Jofemar
            const blocks = res.data.filter(block => block.Code.indexOf("Vision") <= -1);
            // sort blocks by rack
            blocks.forEach(block => {
              block.Order = parseInt(block.Code.split(' ')[1], 10);
            });
            blocks.sort((a, b) => (a.Order > b.Order) ? 1 : -1)

            assignLockersPerBlock(blocks);
          })
          .catch(error => {
            console.log(error);
            setToastType("error");
            setToastMessage("Error getting blocks");
            setShowToastMessage(true);
          });
      }
    }
  }

  async function assignLockersPerBlock(blocks) {
    const _blocks = blocks;
    setShowLoadingModal(false);
    localStorage.setItem('StoreLayout', JSON.stringify(_blocks));
    setStoreLayoutContext(_blocks);
    setBlocks(_blocks);
    const blocksContainer = document.getElementById("blocksContainer");
    if (blocksContainer) {
      setTimeout(() => {
        blocksContainer.scrollLeft = -blocksContainer.scrollWidth
      }, 100);
    }
  }

  function handleChange(e) {
    const value = e.target.value;
    setSelectType(value);
  }

  function handleSelectAction() {
    if (SelectType) {
      if (LockersSelected.length === 0) {
        setToastType("error");
        setToastMessage("No lockers selected");
        setShowToastMessage(true);
        setShowLoadingModal(false);
        return [];
      } else {
        SelectType === 1 ? deactivateLockers() : openAndDeactivateLockers()
      }
    } else {
      setToastType("error");
      setToastMessage("Please select an action");
      setShowToastMessage(true);
    }
  }

  async function deactivateLockers() {
    setShowLoadingModal(true);
    console.log('Deactivate Lockers');
    const [localGWLockers, lockersToUpdate, _Blocks]: any[] = getSelectedLockersRequestBody(Blocks, LockersSelected, ["LockerDisable"]);

    let body = localGWLockers;

    API.put('AE', `/Store/${StoreId}/LockerDisable`, { body })
      .then(response => {

        if (response === '200') {
          const updatedLockersResponse = updateLockers(lockersToUpdate);
          if (!updatedLockersResponse) {
            setToastType("error");
            setToastMessage("Error updating locker(s) ");
            setShowToastMessage(true);
          }
          else {
            setToastType("success");
            setToastMessage("Selected lockers are deactivated");
            setShowToastMessage(true);
            setBlocks(_Blocks);
            setStoreLayoutContext(_Blocks);
            localStorage.setItem("StoreLayout", JSON.stringify(_Blocks))
          }
        }
        else {
          setToastType("error");
          setToastMessage("Error deactivating locker(s)")
          setShowToastMessage(true);
        }
      })
      .catch(error => {
        setToastType("error");
        setToastMessage('Error deactivating locker(s) : ' + error.message);
        setShowToastMessage(true);
      })
      .finally(() => {
        setShowLoadingModal(false);
      })
  }

  async function openAndDeactivateLockers() {
    console.log('Open and deactivate Lockers')
    setShowLoadingModal(true);
    console.log('Deactivate Lockers');
    const [localGWLockers, lockersToUpdate, _Blocks]: any[] = getSelectedLockersRequestBody(Blocks, LockersSelected, ["LockerOpen", "LockerDisable"]);

    let body = localGWLockers;

    API.put('AE', `/Store/${StoreId}/LockerDisable`, { body })
      .then(response => {

        if (response === '200') {
          API.put('AE', `/Store/${StoreId}/LockerOpen`, { body })
            .then(response => {

              if (response === '200') {
                const updatedLockersResponse = updateLockers(lockersToUpdate);
                if (!updatedLockersResponse) {
                  setToastType("error");
                  setToastMessage("Error updating locker(s)");
                  setShowToastMessage(true);
                }
                else {
                  setToastType("success");
                  setToastMessage("Selected lockers are opened");
                  setShowToastMessage(true);
                  setBlocks(_Blocks);
                  setStoreLayoutContext(_Blocks);
                  localStorage.setItem("StoreLayout", JSON.stringify(_Blocks));
                }
              }
              else {
                setToastType("error");
                setToastMessage("Error opening locker(s)");
                setShowToastMessage(true);
              }
            })
            .catch(error => {
              setToastType("error");
              setToastMessage('Error opening locker(s) : ' + error.message);
              setShowToastMessage(true);
            })
        }
        else {
          setToastType("error");
          setToastMessage("Error deactivating locker(s)");
          setShowToastMessage(true);
        }
      })
      .catch(error => {
        setToastType("error");
        setToastMessage('Error deactivating locker(s) : ' + error.message);
        setShowToastMessage(true);
      })
      .finally(() => {
        setShowLoadingModal(false);
      })
  }

  function getProductStatus(locker) {
    let className = "green-locker"
    const product = Products.find(p => p.Id === locker.ProductId)

    let attr = product.Attributes;
    attr = JSON.parse(attr.replace(/'/g, '"'))

    let d = new Date();
    let currDate = formatDate(d);
    let todayObj = new Date(currDate);
    todayObj.setDate(todayObj.getDate() + 1);
    let tomorrowStr = formatDate(todayObj);
    let tomorrowsTime = new Date(tomorrowStr).getTime();

    let expiredDate = new Date(locker.ReplenishedAt);
    expiredDate.setDate(expiredDate.getDate() + attr.ShelfLifeDays);
    let expiredDateStr = formatDate(expiredDate);
    let expTime = new Date(expiredDateStr).getTime();

    if (tomorrowsTime >= expTime) {
      className = "almost-expired-locker";
      const almostExpiredLockerIds = [...AlmostExpiredLockerIds]
      if (!almostExpiredLockerIds.includes(locker.Id)) {
        almostExpiredLockerIds.push(locker.Id)
      }
      // setAlmostExpiredLockerIds(almostExpiredLockerIds)
    }
    if (locker.IsSoldOut) {
      className = "sold-out-locker";
      const soldOutLockerIds = [...SoldOutLockerIds]
      if (!soldOutLockerIds.includes(locker.Id)) {
        soldOutLockerIds.push(locker.Id)
      }
      // setSoldOutLockerIds(soldOutLockerIds)
    }

    return className;
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-') + "T00:00:00";
  }

  function updateLockers(lockers) {
    return new Promise((resolve) => {
      let body = lockers;
      API.put('AE', `/Lockers`, { body })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          console.log(error);
        })
    })
  }

  function navigateToJofemarSupportScreen() {
    history.push(
      {
        store: StoreObj,
        pathname: '/store-support-jofemar',
        storeId: StoreId,
        title: StoreTitle,
        Jofemar: Jofemar,
        Jofemars: Jofemars,
        lockersDistributionPlan: null,
        products: Products
      })
  }

  function determineBlockClass(blockCode) {
    if (blockCode.split('-')[0] === 'G') {
      let numberOfColumns = blockCode.split('-')[3];

      return `block ${numberOfColumns > 1 ? "double" : "single"}`;
    }
    else {
      return `block ${blockCode.split('-')[0].indexOf("2") > -1 ? "double" : "single"} ${blockCode.split('-')[0]}`;
    }
  }

  function determineBlockHeight(blockCode) {
    let blockType = blockCode.split('-')[0];

    switch (blockType) {
      case 'G':
        const lockerHeight = 61;
        let numberOfRows = blockCode.split('-')[4];
        let blockHeight = numberOfRows * lockerHeight;

        return blockHeight;
      case 'D2':
        console.log('D2 gevonden');
        return 410;
      default:
        console.log(blockType + 'Default');
        return 475;
    }
  }

  function JofemarButton() {
    if (Jofemar) {
      return <Button
        id="goToJofemarBtn"
        type="button"
        variant="contained"
        className="green-btn"
        onClick={() => navigateToJofemarSupportScreen()}
      >Stap 2 - Vending machine
      </Button>;
    }
    else {
      return null;
    }
  }

  return (
    StoreId && Blocks.length ?
      <div id="ReplenishmentScreen" className={classes.root}>
        {StoreId && Blocks.length ?
          <>
            <h1>Support (Winkel : {StoreTitle})</h1>
            <div className="replenishment-actions flex-center-align">
              <Select
                id={"selectType"}
                name={"selectType"}
                onChange={handleChange}
                // onBlur={handleBlur}
                value={SelectType}
                variant="outlined"
              >
                <MenuItem value={0}>Actie</MenuItem>
                <MenuItem value={1}>Locker(s) deactiveren voor verkoop</MenuItem>
                <MenuItem value={2}>Locker(s) openen en deactiveren voor verkoop</MenuItem>
              </Select>

              <Button
                id={"openProductPanel"}
                type="button"
                disabled={false}
                variant="contained"
                className="green-btn"
                onClick={() => handleSelectAction()}
              >Toepassen
              </Button>

              <div className="flex-reverse" style={{ flex: 1 }}>
                <div className="flex ai-center">
                  <span>Locker weergave: </span>
                  <ButtonGroup className="ml-1" variant="contained" aria-label="contained primary button group">
                    <Button
                      className={LockersView === "Back" ? "green-btn" : ""}
                      onClick={() => handleLockersViewChange("Back")}
                    >Achterkant</Button>
                    <Button
                      className={LockersView === "Front" ? "green-btn" : ""}
                      onClick={() => handleLockersViewChange("Front")}
                    >Voorkant</Button>
                  </ButtonGroup>
                </div>
              </div>

            </div>
          </>
          : null}
        {Blocks ?
          <div id="blocksContainer" className="blocks-container flex"
            style={{
              // width: Blocks.length * 245 + 'px',
              maxWidth: ShowPanel ? "70vw" : "90vw",
              flexDirection: LockersView === "Back" ? 'row-reverse' : 'unset'
            }}
          >
            {Blocks.map((block, index) => {
              return (
                <div key={index} className={determineBlockClass(block.Code)}>
                  <div className="block-header flex">
                    <Checkbox
                      checked={BlocksSelected.indexOf(index + 1) > -1}
                      onChange={e => handleCheckboxChange(e, "block", block.Lockers, index + 1)}
                      inputProps={{ 'aria-label': 'checkbox' }}
                    />
                    <p>Blok #{index + 1}{" "}({block.Code.split('-')[0]})</p>
                  </div>

                  <div className="lockers-container flex" style={{ maxHeight: determineBlockHeight(block.Code) }}>

                    {block.Lockers.map((locker, lockerIndex) => {
                      return (
                        <div key={locker.Id} id={`locker${locker.Id}`}
                          onClick={e => handleCheckboxChange(e, "locker", locker.Id)}
                          className={`locker flex ${locker.Enabled ? '' : 'disabled-locker'} ${locker.ProductId ?
                            getProductStatus(locker) : "empty-locker"} lblock${index + 1}`}
                          style={{ order: LockersView === "Back" ? block.Lockers.length >= 12 && lockerIndex >= block.Lockers.length / 2 ? -1 : 0 : 'unset' }}
                        >
                          <Checkbox
                            className={`checkbox${locker.Id}`}
                            checked={LockersSelected.indexOf(locker.Id) > -1}
                            // onChange={e => handleCheckboxChange( "locker", locker.Id)}
                            inputProps={{ 'aria-label': 'checkbox' }}
                          />
                          <div className={`product-avatar-container ${block.Code.split('-')[0][0]}`}>
                            {locker.ProductId && UserConfigData.Customer ?
                              <img id={`img${locker.Id}p${locker.ProductId}`}
                                className={`p${locker.ProductId}`}
                                src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${UserConfigData.Customer}/images/products/pid${locker.ProductId}_${Products.find(prod => prod.Id === locker.ProductId).Avatar}`}
                                style={{ opacity: !locker.Enabled ? 0.3 : 1 }}
                                alt="productImg" />
                              : null
                            }
                            {
                              locker.ProductId && locker.Enabled &&
                                getProductStatus(locker) === "green-locker" ?
                                <MdCheckCircle color={"#689f38"} size={20} />
                                : null
                            }
                          </div>
                          {locker.ProductId &&
                            (getProductStatus(locker) === "sold-out-locker" || getProductStatus(locker) === "almost-expired-locker") ?
                            <div className={"locker-shape"} /> : null}
                          <p className="product-code">{locker.ProductId ?
                            Products.find(p => p.Id === locker.ProductId).Code : "Code"}</p>
                        </div>
                      )
                    })}

                  </div>

                </div>
              )
            })
            }
          </div>
          : null}

        {Blocks.length ?
          <div className="replenishment-bottom flex jcsb">
            <div className="legend flex">
              <div className="ml1 flex">
                <div className="green-locker">
                  <MdCheckCircle color={"#689f38"} size={20} />
                </div>
                <span>In verkoop</span>
              </div>
              <div className="ml1 flex">
                <div className="almost-expired-locker">
                  <div className="locker-shape" />
                </div>
                <span>Uiterste verkoopdatum</span>
              </div>
              <div className="ml1 flex">
                <div className="sold-out-locker">
                  <div className="locker-shape" />
                </div>
                <span>Verkocht</span>
              </div>
            </div>
            <div>
              {JofemarButton()}
            </div>
          </div>
          : null}

      </div>
      : <LoadingModal />)
}
export default StoreSupportScreen