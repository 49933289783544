import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Sidebar from '../components/Sidebar';
import TableContainer from '../components/RoutesContainer';

const useStyles = makeStyles(theme => ({

  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    height: "100%",

    '& .content': {
      flex: "1 1 auto",
      display: "flex",
      flexFlow: "row"
    },
    '& #tableContainer': {
      padding: "5px 20px"
    }
  },
}));

function TabletLayout() {
  const classes = useStyles();

  return (
    <div id="tabletLayout" className={classes.root}>
      <div className="row content">
        <Sidebar />
        <TableContainer />
      </div>
    </div>
  )
}

export default TabletLayout
