import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import useUIState from '../../hooks/useUIstate';
import { MdArrowBack } from "react-icons/md";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { MdRemove, MdAdd } from 'react-icons/md';
import useDataState from '../../hooks/useDataState';
import ProductPanel from '../../components/ProductPanel';
import awsconfig from '../../aws-apiSettings';
import JofemarSelectionPanel from '../../components/JofemarSelectionPanel';
import { getInt } from '../../helpers/commonFunctions';
import { updateJofemarReqBody } from './StoreReplenishmentController';
import { API } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  root: {
    '& h1, & h2': {
      marginTop: '5px',
      marginBottom: '5px'
    },
    '& #jofemarHeader': {
      background: '#eee',
      padding: "5px"
    },
    '& #jofemarHeader>*': {
      color: '#777',
      margin: 0
    },
    '& #jofemarContents': {
      background: '#white',
    },
    '& #jofemarBody': {
      maxHeight: "500px",
      overflowY: "scroll"
    },
    '& .jofemar-row': {
      padding: '5px',
      background: "#eaeaea"
    },
    '& .jofemar-columns': {
      background: "#eee"
    },
    '& .jofemar-row-header, & .jofemar-column-body': {
      background: "#fff",
      borderBottom: "1px solid #ddd"
    },
    '& .jofemar-column-body.selected': {
      background: "#e8f5e9",
    },
    '& .header-element': {
      padding: '5px',
    },
    '& .body-element': {
      padding: '5px',
      alignItems: "center",
      display: "flex",
      fontWeight: 700
    },
    '& .product-image-container': {
      minWidth: '65px',
      height: '65px',
      background: "white",
      marginRight: "5px",
      border: "1px solid #ddd"
    },
    '& .product-image-container img': {
      width: '63px',
      height: '63px',
      objectFit: "fill"
    },
    '& .body-element.update-stock .MuiTextField-root': {
      width: "50px",
      margin: "0 5px",
      background: "white",
    },
    '& .body-element.update-stock .MuiButtonBase-root': {
      padding: "5px",
      minWidth: "40px"
    },
    '& .product-img': { width: "170px" },
    '& .current-stock': { width: "150px" },
    '& .max-capacity': { width: "80px" },
    '& .latest-refill': { width: "170px" },
    '& .is-enabled': { width: "100px" },
    '& .update-stock': { width: "180px" },
    '& .button-row': { width: "200px" },
    '& .replenishment-bottom': {
      marginTop: "20px",
      maxWidth: "90vw",
    }
  }
}));

function StoreReplenishmentJofemarScreen(props) {
  const history = useHistory();
  const classes = useStyles();

  const { ProductsContext, ProductCategoriesContext, JofemarContext } = useDataState();
  const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();
  const [Jofemar, setJofemar] = useState({ Attributes: "" });

  const [Jofemars, setJofemars]: [any, any] = useState([]);
  const [JofemarTitle, setJofemarTitle] = useState("");
  const [ActiveJofemarId, setActiveJofemarId] = useState(-1);
  const [JofemarInfo, setJofemarInfo]: [any, any] = useState([]);
  const [IsDirtyCurrentJofemarContents, setIsDirtyCurrentJofemarContents] = useState(false); // true: allow save, false: allow jofemar navigation
  const [ButtonSaveText, setButtonSaveText] = useState("Sluiten");

  const [StoreTitle, setStoreTitle] = useState("");
  const [JofemarContents, setJofemarContents]: [any, any] = useState([]);
  const [Values, setValues]: [any, any] = useState([]);
  const [ShowPanel, setShowPanel] = useState(false);
  const [ActiveRow, setActiveRow] = useState(0);
  const [ActiveColumn, setActiveColumn] = useState(0);
  const [Products, setProducts]: [any, any] = useState([]);
  const [UserConfigData, setUserConfigData]: [any, any] = useState([]);

  useEffect(() => {
    const value = localStorage.getItem("UserConfigData");
    if (typeof value === 'string') {
      setUserConfigData(JSON.parse(value));
    }

    console.log(props);

    if (props.location.Jofemar) {
      // in which scenario is props.location.Jofemar not set?
      setStoreTitle(props.location.title);
      setJofemar(props.location.Jofemar);
      setActiveJofemarId(props.location.Jofemar.Id)
      setJofemars(props.location.Jofemars);
      setIsDirtyCurrentJofemarContents(props.location.IsDirtyCurrentJofemarContents);
      setProducts(props.location.products);
    } else if (JofemarContext && Object.entries(JofemarContext).length) {
      // when is this scenario applicable?
      setJofemar(JofemarContext);
      setProducts(ProductsContext);
    } else if (localStorage.getItem("Jofemar")) {
      // when is this scenario applicable?
      const JofemarLS = localStorage.getItem("Jofemar")
      if (JofemarLS) {
        setJofemar(JSON.parse(JofemarLS));
      }
      const Products = localStorage.getItem("Products")
      if (Products) {
        setProducts(JSON.parse(Products));
      }
    } else {
      history.push('/retail-locations');
    }
  }, []) // useEffect will only evoked once per render

  useEffect(() => {
    if (ActiveJofemarId !== -1) {
      localStorage.setItem("ActiveJofemarId", String(ActiveJofemarId));
    }
  }, [ActiveJofemarId])

  useEffect(() => {
    localStorage.setItem("IsDirtyCurrentJofemarContents", String(IsDirtyCurrentJofemarContents));
    if (IsDirtyCurrentJofemarContents) {
      setButtonSaveText("Opslaan en sluiten")
    } else {
      setButtonSaveText("Sluiten")
    }
  }, [IsDirtyCurrentJofemarContents])

  useEffect(() => {

    if (Jofemars) {
      const jofemarInfo: any[] = [];
      const JofemarsLS = localStorage.getItem("Jofemars")

      if (typeof JofemarsLS === 'string') {
        // create array for buttons in jofemar control panel
        var jofemarList = JSON.parse(JofemarsLS);
        jofemarList.forEach(item => {
          jofemarInfo.push({ id: item.Id, name: item.Code });
        });
      }
      setJofemarInfo(jofemarInfo);
    }

    if (Jofemar) {
      let attrStr = "";
      attrStr = Jofemar.Attributes;

      let attr = attrStr.split('|');
      const attributes: any[] = [];

      attr.forEach((el, index) => {
        let element = el;

        if (element) {
          attributes.push(JSON.parse(element.replace(/'/g, '"')));
        }
      });

      const values: any = [];
      if (attributes.length > 0) {
        setJofemarTitle(attributes[0].vendingMachineLogicalName);
      }

      attributes.forEach(row => {
        const Columns: any = [];
        row.Columns.forEach(col => {
          Columns.push({ UpdatedStock: col.CurrentStock })
        });
        values.push({ Columns })
      });

      // updated from ls (persist from navigation)
      if (localStorage.getItem("CurrentJofemarContents") && values.length && attributes.length) {
        const str = localStorage.getItem("CurrentJofemarContents") || "";
        const currJofemarContents = JSON.parse(str);
        currJofemarContents.forEach((row, rowIndex) => {
          row.Columns.forEach((col, colIndex) => {
            values[rowIndex].Columns[colIndex].UpdatedStock = col.UpdatedStock;
            attributes[rowIndex].Columns[colIndex].ProductId = col.ProductId;
            attributes[rowIndex].Columns[colIndex].LatestRefill = col.LatestRefill;
            attributes[rowIndex].Columns[colIndex].CurrentStock = col.CurrentStock;
            attributes[rowIndex].Columns[colIndex].Enabled = col.Enabled;
          });
        });
      }

      setValues(values);
      setJofemarContents(attributes);
    }

  }, [Jofemar, Jofemars]) // end useEffect

  function handleChange(e, row, column, action = "", value = 0) {
    setIsDirtyCurrentJofemarContents(true)
    const values = [...Values];
    const jofemarContents: any[] = [...JofemarContents];
    const newDateTime = formatDate(new Date())

    if (!action) {
      if (e.target.value === '') {
        values[row].Columns[column].UpdatedStock = e.target.value;
      } else {
        values[row].Columns[column].UpdatedStock = getInt(e.target.value, 0, 0);
      }
    } else if (action === "decrement") {
      if (value > 0) {
        jofemarContents[row].Columns[column].LatestRefill = newDateTime;
        jofemarContents[row].Columns[column].Enabled = value > 1 ? true : false;
        values[row].Columns[column].UpdatedStock = value - 1;
      }
    } else if (action === "increment") {
      if (value < JofemarContents[row].nrDepth) {
        values[row].Columns[column].UpdatedStock = value + 1;
        jofemarContents[row].Columns[column].Enabled = true;
        jofemarContents[row].Columns[column].LatestRefill = newDateTime;
      }
    }
    setValues(values);
    setJofemarContents(jofemarContents);

    // update ls
    const currJofemarContents: any[] = [...jofemarContents];
    values.forEach((r, rowIndex) => {
      r.Columns.forEach((col, colIndex) => {
        currJofemarContents[rowIndex].Columns[colIndex].CurrentStock = getInt(currJofemarContents[rowIndex].Columns[colIndex].CurrentStock, 0, 0);
        currJofemarContents[rowIndex].Columns[colIndex].UpdatedStock = getInt(col.UpdatedStock, 0, 0);
      });
    });
    localStorage.setItem("CurrentJofemarContents", JSON.stringify(currJofemarContents));

  }

  function switchProducts(_productId) {
    setIsDirtyCurrentJofemarContents(true);
    let productId = _productId[0];
    const jofemarContents = JSON.parse(JSON.stringify(JofemarContents));
    const newDateTime = formatDate(new Date())
    jofemarContents[ActiveRow].Columns[ActiveColumn].LatestRefill = newDateTime;
    jofemarContents[ActiveRow].Columns[ActiveColumn].ProductId = productId;
    jofemarContents[ActiveRow].Columns[ActiveColumn].CurrentStock = 0;
    jofemarContents[ActiveRow].Columns[ActiveColumn].Enabled = true;

    setJofemarContents(jofemarContents);

    // update ls
    const currJofemarContents: any[] = [...jofemarContents];
    currJofemarContents.forEach((r, rowIndex) => {
      r.Columns.forEach((col, colIndex) => {
        col.UpdatedStock = Values[rowIndex].Columns[colIndex].UpdatedStock;
      });
    });
    localStorage.setItem("CurrentJofemarContents", JSON.stringify(currJofemarContents));
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = ("0" + d.getHours()).slice(-2),
      minutes = ("0" + d.getMinutes()).slice(-2),
      seconds = ("0" + d.getSeconds()).slice(-2);

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-') + `T${hours}:${minutes}:${seconds}`;
  }


  function getDisplayDateTime(datetime) {
    console.log(datetime)
    let date = datetime.split("T")[0];
    let time = datetime.split("T")[1];

    date = date.split("-");
    date = `${date[2]}/${date[1]}/${date[0]}`;

    time = time.split(":");
    time = `${time[0]}:${time[1]}`;

    return `${date} ${time}`;
  }

  function openProductPanel(row, column) {
    setActiveRow(row);
    setActiveColumn(column);
    setShowPanel(true);
  }

  function saveStoreReplenishmentJofemar(closeDialog) {
    if (closeDialog && !IsDirtyCurrentJofemarContents) {
      console.log('alleen sluiten')
      history.push('/retail-locations');
    }
    const jofemarContents = [...JofemarContents];

    console.log("Jofemar", Jofemar);
    console.log("jofemarContents", jofemarContents);
    console.log("Values", Values);

    jofemarContents.forEach((row, rowIndex) => {
      row.Columns.forEach((col, colIndex) => {
        if (col.CurrentStock !== Values[rowIndex].Columns[colIndex].UpdatedStock) {
          col.CurrentStock = Values[rowIndex].Columns[colIndex].UpdatedStock;
        }
      });
    });
    console.log(jofemarContents)

    UpdateJofemarArray();
    const reqBody = updateJofemarReqBody(Jofemar, jofemarContents);
    console.log(reqBody)

    const body = reqBody;
    setShowLoadingModal(true)
    API.put('AE', `/Blocks`, { body })
      .then(res => {
        console.log(res);
        // save changes to CurrentJofemarContents
        localStorage.setItem("CurrentJofemarContents", JSON.stringify(jofemarContents));
        setIsDirtyCurrentJofemarContents(false);
        setShowLoadingModal(true)
        if (closeDialog) {
          history.push('/retail-locations');
        }
      })
      .catch(error => {
        console.log(error);
        setToastType("error");
        setToastMessage("Error updating Jofemar");
        setShowToastMessage(true);
      })
      .finally(() => {
        setShowLoadingModal(false);
      })
  }

  const handleJofemarOnClick = (jofemarItem) => {
    loadJofemarById(jofemarItem.id)
  };

  handleJofemarOnClick.disabled = !{ IsDirtyCurrentJofemarContents }
  handleJofemarOnClick.jofemarInfo = JofemarInfo;

  function loadJofemarById(id) {
    console.log('loading jofemar with id: ' + id)

    // get from storage
    const jofemars = localStorage.getItem("Jofemars")

    if (jofemars) {
      localStorage.removeItem("CurrentJofemarContents")
      const jofemarsJson = JSON.parse(jofemars)
      console.log('number of jofemars found: ' + jofemarsJson.length)
      const jofemar = jofemarsJson.find(block => block.Id === id)

      let attrStr = "";
      attrStr = jofemar.Attributes;

      let attr = attrStr.split('|');
      const attributes: any[] = [];

      attr.forEach((el, index) => {
        let element = el;

        if (element) {
          attributes.push(JSON.parse(element.replace(/'/g, '"')));
        }
      });
      localStorage.setItem("CurrentJofemarContents", JSON.stringify(attributes));

      setJofemar(jofemar);
      setActiveJofemarId(id);
    }
  }

  function saveActiveJofemar() {
    console.log('SaveActiveJofemar()' + ActiveJofemarId);
    saveStoreReplenishmentJofemar(false)
  }

  function GoBack() {
    // update array in storage
    UpdateJofemarArray()
    history.goBack()
  }

  function UpdateJofemarArray() {
    // update Jofemars array
    let index = 0;
    for (let i = 0; i < Jofemars.length; i++) {
      if (Jofemars[i].Id === ActiveJofemarId) {
        index = i;
        break;
      }
    }

    const jofemarContents = [...JofemarContents];
    const jofemars = Jofemars
    const reqBody = updateJofemarReqBody(jofemars[index], jofemarContents);
    jofemars[index] = reqBody;
    localStorage.setItem("Jofemars", JSON.stringify(jofemars))
    setJofemars(jofemars)
  }

  return (
    <div id="replenishmentJofemar" className={classes.root}>
      <div className="replenishment-header flex-center-align">
        <div style={{ cursor: "pointer" }}>
          <MdArrowBack size="30" onClick={() => history.goBack()} />
        </div>
        <h1 id="storeTitle" className="ml1">{StoreTitle}</h1>
        <h2 className="ml1">Vullen - Stap 2 Vending Machine</h2>
      </div>
      {JofemarContents ?
        <div id="jofemarContents">
          <div id="jofemarHeader">
            <h4>Jofemar {JofemarTitle}</h4>
          </div>
          <JofemarSelectionPanel activeId={ActiveJofemarId} disabled={IsDirtyCurrentJofemarContents} jofemarInfo={JofemarInfo} passedFunction={handleJofemarOnClick}></JofemarSelectionPanel>
          <div id="jofemarBody">
            {JofemarContents.map((entry, index) => {
              return (
                <div key={index} className="jofemar-row">
                  <div className="jofemar-row-header flex">
                    <div className="header-element product-img">Rij #{index + 1}</div>
                    <div className="header-element current-stock">Huidige voorraad</div>
                    <div className="header-element max-capacity">Max</div>
                    <div className="header-element latest-refill">Laatst bijgevuld op</div>
                    <div className="header-element is-enabled">Actief</div>
                    <div className="header-element update-stock">Werk voorraad bij tot</div>
                    <div className="header-element button-row" />
                  </div>

                  {entry.Columns.map((col, colIndex) => {
                    return (
                      <div key={`${index}${colIndex}`} id={`r${index}col${colIndex}`} className={`jofemar-column-body flex ${ActiveRow === index && ActiveColumn === colIndex && ShowPanel ? "selected" : ""}`}>
                        <div className="body-element product-img">
                          <div className="product-image-container">
                            {col.ProductId && UserConfigData.Customer ?
                              <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${UserConfigData.Customer}/images/products/pid${col.ProductId}_${Products.find(prod => prod.Id === col.ProductId).Avatar}`} alt="prod-img" />
                              : null
                            }
                          </div>
                          <div>{col.ProductId ? Products.find(p => p.Id === col.ProductId).Code : "Geen product"}</div>
                        </div>
                        <div className="body-element current-stock">
                          {col.CurrentStock}
                        </div>
                        <div className="body-element max-capacity">
                          {entry.nrDepth}
                        </div>
                        <div className="body-element latest-refill">
                          {col.LatestRefill ? getDisplayDateTime(col.LatestRefill) : null}
                        </div>                                                
                        <div className="body-element is-enabled">
                          {col.Enabled ? "Ja" : "Nee"}
                        </div>
                        <div className="body-element update-stock">
                          <Button variant="contained" className="gray-btn decrement-stock"
                            onClick={(e) => handleChange(e, index, colIndex, "decrement", Values[index].Columns[colIndex].UpdatedStock)}
                          >
                            <MdRemove size={22} />
                          </Button>
                          <TextField
                            className="UpdatedStockValue"
                            type="text"
                            onChange={e => handleChange(e, index, colIndex)}
                            value={Values[index].Columns[colIndex].UpdatedStock}
                            variant="outlined"
                            size="small"
                          />
                          <Button variant="contained" className="gray-btn increment-stock"
                            onClick={(e) => handleChange(e, index, colIndex, "increment", Values[index].Columns[colIndex].UpdatedStock)}
                          >
                            <MdAdd size={22} />
                          </Button>
                        </div>
                        <div className="body-element button-row">
                          <Button
                            variant="contained"
                            className="gray-btn"
                            onClick={() => openProductPanel(index, colIndex)}
                          >
                            Product wisselen
                          </Button>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>

          <div className="replenishment-bottom flex jcsb">

            <div>
              <Button
                type="button"
                variant="contained"
                className="gray-btn"
                onClick={() => GoBack()}
              >Stap 1 - Lockers
              </Button>
            </div>
            <div>
              <Button
                type="button"
                disabled={!IsDirtyCurrentJofemarContents}
                variant="contained"
                className="green-btn"
                onClick={() => saveActiveJofemar()}
              >Opslaan
              </Button>
              &nbsp;
              <Button
                id="goToDistributionPlan"
                type="button"
                variant="contained"
                className="green-btn"
                onClick={() => saveStoreReplenishmentJofemar(true)}
              >{ButtonSaveText}
              </Button>
            </div>
          </div>
        </div>
        : null}

      {ProductCategoriesContext && ProductsContext ?
        <ProductPanel
          productCategories={ProductCategoriesContext}
          products={ProductsContext}
          lockersSelected={[]}
          switchProducts={switchProducts}
          isJofemar={true}
          showPanel={ShowPanel}
          closePanel={() => setShowPanel(false)}
        />
        : null}
    </div>
  )
}

export default StoreReplenishmentJofemarScreen
