import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { compare, convertToSlashDate, datediff, formatDate, formatDateAndTIme, formatDisplayDate, formatExcelDate, getDetailedDate, IsInDateRange, IsInYearRange } from '../../helpers/commonFunctions';
import ReactExport from "react-export-excel";
import LineChart from '../../components/LineChart';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(theme => ({

  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    height: "100%",
    paddingLeft: "10px",

    '& .title': {
      padding: "0 10px 0 5px"
    },
    '& .filters-range': {
      marginTop: "10px",
      marginBottom: "10px"
    },
    '& .filters-range>*': {
      margin: "0 5px"
    },
    '& .range-label': {
      cursor: "pointer",
      fontWeight: 700
    },
    '& .range-label.active': {
      color: "#1976d2"
    },
    '& .total-transactions': {
      fontWeight: 700,
      position: "absolute",
      bottom: 0,
      left: 20
    },
  },
}));

function TransactionsPerProductChart(props) {

  const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni",
    "Juli", "Augustus", "September", "Oktober", "November", "December"
  ];

  const classes = useStyles();
  const weekDates = [
    formatDate(new Date().setDate(new Date().getDate() - 1)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 2)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 3)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 4)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 5)).split("T")[0],
    formatDate(new Date().setDate(new Date().getDate() - 6)).split("T")[0],
  ];

  const [TransactionData, setTransactionData]: [any, any] = useState([])
  const [Products, setProducts]: [any, any] = useState([]);
  const [DataSetDay, setDataSetDay]: [any, any] = useState([]);
  const [DataSetWeek, setDataSetWeek]: [any, any] = useState([]);
  const [DataSetMonth, setDataSetMonth]: [any, any] = useState([]);
  const [DataSetYear, setDataSetYear]: [any, any] = useState([]);
  const [DataSetActive, setDataSetActive]: [any, any] = useState([]);
  const [LabelsActive, setLabelsActive]: [any, any] = useState([]);
  const [RevenueRangeActive, setRevenueRangeActive] = useState("Day");
  const [DatasetList, setDatasetList]: [any, any] = useState([]);
  const [DayTotal, setDayTotal]: [any, any] = useState(0);
  const [WeekTotal, setWeekTotal]: [any, any] = useState(0);
  const [MonthTotal, setMonthTotal]: [any, any] = useState(0);
  const [YearTotal, setYearTotal]: [any, any] = useState(0);
  const [ExcelTabDay, setExcelTabDay]: [any, any] = useState([])
  const [ExcelTabWeek, setExcelTabWeek]: [any, any] = useState([])
  const [ExcelTabMonth, setExcelTabMonth]: [any, any] = useState([])
  const [ExcelTabYear, setExcelTabYear]: [any, any] = useState([])
  const [FirstDayOfMonth, setFirstDayOfMonth] = useState("");
  const [YearPeriodStart, setYearPeriodStart] = useState("");
  const [EmptyDatasets, setEmptyDatasets] = useState<any[]>([]);

  const [LabelsDay, setLabelsDay]: [any, any] = useState([]);
  const [LabelsWeek, setLabelsWeek]: [any, any] = useState([]);
  const [LabelsMonth, setLabelsMonth]: [any, any] = useState([]);
  const [LabelsYear, setLabelsYear]: [any, any] = useState([]);

  const [DataSetDateRange, setDataSetDateRange]: [any, any] = useState([]);
  const [ExcelTabDateRange, setExcelTabDateRange]: [any, any] = useState([]);
  const [LabelsDateRange, setLabelsDateRange]: [any, any] = useState([]);
  const [FromDate, setFromDate] = useState<any>(null);
  const [UntilDate, setUntilDate] = useState<any>(null);
  const [DateRangeTotal, setDateRangeTotal] = useState<number>(0);

  useEffect(() => {
    const transactions = JSON.parse(JSON.stringify(props.transactionData))
    setTransactionData(transactions);
    setProducts(props.products);
  }, []);

  useEffect(() => {
    if (Products.length && TransactionData.length) {
      const [colors, borderColors] = setProductLabelsandColors();
      const emptyDataSets: any = [];

      Products.forEach((product, index) => {
        emptyDataSets.push({
          label: product.Title,
          data: [],
          borderColor: borderColors[index],
          backgroundColor: colors[index],
          fill: "false",
          id: product.Id
        });
      })
      setEmptyDatasets(emptyDataSets);
    }
  }, [Products])

  useEffect(() => {
    if (EmptyDatasets.length) {
      if (!DataSetDay.length) createDayData();
      if (!DataSetWeek.length) createWeekData();
      if (!DataSetMonth.length) createMonthData();
      if (!DataSetYear.length) createYearData();
    }
  }, [EmptyDatasets])

  useEffect(() => {
    if (DataSetActive.length) {
      setDatasetList(DataSetActive);
    } else {
      setDatasetList([])
    }
  }, [DataSetActive])

  useEffect(() => {
    if (RevenueRangeActive) {
      switch (RevenueRangeActive) {
        case 'Day':
          setDataSetActive(DataSetDay);
          setLabelsActive(LabelsDay);
          break;
        case 'Week':
          setDataSetActive(DataSetWeek);
          setLabelsActive(LabelsWeek);
          break;
        case 'Month':
          setDataSetActive(DataSetMonth);
          setLabelsActive(LabelsMonth);
          break;
        case 'Year':
          setDataSetActive(DataSetYear);
          setLabelsActive(LabelsYear);
          break;
        default:
          break;
      }

      if (RevenueRangeActive === "DateRange") {
        if (FromDate && UntilDate) {
          createDateRangeData(FromDate, UntilDate);
        } else {
          setDatasetList([]);
          setLabelsActive([]);
        }
      }
    }
  }, [RevenueRangeActive])

  useEffect(() => {
    if (RevenueRangeActive === "DateRange" && FromDate && UntilDate) {
      createDateRangeData(FromDate, UntilDate);
    }
    // eslint-disable-next-line
  }, [FromDate, UntilDate]);

  function createDateRangeData(fromDate, untilDate) {
    const emptyDataSets = JSON.parse(JSON.stringify(EmptyDatasets));

    let _dateFrom = new Date(fromDate);
    let dateFromTime = _dateFrom.setHours(0, 0, 0, 0);
    let _dateUntil = new Date(untilDate);
    let dateUntilTime = _dateUntil.setHours(23, 59, 59, 999);

    const daysDiff = datediff(_dateFrom, _dateUntil);
    const transactionData = [...TransactionData];

    const TransactionsDateRange =
      transactionData.filter(t => IsInDateRange(dateUntilTime, dateFromTime, t.TransactionDate))

    let datasetsWithTransactions: any[] = [];
    let labels: any[] = [];
    let labelsDetailed: any[] = [];
    let totalTransactions = 0;

    if (daysDiff <= 1) {
      const [_datasetsWithTransactions, _labels, _totalTransactions]: any = createLineChartDatasets(TransactionsDateRange, emptyDataSets, "Day");
      totalTransactions = _totalTransactions;
      labels = _labels.map(l => l.split('T')[1]);
      datasetsWithTransactions = [..._datasetsWithTransactions];
      setLabelsDateRange(labels);
      setLabelsActive(labels);
      setDataSetActive(_datasetsWithTransactions);
    }
    else if (daysDiff > 1 && daysDiff < 32) {
      const [_datasetsWithTransactions, _labels, _totalTransactions]: any = createLineChartDatasets(TransactionsDateRange, emptyDataSets, "Week");
      totalTransactions = _totalTransactions;
      labelsDetailed = _labels.map(l => getDetailedDate(l));
      datasetsWithTransactions = [..._datasetsWithTransactions];
      setLabelsActive(labelsDetailed);
      setLabelsDateRange(labelsDetailed);
      setDataSetActive(_datasetsWithTransactions);
    }
    else if (daysDiff >= 32) {
      const [_datasetsWithTransactions, _labels, _totalTransactions]: any = createLineChartDatasets(TransactionsDateRange, emptyDataSets, "Year");
      totalTransactions = _totalTransactions;
      datasetsWithTransactions = [..._datasetsWithTransactions];
      setLabelsActive(_labels);
      setLabelsDateRange(_labels);
      setDataSetActive(_datasetsWithTransactions);
    }

    // create day excel dataSets
    const excelData: any[] = [];
    datasetsWithTransactions.forEach((dataSet, index) => {
      const excelDateObj: any = {};
      dataSet.data.forEach((transaction, trIndex) => {
        const attr = "transaction" + trIndex
        excelDateObj[attr] = transaction
      });
      if (index === 0) {
        excelDateObj.dateRange = `${formatDisplayDate(new Date(fromDate))} - ${formatDisplayDate(new Date(untilDate))}`
      }
      excelDateObj.product = dataSet.label;
      excelDateObj.transactionsTotal = dataSet.transactionsTotal;
      excelData.push(excelDateObj);
    });

    setExcelTabDateRange(excelData);
    setDateRangeTotal(totalTransactions);
  }

  function setProductLabelsandColors() {
    const colors: any[] = [];
    const borderColors: any[] = [];

    Products.forEach(product => {
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);

      colors.push(`rgba(${r},${g},${b},0.2)`);
      borderColors.push(`rgba(${r},${g},${b},1)`);
    });
    return [colors, borderColors];
  }

  function createLineChartDatasets(transactions, emptyDataSets, range) {
    const labels: any = [];
    let totalTransactions = 0;
    const datasetsWithTransactions: any = [];

    if (range === "Day") {

      transactions.forEach((transaction, index) => {
        labels.push(transaction.TransactionDate);
        const idsToChange: any[] = [];
        totalTransactions++;

        transaction.ProductsSold.forEach(prod => {
          idsToChange.push({ Id: prod.ProductId, Units: prod.Units });
        });

        emptyDataSets.forEach(ds => { ds.data[index] = 0; });

        idsToChange.forEach(itc => {
          const datasetToUpdate = emptyDataSets.find(ds => ds.id === itc.Id);
          if (datasetToUpdate) {
            const _index = emptyDataSets.findIndex(ds => ds.id === itc.Id);
            emptyDataSets[_index].data[index] = 1;
          }
        });
      });
      labels.sort(compare);

      emptyDataSets.forEach(ds => {
        let isEmpty = true;
        let transactionsTotal = 0;
        ds.data.forEach(entry => {
          transactionsTotal = transactionsTotal + entry;
          if (entry > 0) {
            isEmpty = false;
          }
        });
        if (!isEmpty) {
          ds.transactionsTotal = transactionsTotal;
          datasetsWithTransactions.push(ds);
        }
      });
      datasetsWithTransactions.sort((a, b) => (a.transactionsTotal < b.transactionsTotal) ? 1 : -1)
      return [datasetsWithTransactions, labels, totalTransactions];

    } else if (range === 'Week' || range === 'Month') {

      const transactionsGroupedDaily: any[] = [];
      transactions.forEach((tr, index) => {
        const trDate = tr.TransactionDate.split('T')[0];
        const transactionToUpdate = transactionsGroupedDaily.find(tgd => tgd.TransactionDate === trDate);
        totalTransactions++;

        if (!transactionsGroupedDaily.length || !transactionToUpdate) { // just push it
          const newTr = JSON.parse(JSON.stringify(tr));
          newTr.TransactionDate = trDate;

          newTr.ProductsSold.forEach(p => {
            p.Transactions = 1
          });

          transactionsGroupedDaily.push(newTr);
        } else { // combine products sold
          tr.ProductsSold.forEach(prod => {
            prod.Transactions = 1;
            if (transactionToUpdate.ProductsSold.find(trp => trp.ProductId === prod.ProductId)) {
              const indexToUpdate = transactionToUpdate.ProductsSold.findIndex(trp => trp.ProductId === prod.ProductId);
              transactionToUpdate.ProductsSold[indexToUpdate].Units = transactionToUpdate.ProductsSold[indexToUpdate].Units + prod.Units;
              transactionToUpdate.ProductsSold[indexToUpdate].Transactions =
                transactionToUpdate.ProductsSold[indexToUpdate].Transactions + prod.Transactions;
            } else {
              transactionToUpdate.ProductsSold.push(prod);
            }
          });
        }
      });

      transactionsGroupedDaily.forEach((transaction, index) => {
        labels.push(transaction.TransactionDate);
        const idsToChange: any[] = [];

        transaction.ProductsSold.forEach(prod => {
          idsToChange.push({ Id: prod.ProductId, Units: prod.Units, Transactions: prod.Transactions });
        });

        emptyDataSets.forEach(ds => { ds.data[index] = 0; });

        idsToChange.forEach(itc => {
          const datasetToUpdate = emptyDataSets.find(ds => ds.id === itc.Id);
          if (datasetToUpdate) {
            const _index = emptyDataSets.findIndex(ds => ds.id === itc.Id);
            emptyDataSets[_index].data[index] = emptyDataSets[_index].data[index] + itc.Transactions;
          }
        });
      });
      labels.sort(compare);

      emptyDataSets.forEach(ds => {
        let isEmpty = true
        let transactionsTotal = 0;
        ds.data.forEach(entry => {
          transactionsTotal = transactionsTotal + entry;
          if (entry > 0) {
            isEmpty = false;
          }
        });
        if (!isEmpty) {
          ds.transactionsTotal = transactionsTotal;
          datasetsWithTransactions.push(ds);
        }
      });

      datasetsWithTransactions.sort((a, b) => (a.transactionsTotal < b.transactionsTotal) ? 1 : -1);

      return [datasetsWithTransactions, labels, totalTransactions];
    } else if (range === 'Year') {

      // group transactions per month
      // and combine products sold
      const transactionsGroupedMonthly: any[] = []
      transactions.forEach(tr => {
        const trDate = tr.TransactionDate.split('T')[0];
        totalTransactions++;
        const year = tr.TransactionDate.split('-')[0];
        const month = tr.TransactionDate.split('-')[1];

        const transactionToUpdate =
          transactionsGroupedMonthly
            .find(tgd => tgd.TransactionDate.split('-')[0] === year && tgd.TransactionDate.split('-')[1] === month);

        if (!transactionsGroupedMonthly.length || !transactionToUpdate) { // just push it
          const newTr = JSON.parse(JSON.stringify(tr));
          newTr.TransactionDate = trDate;
          newTr.ProductsSold.forEach(p => {
            p.Transactions = 1
          });
          transactionsGroupedMonthly.push(newTr);
        } else { // combine products sold

          tr.ProductsSold.forEach(prod => {
            prod.Transactions = 1;
            if (transactionToUpdate.ProductsSold.find(trp => trp.ProductId === prod.ProductId)) {
              const indexToUpdate = transactionToUpdate.ProductsSold.findIndex(trp => trp.ProductId === prod.ProductId);
              transactionToUpdate.ProductsSold[indexToUpdate].Units = transactionToUpdate.ProductsSold[indexToUpdate].Units + prod.Units;
              transactionToUpdate.ProductsSold[indexToUpdate].Transactions =
                transactionToUpdate.ProductsSold[indexToUpdate].Transactions + prod.Transactions;
            } else {
              transactionToUpdate.ProductsSold.push(prod);
            }
          });

        }

      });

      transactionsGroupedMonthly.forEach((transaction, index) => {
        labels.push(transaction.TransactionDate);
        const idsToChange: any[] = [];

        transaction.ProductsSold.forEach(prod => {
          idsToChange.push({ Id: prod.ProductId, Units: prod.Units, Transactions: prod.Transactions });
        });

        emptyDataSets.forEach(ds => { ds.data[index] = 0; });

        idsToChange.forEach(itc => {
          const datasetToUpdate = emptyDataSets.find(ds => ds.id === itc.Id);
          if (datasetToUpdate) {
            const _index = emptyDataSets.findIndex(ds => ds.id === itc.Id);
            emptyDataSets[_index].data[index] = emptyDataSets[_index].data[index] + itc.Transactions;
          }
        });
      });
      labels.sort(compare);

      const yearLabels: any[] = [];
      labels.forEach(label => {
        const year = label.split('-')[0];
        const month = label.split('-')[1];
        yearLabels.push(monthNames[parseInt(month, 10) - 1] + " " + year)
      });

      emptyDataSets.forEach(ds => {
        let isEmpty = true;
        let transactionsTotal = 0;
        ds.data.forEach(entry => {
          transactionsTotal = transactionsTotal + entry;
          if (entry > 0) {
            isEmpty = false;
          }
        });
        if (!isEmpty) {
          ds.transactionsTotal = transactionsTotal;
          datasetsWithTransactions.push(ds);
        }
      });
      datasetsWithTransactions.sort((a, b) => (a.transactionsTotal < b.transactionsTotal) ? 1 : -1)

      return [datasetsWithTransactions, yearLabels, totalTransactions];

    }

  }

  function createDayData() {
    const _TransactionsData = JSON.parse(JSON.stringify(TransactionData))
    const TransactionsToday =
      _TransactionsData.filter(t => isToday(t.TransactionDate));
    const emptyDataSets = JSON.parse(JSON.stringify(EmptyDatasets));

    if (TransactionsToday.length) {

      const [datasetsWithTransactions, labels, totalTransactions]: any =
        createLineChartDatasets(TransactionsToday, emptyDataSets, "Day")

      const _labels = labels.map(d => d.split('T')[1]);

      setLabelsDay(_labels);
      setDataSetDay(datasetsWithTransactions);
      setDayTotal(totalTransactions);

      setDataSetActive(datasetsWithTransactions);
      setLabelsActive(_labels);

      // create day excel dataSets
      const excelDay: any[] = [];
      datasetsWithTransactions.forEach((dataSet, index) => {
        const excelDayObj: any = {};
        dataSet.data.forEach((transaction, trIndex) => {
          const attr = "transaction" + trIndex
          excelDayObj[attr] = transaction
        });
        if (index === 0) {
          excelDayObj.dateRange = formatDisplayDate(new Date())
        }
        excelDayObj.product = dataSet.label;
        excelDayObj.transactionsTotal = dataSet.transactionsTotal;
        excelDay.push(excelDayObj);
      });


      setExcelTabDay(excelDay);

    } else {
      setLabelsDay([]);
      setDataSetDay([]);
      setDayTotal(0);
      setDatasetList([])
    }

  }

  function createWeekData() {
    const emptyDataSets = JSON.parse(JSON.stringify(EmptyDatasets));

    const TransactionsWeek =
      TransactionData.filter(t => weekDates.includes(t.TransactionDate.split("T")[0]) || t.TransactionDate.split("T")[0] === formatDate(new Date()).split("T")[0]);

    if (TransactionsWeek.length) {
      // group transactions per day
      // and combine products sold

      const [datasetsWithTransactions, labels, totalTransactions]: any =
        createLineChartDatasets(TransactionsWeek, emptyDataSets, "Week")

      const chartLabels = labels.map(l => getDetailedDate(l));

      // create week excel dataSets
      const excelWeek: any[] = [];
      datasetsWithTransactions.forEach((dataSet, index) => {
        const excelWeekObj: any = {};
        dataSet.data.forEach((transaction, trIndex) => {
          const attr = "transaction" + trIndex
          excelWeekObj[attr] = transaction
        });
        if (index === 0) {
          excelWeekObj.dateRange =
            `${formatDisplayDate(new Date().setDate(new Date().getDate() - 6))} - ${formatDisplayDate(new Date())}`

        }
        excelWeekObj.product = dataSet.label;
        excelWeekObj.transactionsTotal = dataSet.transactionsTotal;
        excelWeek.push(excelWeekObj);
      });

      setExcelTabWeek(excelWeek);

      setWeekTotal(totalTransactions);
      setLabelsWeek(chartLabels);
      setDataSetWeek(datasetsWithTransactions);
    }
  }

  function createMonthData() {
    const emptyDataSets = JSON.parse(JSON.stringify(EmptyDatasets));

    const TransactionsMonth =
      TransactionData.filter(t => isSameMonth(t.TransactionDate));

    if (TransactionsMonth.length) {
      // group transactions per day
      // and combine products sold

      const [datasetsWithTransactions, labels, totalTransactions]: any =
        createLineChartDatasets(TransactionsMonth, emptyDataSets, "Month");

      let chartLabels: any[] = []
      if (labels.length < 8) {
        chartLabels = labels.map(l => getDetailedDate(l));
      } else {
        chartLabels = labels.map(l => convertToSlashDate(l.split("T")[0]));
      }

      // create month excel dataSets
      const excelMonth: any[] = [];
      datasetsWithTransactions.forEach((dataSet, index) => {
        const excelMonthObj: any = {};
        dataSet.data.forEach((transaction, trIndex) => {
          const attr = "transaction" + trIndex
          excelMonthObj[attr] = transaction
        });
        if (index === 0) {
          const date = new Date();
          const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
          setFirstDayOfMonth(formatExcelDate(firstDay).split("T")[0]);
          excelMonthObj.dateRange = `${formatDisplayDate(firstDay)} - ${formatDisplayDate(new Date())}`
        }
        excelMonthObj.product = dataSet.label;
        excelMonthObj.transactionsTotal = dataSet.transactionsTotal;
        excelMonth.push(excelMonthObj);
      });

      setExcelTabMonth(excelMonth);
      setMonthTotal(totalTransactions);
      setLabelsMonth(chartLabels);
      setDataSetMonth(datasetsWithTransactions);
    }

  }

  function createYearData() {
    const emptyDataSets = JSON.parse(JSON.stringify(EmptyDatasets));

    const currTimeStamp = new Date().getTime();
    const date = new Date();
    const timeStampLastYear = date.setFullYear(date.getFullYear() - 1);

    const TransactionsYear =
      TransactionData.filter(t => IsInYearRange(currTimeStamp, timeStampLastYear, t.TransactionDate));

    if (TransactionsYear.length) {

      const [datasetsWithTransactions, labels, totalTransactions]: any =
        createLineChartDatasets(TransactionsYear, emptyDataSets, "Year")

      // create year excel dataSets
      const excelYear: any[] = [];
      datasetsWithTransactions.forEach((dataSet, index) => {
        const excelYearObj: any = {};
        dataSet.data.forEach((transaction, trIndex) => {
          const attr = "transaction" + trIndex
          excelYearObj[attr] = transaction
        });
        if (index === 0) {
          excelYearObj.dateRange = `${formatDisplayDate(timeStampLastYear)} - ${formatDisplayDate(new Date())}`
        }
        excelYearObj.transactionsTotal = dataSet.transactionsTotal;
        excelYearObj.product = dataSet.label;
        excelYear.push(excelYearObj);
      });
      setYearPeriodStart(formatExcelDate(timeStampLastYear).split("T")[0]);
      setExcelTabYear(excelYear);

      setYearTotal(totalTransactions);
      setLabelsYear(labels);
      setDataSetYear(datasetsWithTransactions);
    }
  }

  function isToday(timestamp) {
    return formatDate(new Date()).split("T")[0] === timestamp.split("T")[0];
  }
  function isSameMonth(timestamp) {
    return formatDate(new Date()).split("T")[0].split("-")[1] === timestamp.split("T")[0].split("-")[1];
  }

  return (
    <div className={classes.root}>

      <div>
        <div className="flex-center-align jcsb title">
          <h2 className="mr1">Specificatie producten</h2>

          <div className="excel-btn">
            <ExcelFile filename={`SpecificatieProducten_${formatDateAndTIme(new Date())}`} element={<button>Download Statistieken</button>}>
              <ExcelSheet data={ExcelTabDay} name={`Dag (${formatExcelDate(new Date()).split("T")[0]})`}>
                <ExcelColumn label={"Datum"} value="dateRange" />
                <ExcelColumn label={"Product / Transactie Tijd"} value="product" />
                {LabelsDay.map((label, index) => {
                  const attr = "transaction" + index;
                  return <ExcelColumn key={index} label={label} value={attr} />
                })}
                <ExcelColumn label={"Total Transacties per product"} value="transactionsTotal" />
              </ExcelSheet>
              <ExcelSheet data={ExcelTabWeek} name={`Week (${formatExcelDate(new Date().setDate(new Date().getDate() - 6)).split("T")[0]} - ${formatExcelDate(new Date()).split("T")[0]})`}>
                <ExcelColumn label={"Periode"} value="dateRange" />
                <ExcelColumn label={"Product / Transactie Dag"} value="product" />
                {LabelsWeek.map((label, index) => {
                  const attr = "transaction" + index;
                  return <ExcelColumn key={index} label={label} value={attr} />
                })}
                <ExcelColumn label={"Total Transacties per product"} value="transactionsTotal" />
              </ExcelSheet>

              <ExcelSheet data={ExcelTabMonth} name={`Maand (${FirstDayOfMonth} - ${formatExcelDate(new Date()).split("T")[0]})`}>
                <ExcelColumn label={"Periode"} value="dateRange" />
                <ExcelColumn label={"Product / Transactie Dag"} value="product" />
                {LabelsMonth.map((label, index) => {
                  const attr = "transaction" + index;
                  return <ExcelColumn key={index} label={label} value={attr} />
                })}
                <ExcelColumn label={"Total Transactions per product"} value="transactionsTotal" />
              </ExcelSheet>

              <ExcelSheet data={ExcelTabYear} name={`Jaar (${YearPeriodStart} - ${formatExcelDate(new Date()).split("T")[0]})`}>
                <ExcelColumn label={"Periode"} value="dateRange" />
                <ExcelColumn label={"Product / Transactie Maand"} value="product" />
                {LabelsYear.map((label, index) => {
                  const attr = "transaction" + index;
                  return <ExcelColumn key={index} label={label} value={attr} />
                })}
                <ExcelColumn label={"Total Transactions per product"} value="transactionsTotal" />
              </ExcelSheet>

              <ExcelSheet data={ExcelTabDateRange} name={`Periode (${FromDate ? formatExcelDate(new Date(FromDate)).split("T")[0] : "Unset"} - ${UntilDate ? formatExcelDate(new Date(UntilDate)).split("T")[0] : "Unset"})`}>
                <ExcelColumn label={"Periode"} value="dateRange" />
                <ExcelColumn label={"Product / Transactie"} value="product" />
                {LabelsDateRange.map((label, index) => {
                  const attr = "transaction" + index;
                  return <ExcelColumn key={index} label={label} value={attr} />
                })}
                <ExcelColumn label={"Total Transactions per product"} value="transactionsTotal" />
              </ExcelSheet>

            </ExcelFile>
          </div>

        </div>
        <div className="flex">
          <div className="flex-center-align filters-range">
            <label htmlFor="rangeLabel">per:</label>
            <label
              className={`range-label ${RevenueRangeActive === "Day" ? "active" : ""}`}
              onClick={() => { setDataSetActive(DataSetDay); setLabelsActive(LabelsDay); setRevenueRangeActive("Day"); }}
              id="dayRange"
            >Dag</label>
            <span>/</span>
            <label
              className={`range-label ${RevenueRangeActive === "Week" ? "active" : ""}`}
              onClick={() => { setDataSetActive(DataSetWeek); setLabelsActive(LabelsWeek); setRevenueRangeActive("Week"); }}
              id="weekRange"
            >Week</label>
            <span>/</span>
            <label
              className={`range-label ${RevenueRangeActive === "Month" ? "active" : ""}`}
              onClick={() => { setDataSetActive(DataSetMonth); setLabelsActive(LabelsMonth); setRevenueRangeActive("Month"); }}
              id="monthRange"
            >Maand</label>
            <span>/</span>
            <label
              className={`range-label ${RevenueRangeActive === "Year" ? "active" : ""}`}
              onClick={() => { setDataSetActive(DataSetYear); setLabelsActive(LabelsYear); setRevenueRangeActive("Year"); }}
              id="yearRange"
            >Jaar</label>
            <span>/</span>
            <label
              className={`range-label ${RevenueRangeActive === "DateRange" ? "active" : ""}`}
              onClick={() => { setRevenueRangeActive("DateRange"); }}
              id="dateRange"
            >Selecteer periode</label>
          </div>
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="flex-center-align chart-date-filters" style={{ display: RevenueRangeActive === "DateRange" ? "flex" : "none" }}>
            <label htmlFor={"From:"}>{"Van:"}</label>
            <DatePicker
              name={"FromPPT"}
              className="datetimepicker-dashboard"
              clearable
              autoOk
              // label="Van:"
              format="dd/MM/yyyy"
              placeholder={formatDisplayDate(Date.now())}
              onChange={value => {
                setFromDate(value);
              }}
              value={FromDate}
              animateYearScrolling={false}
            />
            <label htmlFor={"Until:"}>{"Tot:"}</label>
            <DatePicker
              name={"UntilPPT"}
              className="datetimepicker-dashboard"
              clearable
              autoOk
              // label="Van:"
              format="dd/MM/yyyy"
              placeholder={formatDisplayDate(Date.now())}
              onChange={value => {
                setUntilDate(value);
              }}
              value={UntilDate}
              animateYearScrolling={false}
            />
          </div>
        </MuiPickersUtilsProvider>
      </div>

      <LineChart labels={LabelsActive} dataSet={DatasetList} />
      <p className="total-transactions">
        Totale transacties: {RevenueRangeActive === "Day" ?
          DayTotal : RevenueRangeActive === "Week" ?
            WeekTotal : RevenueRangeActive === "Month" ?
              MonthTotal : RevenueRangeActive === "Year" ?
                YearTotal : DateRangeTotal
        }
      </p>
    </div>
  )
}

export default TransactionsPerProductChart

