import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import useUIState from '../../hooks/useUIstate';
import { MdArrowBack, MdCheckCircle } from "react-icons/md";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Select, MenuItem, ButtonGroup } from '@material-ui/core';
import { API } from 'aws-amplify';
import Checkbox from '@material-ui/core/Checkbox';
import ProductPanel from '../../components/ProductPanel';
import ConfirmationModal from '../../components/ConfirmationModal';
import useDataState from '../../hooks/useDataState';
import { convertStringToArray, fillingPlanReqBody, lockerSizeValidation } from './ReplenishmentController';
import LoadingModal from '../../components/LoadingModal';
import awsconfig from '../../aws-apiSettings';
import { countBlockTypes } from '../StoreReplenishment/StoreReplenishmentController';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSelect-select': {
      padding: "10.5px 14px",
      width: "250px",
    },
    '& .MuiInputBase-root ': {
      marginRight: "10px"
    },
    '& h1, & h2': {
      marginTop: '5px',
      marginBottom: '5px'
    },
    '& .blocks-container': {
      marginTop: "20px",
      overflowX: "auto",
      position: "relative",
    },
    // '& .blocks-container:before': {
    //   content: '',
    //   width: "100%",
    //   height: "100%",
    //   position: "absolute",
    //   left: 0,
    //   top: 0,
    //   background: "linear-gradient(transparent 150px, white)",
    // },
    '& .block': {
      background: '#eee',
      marginRight: "20px"
    },
    '& .double': {
      width: '450px',
      minWidth: '450px',
    },
    '& .single': {
      width: '225px',
      minWidth: '225px',
    },
    '& .block-header': {
      background: '#ddd'
    },
    '& .lockers-container': {
      flexWrap: "wrap",
      padding: "5px 0"
    },
    '& .locker': {
      width: '225px',
      padding: '5px 0',
      alignItems: 'center'
    },
    '& .product-avatar-container': {
      width: "50px",
      height: "45px",
      minWidth: "50px",
      minHeight: "45px",
      background: "#fff",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "5px"
    },
    '& .product-avatar-container svg': {
      position: "absolute",
      padding: "0.25px",
      background: "white",
      borderRadius: "50%"
    },
    '& .locker-disabled .product-avatar-container svg': {
      padding: "2px"
    },
    '& .product-avatar-container img': {
      width: "100%",
      height: "100%"
    },
    '& .product-avatar-container.B': {
      width: "60px",
    },
    '& .product-avatar-container.C': {
      width: "70px",
    },
    '& .product-avatar-container.D': {
      width: "60px",
    },
    '& .green-locker .locker-shape': {
      height: "15px",
      width: "15px",
      minHeight: "15px",
      minWidth: "15px",
      backgroundColor: "#689f38",
      borderRadius: "50%",
      border: "1px solid #689f38",
      margin: "0 10px 0 5px"
    },
    '& .empty-locker .locker-shape': {
      height: "15px",
      width: "15px",
      minHeight: "15px",
      minWidth: "15px",
      backgroundColor: "#fff",
      borderRadius: "50%",
      border: "1px solid #222",
      margin: "0 10px 0 5px"
    },
    '& .almost-expired-locker .locker-shape': {
      height: "15px",
      width: "15px",
      minHeight: "15px",
      minWidth: "15px",
      backgroundColor: "#ff9800",
      margin: "0 10px 0 5px"
    },
    '& .almost-expired-locker .product-avatar-container': {
      border: "3px solid #ff9800"
    },
    '& .sold-out-locker .locker-shape': {
      width: 0,
      height: 0,
      borderLeft: "7.5px solid transparent",
      borderRight: "7.5px solid transparent",
      borderBottom: "15px solid #d81b60",
      margin: "0 10px 0 5px"
    },
    '& .sold-out-locker .product-avatar-container': {
      border: "3px solid #d81b60"
    },
    '& .product-code': {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    },
    '& .legend': {
      alignItems: "center",
    },
    '& .replenishment-bottom': {
      marginTop: "20px",
      maxWidth: "90vw"
    },
    '& .replenishment-actions>*': {
      marginRight: "20px",
    }
  }
}));

function ReplenishmentScreen(props) {
  const history = useHistory();
  const classes = useStyles();

  const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();
  const { ProductCategoriesContext, setProductCategoriesContext, ProductsContext, setProductsContext, StoreLayoutContext, setStoreLayoutContext, JofemarContext, setJofemarContext, setLockersDPlanContext, StoreContext, setStoreContext } = useDataState();

  const [StoreId, setStoreId] = useState(null);
  const [StoreObj, setStoreObj] = useState({ FillingPlan: "" });
  const [StoreTitle, setStoreTitle] = useState("")
  const [SelectType, setSelectType] = useState(0)
  const [SelectAction, setSelectAction] = useState(0)
  const [Blocks, setBlocks]: [any, any] = useState([]);
  const [Jofemar, setJofemar] = useState(null)
  
  const [Jofemars, setJofemars] = useState(null)
  const [IsDirtyCurrentJofemarContents, setIsDirtyCurrentJofemarContents] = useState(false); // true: allow save, false: allow jofemar navigation

  const [ProductCategories, setProductCategories]: [any, any] = useState([]);
  const [Products, setProducts]: [any, any] = useState([]);
  const [LockersSelected, setLockersSelected]: [any, any] = useState([])
  const [BlocksSelected, setBlocksSelected]: [any, any] = useState([])
  const [AlmostExpiredLockerIds, setAlmostExpiredLockerIds]: [any, any] = useState([]);
  const [SoldOutLockerIds, setSoldOutLockerIds]: [any, any] = useState([]);
  const [ShowPanel, setShowPanel] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal]: [boolean, any] = useState(false);
  const [LockersDistributionPlan, setLockersDistributionPlan]: [any, any] = useState([])
  const [ShowConfirmationPreviousPlan, setShowConfirmationPreviousPlan] = useState(false);
  const [LockersView, setLockersView] = useState<string>("Back");
  const [UserConfigData, setUserConfigData]: [any, any] = useState([]);

  function handleLockersViewChange(view) {
    const blocksContainer = document.getElementById("blocksContainer");
    if (view === "Back") {
      setLockersView("Back");
      if (blocksContainer) {
        setTimeout(() => {
          blocksContainer.scrollLeft = -blocksContainer.scrollWidth
        }, 100);
      }
      return;
    }
    setLockersView("Front");
  }

  function JofemarButton() {
    if (Jofemar) {
      return <Button
        id="goToJofemarBtn"
        type="button"
        variant="contained"
        className="green-btn ml1"
        onClick={() => prepFillingPlan()}
      >Stap 2 - Vending machine
      </Button>
    }
    else {
      return null;
    }
  }

  const handleCheckboxChange = (e, type, selection, selectedBlock = null) => {
    const lockersSelected: any = [...LockersSelected];
    const blocksSelected: any = [...BlocksSelected];
    console.log(lockersSelected)

    if (type === 'locker') {
      if (lockersSelected.indexOf(selection) > -1) {
        const position = lockersSelected.indexOf(selection);
        lockersSelected.splice(position, 1)
      } else {
        lockersSelected.push(selection);
      }
    } else if (type === "block") {
      if (blocksSelected.indexOf(selectedBlock) > -1) {
        // de-selecting
        const pos = blocksSelected.indexOf(selectedBlock);
        blocksSelected.splice(pos, 1)

        selection.forEach(locker => {
          if (lockersSelected.includes(locker.Id)) {
            const pos = lockersSelected.indexOf(locker.Id);
            lockersSelected.splice(pos, 1);
          }
        });
      } else {
        // selecting non green lockers
        blocksSelected.push(selectedBlock);
        const lockersOfBlock: any = document.querySelectorAll(
          ".lblock" + selectedBlock
        );
        [].forEach.call(lockersOfBlock, (el) => {
          const element: any = el;
          const lockerId = parseInt(element.id.split('locker')[1], 10);
          if (element.classList.contains('green-locker') && element.classList.contains('lblock' + selectedBlock)) {
            // remove green lockers selected
            const pos = lockersSelected.indexOf(lockerId);
            if (pos >= 0) {
              lockersSelected.splice(pos, 1);
            }
          }
          if (
            element.classList.contains('empty-locker') ||
            element.classList.contains('almost-expired-locker') ||
            element.classList.contains('sold-out-locker') ||
            element.classList.contains('disabled-locker')
          ) {
            if (!lockersSelected.includes(lockerId)) {
              lockersSelected.push(lockerId)
            }
          }
        });

      }
      setBlocksSelected(blocksSelected)
    }

    setLockersSelected(lockersSelected)
  };

  useEffect(() => {
    const value = localStorage.getItem("UserConfigData");
    if (typeof value === 'string') {
      setUserConfigData(JSON.parse(value));
    }

    if (props.location.store) {
      const Store = props.location.store
      setStoreObj(props.location.store);
      setStoreContext(Store);
      localStorage.setItem("ActiveStore", JSON.stringify(Store))
      setStoreId(props.location.storeId);
      setStoreTitle(props.location.title);

    } else if (StoreContext) {
      localStorage.setItem("ActiveStore", JSON.stringify(StoreContext))
      setStoreId(StoreContext.Id);
      setStoreTitle(StoreContext.Title);
      setStoreObj(StoreContext)
    } else if (localStorage.getItem("ActiveStore")) {

      const Store = localStorage.getItem("ActiveStore");
      if (Store) {

        const StoreJson = JSON.parse(Store)
        console.log(StoreJson)

        setStoreObj(StoreJson);
        setStoreContext(StoreJson);
        setStoreId(StoreJson.StoreId);
        setStoreTitle(StoreJson.StoreTitle);
      }

    } else {
      history.push('/retail-locations');
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (StoreId) {
      getProductCategories();
    }
    // eslint-disable-next-line
  }, [StoreId])

  function getProductCategories() {
    if (ProductCategoriesContext.length) {
      setProductCategories(ProductCategoriesContext);
      setShowLoadingModal(false);
      getProducts();
    } else {
      setShowLoadingModal(true);
      API.get("AE", `/ProductCategories`, { response: true })
        .then(res => {

          setProductCategories(res.data);
          setProductCategoriesContext(res.data);
          getProducts();
        })
        .catch(error => {
          console.log(error);
          setToastType("error");
          setToastMessage("Error getting products");
          setShowToastMessage(true);
        })
    }

  }

  function getProducts() {
    if (ProductsContext.length) {
      setProducts(ProductsContext);
      setShowLoadingModal(false);
      getBlocksOfStore(StoreId);

    } else {
      setShowLoadingModal(true);
      API.get("AE", `/Products`, { response: true })
        .then(res => {
          const activeProducts: any[] = []

          res.data.forEach(product => {
            const attr = JSON.parse(product.Attributes.replace(/'/g, '"'));
            if (attr.Enabled) {
              activeProducts.push(product);
            }
          });

          setProducts(activeProducts);
          localStorage.setItem("Products", JSON.stringify(activeProducts))
          setProductsContext(activeProducts);
          getBlocksOfStore(StoreId);
        })
        .catch(error => {
          console.log(error);
          setToastType("error");
          setToastMessage("Error getting products");
          setShowToastMessage(true);
        })
    }
  }

  function getBlocksOfStore(storeId) {
    if (localStorage.getItem("StoreLayout")) {
      const storeLayout = localStorage.getItem("StoreLayout");
      if (storeLayout) {

        //set Jofemar from Jofemars
        const activeJofemarId = localStorage.getItem("ActiveJofemarId");
        const jofemars = localStorage.getItem("Jofemars")
        if (jofemars) {
          const jofemarsJson = JSON.parse(jofemars)
          console.log('number of jofemars found: ' + jofemarsJson.length)
          const jofemarJson = jofemarsJson.find(block => block.Id === Number(activeJofemarId))
          const isDirtyCurrentJofemarContents = localStorage.getItem("IsDirtyCurrentJofemarContents")
          if (isDirtyCurrentJofemarContents === "true") {
            setIsDirtyCurrentJofemarContents(true);
          } else {
            setIsDirtyCurrentJofemarContents(false);
          }
          
          setJofemar(jofemarJson);
          setJofemars(jofemarsJson);
          setJofemarContext(jofemarJson);
        }

        const storeJson = JSON.parse(storeLayout);

        // if there is a current filling plan apply it 
        // (navigation back from Jofemar screen)
        let fillingPlanStr, fillingPlan;
        if (localStorage.getItem("CurrentFillingPlan")) {
          fillingPlanStr = localStorage.getItem("CurrentFillingPlan");
          fillingPlanToLockersDistributionPlan(fillingPlanStr);

          fillingPlan = fillingPlanStr.split("|");
          fillingPlan.shift();
          fillingPlan = fillingPlan.map(l => JSON.parse(l.replace(/'/g, '"')))
          console.log(fillingPlan)

          storeJson.forEach(blocks => {
            blocks.Lockers.forEach(locker => {
              if (fillingPlan.find(l => l.LockerId === locker.Id)) {
                locker.ProductId = fillingPlan.find(l => l.LockerId === locker.Id).ProductId;
              }
            });
          });
        }

        setBlocks(storeJson);
        setStoreLayoutContext(storeLayout);
        setShowLoadingModal(false);
      }
    } else {
      if (StoreLayoutContext.length) {
        setBlocks(StoreLayoutContext);
        setJofemar(JofemarContext);
        localStorage.setItem('StoreLayout', JSON.stringify(StoreLayoutContext));
        setShowLoadingModal(false);
      } else {
        setShowLoadingModal(true);
        API.get("AE", `/Blocks/GetBlocksAndLockers?storeId=${storeId}`, { response: true })
          .then(res => {
           
            //set Jofemar
            const jofemar = res.data.find(block => block.Code.indexOf("Vision") > -1);

            const jofemars = res.data.filter(function(obj) {
              return (obj.Code.indexOf("Vision") > -1);
            });

            setJofemar(jofemar);
            setJofemars(jofemars);
            localStorage.setItem("Jofemar", JSON.stringify(jofemar))
            localStorage.setItem("Jofemars", JSON.stringify(jofemars))
            setJofemarContext(jofemar);

            // filter out Jofemar
            const blocks = res.data.filter(block => block.Code.indexOf("Vision") <= -1);
            // sort blocks by rack
            blocks.forEach(block => {
              block.Order = parseInt(block.Code.split(' ')[1], 10);
            });
            blocks.sort((a, b) => (a.Order > b.Order) ? 1 : -1)

            assignLockersPerBlock(blocks);
          })
          .catch(error => {
            console.log(error);
            setToastType("error");
            setToastMessage("Error getting blocks");
            setShowToastMessage(true);
          });
      }
    }
  }

  async function assignLockersPerBlock(blocks) {
    const _blocks = blocks;
    setShowLoadingModal(false);
    const blocksDesc = countBlockTypes(_blocks)
    localStorage.setItem('StoreLayout', JSON.stringify(blocksDesc));
    setStoreLayoutContext(blocksDesc);
    setBlocks(blocksDesc);
    const blocksContainer = document.getElementById("blocksContainer");
    if (blocksContainer) {
      setTimeout(() => {
        blocksContainer.scrollLeft = -blocksContainer.scrollWidth
      }, 100);
    }
  }

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    setBlocksSelected([]);
    setLockersSelected([]);

    if (name === "selectType") {
      setSelectType(value);

      if (value === 1) {
        const blocksSelected: any[] = [];
        const lockersSelected: any[] = [];
        Blocks.forEach((block, index) => {
          blocksSelected.push(index + 1);
          block.Lockers.forEach(locker => {
            lockersSelected.push(locker.Id)
          });
        });
        setBlocksSelected(blocksSelected);
        setLockersSelected(lockersSelected);
      }
      if (value === 2) {

        const lockersSelected: any[] = [];
        const almostExpiredLockerIds = getExpiredLockerIds();
        almostExpiredLockerIds.forEach(lockerId => {
          lockersSelected.push(lockerId)
        });
        setLockersSelected(lockersSelected);
      }
      if (value === 3) {

        const lockersSelected: any[] = [];
        const soldOutLockerIds = getSoldOutLockerIds();
        soldOutLockerIds.forEach(lockerId => {
          lockersSelected.push(lockerId)
        });
        setLockersSelected(lockersSelected);
      }

    } else {
      console.log(SelectAction);
      setSelectAction(value)
    }
  }

  function getSoldOutLockerIds() {
    const soldOutLockerIds: any = [];

    Blocks.forEach(block => {
      block.Lockers.forEach(locker => {
        if (locker.ProductId) {
          if (locker.IsSoldOut) {
            if (!soldOutLockerIds.includes(locker.Id)) {
              soldOutLockerIds.push(locker.Id)
            }
          }
        }
      });

    });
    console.log(soldOutLockerIds)
    return soldOutLockerIds;
  }

  function getExpiredLockerIds() {
    const almostExpiredLockerIds: any[] = []

    Blocks.forEach(block => {
      block.Lockers.forEach(locker => {


        if (locker.ProductId && !locker.IsSoldOut) {
          const product = Products.find(p => p.Id === locker.ProductId)

          let attr = product.Attributes;
          attr = JSON.parse(attr.replace(/'/g, '"'))

          let d = new Date();
          let currDate = formatDate(d);
          let todayObj = new Date(currDate);
          todayObj.setDate(todayObj.getDate() + 1);
          let tomorrowStr = formatDate(todayObj);
          let tomorrowsTime = new Date(tomorrowStr).getTime();

          let expiredDate = new Date(locker.ReplenishedAt);
          expiredDate.setDate(expiredDate.getDate() + attr.ShelfLifeDays);
          let expiredDateStr = formatDate(expiredDate);
          let expTime = new Date(expiredDateStr).getTime();

          if (tomorrowsTime >= expTime) {
            if (!almostExpiredLockerIds.includes(locker.Id)) {
              almostExpiredLockerIds.push(locker.Id)
            }

          }
        }

      });

    });
    console.log(almostExpiredLockerIds)
    return almostExpiredLockerIds;
  }

  function getProductStatus(locker) {
    let className = "green-locker"
    const product = Products.find(p => p.Id === locker.ProductId)

    let attr = product.Attributes;
    attr = JSON.parse(attr.replace(/'/g, '"'))

    let d = new Date();
    let currDate = formatDate(d);
    let todayObj = new Date(currDate);
    todayObj.setDate(todayObj.getDate() + 1);
    let tomorrowStr = formatDate(todayObj);
    let tomorrowsTime = new Date(tomorrowStr).getTime();

    let expiredDate = new Date(locker.ReplenishedAt);
    expiredDate.setDate(expiredDate.getDate() + attr.ShelfLifeDays);
    let expiredDateStr = formatDate(expiredDate);
    let expTime = new Date(expiredDateStr).getTime();

    if (tomorrowsTime >= expTime) {
      className = "almost-expired-locker";
      const almostExpiredLockerIds = [...AlmostExpiredLockerIds]
      if (!almostExpiredLockerIds.includes(locker.Id)) {
        almostExpiredLockerIds.push(locker.Id)
      }
      // setAlmostExpiredLockerIds(almostExpiredLockerIds)
    }
    if (locker.IsSoldOut) {
      className = "sold-out-locker";
      const soldOutLockerIds = [...SoldOutLockerIds]
      if (!soldOutLockerIds.includes(locker.Id)) {
        soldOutLockerIds.push(locker.Id)
      }
      // setSoldOutLockerIds(soldOutLockerIds)
    }

    return className;
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-') + "T00:00:00";
  }

  async function switchProductsR(lockerIdsToReplenish, lockersSelected) {
    let lockersToReplenish: any[] = [];

    if (lockersSelected.length === 0) {
      setToastType("error");
      setToastMessage("You must select a locker first");
      setShowToastMessage(true);
    } else {
      const _Blocks = JSON.parse(JSON.stringify(Blocks));
      let failedValidationMsg = "";

      lockerIdsToReplenish.forEach(lockerSelectedId => {
        _Blocks.forEach((block, blockIndex) => {
          block.Lockers.forEach((locker, lockerIndex) => {

            if (locker.Id === lockerSelectedId.lockerId) {

              let failedValidation = lockerSizeValidation(block, ProductsContext, lockerSelectedId.productId)

              if (failedValidation) {
                failedValidationMsg = failedValidation;
              } else {

                const todayStr = formatDate(new Date());
                locker.ReplenishedAt = todayStr;
                locker.ProductId = lockerSelectedId.productId;
                locker.IsSoldOut = false;
                lockersToReplenish.push(locker);

              }
            }

          });
        });
      });

      if (failedValidationMsg) {
        setToastType("error");
        setToastMessage(failedValidationMsg);
        setShowToastMessage(true);
      } else {

        setBlocks(_Blocks);
        setShowLoadingModal(true);
        let lockersUpdated = true;
        setLockersSelected([]);
        setBlocksSelected([]);

        if (lockersUpdated) {
          updateDistributionPlan(lockersToReplenish);
        }
        setShowLoadingModal(false);

      }

    }
  }

  function updateDistributionPlan(lockersUpdated) {
    // Update lockers Distribution Plan
    const lockersDistributionPlan: any[] = [...LockersDistributionPlan];
    console.log("lockersDistributionPlan", lockersDistributionPlan);
    const productsToRemove: any[] = [];
    // prodEntry = { ProductId: locker.ProductId, Amount: 0, LockersUpdatedIds: [] }

    lockersUpdated.forEach(locker => {
      // look for lockers updated in dist plan
      // with another product on them (updated switched products in lockers in dist plan)
      lockersDistributionPlan.forEach((product, index) => {
        if (product.LockersUpdatedIds.includes(locker.Id) && product.ProductId !== locker.ProductId) {
          // if product.LockersUpdatedIds is just 1 then remove whole entry
          if (product.LockersUpdatedIds.length === 1) {
            productsToRemove.push(product.ProductId);
          } else {
            // else remove locker from product.LockersUpdatedIds
            // and update Amount
            const newLockersUpdatedIds = product.LockersUpdatedIds;
            const indexToRemove = product.LockersUpdatedIds.indexOf(locker.Id);
            newLockersUpdatedIds.splice(indexToRemove, 1);
            lockersDistributionPlan[index].LockersUpdatedIds = newLockersUpdatedIds;
            lockersDistributionPlan[index].Amount = newLockersUpdatedIds.length;
          }
        }
      });



      if (lockersDistributionPlan.find(product => product.ProductId === locker.ProductId)) {
        // if product exists in distribution plan

        const index = lockersDistributionPlan.findIndex(product => product.ProductId === locker.ProductId)
        const newLockersUpdatedIds = lockersDistributionPlan[index].LockersUpdatedIds
        newLockersUpdatedIds.push(locker.Id)
        lockersDistributionPlan[index] = {
          ProductId: locker.ProductId,
          Amount: newLockersUpdatedIds.length,
          LockersUpdatedIds: newLockersUpdatedIds
        }
      } else {
        // if product does not exists in distribution plan
        // just add it
        lockersDistributionPlan.push({
          ProductId: locker.ProductId, Amount: 1, LockersUpdatedIds: [locker.Id]
        });
      }

    });

    productsToRemove.forEach(productId => {
      if (lockersDistributionPlan.find(p => p.ProductId === productId)) {
        const indexToRemove = lockersDistributionPlan.findIndex(p => p.ProductId === productId);
        lockersDistributionPlan.splice(indexToRemove, 1);
      }
    });

    console.log(lockersDistributionPlan);
    setLockersDistributionPlan(lockersDistributionPlan)
    localStorage.setItem("LockersDistPlan", JSON.stringify(lockersDistributionPlan))
    setLockersDPlanContext(lockersDistributionPlan)
  }

  function deleteLockersHandler() {
    if (LockersSelected.length === 0) {
      setToastType("error");
      setToastMessage("You must select Locker(s) first");
      setShowToastMessage(true);
    } else {
      setShowConfirmationModal(true);
    }
  }

  async function confirmEmptyLockers() {
    setShowConfirmationModal(false);
    setShowLoadingModal(true)
    let lockersToEmpty: any[] = [];
    const _Blocks = [...Blocks];

    // Update lockers Distribution Plan
    const lockersDistributionPlan: any[] = [...LockersDistributionPlan];
    console.log(lockersDistributionPlan);

    LockersSelected.forEach(lockerSelectedId => {
      _Blocks.forEach(block => {
        block.Lockers.forEach(locker => {
          if (locker.Id === lockerSelectedId) {
            locker.ReplenishedAt = null;
            locker.ProductId = null;
            lockersToEmpty.push(locker);
          }
        });
      });
    });

    let productsToRemove: any[] = [];

    // update dist plan array
    lockersToEmpty.forEach(locker => {
      const lockerId = locker.Id;
      lockersDistributionPlan.forEach(entry => {
        const lockersUpdated = entry.LockersUpdatedIds;
        // remove locker from product entry in dist plan array
        if (lockersUpdated.includes(lockerId)) {

          if (entry.LockersUpdatedIds.length === 1) {
            productsToRemove.push(entry.ProductId);
          } else {
            const index = lockersUpdated.indexOf(lockerId);
            lockersUpdated.splice(index, 1);
            entry.Amount = entry.Amount - 1;
            entry.LockersUpdatedIds = lockersUpdated;
          }
        }
      });
    });

    if (productsToRemove.length) {
      productsToRemove.forEach(removeProductId => {
        if (lockersDistributionPlan.find(x => x.ProductId === removeProductId)) {
          const index = lockersDistributionPlan.findIndex(x => x.ProductId === removeProductId);
          lockersDistributionPlan.splice(index, 1);
        }
      });
    }

    console.log(lockersDistributionPlan);
    console.log(lockersToEmpty)
    setLockersSelected([]);
    setLockersDistributionPlan(lockersDistributionPlan)
    localStorage.setItem("LockersDistPlan", JSON.stringify(lockersDistributionPlan))
    setLockersDPlanContext(lockersDistributionPlan)

    // const updatedLockersRes = await updateLockers(lockersToEmpty);
    // if (!updatedLockersRes) {
    //   setToastType("error");
    //   setToastMessage("Error updating locker(s) ");
    //   setShowToastMessage(true);
    // } else {
    //   console.log("lockers emptied");
    // }

    setLockersSelected([])
    setBlocksSelected([])
    setShowLoadingModal(false);
    setBlocks(_Blocks);
    localStorage.setItem('StoreLayout', JSON.stringify(_Blocks));
    setStoreLayoutContext(_Blocks);
  }

  function prepFillingPlan() {
    let body = fillingPlanReqBody(LockersDistributionPlan, StoreObj, localStorage.getItem("CurrentFillingPlan") ? true : false);

    localStorage.setItem("ActiveStore", JSON.stringify(body));
    setStoreContext(body)

    console.log(body);
    console.log(localStorage.getItem("CurrentFillingPlan"))

    // Save current Filling plan for navigation
    let currFillingPlan = localStorage.getItem("CurrentFillingPlan") ?
      convertStringToArray(localStorage.getItem("CurrentFillingPlan")) : [];

    if (body.FillingPlan) {
      let bodyFillingPlanArr = convertStringToArray(body.FillingPlan);
      bodyFillingPlanArr.forEach(locker => {
        if (currFillingPlan.find(l => l.LockerId === locker.LockerId)) {
          let index = currFillingPlan.findIndex(l => l.LockerId === locker.LockerId);
          currFillingPlan[index].ProductId = locker.ProductId;
        } else {
          currFillingPlan.push(locker);
        }
      });
    }

    let newFillingPlanStr = "";
    if (currFillingPlan.length) {
      currFillingPlan.forEach(locker => {
        newFillingPlanStr = newFillingPlanStr + "|" + JSON.stringify(locker).replace(/"/g, "'")
      });

      localStorage.setItem("CurrentFillingPlan", newFillingPlanStr);
    }

    setShowLoadingModal(true)

    API.put('AE', `/Stores`, { body })
      .then(res => {
        console.log(res)
        localStorage.setItem("ActiveStore", JSON.stringify(res))
        setStoreContext(res)
        history.push(
          {
            store: body,
            pathname: '/replenishment-plan-jofemar',
            storeId: StoreId,
            title: StoreTitle,
            Jofemar: Jofemar,
            Jofemars: Jofemars,
            IsDirtyCurrentJofemarContents: IsDirtyCurrentJofemarContents,
            lockersDistributionPlan: LockersDistributionPlan,
            products: Products
          })
      })
      .catch(error => {
        console.log(error);
        setToastType("error");
        setToastMessage("Error updating store");
        setShowToastMessage(true);
      })
      .finally(() => {
        setShowLoadingModal(false);
      })
  }

  function fillingPlanToLockersDistributionPlan(FillingPlanStr) {
    let fillingPlanSplit;
    let FillingPlan: any[] = [];
    let lockersDistributionPlan: any[] = [];
    fillingPlanSplit = FillingPlanStr.split("|");
    fillingPlanSplit.shift();

    fillingPlanSplit.forEach(entry => {
      FillingPlan.push(JSON.parse(entry.replace(/'/g, '"')));
    });

    FillingPlan.forEach(entry => {
      if (!lockersDistributionPlan.find(x => x.ProductId === entry.ProductId)) {
        lockersDistributionPlan.push({
          ProductId: entry.ProductId,
          Amount: 1,
          LockersUpdatedIds: [entry.LockerId]
        })
      } else {
        const lockersDPlanEntry = lockersDistributionPlan.find(x => x.ProductId === entry.ProductId)
        const index = lockersDistributionPlan.findIndex(x => x.ProductId === entry.ProductId)
        const lockersArr = lockersDPlanEntry.LockersUpdatedIds;
        lockersArr.push(entry.LockerId);
        lockersDistributionPlan[index].Amount = lockersDistributionPlan[index].Amount + 1;
        lockersDistributionPlan[index].LockersUpdatedIds = lockersArr;
      }
    });

    return lockersDistributionPlan;
  }

  async function applyPreviousFillingPlan() {
    setShowConfirmationPreviousPlan(false);
    const _Blocks = [...Blocks];
    console.log(StoreObj);
    if (StoreObj !== null) {
      const fillingPlanStr = StoreObj.FillingPlan;
      const lockersDistPlan = fillingPlanToLockersDistributionPlan(fillingPlanStr)

      const lockersToUpdate: any[] = [];

      console.log(fillingPlanStr);
      let fillingPlan = fillingPlanStr.split("|");
      fillingPlan.splice(0, 1);
      console.log(fillingPlan);
      fillingPlan.forEach(locker => {
        const _locker = locker.replace(/'/g, '"');
        lockersToUpdate.push(JSON.parse(_locker));
      });
      console.log(lockersToUpdate);


      _Blocks.forEach(async block => {
        block.Lockers.forEach(async locker => {
          if (lockersToUpdate.find(l => l.LockerId === locker.Id)) {
            const lockerToUpdate = lockersToUpdate.find(l => l.LockerId === locker.Id);
            const todayStr = formatDate(new Date());
            locker.ReplenishedAt = todayStr;
            locker.ProductId = lockerToUpdate.ProductId;
            lockersToUpdate.push(locker);
          }
          // else {
          //   locker.ReplenishedAt = null;
          //   locker.ProductId = null;
          //   lockersToUpdate.push(locker);
          // }
        });
      });


      console.log(lockersToUpdate);
      console.log(lockersDistPlan);

      setBlocks(_Blocks);
      setLockersSelected([]);
      setLockersDPlanContext(lockersDistPlan);
      setLockersDistributionPlan(lockersDistPlan)
      localStorage.setItem("LockersDistPlan", JSON.stringify(lockersDistPlan))
      localStorage.setItem("StoreLayout", JSON.stringify(_Blocks));
    }
  }

  function isLockerToBeReplenished(lockerId) {
    let toBeReplenished = false;

    LockersDistributionPlan.forEach(product => {
      product.LockersUpdatedIds.forEach(updatedLockerId => {
        if (updatedLockerId === lockerId) {
          toBeReplenished = true;
        }
      });
    });

    return toBeReplenished;
  }

  function determineBlockClass(blockCode) {
    if (blockCode.split('-')[0] === 'G') {
      let numberOfColumns = blockCode.split('-')[3];

      return `block ${numberOfColumns > 1 ? "double" : "single"}`;
    }
    else {
      return `block ${blockCode.split('-')[0].indexOf("2") > -1 ? "double" : "single"} ${blockCode.split('-')[0]}`;
    }
  }

  function determineBlockHeight(blockCode) {
    let blockType = blockCode.split('-')[0];

    switch (blockType) {
      case 'G':
        const lockerHeight = 61;
        let numberOfRows = blockCode.split('-')[4];
        let blockHeight = numberOfRows * lockerHeight;

        return blockHeight;
      case 'D2':
        console.log('D2 gevonden');
        return 410;
      default:
        console.log(blockType + 'Default');
        return 475;
    }
  }

  return (
    StoreId && Blocks.length ?
      <div id="ReplenishmentScreen" className={classes.root}>
        {StoreId && Blocks.length ?
          <>
            <div className="replenishment-header flex-center-align">
              <div style={{ cursor: "pointer" }}>
                <MdArrowBack size="30" onClick={() => history.goBack()} />
              </div>
              <h1 id="storeTitle" className="ml1">{StoreTitle}</h1>
              <h2 className="ml1">Vulplan - Stap 1 lockers</h2>
            </div>
            <div className="replenishment-actions flex-center-align">
              <Select
                id={"selectType"}
                name={"selectType"}
                onChange={handleChange}
                // onBlur={handleBlur}
                value={SelectType}
                variant="outlined"
              >
                <MenuItem value={0}>Selecteer Product(en)</MenuItem>
                <MenuItem value={1}>Alle</MenuItem>
                <MenuItem value={2}>Uiterste verkoopdatum</MenuItem>
                <MenuItem value={3}>Verkocht</MenuItem>
              </Select>
              <Button
                id={"openProductPanel"}
                type="button"
                disabled={false}
                variant="contained"
                className="green-btn"
                onClick={() => setShowPanel(true)}
              >Wissel
              </Button>

              <Button
                id="emptyLocker"
                type="button"
                disabled={false}
                variant="contained"
                className="red-btn"
                onClick={() => deleteLockersHandler()}
              >Verwijder
              </Button>

              <div className="flex-reverse" style={{ flex: 1 }}>
                <div className="flex ai-center">
                  <span>Locker weergave: </span>
                  <ButtonGroup className="ml-1" variant="contained" aria-label="contained primary button group">
                    <Button
                      className={LockersView === "Back" ? "green-btn" : ""}
                      onClick={() => handleLockersViewChange("Back")}
                    >Achterkant</Button>
                    <Button
                      className={LockersView === "Front" ? "green-btn" : ""}
                      onClick={() => handleLockersViewChange("Front")}
                    >Voorkant</Button>
                  </ButtonGroup>
                </div>
              </div>

            </div>
          </>
          : null}
        {Blocks ?
          <div
            id="blocksContainer"
            className="blocks-container flex"
            style={{
              // width: Blocks.length * 245 + 'px',
              maxWidth: ShowPanel ? "70vw" : "90vw",
              flexDirection: LockersView === "Back" ? 'row-reverse' : 'unset'
            }}>
            {Blocks.map((block, index) => {
              return (
                <div key={index} className={determineBlockClass(block.Code)}>
                  <div className="block-header flex">
                    <Checkbox
                      checked={BlocksSelected.indexOf(index + 1) > -1}
                      onChange={e => handleCheckboxChange(e, "block", block.Lockers, index + 1)}
                      inputProps={{ 'aria-label': 'checkbox' }}
                    />
                    <p>Block{" "}{block.Description}</p>
                  </div>

                  <div className="lockers-container flex" style={{ maxHeight: determineBlockHeight(block.Code) }}>
                    {block.Lockers.map((locker, lockerIndex) => {
                      return (
                        <div key={locker.Id} id={`locker${locker.Id}`}
                          onClick={e => handleCheckboxChange(e, "locker", locker.Id)}
                          className={`locker flex ${locker.Enabled ? '' : 'disabled-locker'} ${locker.ProductId ?
                            getProductStatus(locker) : "empty-locker"} lblock${index + 1}`}
                          style={{ order: LockersView === "Back" ? block.Lockers.length >= 12 && lockerIndex >= block.Lockers.length / 2 ? -1 : 0 : 'unset' }}
                        >
                          <Checkbox
                            className={`checkbox${locker.Id}`}
                            checked={LockersSelected.indexOf(locker.Id) > -1}
                            // onChange={e => handleCheckboxChange( "locker", locker.Id)}
                            inputProps={{ 'aria-label': 'checkbox' }}
                          />
                          <div className={`product-avatar-container ${block.Code.split('-')[0][0]}`}>
                            {locker.ProductId && UserConfigData.Customer ?
                              <img id={`img${locker.Id}p${locker.ProductId}`}
                                className={`p${locker.ProductId}`}
                                src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${UserConfigData.Customer}/images/products/pid${locker.ProductId}_${Products.find(prod => prod.Id === locker.ProductId).Avatar}`}
                                style={{ opacity: !locker.Enabled || isLockerToBeReplenished(locker.Id) ? 0.3 : 1 }}
                                alt="productImg" />
                              : null
                            }
                            {
                              locker.ProductId && locker.Enabled && !isLockerToBeReplenished(locker.Id) &&
                                getProductStatus(locker) === "green-locker" ?
                                <MdCheckCircle color={"#689f38"} size={20} />
                                : null
                            }
                          </div>
                          {locker.ProductId &&
                            (getProductStatus(locker) === "sold-out-locker" || getProductStatus(locker) === "almost-expired-locker") ?
                            <div className={"locker-shape"} /> : null}
                          <p className="product-code">{locker.ProductId ?
                            Products.find(p => p.Id === locker.ProductId).Code : "Code"}</p>
                        </div>
                      )
                    })}

                  </div>

                </div>
              )
            })
            }
          </div>
          : null}

        {Blocks.length ?
          <div className="replenishment-bottom flex jcsb">
            <div className="legend flex">
              <div className="ml1 flex">
                <div className="green-locker">
                  <MdCheckCircle color={"#689f38"} size={20} />
                </div>
                <span>In verkoop</span>
              </div>
              <div className="ml1 flex">
                <div className="almost-expired-locker">
                  <div className="locker-shape" />
                </div>
                <span>Uiterste verkoopdatum</span>
              </div>
              <div className="ml1 flex">
                <div className="sold-out-locker">
                  <div className="locker-shape" />
                </div>
                <span>Verkocht</span>
              </div>
            </div>

            <div>
              <Button
                id="applyFillingPlan"
                type="button"
                variant="contained"
                className="green-btn"
                onClick={() => setShowConfirmationPreviousPlan(true)}
              >Herhaal vorig vulplan
              </Button>
              {JofemarButton()}
            </div>

          </div>
          : null}

        {ProductCategories && Products ?
          <ProductPanel
            productCategories={ProductCategories}
            products={Products}
            lockersSelected={LockersSelected}
            switchProducts={switchProductsR}
            showPanel={ShowPanel}
            isJofemar={false}
            closePanel={() => setShowPanel(false)}
          />
          : null}

        {showConfirmationModal ?
          <ConfirmationModal
            message={"Empty Locker(s)?"}
            confirm={confirmEmptyLockers}
            closeModal={() => setShowConfirmationModal(false)}
          />
          : null}

        {ShowConfirmationPreviousPlan ?
          <ConfirmationModal
            message={"Herhaal vorig vul plan?"}
            confirm={applyPreviousFillingPlan}
            closeModal={() => setShowConfirmationPreviousPlan(false)}
          />
          : null}
      </div>
      : <LoadingModal />)
}


export default ReplenishmentScreen

// ALMOST EXPIRED LOCKER (change ReplenishedAt (shelflifedays -1) DAYS FROM TODAY)
//  [
//   {
//     "Id": 4,
//     "BlockId": 1,
//     "ProductId": 2,
//     "Code": "A2-1--R1L13",
//     "Enabled": true,
//     "Opened": false,
//     "IsSoldOut": false,
//     "Title": "Rack 1",
//     "Description": "",
//     "Avatar": "",
//     "Attributes": "",
//     "ReplenishedAt": "2021-04-30T00:00:00",
//     "Amount": null,
//     "Weight": null,
//     "Block": null,
//     "Product": null
//   },
//   {
//     "Id": 5,
//     "BlockId": 1,
//     "ProductId": 2,
//     "Code": "A2-1--R1L12",
//     "Enabled": true,
//     "Opened": false,
//     "IsSoldOut": false,
//     "Title": "Rack 1",
//     "Description": "",
//     "Avatar": "",
//     "Attributes": "",
//     "ReplenishedAt": "2021-04-30T00:00:00",
//     "Amount": null,
//     "Weight": null,
//     "Block": null,
//     "Product": null
//   }
//  ]

//  SOLD OUT LOCKER

// {
//   "Id": 1,
//   "BlockId": 1,
//   "ProductId": 5,
//   "Code": "A2-1--R1L16",
//   "Enabled": true,
//   "Opened": false,
//   "IsSoldOut": true,
//   "Title": "Rack 1",
//   "Description": "",
//   "Avatar": "",
//   "Attributes": "",
//   "ReplenishedAt": "2021-05-04T00:00:00",
//   "Amount": null,
//   "Weight": null,
//   "Block": null,
//   "Product": null
// }

// {
//   "Id": 4192,
//   "BlockId": 85,
//   "ProductId": 25,
//   "Code": "A2-19--R2L16",
//   "Enabled": false,
//   "IsSoldOut": false,
//   "Title": "Rack 2",
//   "Description": "",
//   "Avatar": "",
//   "Attributes": "",
//   "ReplenishedAt": "2021-02-12T00:00:00",
//   "Amount": null,
//   "Weight": null,
//   "Block": null,
//   "Product": null
// }