import React, { useState } from 'react';
import useAuthState from '../hooks/useAuthState';
import Logo from '../images/transparent128x128.png';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Auth } from "aws-amplify";
import { makeStyles } from '@material-ui/core/styles';
import FScreen from '../components/Screen';
import useUIState from '../hooks/useUIstate';
import { MdArrowBack } from "react-icons/md";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 375,
    },
    '& label': {
      marginLeft: theme.spacing(1),
      marginBottom: '0',
      fontSize: '0.8rem',
      textTransform: "uppercase"
    },
    '& .MuiButtonBase-root ': {
      margin: theme.spacing(1),
      width: "auto",
      padding: "9px 14px",
      marginTop: "12px",
    }
  },
}));

function LoginScreen() {
  const classes = useStyles();
  const [LoginMode, setLoginMode] = useState("Login");
  const history = useHistory();
  const { setAuthStatus, setUser, User } = useAuthState();
  const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();
  // const { setShowLoadingModal, setToastMessage, setShowToastMessage, setShowRoleSelectModal } = useUIState();

  const [inputs, setInputs] = useState({
    username: '',
    password: '',
    newPassword: '',
    verificationCode: ''
  });
  // const [submitted, setSubmitted] = useState(false);
  const {
    username,
    password, // more than 7 char, with lowercase, uppercase and numeric
    newPassword,
    verificationCode
  } = inputs;

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setShowLoadingModal(true);

    if (LoginMode === "Login") {
      try {
        const user = await Auth.signIn(username, password);
        setUser(user);
        setShowLoadingModal(false);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setLoginMode("CompleteNewPassword");
        } else {
          // Login Successful
          setAuthStatus("loggedIn");
          localStorage.setItem("User", JSON.stringify(user));
          history.push("/retail-locations");
        }
      } catch (err) {
        console.log('error signing in', err);
        setShowLoadingModal(false);
        let errorMessage;

        if (err.code === "UserNotFoundException") {
          errorMessage = "Gebruikersnaam onbekend";
        } else if (err.code === "NotAuthorizedException") {
          errorMessage = "Onjuist wachtwoord of gebruikersnaam";
        } else {
          errorMessage = err.Message;
        }

        setToastMessage(errorMessage);
        setShowToastMessage(true);
      }
    }
    else if (LoginMode === "CompleteNewPassword") {
      const userAttributes = [];
      Auth.completeNewPassword(User, newPassword, userAttributes)
        .then((user) => {
          setAuthStatus("loggedIn");
          localStorage.setItem("User", JSON.stringify(user));
          setUser(user);
          setShowLoadingModal(false);
          history.push("/retail-locations");
        })
        .catch((err) => {
          setShowLoadingModal(false);
          let errorMessage;

          if (err.message.indexOf("not long") !== -1) {
            errorMessage = "Het wachtwoord moet minimaal 8 karakters bevatten";
          } else if (err.message.indexOf("lowercase") !== -1) {
            errorMessage =
              "Het wachtwoord moet minimaal 1 kleine letter bevatten";
          } else if (err.message.indexOf("uppercase") !== -1) {
            errorMessage = "Het wachtwoord moet minimaal 1 hoofdletter bevatten";
          } else if (err.message.indexOf("symbol") !== -1) {
            errorMessage =
              "Het wachtwoord moet minimaal 1 speciaal teken bevatten";
          } else if (err.message.indexOf("numeric") !== -1) {
            errorMessage = "Het wachtwoord moet minimaal 1 cijfer bevatten";
          } else {
            errorMessage = err.Message;
          }
          setToastMessage(errorMessage);
          setShowToastMessage(true);
        })

    }
    else if (LoginMode === "ForgotPassword") {
      Auth.forgotPassword(username)
        .then((user) => {
          // show success message
          setLoginMode("ForgotPasswordVerificationCode");
          setShowLoadingModal(false);
        })
        .catch((err) => {
          console.log(err);
          setShowLoadingModal(false);

          let errorMessage;

          if (err.message.indexOf("Username") !== -1) {
            errorMessage = "Gebruikersnaam onbekend";
          } else {
            errorMessage = err.message;
          }

          setToastMessage(errorMessage);
          setShowToastMessage(true);
        })

    }
    else if (LoginMode === "ForgotPasswordVerificationCode") {
      Auth.forgotPasswordSubmit(username, verificationCode, newPassword)
        .then((user) => {
          // show success message
          setShowLoadingModal(false);
        })
        .catch((err) => {
          console.log(err);
          setShowLoadingModal(false);

          let errorMessage;

          if (err.message.indexOf("Username/client") !== -1) {
            errorMessage = "Gebruikersnaam onbekend";
          } else if (err.message.indexOf("Invalid code") !== -1) {
            errorMessage =
              "Er is een ongeldige verificatiecode opgegeven. Probeer het opnieuw.";
          } else if (err.message.indexOf("verification code") !== -1) {
            errorMessage =
              "Er is een ongeldige verificatiecode opgegeven. Probeer het opnieuw.";
          } else if (err.message.indexOf("validation errors") !== -1) {
            errorMessage =
              "Je wachtwoord moet minimaal bestaan uit 8 karakters, waaronder 1 hoofdletter of kleine letter, 1 cijfer en 1 speciaal karakter";
          } else if (err.message.indexOf("1 validation error") !== -1) {
            errorMessage = "Het wachtwoord moet minimaal 8 karakters bevatten";
          } else if (err.message.indexOf("not long") !== -1) {
            errorMessage = "Het wachtwoord moet minimaal 8 karakters bevatten";
          } else if (err.message.indexOf("lowercase") !== -1) {
            errorMessage =
              "Het wachtwoord moet minimaal 1 kleine letter bevatten";
          } else if (err.message.indexOf("uppercase") !== -1) {
            errorMessage =
              "Het wachtwoord moet minimaal 1 hoofdletter bevatten";
          } else if (err.message.indexOf("symbol") !== -1) {
            errorMessage =
              "Het wachtwoord moet minimaal 1 speciaal teken bevatten";
          } else if (err.message.indexOf("numeric") !== -1) {
            errorMessage = "Het wachtwoord moet minimaal 1 cijfer bevatten";
          } else {
            errorMessage = err.message;
          }

          setToastType('error')
          setToastMessage(errorMessage)
          setShowToastMessage(true);

        })

    }

  }

  function signOut() {
    Auth.signOut()
      .then((data) => {
        setLoginMode("Login");
        localStorage.clear();
        setAuthStatus("noUser");
      })
      .catch((err) => console.log(err));
  }

  return (
    <FScreen>
      <div className="flex-col" style={{ alignItems: "center" }}>

        {/* NORMAL LOGIN */}
        {LoginMode === "Login" ?
          <>
            <img src={Logo} width="100" alt="logo" className="logo" />
            <h1 className="text-center">Inloggen</h1>
            <h4 style={{ marginTop: 0 }}>Voer je gebruikersnaam en wachtwoord in.</h4>
            <form
              className={classes.root}
              style={{ display: 'flex', flexDirection: "column", marginTop: "10px" }}
              onSubmit={handleSubmit}
            >
              <label htmlFor="username">Gebruikersnaam</label>
              <TextField
                id="username"
                name="username"
                type="email"
                required
                onChange={handleChange}
                variant="outlined"
                size="small"
              />
              <label htmlFor="username">Wachtwoord</label>
              <TextField
                id="password"
                name="password"
                type="password"
                required
                onChange={handleChange}
                variant="outlined"
                size="small"
              />
              <Button type="submit" variant="contained" color="primary">INLOGGEN</Button>
              <div
                className="mt-1 pointer text-center"
                onClick={() => setLoginMode("ForgotPassword")}>Wachtwoord vergeten?</div>
            </form>
          </> : null}


        {/* COMPLETE NEW PASSWORD */}
        {LoginMode === "CompleteNewPassword" ?
          <>
            <img src={Logo} width="100" alt="logo" className="logo" />
            <h1 className="text-center">Wachtwoord wijzigen</h1>
            <div className="text-center">
              Kies een nieuw wachtwoord.
              <br />
              Je wachtwoord moet <b>minimaal</b> bestaan
              uit <b>8 karakters</b>,
              <br />
              waaronder <b>1 hoofdletter of kleine</b> letter, <b>1 cijfer en 1 speciaal
                karakter</b>.
            </div>
            <form
              className={classes.root}
              style={{ display: 'flex', flexDirection: "column", marginTop: "20px" }}
              onSubmit={handleSubmit}
            >
              <label htmlFor="username">Nieuw wachtwoord</label>
              <TextField
                id="newPassword"
                name="newPassword"
                type="password"
                required
                onChange={handleChange}
                variant="outlined"
                size="small"
              />
              <div className="flex-center-align jcsb">
                <Button className="pointer" onClick={() => signOut()}>
                  <MdArrowBack size="20" />
                  Terug naar inloggen
                </Button>
                <Button type="submit" variant="contained" color="primary">Wijzigen</Button>
              </div>
            </form>
          </>
          : null}

        {/* FORGOT PASSWORD */}
        {LoginMode === "ForgotPassword" ?
          <>
            <img src={Logo} width="100" alt="logo" className="logo" />
            <h1 className="text-center">Wachtwoord vergeten?</h1>
            <p className="text-center">
              Als je je wachtwoord bent kwijtgeraakt kan je <br />
              verificatiecode aanvragen waarmee je het wachtwoord kan resetten.
            </p>
            <p className="text-center">
              Controleer je spam-folder mocht je geen e-mail ontvangen.
            </p>
            <form
              className={classes.root}
              style={{ display: 'flex', flexDirection: "column", marginTop: "20px" }}
              onSubmit={handleSubmit}
            >
              <label htmlFor="username">Email</label>
              <TextField
                id="username"
                name="username"
                type="email"
                required
                onChange={handleChange}
                variant="outlined"
                size="small"
              />
              <div className="flex-center-align jcsb">
                <Button className="pointer" onClick={() => { setLoginMode("Login") }}>
                  <MdArrowBack size="20" />
                  Terug naar inloggen
                </Button>
                <Button type="submit" variant="contained" color="primary">Versturen</Button>
              </div>
              {/* <Link className="link text-center" to="/forgot-password">Wachtwoord vergeten?</Link> */}
            </form>
          </>
          : null}

        {/* FORGOT PASSWORD /w VERIFICATION CODE */}
        {LoginMode === "ForgotPasswordVerificationCode" ?
          <>
            <img src={Logo} width="100" alt="logo" className="logo" />
            <h1 className="text-center">Wachtwoord vergeten?</h1>
            <p className="text-center">
              Voer je emailadres in, de verificatiecode die je hebt<br />
              ontvangen, en kies een nieuw wachtwoord.
            </p>
            <div className="text-center">
              Kies een nieuw wachtwoord.
              <br />
              Je wachtwoord moet <b>minimaal</b> bestaan
              uit <b>8 karakters</b>,
              <br />
              waaronder <b>1 hoofdletter of kleine</b> letter, <b>1 cijfer en 1 speciaal
                karakter</b>.
            </div>
            <form
              className={classes.root}
              style={{ display: 'flex', flexDirection: "column", marginTop: "20px" }}
              onSubmit={handleSubmit}
            >
              <label htmlFor="username">Email</label>
              <TextField
                id="username"
                name="username"
                type="email"
                required
                onChange={handleChange}
                variant="outlined"
                size="small"
              />
              <label htmlFor="username">Verificatiecode</label>
              <TextField
                id="verificationCode"
                name="verificationCode"
                type="password"
                required
                onChange={handleChange}
                variant="outlined"
                size="small"
              />
              <label htmlFor="username">Nieuw wachtwoord</label>
              <TextField
                id="newPassword"
                name="newPassword"
                type="password"
                required
                onChange={handleChange}
                variant="outlined"
                size="small"
              />
              <div className="flex-center-align jcsb">
                <Button className="pointer" onClick={() => { setLoginMode("Login") }}>
                  <MdArrowBack size="20" />
                  Terug naar inloggen
                </Button>
                <Button type="submit" variant="contained" color="primary">Wijzigen</Button>
              </div>
            </form>
          </>
          : null}

      </div>
    </FScreen>
  );
}

export default LoginScreen;