import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { API, Storage } from 'aws-amplify';
import useUIState from '../../hooks/useUIstate';
import ProductsPerTransactionChart from './ProductsPerTransactionChart';
import TransactionsPerOfferChart from './TransactionsPerOfferChart';
import RevenueChart from './RevenueChart';
import TransactionsPerProductChart from './TransactionsPerProductChart';
import ProductsList from './ProductsList';
import TransactionList from './TransactionList';

const useStyles = makeStyles(theme => ({

  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    height: "100%",
    paddingLeft: "10px",
    overflowX: "auto",

    '& .filters-range>*': {
      margin: "0 5px"
    },
    '& .range-label': {
      cursor: "pointer"
    },
    '& .range-label.active': {
      color: "#1976d2"
    },
    '& .chart': {
      border: "2px solid #ccc",
      borderRadius: "4px",
      margin: "10px",
      padding: "10px",
      width: "600px",
      paddingBottom: "20px",
      position: "relative",
    },
    '& .chart-big': {
      width: "1220px",
      paddingBottom: "30px",
    }
  },
}));

function Dashboard(props) {

  const classes = useStyles();
  const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();
  const [Products, setProducts]: [any, any] = useState([]);
  const [Offers, setOffers]: [any, any] = useState([]);
  const [TransactionData, setTransactionData]: [any, any] = useState([])

  useEffect(() => {
    localStorage.setItem("ActiveRoute", "/dashboard");
    getProducts();

    // eslint-disable-next-line
  }, [])

  function getProducts() {
    setShowLoadingModal(true);
    API.get("AE", `/Products`, { response: true })
      .then(res => {
        setProducts(res.data);
        getOffers();
      })
      .catch(error => {
        console.log(error);
        setToastType("error");
        setToastMessage("Error getting products");
        setShowToastMessage(true);
      })
      .finally(() => {
        setShowLoadingModal(false)
      })
  }

  function getOffers() {
    setShowLoadingModal(true);
    API.get("AE", `/Offers`, { response: true })
      .then(res => {
        setOffers(res.data);
        getTransactionData();
      })
      .catch(error => {
        console.log(error);
        setToastType("error");
        setToastMessage("Error getting offers");
        setShowToastMessage(true);
      })
      .finally(() => {
        setShowLoadingModal(false)
      })
  }

  function getTransactionData() {
    setShowLoadingModal(true);

    // for testing only!
    // fetch('data.json',
    //   {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Accept': 'application/json'
    //     }
    //   })
    //   .then(res => {
    //     return res.json()
    //   })
    //   .then(resjson => {
    //     console.log(resjson);
    //     setTransactionData(resjson);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //     setToastType("error");
    //     setToastMessage("Error getting transactions");
    //     setShowToastMessage(true);
    //   })
    //   .finally(() => {
    //     setShowLoadingModal(false)
    //   })

    API.get("AE", `/ShoppingBasket?storeId=${props.location.storeId}`, { response: true })
      .then(res => {
        console.log(res.data);
        setTransactionData(res.data);
      })
      .catch(error => {
        console.log(error);
        setToastType("error");
        setToastMessage("Error getting transactions");
        setShowToastMessage(true);
      })
      .finally(() => {
        setShowLoadingModal(false)
      })
  }

  return (
    <div className={classes.root}>
      <h1>Dashboard (Winkel : {props.location.title})</h1>

      <div className="flex">
        {Products.length && TransactionData.length ?
          <div className="chart">
            <RevenueChart products={Products} transactionData={TransactionData} />
          </div>
          : null}

        {Products.length && TransactionData.length ?
          <div className="chart">
            <ProductsPerTransactionChart products={Products} transactionData={TransactionData} />
          </div>
          : null}
      </div>

      <div className="flex">
        {Products.length && TransactionData.length ?
          <div className="chart chart-big">
            <TransactionList products={Products} transactionData={TransactionData} />
          </div>
          : null}
      </div>

      <div className="flex">
        {Products.length && TransactionData.length ?
          <div className="chart chart-big">
            <ProductsList products={Products} transactionData={TransactionData} />
          </div>
          : null}
      </div>

      <div className="flex-col">

        {Products.length && TransactionData.length ?
          <div className="chart chart-big">
            <TransactionsPerProductChart products={Products} transactionData={TransactionData} />
          </div>
          : null}

        {Products.length && TransactionData.length && Offers.length ?
          <div className="chart chart-big">
            <TransactionsPerOfferChart offers={Offers} transactionData={TransactionData} />
          </div>
          :
          <div>Loading...</div>
        }
      </div>



    </div>
  )
}

export default Dashboard;
