import { Storage } from 'aws-amplify';

export async function s3put(path: string, file: Record<string, any>) {
  try {
    const res: any = await Storage.put(`${path}`, file);
    return res.key;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function s3get(filename) {
  try {
    const res = await Storage.get(`${filename}`);
    return res;
  } catch (err) {
    console.log(err);
    return "";
  }
}

export async function s3getObjUrl(path) {
  return new Promise((resolve) => {
    // try {
    //   const blob = await Storage.get(`${path}`, { download: true })

    //   resolve (URL.createObjectURL(blob));
    // } catch (err) {
    //   console.log(err);
    //   return null;
    // }

    Storage.get(path, { download: true })
      .then((res: any) => {
        const url = URL.createObjectURL(res.Body);
        resolve(url)
      })
  })
}

export async function s3remove(path) {
  try {
    const res = await Storage.remove(`${path}`)
    return res;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export function getInt(inpValue, minValue, defaultValue) {
  var result = parseInt(inpValue);

  if (result === undefined || isNaN(result) || !Number.isInteger(result) || result < minValue) {
    result = defaultValue;
  }
  
  return result;
}

export function getZeroOnEmpty(inpValue) {
  var result = inpValue;

  if (result === '') {
    result = 0;
  }

  return result;
}

export function sum(items, prop) {
  return items.reduce(function (a, b) {
    return a + b[prop];
  }, 0);
};

export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-') + "T00:00:00";
}

export function formatExcelDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [day, month, year].join('-') + "T00:00:00";
}

export function formatDisplayDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [day, month, year].join('/');
}

export function formatDateAndTIme(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    hours = '' + d.getHours(),
    minutes = '' + d.getMinutes(),
    seconds = '' + d.getSeconds(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;
  if (hours.length < 2)
    hours = '0' + hours;
  if (minutes.length < 2)
    minutes = '0' + minutes;
  if (seconds.length < 2)
    seconds = '0' + seconds;

  return [year, month, day].join('-') + `T${hours}:${minutes}:${seconds}`;
}

export function formatDateAndTimeDashboard(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    hours = '' + d.getHours(),
    minutes = '' + d.getMinutes(),
    seconds = '' + d.getSeconds(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;
  if (hours.length < 2)
    hours = '0' + hours;
  if (minutes.length < 2)
    minutes = '0' + minutes;
  if (seconds.length < 2)
    seconds = '0' + seconds;

  return [day, month, year].join('-') + ` ` + `${hours}:${minutes}:${seconds}`;
}

export function IsInDateRange(dateUntil, dateFrom, transactionDate) {
  const date = new Date(transactionDate);
  const timeStamp = date.getTime();

  return dateUntil >= timeStamp && timeStamp >= dateFrom;
}

export function IsInYearRange(currTimeStamp, timeStampLastYear, transactionDate) {
  const date = new Date(transactionDate);
  const timeStamp = date.getTime();
  return currTimeStamp >= timeStamp && timeStamp >= timeStampLastYear;
}

export function isToday(timestamp) {
  return formatDate(new Date()).split("T")[0] === timestamp.split("T")[0];
}

export function isSameMonth(timestamp) {
  return formatDate(new Date()).split("T")[0].split("-")[1] === timestamp.split("T")[0].split("-")[1] &&
    formatDate(new Date()).split("T")[0].split("-")[1] === timestamp.split("T")[0].split("-")[1];
}

export function convertToSlashDate(date) {
  let displayDate = date;
  displayDate = displayDate.split("-");
  displayDate = `${displayDate[2]}/${displayDate[1]}/${displayDate[0].slice(-2)}`;
  return displayDate;
}

export function datediff(first, second) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

export function compare(a, b) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

export function getDetailedDate(date) {
  const d = new Date(date);
  let day = new Date(date).toLocaleString('nl-NL', { weekday: 'short' });
  day = day.charAt(0).toUpperCase() + day.slice(1);

  let month = '' + (d.getMonth() + 1);
  if (month.length < 2) { month = '0' + month; }

  let year = '' + d.getFullYear();
  year = year.slice(-2);

  return `${day} ${month}/${year}`
}