import React, { useEffect, useState } from 'react'
import TabletLayout from '../layouts/TabletLayout'

function PrivateRoutesWrapper() {

  const [Layout, setLayout] = useState("")

  useEffect(() => {
    setLayout("Tablet")
  }, [])

  return (
    <div id="privateRoutesWrapper">
      { Layout === "Tablet" && <TabletLayout />}
    </div>
  )
}

export default PrivateRoutesWrapper
