import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react'
import StoreIcon from '../../images/store-icon.jpg';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { MdMoreVert, MdAdd } from 'react-icons/md';
import Popover from '@material-ui/core/Popover';
import yaml from 'js-yaml';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import useUIState from '../../hooks/useUIstate';
import useDataState from '../../hooks/useDataState';
import useAuthState from '../../hooks/useAuthState';
import { Feature } from 'flagged';

const useStyles = makeStyles(theme => ({

  root: {
    '& .paper': {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: "5px",
      borderRadius: "4px",
      outline: "none"
    },
    '& .paperInner': {
      display: "flex",
      alignItems: "center",

      '&>*': {
        margin: '7px'
      }
    },
    '& #storesContainer': {
      width: "100%",
      flexWrap: "wrap",
    },
    '& .store-block': {
      padding: '30px',
      alignItems: "center",
      width: "275px",
      height: "370px",
      position: "relative",
      marginRight: "30px",
      marginTop: "15px",
      borderRadius: "8px"
    },
    '& .store-block-add': {
      padding: '30px',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "275px",
      height: "370px",
      backgroundColor: "#eee",
      marginRight: "30px",
      marginTop: "15px",
      borderRadius: "8px"
    },
    '& .store-img': {
      width: "75px",
      height: "75px",
      borderRadius: "50%",
      marginBottom: "5px"
    },
    '& .store-block-actions>*': {
      marginTop: "10px"
    },
    '& .store-menu-btn': {
      position: "absolute",
      right: "10px",
      top: "10px",
      cursor: "pointer"
    },
    '& svg': {
      fill: "#777"
    }
  },
}));

function StoreScreen() {

  const { setStoreLayoutContext, setJofemarContext, setLockersDPlanContext, setStoreContext, setJofemarDPlanContext, setProductsContext } = useDataState();
  const { setShowLoadingModal, setToastMessage, setShowToastMessage, setToastType } = useUIState();
  const history = useHistory();
  const { User } = useAuthState();

  const classes = useStyles();
  const [Stores, setStores]: [any, any] = useState([])
  const [Products, setProducts]: [any, any] = useState([])
  const [ShowModal, setShowModal] = useState(false);
  const [UserGroup, setUserGroup] = useState("");
  const [AssignedStores, setAssignedStores]: [any, any] = useState([]);
  const [UnfilteredStores, setUnfilteredStores]: [any, any] = useState([]);
  const [anchorElStoreMenu, setAnchorElStoreMenu] = React.useState(null);
  const [ActiveStoreId, setActiveStoreId] = useState(0)
  const [ActiveStore, setActiveStore]: any = useState({})

  const handleStoreMenuClick = (event, store, Id) => {
    console.log(store);
    setAnchorElStoreMenu(event.currentTarget);
    setActiveStore(store)
    console.log(ActiveStoreId);
    setActiveStoreId(Id)
  };
  const handleClose = () => {
    setAnchorElStoreMenu(null);
  };
  const open = Boolean(anchorElStoreMenu);
  const id = open ? 'account-menu-popover' : undefined;

  useEffect(() => {

    localStorage.removeItem("ActiveStore");
    localStorage.removeItem("StoreLayout");
    localStorage.removeItem("LockersDistPlan");
    localStorage.removeItem("Jofemar");
    localStorage.removeItem("Jofemars");
    localStorage.removeItem("IsDirtyCurrentJofemarContents");
    localStorage.removeItem("ActiveJofemarId"); 
    localStorage.removeItem("JofemarDistPlan");
    localStorage.removeItem("CurrentFillingPlan");
    localStorage.removeItem("CurrentJofemarContents")
    localStorage.setItem("activeRoute", "/retail-locations");

    setStoreContext(null);
    setStoreLayoutContext([]);
    setJofemarContext(null);
    setLockersDPlanContext([]);
    setJofemarDPlanContext([]);

    getProducts();

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (User.attributes["custom:role"]) {
      setUserGroup(User.attributes["custom:role"])

      if (User.attributes["custom:storeId"]) {
        let assignedStores = User.attributes["custom:storeId"].split(",");
        console.log(assignedStores)
        setAssignedStores(assignedStores);
      } else {
        setToastType("error");
        setToastMessage("User is not assigned to any stores");
        setShowToastMessage(true);
        setShowLoadingModal(false);
      }
    }
    // eslint-disable-next-line
  }, [User])

  useEffect(() => {
    if (AssignedStores.length) {
      getStores();
    }
    // eslint-disable-next-line
  }, [AssignedStores])

  useEffect(() => {
    if (UnfilteredStores.length) {
      const filteredStores = UnfilteredStores.filter(store => AssignedStores.includes(`${store.Id}`));
      console.log(filteredStores);
      setStores(filteredStores);
    }
    // eslint-disable-next-line
  }, [UnfilteredStores])


  function getProducts() {
    setShowLoadingModal(true);
    API.get("AE", `/Products`, { response: true })
      .then(res => {
        setProducts(res.data);
        setProductsContext(res.data);
        localStorage.setItem("Products", JSON.stringify(res.data));
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setShowLoadingModal(false);
      })
  }

  async function readYaml(file) {
    try {
      const doc = yaml.load(file);
      console.log(doc)
      yamlToStoreImport(doc);
    } catch (e) {
      console.log(e);
    }
  }

  function yamlToStoreImport(json) {
    console.log(json);
    const yamlBlocks: any = json.location.locationConfiguration.blocks;
    const yamlLocker: any = json.location.locationConfiguration.racks;

    const blocks: any = [];
    let orderIndex = 0;
    yamlBlocks.forEach(block => {

      for (let i = 0; i < block.amount; i++) {
        blocks.push({
          Name: block.name,
          Amount: block.amount,
          LogicalName: yamlLocker[orderIndex].logicalName,
          Attributes: "",
          CUnr: yamlLocker[orderIndex]["CU-nr"]
        })
        orderIndex++;
      }

    });

    const lockers: any[] = [];
    blocks.forEach((block, index) => {
      let lockerAmount;

      if (block.Name === 'A2' || block.Name === 'B2') { lockerAmount = 16; }
      else if (block.Name === 'A1' || block.Name === 'C1') { lockerAmount = 8; }
      else if (block.Name === 'D2') { lockerAmount = 12; }
      else { lockerAmount = 1; }

      for (let i = 0; i < lockerAmount; i++) {

        lockers.push({
          Rack: 0,
          LogicalName: yamlLocker[index].logicalName,
          ModelType: block.Name,
          ModelVersion: "",
          TCPIP: yamlLocker[index].tcpip,
          CUnr: block.CUnr
        })
      }
    });

    const filteredLockers = lockers.filter(locker => locker.ModelType !== 'Vision v2');
    const yamlLocDetails = json.location.locationDetails;

    const LocationDetails = {
      "Address": yamlLocDetails.address,
      "Info": yamlLocDetails.info,
      "Zip": yamlLocDetails.zip,
      "City": yamlLocDetails.city,
      "Admin": yamlLocDetails.admin,
      "GroupId": yamlLocDetails.groupId
    }

    const storeImportBody = {
      "LocationId": json.location.locationId,
      "StoreName": json.location.name,
      "Blocks": blocks,
      "Lockers": filteredLockers,
      "LocationDetails": LocationDetails
    }
    setShowModal(false);
    console.log(storeImportBody);
    importStore(storeImportBody);
  }

  function importStore(reqBody) {
    setShowLoadingModal(true);
    let body = reqBody;
    API.post("AE", `/Stores/Import`, { body })
      .then(res => {
        getStores();
      })
      .catch(error => {
        console.log(error);
        setToastType("error");
        setToastMessage("Error importing store data");
        setShowToastMessage(true);
        setShowLoadingModal(false);
      })
  }

  const readFile = async (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      if (e.target) {
        const text = (e.target.result)
        readYaml(text);
      }
    };
    reader.readAsText(e.target.files[0])
  }

  function getStores() {
    setShowLoadingModal(true);

    API.get("AE", `/Stores`, { response: true })
      .then(res => {
        // filter by assigned user stores
        setUnfilteredStores(res.data)
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setShowLoadingModal(false);
      })
  }

  function goToReplenishmentPlan(Id, Code, store) {
    history.push({ pathname: '/replenishment-plan', storeId: Id, title: Code, store: store });
  }

  function goToStoreReplenishment(Id, Code, store) {
    history.push({ pathname: '/store-replenishment', storeId: Id, title: Code, store: store });
  }

  function goToStoreSupport() {
    console.log(ActiveStore)
    if (ActiveStore) {
      history.push({ pathname: '/store-support', storeId: ActiveStore.Id, title: ActiveStore.Code, store: ActiveStore });
    }
  }

  function goToStoreDashboard() {
    if (ActiveStore) {
      history.push({ pathname: '/dashboard', storeId: ActiveStore.Id, title: ActiveStore.Code, store: ActiveStore });
    }
  }

  function goToStoreOverview(Id, Code, store) {
    history.push({ pathname: '/store-overview', storeId: Id, title: Code, store: store });
  }

  function goToDistributionPlan(store) {
    setShowLoadingModal(true);
    API.get("AE", `/Products`, { response: true })
      .then(res => {
        console.log(res.data);

        console.log(store)

        history.push({ pathname: '/distributieplan', store: store, products: res.data, storeId: store.Id, storeTitle: store.Title, lockersDistributionPlanCG: store.LockersDistributionPlan, jofemarDistributionPlanCG: store.JofemarDistributionPlan });
      })
      .catch(error => {
        console.log(error);
        setToastType("error");
        setToastMessage("Error getting distribution plan");
        setShowToastMessage(true);
      })
      .finally(() => {
        setShowLoadingModal(false);
      })
  }

  return (
    <div id="storesScreen" className={classes.root}>
      <h1>Winkels</h1>
      <div id="storesContainer" className="flex">
        {Stores.map(store => {


          return (
            <div key={store.Id} className="store-block flex-col shadow">
              <img className="store-img" src={StoreIcon} alt="store-img" />
              <h2 className="mt0">{store.Code}</h2>
              <div className="store-block-actions flex-col">
                <Button onClick={() => goToStoreOverview(store.Id, store.Code, store)} variant="contained">Winkel Bekijken</Button>
                <Feature name="replenishplanning">
                  {UserGroup !== "replenishmentuser" ?
                  <Button onClick={() => goToReplenishmentPlan(store.Id, store.Code, store)} variant="contained">Vulplan Maken</Button>
                  : null
                }
                </Feature>
                <Button onClick={() => goToStoreReplenishment(store.Id, store.Code, store)} variant="contained">Winkel vullen</Button>
                <Feature name="replenishplanning">
                  <Button onClick={() => goToDistributionPlan(store)} variant="contained" disabled={Products.length === 0}>Distributieplan</Button>
                </Feature>
              </div>

              <div className="flex-center-align store-menu-btn">
                <div id={`menuStore${store.Id}`} onClick={e => handleStoreMenuClick(e, store, store.Id)}>
                  <MdMoreVert size="2.2em" />
                </div>
                <Popover id={id} open={open} anchorEl={anchorElStoreMenu} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} >
                  <div className="flex-col store-popover">
                    <Button id={`Support${store.Id}`} onClick={() => goToStoreSupport()}>Winkel support</Button>
                    {UserGroup !== "replenishmentuser" ?
                      <Button id={`Dashboard${store.Id}`} onClick={() => goToStoreDashboard()}>Winkel dashboard</Button>
                      : null
                    }
                    {UserGroup === "superadmin" || UserGroup === "administrator" ?
                      <Button onClick={() => alert("Under development")}>Winkel configuratie</Button>
                      : null
                    }
                  </div>
                </Popover>
              </div>
            </div>

          )
        })}

        {UserGroup !== "replenishmentuser" ?
          <div className="store-block-add shadow pointer" onClick={() => setShowModal(true)}>
            <MdAdd size="2.2em" />
          </div> : null}

      </div>
      {
        ShowModal ?
          <Dialog onClose={() => setShowModal(false)} aria-labelledby="simple-dialog-title" open={true}>
            <DialogTitle id="simple-dialog-title">Upload store config file</DialogTitle>
            <div style={{ padding: "20px" }}>
              <input type="file" accept=".yaml,.yml" onChange={readFile} />
            </div>
          </Dialog>
          : null
      }

    </div>
  )
}

export default StoreScreen

// store import body example
// const json = {
//   "LocationId": 4,
//   "StoreName": "Arnhem_1",
//   "WANIP": "44.61.86.251",
//   "Blocks": [
//     { "Name": "A2", "Amount": 9, "CUnr": 0, "Attributes": "" },
//     { "Name": "A2", "Amount": 9, "CUnr": 1, "Attributes": "" },
//     { "Name": "A2", "Amount": 9, "CUnr": 2, "Attributes": "" },
//     { "Name": "A2", "Amount": 9, "CUnr": 3, "Attributes": "" },
//     { "Name": "A2", "Amount": 9, "CUnr": 4, "Attributes": "" },
//     { "Name": "A2", "Amount": 9, "CUnr": 5, "Attributes": "" },
//     { "Name": "A2", "Amount": 9, "CUnr": 6, "Attributes": "" },
//     { "Name": "A2", "Amount": 9, "CUnr": 7, "Attributes": "" },
//     { "Name": "A2", "Amount": 9, "CUnr": 8, "Attributes": "" },
//     { "Name": "C1", "Amount": 1, "CUnr": 9, "Attributes": "" },
//     {
//       "Name": "Vision v2", "Amount": 1, "CUnr": null,
//       "Attributes": "|{'nrColumns':6,'nrDepth':8,'Columns':[{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null}]}|{'nrColumns':6,'nrDepth':8,'Columns':[{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null}]}|{'nrColumns':6,'nrDepth':8,'Columns':[{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null}]}|{'nrColumns':4,'nrDepth':8,'Columns':[{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null}]}|{'nrColumns':4,'nrDepth':8,'Columns':[{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null},{'ProductId':null,'CurrentStock':0,'LatestRefill':null}]}"
//     },
//     { "Name": "D2", "Amount": 1, "CUnr": 11, "Attributes": "" },
//     { "Name": "B2", "Amount": 6, "CUnr": 12, "Attributes": "" },
//     { "Name": "B2", "Amount": 6, "CUnr": 13, "Attributes": "" },
//     { "Name": "B2", "Amount": 6, "CUnr": 14, "Attributes": "" },
//     { "Name": "B2", "Amount": 6, "CUnr": 15, "Attributes": "" },
//     { "Name": "B2", "Amount": 6, "CUnr": 16, "Attributes": "" },
//     { "Name": "B2", "Amount": 6, "CUnr": 17, "Attributes": "" },
//   ],
//   "Lockers": [
//     {
//       "Rack": 0,
//       "LogicalName": "Rack 1",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 0
//     },
//     {
//       "Rack": 1,
//       "LogicalName": "Rack 2",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 1
//     },
//     {
//       "Rack": 2,
//       "LogicalName": "Rack 3",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 2
//     },
//     {
//       "Rack": 3,
//       "LogicalName": "Rack 4",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 3
//     },
//     {
//       "Rack": 4,
//       "LogicalName": "Rack 5",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 4
//     },
//     {
//       "Rack": 5,
//       "LogicalName": "Rack 6",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 5
//     },
//     {
//       "Rack": 6,
//       "LogicalName": "Rack 7",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 6
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 8",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 0,
//       "LogicalName": "Rack 1",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 0
//     },
//     {
//       "Rack": 1,
//       "LogicalName": "Rack 2",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 1
//     },
//     {
//       "Rack": 2,
//       "LogicalName": "Rack 3",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 2
//     },
//     {
//       "Rack": 3,
//       "LogicalName": "Rack 4",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 3
//     },
//     {
//       "Rack": 4,
//       "LogicalName": "Rack 5",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 4
//     },
//     {
//       "Rack": 5,
//       "LogicalName": "Rack 6",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 5
//     },
//     {
//       "Rack": 6,
//       "LogicalName": "Rack 7",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 6
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 8",
//       "ModelType": "A2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "C1",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "C1",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "C1",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "C1",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "C1",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "C1",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "C1",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "C1",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "D2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "D2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "D2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "D2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "D2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "D2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "D2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "D2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "D2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "D2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "D2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 10",
//       "ModelType": "D2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 0,
//       "LogicalName": "Rack 1",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 0
//     },
//     {
//       "Rack": 1,
//       "LogicalName": "Rack 2",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 1
//     },
//     {
//       "Rack": 2,
//       "LogicalName": "Rack 3",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 2
//     },
//     {
//       "Rack": 3,
//       "LogicalName": "Rack 4",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 3
//     },
//     {
//       "Rack": 4,
//       "LogicalName": "Rack 5",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 4
//     },
//     {
//       "Rack": 5,
//       "LogicalName": "Rack 6",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 5
//     },
//     {
//       "Rack": 6,
//       "LogicalName": "Rack 7",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 6
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 8",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },
//     {
//       "Rack": 0,
//       "LogicalName": "Rack 1",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 0
//     },
//     {
//       "Rack": 1,
//       "LogicalName": "Rack 2",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 1
//     },
//     {
//       "Rack": 2,
//       "LogicalName": "Rack 3",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 2
//     },
//     {
//       "Rack": 3,
//       "LogicalName": "Rack 4",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 3
//     },
//     {
//       "Rack": 4,
//       "LogicalName": "Rack 5",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 4
//     },
//     {
//       "Rack": 5,
//       "LogicalName": "Rack 6",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 5
//     },
//     {
//       "Rack": 6,
//       "LogicalName": "Rack 7",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 6
//     },
//     {
//       "Rack": 7,
//       "LogicalName": "Rack 8",
//       "ModelType": "B2",
//       "ModelVersion": "",
//       "TCPIP": "192.168.1.110",
//       "CUnr": 7
//     },





//   ],
//   "LocationDetails": {
//     "Address": "Groenestraat 261",
//     "Info": "Naast de Albert Heijn",
//     "Zip": "1111AB",
//     "City": "Arnhem",
//     "Admin": "lotte.mulders@di-vision.nl",
//     "GroupId": "AECorp"
//   }
// }